import React, { useEffect, useState } from 'react';
import { BasicTable } from './basicTable';
import AxiosInstance from '../../services/api';
import { GET_ALL_RMS } from '../../services/routes/rms';

const RmsTable = () => {
  const [rms, setRms] = useState([]);
  const [arermsLoading, setRmsLoading] = useState(false);

  const getAllRms = async () => {
    try {
      setRmsLoading(true);
      const rmsData = await AxiosInstance.get(GET_ALL_RMS);
      setRms(rmsData.data.rms);
      setRmsLoading(false);
    } catch (e) {
      setRmsLoading(false);
    }
  };

  useEffect(() => {
    getAllRms();
  }, []);

  return <BasicTable data={rms} arermssLoading={arermsLoading} />;
};

export default RmsTable;
