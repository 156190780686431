/* eslint-disable react/prop-types */

import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Cell,
  ResponsiveContainer,
} from 'recharts';
import { colorMapping } from '../helper/constant';

const ClosedStatusCountClient = ({ data }) => (
  <div className="d-flex flex-column">
    {Object.entries(data).map(([department, departmentData]) => {
      const chartData = Object.entries(departmentData).map(
        ([name, { prf, openings }]) => ({
          name,
          prf,
          openings,
        }),
      );
      const total = chartData.reduce((sum, item) => sum + item.PRFs, 0);

      if (!Object.keys(departmentData)?.length)
        return (
          <div className="prfDepartmentData">
            <h2>Number Of PRFs Closed</h2>
            <div className="noDataFound">
              <div className="d-flex flex-column text-center">
                <p className="mb-0">No Data Available</p>
              </div>
            </div>
          </div>
        );

      return (
        <div key={department} className="prfAccountData">
          <div className="d-flex flex-column">
            <h2>Number Of PRFs Closed</h2>
          </div>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={500}
              height={326}
              data={chartData}
              barSize={20}
              margin={{ top: 20, right: 10, bottom: 60, left: -20 }}
            >
              <XAxis
                dataKey="name"
                angle={-45}
                textAnchor="end"
                interval={0}
                height={60}
              />
              <YAxis domain={[0, total]} />
              <Tooltip />
              <Bar dataKey="prf" fill="rgb(112, 129, 185)" name="PRFs">
                {chartData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={colorMapping[entry.name] || '#7081B9'}
                  />
                ))}
              </Bar>
              <Bar dataKey="openings" fill="rgb(112, 129, 185)" name="Openings">
                {chartData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={colorMapping[entry.name] || '#7081B9'}
                  />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      );
    })}
  </div>
);

export default ClosedStatusCountClient;
