import React, { useState, useContext } from 'react';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import { Formik, Form as FormikForm } from 'formik';
import * as Yup from 'yup';
import Modal from 'react-bootstrap/Modal';
import { Container, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { propTypes } from 'react-bootstrap/esm/Image';
import AxiosInstance from '../../services/api';
import Field from '../form/field';
import { POST_PRF_CLIENTS } from '../../services/routes/prf';
import { AppContext } from '../../context/appContext';
import { GET_USER_ROLES } from '../helper/constant';

const AddAClient = ({ buttonID, getAllUsers }) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { getRole } = useContext(AppContext);

  const addUClientHandler = async (values) => {
    // eslint-disable-next-line no-param-reassign

    const toastId = toast.loading('updated Client');
    try {
      // eslint-disable-next-line no-unsafe-optional-chaining
      await AxiosInstance.post(POST_PRF_CLIENTS, values);
      handleClose();
      if (getRole() === GET_USER_ROLES.ADMIN) {
        navigate('/');
      } else {
        navigate('/admin');
      }
      toast.update(toastId, {
        render: 'Client Create successfully',
        type: 'success',
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: false,
      });
      getAllUsers();
    } catch (e) {
      toast.update(toastId, {
        render: 'Error Create User :(',
        type: 'error',
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: false,
      });
    }
  };
  return (
    <>
      <Button
        id={buttonID}
        onClick={handleShow}
        className="btn btn-primary btn-sm d-flex align-items-center justify-content-center btn-createPrf mx-4"
      >
        <span className="me-2">
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.1429 6.85714H6.85714V11.1429C6.85714 11.6143 6.47143 12 6 12C5.52857 12 5.14286 11.6143 5.14286 11.1429V6.85714H0.857143C0.385714 6.85714 0 6.47143 0 6C0 5.52857 0.385714 5.14286 0.857143 5.14286H5.14286V0.857143C5.14286 0.385714 5.52857 0 6 0C6.47143 0 6.85714 0.385714 6.85714 0.857143V5.14286H11.1429C11.6143 5.14286 12 5.52857 12 6C12 6.47143 11.6143 6.85714 11.1429 6.85714Z"
              fill="white"
            />
          </svg>
        </span>
        <span>Add Client</span>
      </Button>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            <span className="me-2">
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.1429 6.85714H6.85714V11.1429C6.85714 11.6143 6.47143 12 6 12C5.52857 12 5.14286 11.6143 5.14286 11.1429V6.85714H0.857143C0.385714 6.85714 0 6.47143 0 6C0 5.52857 0.385714 5.14286 0.857143 5.14286H5.14286V0.857143C5.14286 0.385714 5.52857 0 6 0C6.47143 0 6.85714 0.385714 6.85714 0.857143V5.14286H11.1429C11.6143 5.14286 12 5.52857 12 6C12 6.47143 11.6143 6.85714 11.1429 6.85714Z"
                  fill="white"
                />
              </svg>
            </span>
            <span>Add Client</span>
          </Modal.Title>
        </Modal.Header>

        <main className={`flex-grow-1 d-flex`}>
          <Container fluid className="d-flex flex-column p-0">
            <Formik
              initialValues={{
                client: '',
                location: '',
                industry: '',
              }}
              validationSchema={Yup.object().shape({
                client: Yup.string().required('Client name is required'),
                location: Yup.string().required('Location name is required'),
                industry: Yup.string().required('Industry is required'),
              })}
              onSubmit={(values) => {
                addUClientHandler(values);
              }}
            >
              {({ handleSubmit, values, handleChange, handleBlur }) => (
                <div>
                  <Modal.Body>
                    <FormikForm onSubmit={handleSubmit}>
                      <div className="userDetail rounded border pt-4 px-4 pb-4">
                        <Row>
                          <Col sm={10}>
                            <Row>
                              <Col md={4}>
                                <div className="mb-3 pb-3 d-flex flex-column">
                                  <Field
                                    onChange={handleChange}
                                    placeholder="Enter Title"
                                    className="form-control"
                                    onBlur={handleBlur}
                                    value={values.client}
                                    label="Client"
                                    name={`client`}
                                    type="text"
                                    Asterisk="*"
                                  />
                                </div>
                              </Col>
                              <Col md={4}>
                                <div className="mb-3 pb-3 d-flex flex-column">
                                  <Field
                                    onChange={handleChange}
                                    placeholder="Enter location"
                                    className="form-control"
                                    onBlur={handleBlur}
                                    value={values.location}
                                    label="Location"
                                    name={`location`}
                                    type="text"
                                    Asterisk="*"
                                  />
                                </div>
                              </Col>
                              <Col md={4}>
                                <div className="mb-3 pb-3 d-flex flex-column">
                                  <Field
                                    onChange={handleChange}
                                    placeholder="Enter industry"
                                    className="form-control"
                                    onBlur={handleBlur}
                                    value={values.industry}
                                    label="Industry"
                                    name={`industry`}
                                    type="text"
                                    Asterisk="*"
                                  />
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </FormikForm>
                  </Modal.Body>

                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={handleClose}
                      id="close-button"
                    >
                      Close
                    </Button>
                    <Button
                      //   id={secondaryButtonID}
                      onClick={handleSubmit}
                      type="submit"
                      variant="primary"
                      size="sm"
                      className="d-flex align-items-center justify-content-center btn-createPrf"
                    >
                      <span className="me-2">
                        <svg
                          width="12"
                          height="10"
                          viewBox="0 0 12 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.99993 7.79994L1.19993 4.99994L0.266602 5.93328L3.99993 9.66661L11.9999 1.66661L11.0666 0.733276L3.99993 7.79994Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                      <span>Save</span>
                    </Button>
                  </Modal.Footer>
                </div>
              )}
            </Formik>
          </Container>
        </main>
      </Modal>
    </>
  );
};

AddAClient.propTypes = {
  buttonID: propTypes.string,
  secondaryButtonID: propTypes.string,
  getAllUsers: propTypes.func,
};
export default AddAClient;
