import React, { useState } from 'react';
import { FaLongArrowAltUp, FaLongArrowAltDown } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { Table, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  getFilteredRowModel,
  getPaginationRowModel,
} from '@tanstack/react-table';
import { rankItem } from '@tanstack/match-sorter-utils';
import ReactPaginate from 'react-paginate';
import iconFilter from '../../assets/images/icon-filter.svg';
import { DebouncedInput } from './debounceInput';
import Loader from '../../assets/images/loader.gif';
import { COLUMNS } from './columns';
import { getFirstIndexPagination, getLastIndexPagination } from '../utils/util';

const fuzzyFilter = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value);
  addMeta({ itemRank });
  return itemRank.passed;
};

export const BasicTable = ({ data, arermssLoading }) => {
  const navigate = useNavigate();
  const [, setActivePageIndex] = useState(0);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const table = useReactTable({
    data,
    columns: COLUMNS,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      sorting,
      globalFilter,
      pagination,
    },
    columnFilters: [],
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    debugTable: true,
    globalFilterFn: fuzzyFilter,
  });

  const handlePageClick = (event) => {
    setPagination((prev) => ({ ...prev, pageIndex: event.selected }));
    setActivePageIndex(event.selected);
    table.setPageIndex(event.selected);
  };

  // const startFrom = pagination.pageIndex * pagination.pageSize;
  // const pageCount = Math.floor(data.length / pagination.pageSize);

  return (
    <>
      <div className={'prfListHead'}>
        <div className="prfListTitle">
          <div className="d-flex flex-row justify-content-between align-items-baseline">
            <h2>
              Dashboard <span></span> RMS
            </h2>
          </div>
        </div>

        <div className="prfTableFilters d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <span>Show</span>
            <Form.Select
              className={'mx-2'}
              aria-label="Default select example"
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40].map((pageSize) => (
                <option key={`pageSize-${pageSize}`} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </Form.Select>
            <span>entries</span>
          </div>
          <DebouncedInput
            value={globalFilter ?? ''}
            onChange={(value) => setGlobalFilter(String(value))}
          />
        </div>
      </div>
      <div className="tabelHolder profileTable">
        <Table bordered striped hover responsive className="m-0">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={`headerGroup-${headerGroup.id}`}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={`header-${header.id}`}
                    style={{
                      width: header.getSize() ? header.getSize() : undefined,
                    }}
                  >
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? 'cursor-pointer'
                            : '',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                        {{
                          asc: <FaLongArrowAltUp className="ms-2" />,
                          desc: <FaLongArrowAltDown className="ms-2" />,
                        }[header.column.getIsSorted()] ?? (
                          <img
                            className="ms-2"
                            src={iconFilter}
                            alt="filter icon"
                          />
                        )}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {data.length ? (
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr
                  key={`row-${row.original.id}`}
                  onClick={() => navigate(`/rms/${row.original.id}`)}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td key={`cell-${cell.id}`}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          ) : arermssLoading ? (
            <tbody>
              <tr>
                <td
                  style={{ height: '400px', textAlign: 'center' }}
                  colSpan={9}
                >
                  <img src={Loader} width={50} height={50} />
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td colSpan={'9'} className={'text-center bg-white'}>
                  <div className="noPRF d-flex flex-column text-center">
                    <img
                      className="mx-auto"
                      src={'/noPRF.svg'}
                      width={'237'}
                      height={'155'}
                      alt="no comments found"
                    />
                    <p className="mb-0">No Data Available</p>
                  </div>
                </td>
              </tr>
            </tbody>
          )}
        </Table>
      </div>

      <div className="paginations d-flex justify-content-between align-items-center">
        <span>
          Showing {getFirstIndexPagination(pagination, table, data)} to{' '}
          {getLastIndexPagination(pagination, table, data)} of {data.length}{' '}
          entries
        </span>

        {data.length ? (
          <ReactPaginate
            containerClassName="pagination m-0"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            activeClassName="active"
            previousClassName="page-item previous"
            nextClassName="page-item next"
            disabledClassName="disabled"
            previousLinkClassName="page-link"
            nextLinkClassName="page-link"
            breakLabel="..."
            nextLabel="Next"
            onPageChange={handlePageClick}
            pageCount={Math.ceil(data.length / pagination.pageSize)}
            previousLabel="Previous"
            renderOnZeroPageCount={null}
            pageRangeDisplayed="2"
            marginPagesDisplayed="2"
          />
        ) : null}
      </div>
    </>
  );
};

BasicTable.propTypes = {
  // The Data is used to show values in the table
  data: PropTypes.array,
  // Sets the loading true or false
  arermssLoading: PropTypes.bool,
};
