import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { object } from 'yup';
import { PRF_FIELDS } from '../../components/helper/constant';

const { PRF_STATUS_NAME } = PRF_FIELDS;
const {
  INITIATED,
  FORWARD,
  REJECTED,
  ACCEPTED,
  REQUEST_CLOSURE,
  INTERNAL_HIRE,
} = PRF_STATUS_NAME;

const CommentSection = ({ comments }) => (
  <>
    <div className="commentSectionHead">
      <h2 className="commentHeading">Comments</h2>
      {comments?.length ? (
        comments.map(({ user, updatedAt, content, type }) => (
          <Comment
            key={`comment-${updatedAt}`}
            role={user?.roles?.map((role) => role.displayName)}
            type={type}
            imageUrl={user?.picture || 'https://i.stack.imgur.com/YaL3s.jpg'}
            name={`${user?.firstName} ${user?.lastName}`}
            date={updatedAt}
            content={content}
          />
        ))
      ) : (
        <div className="cmtsection d-flex justify-content-center align-items-center">
          <div className="nocommentsData d-flex flex-column text-center">
            <img
              className="noCommentsFound"
              src={'/noComments.svg'}
              width={'249'}
              height={'185'}
              alt="no comments found"
            />
            <p className="mb-0">You don’t have any comment yet</p>
          </div>
        </div>
      )}
    </div>
  </>
);
CommentSection.propTypes = {
  // The comment is array of object data
  comments: PropTypes.arrayOf([object]),
};

export default CommentSection;

const Comment = ({ imageUrl, name, content, date, type, role }) => {
  const dateFormatted = dayjs(date).format('DD/MM/YYYY hh:mmA');
  const handleButtonClick = (commentType) => {
    if (commentType === INITIATED) {
      return 'Initiated';
    }
    if (commentType === FORWARD) {
      return 'Forward';
    }
    if (commentType === REJECTED) {
      return 'Rejected';
    }
    if (commentType === ACCEPTED) {
      return 'Accepted';
    }
    if (commentType === REQUEST_CLOSURE) {
      return 'Closed';
    }
    if (commentType === INTERNAL_HIRE) {
      return 'Internal Hire';
    }
    if (commentType === INTERNAL_HIRE) {
      return 'Internal Hire';
    }
    return 'Submitted';
  };
  return (
    <div className="cmtsection">
      <img
        className="rounded-circle profilePic"
        src={imageUrl}
        width={'40'}
        height={'40'}
        alt="user"
      />
      <div className="cmtuser">
        <h6 className="cmtHead">
          {name} <span className="Roless">({role})</span>
        </h6>
        <p className="cmtPgh">
          <span style={{ fontWeight: 'bold' }}>{handleButtonClick(type)}</span>:
          `{content}`
        </p>
        <h6 className="datetime">{dateFormatted}</h6>
      </div>
    </div>
  );
};

Comment.propTypes = {
  // The Proptypes for Comment props
  imageUrl: PropTypes.string,
  name: PropTypes.string,
  content: PropTypes.string,
  date: PropTypes.number,
  type: PropTypes.string,
  role: PropTypes.string,
};
