import { createColumnHelper } from '@tanstack/react-table';
import { OverlayTrigger, Tooltip, Badge } from 'react-bootstrap';
import { format } from 'date-fns';
import { getPrfStatus } from '../utils/util';

const columnHelper = createColumnHelper();

export const COLUMNS = [
  columnHelper.accessor(
    (row) => row?.prf_statuses[row.prf_statuses.length - 1]?.status,
    {
      header: () => 'PRF Status',
      // type: () => 'number',
      id: () => 'status',
      enableColumnFilter: false,
      filter: 'fuzzy',
      enableGlobalFilter: true,
      cell: (itm) => {
        const status = itm.getValue();
        let statusColor;
        const statusText = getPrfStatus(status);
        const lastComment = itm.row?.original.comments?.slice(-1)[0]; // Get the last item of the comments array
        let lastCommentContent = <div>No comments.</div>; // Default content if there are no comments

        if (lastComment) {
          lastCommentContent = (
            <div>
              <strong>
                {lastComment?.userFirstName} {lastComment?.userLastName}:
              </strong>{' '}
              {lastComment.content}
              <br />
              <small>
                {format(new Date(lastComment.createdAt), 'dd/MM/yyyy HH:mm')}
              </small>
            </div>
          );
        }

        const tooltipContent = <div>{lastCommentContent}</div>;

        if (status === 1) {
          statusColor = 'primary';
        } else if (status === 2) {
          statusColor = 'warning';
        } else if (status === 3) {
          statusColor = 'info';
        } else if (status === 4) {
          statusColor = 'danger';
        } else if (status === 5) {
          statusColor = 'success';
        } else if (status === 6) {
          statusColor = 'success';
        } else if (status === 7) {
          statusColor = 'closed';
        } else if (status === 8) {
          statusColor = 'success';
        } else {
          statusColor = null;
        }

        return (
          <div className="d-flex flex-row align-items-center overflow">
            <Badge bg={statusColor}>{statusText}</Badge>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id={`tooltip-${status}`}>{tooltipContent}</Tooltip>
              }
            >
              <span className="info_icon">
                <svg
                  width={17}
                  height={17}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="gray"
                    d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"
                  />
                </svg>
              </span>
            </OverlayTrigger>
          </div>
        );
      },
      sortingFn: (rowA, rowB) => {
        // Assuming that statuses are numerical and directly comparable
        const statusA =
          rowA.original?.prf_statuses?.[rowA.original.prf_statuses.length - 1]
            ?.status ?? 0;
        const statusB =
          rowB.original?.prf_statuses?.[rowB.original.prf_statuses.length - 1]
            ?.status ?? 0;
        return statusA - statusB; // Sort numerically
      },
    },
  ),
  columnHelper.accessor('jobTitle', {
    header: () => 'Job Title',
    type: () => 'string',
  }),
  columnHelper.accessor('numOfOpenings', {
    header: () => 'No. of Positions',
    type: () => 'string',
    enableColumnFilter: false,
    enableGlobalFilter: false,
  }),
  columnHelper.accessor('requestedBy', {
    header: () => 'Requested by',
    type: () => 'string',
  }),
  // columnHelper.accessor(row => `${row.user.firstName} ${row.user.lastName}`, {
  //   id: 'fullName',
  //   header: 'Requested by',
  //   type: () => "string",
  // }),
  columnHelper.accessor('client', {
    header: () => 'Client',
    type: () => 'string',
  }),
  columnHelper.accessor('priority', {
    header: () => 'Priority',
    type: () => 'string',
    enableColumnFilter: false,
    enableGlobalFilter: false,
  }),
  columnHelper.accessor('createdAt', {
    header: () => 'Created Date',
    type: () => 'string',
    enableColumnFilter: false,
    enableGlobalFilter: false,
    cell: (prop) => (
      <time dateTime={format(new Date(prop.getValue()), 'dd/MM/yyyy')}>
        {format(new Date(prop.getValue()), 'dd/MM/yyyy')}
      </time>
    ),
  }),
  columnHelper.accessor('seniorityLevel', {
    header: () => 'Seniority Level',
    type: () => 'string',
    enableColumnFilter: false,
    enableGlobalFilter: false,
  }),
  columnHelper.accessor('city', {
    header: () => 'Location',
    type: () => 'string',
    enableColumnFilter: false,
    enableGlobalFilter: false,
  }),
  /* columnHelper.accessor('id', {
    header: () => 'Action',
    type: () => 'string',

    size: 40,
  }), */
];
