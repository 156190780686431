import React, { useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { toast } from 'react-toastify';
import { Formik, Form as FormikForm } from 'formik';
import * as Yup from 'yup';
import Modal from 'react-bootstrap/Modal';
import { Container, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { propTypes } from 'react-bootstrap/esm/Image';
import Select from 'react-select';
import AxiosInstance from '../../services/api';
import { GET_USER_ROLES, USER_ROLES } from '../helper/constant';
import Field from '../form/field';
import { AppContext } from '../../context/appContext';
import Dropdown from '../dashboard/dropdown';
import { GET_PRF_CLIENTS } from '../../services/routes/prf';
import { UPDATE_USER, GET_ALL_USERS } from '../../services/routes/dashboard';
import Location from '../dashboard/locationField';

const { userRoles, userDepartments, accountManagerDepartment } = USER_ROLES;

const AddAdminUser = ({ buttonID, secondaryButtonID, getAllUsers }) => {
  const { profile, getRole } = useContext(AppContext);
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const [prfClient, setPrfClient] = useState();
  const [users, setUsers] = useState([]);
  const [, setUsersLoading] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const loggedInUser = `${profile?.user?.firstName} ${profile?.user?.lastName}`;

  const getEmail = async () => {
    try {
      setUsersLoading(true);
      const allUsers = await AxiosInstance.get(GET_ALL_USERS);
      setUsers(allUsers.data.users);
      setUsersLoading(false);
    } catch (e) {
      setUsersLoading(false);
    }
  };

  useEffect(() => {
    getEmail();
  }, []);
  const fetchPrfClients = async () => {
    try {
      const clients = await AxiosInstance.get(GET_PRF_CLIENTS);
      setPrfClient(clients?.data?.clients);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (instance && isAuthenticated && profile) {
      const userRole = getRole();
      if (
        ![GET_USER_ROLES.ADMIN, GET_USER_ROLES.SUPER_USER].includes(userRole)
      ) {
        navigate('/');
      } else {
        fetchPrfClients();
      }
    }
  }, [instance, isAuthenticated, profile]);

  const options = prfClient
    ? prfClient.map((item) => ({
        value: item.client,
        label: item.client,
      }))
    : [];
  const addUserHandler = async (values) => {
    // eslint-disable-next-line no-param-reassign
    values.manager = loggedInUser;
    const clients = values.clients.map((option) => ({ client: option }));
    const updatedValues = { ...values, clients };

    const toastId = toast.loading('updated User');
    try {
      // eslint-disable-next-line no-unsafe-optional-chaining
      await AxiosInstance.post(UPDATE_USER, updatedValues);
      handleClose();
      if (getRole() === GET_USER_ROLES.ADMIN) {
        navigate('/');
      } else {
        navigate('/admin');
      }
      toast.update(toastId, {
        render: 'User Create successfully',
        type: 'success',
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: false,
      });
      getAllUsers();
    } catch (e) {
      toast.update(toastId, {
        render: 'Error Create User :(',
        type: 'error',
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: false,
      });
    }
  };
  return (
    <>
      <Button
        id={buttonID}
        onClick={handleShow}
        className="btn btn-primary btn-sm d-flex align-items-center justify-content-center btn-createPrf"
      >
        <span className="me-2">
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.1429 6.85714H6.85714V11.1429C6.85714 11.6143 6.47143 12 6 12C5.52857 12 5.14286 11.6143 5.14286 11.1429V6.85714H0.857143C0.385714 6.85714 0 6.47143 0 6C0 5.52857 0.385714 5.14286 0.857143 5.14286H5.14286V0.857143C5.14286 0.385714 5.52857 0 6 0C6.47143 0 6.85714 0.385714 6.85714 0.857143V5.14286H11.1429C11.6143 5.14286 12 5.52857 12 6C12 6.47143 11.6143 6.85714 11.1429 6.85714Z"
              fill="white"
            />
          </svg>
        </span>
        <span>Add User</span>
      </Button>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            <span className="me-2">
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.1429 6.85714H6.85714V11.1429C6.85714 11.6143 6.47143 12 6 12C5.52857 12 5.14286 11.6143 5.14286 11.1429V6.85714H0.857143C0.385714 6.85714 0 6.47143 0 6C0 5.52857 0.385714 5.14286 0.857143 5.14286H5.14286V0.857143C5.14286 0.385714 5.52857 0 6 0C6.47143 0 6.85714 0.385714 6.85714 0.857143V5.14286H11.1429C11.6143 5.14286 12 5.52857 12 6C12 6.47143 11.6143 6.85714 11.1429 6.85714Z"
                  fill="white"
                />
              </svg>
            </span>
            <span>Add User</span>
          </Modal.Title>
        </Modal.Header>

        <main className={`flex-grow-1 d-flex`}>
          <Container fluid className="d-flex flex-column p-0">
            <Formik
              initialValues={{
                clients: [],
                department: '',
                email: '',
                firstName: '',
                lastName: '',
                title: '',
                role: '',
              }}
              validationSchema={Yup.object().shape({
                firstName: Yup.string().required('First name is required'),
                email: Yup.string()
                  .required('Email is required')
                  .email('Invalid email address')
                  .notOneOf(
                    users.map((user) => user.email),
                    'This email already exists',
                  ),
                department: Yup.string().required('Department is required'),

                title: Yup.string().required('Title is required'),
                // department: Yup.string().required('Department is required'),
                role: Yup.string().required('Role is required'),
              })}
              onSubmit={(values, { setSubmitting }) => {
                addUserHandler(values); // Call the function to make API call
                setSubmitting(false); // Reset form submission state
              }}
            >
              {({
                handleSubmit,
                values,
                setFieldValue,
                touched,
                handleChange,
                handleBlur,
              }) => (
                <div>
                  <Modal.Body>
                    <FormikForm onSubmit={handleSubmit}>
                      <div className="userDetail rounded border pt-4 px-4 pb-4">
                        <Row>
                          <Col sm={10}>
                            <Row>
                              <Col md={4}>
                                <Location
                                  label="Name"
                                  field1="firstName"
                                  placeholder1="First Name"
                                  placeholder2="Last Name"
                                  field2="lastName"
                                  onChange2={(e) => {
                                    setFieldValue('lastName', e.target.value);
                                  }}
                                  onChange1={(e) => {
                                    setFieldValue('firstName', e.target.value);
                                  }}
                                  type="text"
                                  onChange={handleChange}
                                />
                              </Col>
                              <Col md={4}>
                                <div className="mb-3 pb-3 d-flex flex-column">
                                  <Field
                                    className="form-control"
                                    onChange={handleChange}
                                    label="Email Address"
                                    name="email"
                                    type="email"
                                    Asterisk="*"
                                  />
                                </div>
                              </Col>
                              <Col md={4}>
                                <div className="mb-3 pb-3 d-flex flex-column">
                                  <Dropdown
                                    onChange={(e) => {
                                      setFieldValue('role', e.target.value);
                                    }}
                                    onBlur={handleBlur}
                                    label="Role"
                                    name="role"
                                    Asterisk="*"
                                    roles={userRoles}
                                    selected={!(touched && touched.role)}
                                  />
                                </div>
                              </Col>
                              <Col md={4}>
                                <div className="mb-3 pb-3 d-flex flex-column">
                                  <Field
                                    onChange={handleChange}
                                    placeholder="Enter Title"
                                    className="form-control"
                                    onBlur={handleBlur}
                                    value={values.title}
                                    label="Title"
                                    name={`title`}
                                    type="text"
                                    Asterisk="*"
                                  />
                                </div>
                              </Col>
                              <Col md={4}>
                                <div className="mb-3 pb-3 d-flex flex-column">
                                  <label className="detailLabel-control">
                                    Clients
                                    {!(values.role === 'department_head') && (
                                      <span style={{ color: 'red' }}>*</span>
                                    )}
                                  </label>
                                  <Select
                                    isDisabled={
                                      values.role === 'department_head'
                                    }
                                    styles={{
                                      control: (provided) => ({
                                        ...provided,
                                        border: '1px solid #e2e2e2',
                                        minHeight: '40px',
                                        borderRadius: '5px',
                                        boxShadow: 'none',
                                      }),
                                    }}
                                    isMulti
                                    name="clients"
                                    options={options}
                                    onChange={(selectedOptions) => {
                                      // Formik's setFieldValue function can be used to update the values
                                      setFieldValue(
                                        'clients',
                                        selectedOptions.map(
                                          (option) => option.value,
                                        ),
                                      );
                                    }}
                                    value={values.clients.map((client) => ({
                                      value: client,
                                      label: client,
                                    }))}
                                  />
                                </div>
                              </Col>
                              <Col md={4}>
                                <div className="mb-3 pb-3 d-flex flex-column">
                                  <Dropdown
                                    onChange={(e) => {
                                      setFieldValue(
                                        'department',
                                        e.target.value,
                                      );
                                    }}
                                    placeholder="Select Department"
                                    onBlur={handleBlur}
                                    label="Department"
                                    name="department"
                                    Asterisk="*"
                                    roles={
                                      values.role === 'account_manager'
                                        ? accountManagerDepartment
                                        : userDepartments
                                    }
                                    selected={!(touched && touched.department)}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </FormikForm>
                  </Modal.Body>

                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={handleClose}
                      id="close-button"
                    >
                      Close
                    </Button>
                    <Button
                      id={secondaryButtonID}
                      onClick={handleSubmit} // This should trigger the form submission
                      type="button" // Set the button type to "button"
                      variant="primary"
                      size="sm"
                      className="d-flex align-items-center justify-content-center btn-createPrf"
                    >
                      <span className="me-2">
                        <svg
                          width="12"
                          height="10"
                          viewBox="0 0 12 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.99993 7.79994L1.19993 4.99994L0.266602 5.93328L3.99993 9.66661L11.9999 1.66661L11.0666 0.733276L3.99993 7.79994Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                      <span>Save</span>
                    </Button>
                  </Modal.Footer>
                </div>
              )}
            </Formik>
          </Container>
        </main>
      </Modal>
    </>
  );
};

AddAdminUser.propTypes = {
  buttonID: propTypes.string,
  secondaryButtonID: propTypes.string,
  getAllUsers: propTypes.func,
};
export default AddAdminUser;
