import PropTypes from 'prop-types';

const AuthLayout = ({ children }) => <>{children}</>;

AuthLayout.propTypes = {
  // The Proptypes for Layout
  children: PropTypes.any,
};

export default AuthLayout;
