import { useContext } from 'react';
import { useMsal } from '@azure/msal-react';
import AuthLayout from '../components/layout/authLayout';
import mlLogo from '../assets/images/mlLogo.svg';
import { loginRequest } from '../authConfig';
import MicrosoftLoginButton from '../components/layout/microsoftLoginButtonComp';
import { AppContext } from '../context/appContext';
import { LOCAL_STORAGE_KEYS, LOCAL_STORAGE_VALUES } from '../utils/constants';

const Login = () => {
  const { instance } = useMsal();
  const { setLoading } = useContext(AppContext);
  const handleLoginRedirect = async () => {
    localStorage.setItem(
      LOCAL_STORAGE_KEYS.LOGIN_IN_PROCESS,
      LOCAL_STORAGE_VALUES.LOGIN_IN_PROCESS,
    );
    setLoading(true);
    await instance.loginRedirect(loginRequest);
  };

  return (
    <AuthLayout>
      <main className="loginPage flex-grow-1 d-flex align-items-center justify-content-center">
        <div className="logInContent text-black text-center">
          <div className="logInContentHead d-flex flex-column">
            <span>
              <img src={mlLogo} alt="mobileLIVE logo" />
            </span>
            <h2 className="mb-0">
              Let’s Get Started Position Requisition Form Portal
            </h2>
            <p className="mb-0">Sign in to continue to PRF</p>
          </div>
          <div
            className="btn-google bg-white"
            data-testid="microsoft-login-button"
          >
            <MicrosoftLoginButton
              buttonTheme={'light'}
              onClick={handleLoginRedirect}
            />
          </div>
          <p className="mb-0">
            mobileLIVE Inc &copy; {new Date().getFullYear()}
          </p>
        </div>
      </main>
    </AuthLayout>
  );
};

export default Login;
