import React, { useState } from 'react';
import { FaLongArrowAltUp, FaLongArrowAltDown } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { Table, Form } from 'react-bootstrap';
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  getFilteredRowModel,
  getPaginationRowModel,
} from '@tanstack/react-table';
import { rankItem } from '@tanstack/match-sorter-utils';
import ReactPaginate from 'react-paginate';
import UploadResume from './UploadResume';
import iconFilter from '../../assets/images/icon-filter.svg';
import Loader from '../../assets/images/loader.gif';
import { COLUMNS } from './columns';
import { getFirstIndexPagination, getLastIndexPagination } from '../utils/util';
import ManualButton from './manualButton';

const fuzzyFilter = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value);
  addMeta({ itemRank });
  return itemRank.passed;
};

export const BasicTable = ({ data, resumesLoading, getAllResumes }) => {
  const [, setActivePageIndex] = useState(0);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [editID, setEditID] = useState(null);
  const [downloadID, setDownloadID] = useState(null);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const table = useReactTable({
    data,
    columns: COLUMNS(setEditID, getAllResumes, data),
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      sorting,
      globalFilter,
      pagination,
    },
    columnFilters: [],
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    debugTable: true,
    globalFilterFn: fuzzyFilter,
  });

  const handlePageClick = (event) => {
    setPagination((prev) => ({ ...prev, pageIndex: event.selected }));
    setActivePageIndex(event.selected);
    table.setPageIndex(event.selected);
  };

  return (
    <>
      <div className={'prfListHead'}>
        <div className="prfListTitle d-flex justify-content-between align-items-center">
          <h2>Resource Profiles</h2>
          <div className="d-flex flex-row align-items-center">
            <div>
              <ManualButton
                downloadID={downloadID}
                setDownloadID={setDownloadID}
                editID={editID}
                getAllResumes={getAllResumes}
                setEditID={setEditID}
              />
            </div>
            <p className="mx-2">Or </p>
            <UploadResume getAllResumes={getAllResumes} />
          </div>
        </div>

        <div className="prfTableFilters d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <span>Show</span>
            <Form.Select
              className={'mx-2'}
              aria-label="Default select example"
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40].map((pageSize) => (
                <option key={`pageSize-${pageSize}`} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </Form.Select>
            <span>entries</span>
          </div>
        </div>
      </div>
      <div className="tabelHolder resumeTable">
        <Table bordered striped hover responsive className="m-0">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={`headerGroup-${headerGroup.id}`}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={`header-${header.id}`}
                    className={header.column.columnDef.className} // Add class name to <th> element
                    // style={{
                    //   width: header.getSize() ? header.getSize() : undefined,
                    // }}
                  >
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? 'cursor-pointer'
                            : '',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                        {{
                          asc: <FaLongArrowAltUp className="ms-2" />,
                          desc: <FaLongArrowAltDown className="ms-2" />,
                        }[header.column.getIsSorted()] ?? (
                          <img
                            className="ms-2"
                            src={iconFilter}
                            alt="filter icon"
                          />
                        )}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {data.length ? (
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr key={`row-${row.original.id}`}>
                  {row.getVisibleCells().map((cell) => (
                    <td key={`cell-${cell.id}`}>
                      <div className="tdHead">
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          ) : resumesLoading ? (
            <tbody>
              <tr>
                <td
                  style={{ height: '400px', textAlign: 'center' }}
                  colSpan={9}
                >
                  <img src={Loader} width={50} height={50} />
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td colSpan={'9'} className={'text-center bg-white'}>
                  <div className="noPRF d-flex flex-column text-center">
                    <p className="mb-0">No Data Available</p>
                  </div>
                </td>
              </tr>
            </tbody>
          )}
        </Table>
      </div>

      <div className="paginations d-flex justify-content-between align-items-center">
        <span>
          Showing {getFirstIndexPagination(pagination, table, data)} to{' '}
          {getLastIndexPagination(pagination, table, data)} of {data.length}{' '}
          entries
        </span>

        {data.length ? (
          <ReactPaginate
            containerClassName="pagination m-0"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            activeClassName="active"
            previousClassName="page-item previous"
            nextClassName="page-item next"
            disabledClassName="disabled"
            previousLinkClassName="page-link"
            nextLinkClassName="page-link"
            breakLabel="..."
            nextLabel="Next"
            onPageChange={handlePageClick}
            pageCount={Math.ceil(data.length / pagination.pageSize)}
            previousLabel="Previous"
            renderOnZeroPageCount={null}
            pageRangeDisplayed="2"
            marginPagesDisplayed="2"
          />
        ) : null}
      </div>
    </>
  );
};

BasicTable.propTypes = {
  data: PropTypes.array,
  resumesLoading: PropTypes.bool,
  getAllResumes: PropTypes.func,
};
