import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
  ComposedChart,
} from 'recharts';

const TotalPrfperDepartmentNormal = ({ prfDepartmentData = {} }) => {
  const [XAxisMax, setXAxisMax] = useState(2);
  const [prfDepartmentFormattedData, setPrfDepartmentFormattedData] = useState(
    [],
  );

  useEffect(() => {
    if (prfDepartmentData && Object.keys(prfDepartmentData).length > 0) {
      const formattedData = Object.entries(prfDepartmentData)
        .map(([department, { prf, openings }]) => ({
          department,
          prf,
          openings,
        }))
        .filter(({ prf, openings }) => prf !== 0 && openings !== 0); // Filter out entries where prf is 0
      const maxXVal = Math.max(...formattedData.map((item) => item.count), 4);
      setXAxisMax(maxXVal + 2);
      setPrfDepartmentFormattedData(formattedData);
    }
  }, [prfDepartmentData]);

  if (!prfDepartmentFormattedData.length) {
    return (
      <div className="prfDepartmentData">
        <h2>Total Number of PRFs per Department</h2>
        <div className="noDataFound">
          <div className="d-flex flex-column text-center">
            <p className="mb-0">No Data Available</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="prfDepartmentData">
      <h2>Total Number of PRFs per Department</h2>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          layout="horizontal"
          width={500}
          height={326}
          data={prfDepartmentFormattedData}
          margin={{ top: 20, right: 10, bottom: 60, left: -20 }}
          barSize={16}
        >
          <XAxis
            dataKey="department"
            angle={-45}
            textAnchor="end"
            interval={0}
            height={60}
          />
          <YAxis
            domain={[0, XAxisMax]}
            allowDataOverflow={true}
            tick={{ fill: '#000' }}
            strokeWidth="2px"
            style={{
              fontSize: '10px',
              fontWeight: '400',
              lineHeight: '14px',
            }}
          />
          <Tooltip />
          <Bar dataKey="prf" name="PRFs" fill="#7081B9" />
          <Bar dataKey="openings" name="Openings" fill="#7081B9" />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

TotalPrfperDepartmentNormal.propTypes = {
  prfDepartmentData: PropTypes.object,
};

export default TotalPrfperDepartmentNormal;
