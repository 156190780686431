import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';

const PayRateCountClient = ({ prfAccountData = {}, heading = {} }) => {
  const [XAxisMax, setXAxisMax] = useState(0);
  const [prfAccountFormattedData, setPrfAccountFormattedData] = useState([]);

  useEffect(() => {
    if (prfAccountData && Object.keys(prfAccountData).length > 0) {
      const formattedData = Object.entries(prfAccountData).map(
        ([account, data]) => ({
          account,
          abovePrf: data.above.prf,
          aboveOpenings: data.above.openings,
          withinPrf: data.within.prf,
          withinOpenings: data.within.openings,
        }),
      );

      const maxXVal = Math.max(
        ...formattedData.flatMap((item) => [item.abovePrf, item.withinPrf]),
        4,
      );
      setXAxisMax(maxXVal + 2);
      setPrfAccountFormattedData(formattedData);
    }
  }, [prfAccountData]);

  if (!prfAccountFormattedData.length) {
    return (
      <div className="prfAccountData">
        <h2>{heading}</h2>
        <div className="noDataFound">
          <div className="d-flex flex-column text-center">
            <p className="mb-0">No Data Available</p>
          </div>
        </div>
      </div>
    );
  }

  if (!Object.keys(prfAccountData)?.length)
    return (
      <div className="prfDepartmentData">
        <h2>{heading}</h2>
        <div className="noDataFound">
          <div className="d-flex flex-column text-center">
            <p className="mb-0">No Data Available</p>
          </div>
        </div>
      </div>
    );

  return (
    <div className="prfAccountData">
      <h2>{heading}</h2>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          layout="horizontal"
          width={500}
          height={326}
          data={prfAccountFormattedData}
          margin={{ top: 20, right: 10, bottom: 60, left: -20 }}
          barSize={16}
        >
          <XAxis
            dataKey="account"
            type="category"
            data-testid="x-axis"
            angle={-45}
            textAnchor="end"
            interval={0}
            height={60}
          />
          <YAxis
            domain={[0, XAxisMax]}
            type="number"
            allowDataOverflow={true}
            tick={{ fill: '#000' }}
            strokeWidth="2px"
            style={{
              fontSize: '10px',
              fontWeight: '400',
              lineHeight: '14px',
            }}
          />
          <Tooltip />
          <Bar
            dataKey="abovePrf"
            stackId="a"
            fill="#8884d8"
            name="Above PRFs"
          />
          <Bar
            dataKey="withinPrf"
            stackId="a"
            fill="#82ca9d"
            name="Within PRFs"
          />
          <Bar
            dataKey="aboveOpenings"
            stackId="b"
            fill="#413ea0"
            name="Above Openings"
          />
          <Bar
            dataKey="withinOpenings"
            stackId="b"
            fill="#4ca64c"
            name="Within Openings"
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

PayRateCountClient.propTypes = {
  prfAccountData: PropTypes.any,
  heading: PropTypes.string,
};

export default PayRateCountClient;
