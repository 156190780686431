import React from 'react';
import { Field, ErrorMessage } from 'formik';
import PropTypes from 'prop-types';

const Dropdown = ({
  roles,
  name,
  label,
  selected,
  disabled,
  onChange,
  nulls,
  Asterisk,
}) => (
  <>
    <label className="detailLabel">
      {label}
      {Asterisk && <span style={{ color: 'red' }}>{Asterisk}</span>}
    </label>
    <Field
      as="select"
      className="postionDetail"
      disabled={disabled}
      onChange={onChange}
      name={name}
      data-testid={`dropdown-${name}`}
    >
      <option
        style={{ color: '#c3bfbf' }}
        value=""
        disabled
        selected={selected}
      >
        -Select-
      </option>
      {name === 'hiringManager' ? ( /// Hiring Manager Dropdown
        <>
          {roles &&
            roles.map((role, index) => (
              <option key={`${index}-${role.value}`} value={role.email || ''}>
                {role.text}
                {role.firstName} {role.lastName}
              </option>
            ))}
        </>
      ) : name === 'client' ? ( /// / Admin Client Dropdown
        <>
          {roles &&
            roles.map((role, index) => (
              <option key={`${index}-${role.value}`} value={role.client || ''}>
                {role.text}
                {role.client}
              </option>
            ))}
        </>
      ) : (
        <>
          {roles &&
            roles.map((role, index) => (
              <option key={`${index}-${role.value}`} value={role.value || ''}>
                {role.text}
                {role.client}
              </option>
            ))}
        </>
      )}
    </Field>
    <div className="errorMessage" data-testid="error-message">
      <ErrorMessage name={name} component={nulls} />
    </div>
  </>
);
Dropdown.propTypes = {
  // Indicates the Fields is disabled
  disabled: PropTypes.bool,
  // It's typically called when a user selects an option from the dropdown.
  onChange: PropTypes.func,
  // The label to display for the Dropdown.
  label: PropTypes.string,
  // The name attribute for the Dropdown.
  name: PropTypes.string,
  // The placeholder text to display in the Dropdown.
  placeholder: PropTypes.string,
  // The role show array list of dropdown.
  roles: PropTypes.array,
  // The Selected is use to ture or false
  selected: PropTypes.bool,
  nulls: PropTypes.any,
  Asterisk: PropTypes.string,
};
export default Dropdown;
