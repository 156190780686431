import { Routes, Route, BrowserRouter } from 'react-router-dom';
import UserDetail from '../pages/adminDashboard/userDetail';
import CreatePRF from '../pages/createPRF';
import Analytics from '../pages/analytics';
import NotFound from '../pages/notFound';
import LandingPageHandler from '../pages/landingPageHandler';
import RmsForm from '../pages/rms/rmsForm';
import RMS from '../pages/rms';
import PrfLogs from '../pages/prfLogs';
import Resume from '../pages/resume';
import MyDocument from '../components/resume/resumeTemplate';
import Dashboard from '../pages/dashboard';
import AdminDashboard from '../pages/adminDashboard';

const RoutesHandler = () => (
  <BrowserRouter>
    <Routes>
      <>
        <Route path="/" element={<LandingPageHandler />} />
        <Route path="/user-detail/:userID" element={<UserDetail />} />
        <Route path="/rmsForm" element={<RmsForm />} />
        <Route path="/rms/:id" element={<RmsForm />} />
        <Route path="/rms" element={<RMS />} />
        <Route path="/edit/:id" element={<CreatePRF />} />
        <Route path="/create-prf" element={<CreatePRF />} />
        <Route path="/analytics" element={<Analytics />} />
        <Route path="/prf-logs" element={<PrfLogs />} />
        <Route path="/resume" element={<Resume />} />
        <Route path="/prf" element={<Dashboard />} />
        <Route path="/resume-template" element={<MyDocument />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/admin" element={<AdminDashboard />} />
      </>
    </Routes>
  </BrowserRouter>
);

export default RoutesHandler;
