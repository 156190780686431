/* eslint-disable react/prop-types */

import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Cell,
  ResponsiveContainer,
} from 'recharts';
import { colorMapping } from '../helper/constant';

const DepartmentRoleChart = ({ data, isAnimated }) => (
  <div className="d-flex flex-column">
    {Object.entries(data).map(([department, departmentData]) => {
      const chartData = Object.entries(departmentData)
        .map(([name, { prf, openings }]) => ({
          name,
          prf,
          openings,
        }))
        .filter(({ prf, openings }) => prf !== 0 && openings !== 0);
      const total = chartData.reduce((sum, item) => sum + item.PRFs, 0);

      return (
        <div key={department} className="prfAccountData">
          <div className="d-flex flex-column">
            <h2>Total Number of ({department}) PRFs per Account</h2>
          </div>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={500}
              height={326}
              data={chartData}
              barSize={20}
              margin={{ top: 20, right: 10, bottom: 60, left: -20 }}
            >
              <XAxis
                dataKey="name"
                angle={-45}
                textAnchor="end"
                interval={0}
                height={60}
              />
              <YAxis domain={[0, total]} />
              <Tooltip />
              <Bar
                dataKey="prf"
                fill="rgb(112, 129, 185)"
                isAnimationActive={isAnimated}
              >
                {chartData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={colorMapping[entry.name] || '#7081B9'}
                  />
                ))}
              </Bar>
              <Bar
                dataKey="openings"
                fill="rgb(112, 129, 185)"
                isAnimationActive={isAnimated}
              >
                {chartData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={colorMapping[entry.name] || '#7081B9'}
                  />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      );
    })}
  </div>
);

export default DepartmentRoleChart;
