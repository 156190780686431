/* eslint-disable */
import PropTypes from 'prop-types';
import Tick from '../assets/images/tickMark.png';
import LoadingIcon from '../assets/images/icon-step.svg';
import StepData from './stepsfile';
import { Styles } from './stepData';
import { PRF_FIELDS } from '../components/helper/constant';

const { PRF_STATUS_LABLE, PRF_STATUS_GRADIENT, REPEATED_STATUS_GRADIENT } =
  PRF_FIELDS;
const { LightBlue, LightGray } = Styles;
const Step = ({ isSubmitting, currentStatus, prfStatusesList }) => {
  const statusCount = {};

  const findLabel = (status) => {
    statusCount[status] = (statusCount[status] || 0) + 1;
    if (status === 6 && statusCount[status] > 1) {
      return null;
    }

    if (statusCount[status] > 1) {
      return `Re-${PRF_STATUS_LABLE[status].split(' ')[1]}`;
    }

    return PRF_STATUS_LABLE[status];
  };

  const findGradient = (status) => {
    if (status === 6 && statusCount[status] > 1) {
      return null;
    }
    if (statusCount[status] > 1) {
      return REPEATED_STATUS_GRADIENT[status];
    }

    return PRF_STATUS_GRADIENT[status];
  };

  const getNextSteps = (status, index, statusesList) => {
    const steps = {
      1: [
        'PRF To Be Forwarded',
        'PRF To Be Accepted',
        'Profile To Be Submitted',
      ],
      3: ['PRF To Be Accepted', 'Profile To Be Submitted'],
      5: ['Profile To Be Submitted'],
      4: [
        'PRF To Be Re-Initiated',
        'PRF To Be Forwarded',
        'PRF To Be Accepted',
        'Profile To Be Submitted',
      ],
    };

    // Determine if this is the last occurrence of the status in the list
    const isLastOccurrence = statusesList
      .slice(index + 1)
      .every((item) => item.status !== status);

    return isLastOccurrence ? steps[status] || [] : [];
  };

  return (
    <>
      {!currentStatus && isSubmitting ? (
        <StepData
          Step1="PRF to be Initiated"
          Step3="-------"
          Step2="-------"
          LoadingIcon={
            <span
              style={{
                width: '20px',
                height: '20px',
                border: '2px solid rgb(15, 98, 254)',
                borderRadius: '20px',
              }}
            ></span>
          }
          icon2=""
          icon3=""
          style1={LightBlue}
          style2={LightGray}
          style3={LightGray}
        />
      ) : (
        <div className="d-flex flex-row flex-wrap">
          {prfStatusesList?.map((item, index) => {
            const label = findLabel(item.status);
            return label ? (
              <>
                <StepData
                  Step1={label}
                  title={`Step ${index + 1}`}
                  icon1={Tick}
                  style1={{ background: findGradient(item.status) }} // Use gradient for background
                  textWhite="text-white"
                />
                {/* Only display next steps for the last status */}
                {index === prfStatusesList?.length - 1 &&
                  getNextSteps(item.status, index, prfStatusesList).map(
                    (step, idx) => (
                      <StepData
                        key={`future_${index}_${idx}`}
                        Step1={step}
                        title={`Step ${index + 2 + idx}`}
                        LoadingIcon={
                          <span
                            style={{
                              width: '20px',
                              height: '20px',
                              border: '2px solid rgb(15, 98, 254)',
                              borderRadius: '20px',
                            }}
                          ></span>
                        }
                        // icon1={LoadingIcon}
                        style1={LightBlue}
                      />
                    ),
                  )}
              </>
            ) : null;
          })}
        </div>
      )}
    </>
  );
};

Step.propTypes = {
  currentStep: PropTypes.number,
  currentStatus: PropTypes.number,
  isSubmitting: PropTypes.bool,
  prfSubmitTalentTeam: PropTypes.bool,
  prfStatusesList: PropTypes.array,
};

export default Step;
