/* eslint-disable */

import React, { useContext, useEffect, useState } from 'react';
import { Button, Container, Col, Row } from 'react-bootstrap';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from '@azure/msal-react';
import { useNavigate, useParams, Navigate } from 'react-router-dom';
import { Formik, Form as FormikForm } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import Select from 'react-select';
import Layout from '../../components/layout/layout';
import AxiosInstance from '../../services/api';
import Field from '../../components/form/field';
import Dropdown from '../../components/dashboard/dropdown';
import { GET_USER_ROLES, USER_ROLES } from '../../components/helper/constant';
import Loader from '../../assets/images/loader.gif';
import { AppContext } from '../../context/appContext';
import { GET_PRF_CLIENTS } from '../../services/routes/prf';
import { UPDATE_USER } from '../../services/routes/dashboard';

const { userRoles, userDepartments, accountManagerDepartment } = USER_ROLES;

const UserDetail = () => {
  const { profile, getRole } = useContext(AppContext);
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const getUserID = useParams();
  const [user, setUser] = useState({});
  const [prfClient, setPrfClient] = useState();
  const [areUserLoading, setUserLoading] = useState(true);
  const [locked, setLocked] = useState(true);
  const editUserID = getUserID?.userID;
  const currentUserID = profile?.user?.id;
  const userIDNotMatched = editUserID !== currentUserID;

  const fetchPrfClients = async () => {
    try {
      setUserLoading(true);
      const clients = await AxiosInstance.get(GET_PRF_CLIENTS);
      setPrfClient(clients?.data?.clients);
      setUserLoading(false);
    } catch (e) {
      setUserLoading(false);
    }
  };

  const getUser = async () => {
    try {
      setUserLoading(true);
      const userData = await AxiosInstance.get(
        // eslint-disable-next-line no-unsafe-optional-chaining
        UPDATE_USER + getUserID?.userID,
      );
      const role = userData?.data?.user?.roles[0]?.role;
      setUser({ ...userData?.data?.user, role });
      setUserLoading(false);
    } catch (e) {
      setUserLoading(false);
    }
  };

  useEffect(() => {
    if (instance && isAuthenticated && profile) {
      const userRole = getRole();
      if (
        ![GET_USER_ROLES.ADMIN, GET_USER_ROLES.SUPER_USER].includes(userRole)
      ) {
        navigate('/');
      } else {
        getUser();
        fetchPrfClients();
      }
    }
  }, [instance, isAuthenticated, profile]);

  const updateUserHandler = async (values) => {
    const clients = values.clients.map((option) => ({ client: option.value }));
    delete values.userClients;
    const updatedValues = { ...values, clients };
    const toastId = toast.loading('updated User');
    setLocked(true);
    try {
      // eslint-disable-next-line no-unsafe-optional-chaining
      await AxiosInstance.patch(UPDATE_USER + getUserID?.userID, updatedValues);
      if (getRole() === GET_USER_ROLES.ADMIN) {
        navigate('/');
      } else {
        navigate('/admin');
      }
      setLocked(true);
      setUserLoading(false);
      toast.update(toastId, {
        render: 'User updated successfully',
        type: 'success',
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: false,
      });
    } catch (e) {
      toast.update(toastId, {
        render: 'Error updating User :(',
        type: 'error',
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: false,
      });
      setUserLoading(false);
    }
  };
  if (areUserLoading) {
    return (
      <div className="backdrop">
        <img src={Loader} width={50} height={50} />
      </div>
    );
  }

  const dropdownOptions = prfClient
    ? prfClient.map((item) => ({
        value: item.client,
        label: item.client,
      }))
    : [];

  const opt = user?.userClients ? user?.userClients : [];

  const options = opt.map((item) => {
    return {
      value: item.client,
      label: item.client,
    };
  });

  return (
    <>
      <UnauthenticatedTemplate>
        <Navigate to="/" />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <Layout>
          <main className={`flex-grow-1 d-flex dashboard`}>
            <Container fluid className="d-flex flex-column p-0">
              <Formik
                initialValues={{
                  ...user,
                  fullName: `${user?.firstName} ${user.lastName}`,
                  clients: options,
                }}
                enableReinitialize
                validationSchema={Yup.object().shape({
                  fullName: Yup.string(),
                  // firstName: Yup.string().required('first name is required'),
                  // lastName: Yup.string().required('last name is required'),
                  // email: Yup.string().required('email is required'),
                  title: Yup.string().required('title is required'),
                  department: Yup.string().when('role', {
                    is: (e) => e === 'account_manager',
                    then: () => Yup.string().notRequired(),
                    otherwise: () => Yup.string().required('This is required'),
                  }),
                  role: Yup.string().required('role is required'),
                })}
                onSubmit={(values) => {
                  updateUserHandler(values);
                }}
              >
                {({
                  handleSubmit,
                  values,
                  setFieldValue,
                  touched,
                  handleChange,
                  handleBlur,
                }) => (
                  <FormikForm onSubmit={handleSubmit}>
                    <div className="pageHead d-flex justify-content-between align-items-center">
                      <div className={'d-flex flex-column'}>
                        <h1 className="title mb-0">{`${user?.firstName} ${user?.lastName}`}</h1>
                        <nav aria-label="breadcrumb">
                          <ol className="breadcrumb mb-0">
                            <li className="breadcrumb-item">
                              <a href="/" className="breadcrumb-style">
                                Dashboard
                              </a>
                            </li>
                            <li className="breadcrumb-item active">
                              User Detail
                            </li>
                          </ol>
                        </nav>
                      </div>

                      {!locked && (
                        <div className="d-flex">
                          <Button
                            id="cancel-button"
                            variant="light"
                            size="sm"
                            className="btn-createPrf me-3"
                            onClick={() => {
                              setLocked((prevCheck) => !prevCheck);
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            id="updateUser-button"
                            type="submit"
                            variant="primary"
                            size="sm"
                            className="d-flex align-items-center justify-content-center btn-createPrf"
                          >
                            <span className="me-2">
                              <svg
                                width="12"
                                height="10"
                                viewBox="0 0 12 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M3.99993 7.79994L1.19993 4.99994L0.266602 5.93328L3.99993 9.66661L11.9999 1.66661L11.0666 0.733276L3.99993 7.79994Z"
                                  fill="white"
                                />
                              </svg>
                            </span>
                            <span>Update User</span>
                          </Button>
                        </div>
                      )}
                      {locked && (
                        <Button
                          id="edit-button"
                          variant="primary"
                          size="sm"
                          className="d-flex align-items-center justify-content-center btn-createPrf"
                          onClick={() => {
                            setLocked((prevCheck) => !prevCheck);
                          }}
                        >
                          <span className="me-2">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clipPath="url(#clip0_1160_2199)">
                                <path
                                  d="M9.37333 6.01333L9.98667 6.62667L3.94667 12.6667H3.33333V12.0533L9.37333 6.01333ZM11.7733 2C11.6067 2 11.4333 2.06667 11.3067 2.19333L10.0867 3.41333L12.5867 5.91333L13.8067 4.69333C14.0667 4.43333 14.0667 4.01333 13.8067 3.75333L12.2467 2.19333C12.1133 2.06 11.9467 2 11.7733 2ZM9.37333 4.12667L2 11.5V14H4.5L11.8733 6.62667L9.37333 4.12667Z"
                                  fill="white"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_1160_2199">
                                  <rect width="16" height="16" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </span>
                          <span>Edit</span>
                        </Button>
                      )}
                    </div>

                    <div className="userDetail rounded border pt-4 px-4 pb-4">
                      <h2 className="mb-4 text-white">User Detail</h2>
                      <Row>
                        <Col sm={10}>
                          <Row>
                            <Col md={4}>
                              <div className="mb-3 pb-3 d-flex flex-column">
                                <Field
                                  className="form-control"
                                  label="Name"
                                  name="fullName"
                                  type="text"
                                  value={values.fullName}
                                  disabled
                                />
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="mb-3 pb-3 d-flex flex-column">
                                <Field
                                  className="form-control"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.email}
                                  label="Email Address"
                                  name="email"
                                  type="email"
                                  disabled
                                />
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="mb-3 pb-3 d-flex flex-column">
                                <Dropdown
                                  onChange={(e) => {
                                    setFieldValue('role', e.target.value);
                                  }}
                                  // placeholder='Select a Role'
                                  onBlur={handleBlur}
                                  label="Role"
                                  name="role"
                                  Asterisk="*"
                                  roles={userRoles}
                                  selected={!(touched && touched.role)}
                                  disabled={userIDNotMatched ? locked : true}
                                />
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="mb-3 pb-3 d-flex flex-column">
                                <Field
                                  onChange={handleChange}
                                  placeholder="Enter Title"
                                  className="form-control"
                                  onBlur={handleBlur}
                                  value={values.title}
                                  label="Title"
                                  name={`title`}
                                  type="text"
                                  Asterisk="*"
                                  disabled={locked}
                                />
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="mb-3 pb-3 d-flex flex-column">
                                <label className="detailLabel-control">
                                  Clients
                                  {!(values.role === 'department_head') && (
                                    <span style={{ color: 'red' }}>*</span>
                                  )}
                                </label>
                                <Select
                                  isDisabled={
                                    values.role === 'department_head' || locked
                                  }
                                  styles={{
                                    control: (provided) => ({
                                      ...provided,
                                      border: '1px solid #e2e2e2',
                                      minHeight: '40px',
                                      borderRadius: '5px',
                                      boxShadow: 'none',
                                    }),
                                  }}
                                  isMulti
                                  name="clients"
                                  options={dropdownOptions}
                                  onChange={(selectedOptions) => {
                                    // Formik's setFieldValue function can be used to update the values
                                    const prfOpt = selectedOptions.map(
                                      (item) => ({
                                        value: item.value,
                                        label: item.value,
                                      }),
                                    );
                                    setFieldValue('clients', prfOpt);
                                  }}
                                  value={
                                    values.role === 'department_head'
                                      ? []
                                      : values.clients.map((client) => ({
                                          value: client.value,
                                          label: client.label,
                                        }))
                                  }
                                />
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="mb-3 pb-3 d-flex flex-column">
                                <Dropdown
                                  onChange={(e) => {
                                    setFieldValue('department', e.target.value);
                                  }}
                                  placeholder="Select Department"
                                  onBlur={handleBlur}
                                  label="Department"
                                  name="department"
                                  Asterisk="*"
                                  roles={
                                    values.role === 'account_manager'
                                      ? accountManagerDepartment
                                      : userDepartments
                                  }
                                  selected={!(touched && touched.department)}
                                  disabled={locked}
                                />
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </FormikForm>
                )}
              </Formik>
            </Container>
          </main>
        </Layout>
      </AuthenticatedTemplate>
    </>
  );
};

export default UserDetail;
