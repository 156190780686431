/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
  useIsAuthenticated,
} from '@azure/msal-react';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import { Navigate, useNavigate } from 'react-router-dom';
import ReactDatePicker from 'react-datepicker';
import AxiosInstance from '../../services/api';
import { GET_ALL_LOGS } from '../../services/routes/prf';
import { AppContext } from '../../context/appContext';
import { GET_USER_ROLES } from '../helper/constant';
import ExcelExport from './excelExport';

const { SUPER_USER, DEPARTMENT_HEAD, ACCOUNT_MANAGER, TALENT } = GET_USER_ROLES;
// const { TALENT } = GET_USER_ROLES;

const PrfLogTable = ({ show, onHide }) => {
  const { getRole, profile } = React.useContext(AppContext);
  const { instance } = useMsal();
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const [, setLoading] = React.useState(false);
  const [logs, setLogs] = React.useState([]);
  const [dateRange, setDateRange] = React.useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [activeTab, setActiveTab] = React.useState(30);
  const getAllLogs = async (sDate, eDate) => {
    try {
      setLoading(true);
      const res = await AxiosInstance.get(
        `${GET_ALL_LOGS}?startDate=${sDate.format(
          'YYYY-MM-DD',
        )}&endDate=${eDate.format('YYYY-MM-DD')}`,
      );
      moment(startDate).add('day', 1).toDate();
      setLogs(res.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const getByDays = async (days) => {
    const endDateParam = moment();
    const startDateParam = endDateParam.clone().subtract(days, 'days');
    setActiveTab(days);
    setDateRange([startDateParam.toDate(), endDateParam.toDate()]);
    await getAllLogs(startDateParam, endDateParam);
  };

  React.useEffect(() => {
    if (instance && isAuthenticated && profile) {
      const userRole = getRole();
      if (
        ![SUPER_USER, DEPARTMENT_HEAD, TALENT, ACCOUNT_MANAGER].includes(
          userRole,
        )
      ) {
        navigate('/');
      }
      getByDays(30);
    }
  }, [instance, isAuthenticated, profile]);

  const fetchStatuses = (statusesArray) => {
    const result = {
      'Forwarded Date': '',
      'Rejected Date': '',
      'ReSubmitted Date': '',
      'Internal Hire Date': '',
    };

    statusesArray.forEach((item) => {
      result['Forwarded Date'] =
        item.status === 3 ? item.createdAt : result['Forwarded Date'];

      result['Rejected Date'] =
        item.status === 4 ? item.createdAt : result['Rejected Date'];

      result['ReSubmitted Date'] =
        item.status === 6 ? item.createdAt : result['ReSubmitted Date'];

      result['Internal Hire Date'] =
        item.status === 8 ? item.createdAt : result['Internal Hire Date'];
    });

    return result;
  };

  const fetchComments = (commentsArray) =>
    commentsArray.map((item) => ({ 'Reason for Closure': item.content }));

  function formatEngagementType(type) {
    return type
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  const excelData = logs.map((log) => {
    console.log(log);
    const formattedType = log.typesOfEngagement
      ? formatEngagementType(log.typesOfEngagement)
      : '';
    return {
      'Hubspot ID': log.hubspotid,
      Client: log.client,
      Role: log.jobTitle,
      'Project / Team Name': log.projectName,
      'Created by': log.requestedBy,
      'Created Date': log.createdAt,
      'Types of Engagement': formattedType,
      'Approved Date': log.acceptedDate,
      ...fetchStatuses(log.prf_statuses),
      'PRF Closed Date': log.closedDate,
      ...fetchComments(log.comments)[0],
    };
  });

  function flattenObject(obj, parentKey = '') {
    let result = {};
    let count = 1;

    // eslint-disable-next-line no-restricted-syntax
    for (const key in obj) {
      if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
        // eslint-disable-next-line prefer-template
        const nestedObject = flattenObject(obj[key], parentKey + ' ' + count);
        result = { ...result, ...nestedObject };
        // eslint-disable-next-line no-plusplus
        count++;
      } else {
        result[key + parentKey] = obj[key];
      }
    }

    return result;
  }

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h2>PRF Logs</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="grid-example">
          <Container>
            <Row>
              <Col xs={12} md={12}>
                <Container fluid className="d-flex flex-column p-0">
                  <div className="analyticsContent">
                    <div className="p-4 filters d-flex flex-column flex-md-row justify-content-between align-items-center">
                      <ul className="filterList d-flex list-unstyled mb-3 mb-md-0">
                        <li>
                          <Button
                            id="monthly-logs-button"
                            onClick={() => getByDays(30)}
                            type="button"
                            className={`${activeTab === 30 ? 'active' : ''}`}
                          >
                            Monthly
                          </Button>
                        </li>
                        <li>
                          <Button
                            id="quarterly-logs-button"
                            onClick={() => getByDays(90)}
                            type="button"
                            className={`${activeTab === 90 ? 'active' : ''}`}
                          >
                            Quarterly
                          </Button>
                        </li>
                        <li>
                          <Button
                            id="annually-logs-button"
                            onClick={() => getByDays(365)}
                            type="button"
                            className={`${activeTab === 365 ? 'active' : ''}`}
                          >
                            Annually
                          </Button>
                        </li>
                      </ul>
                      <div className="dateArea">
                        <span className="d-flex align-items-center">
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_3493_5168)">
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M6.42857 1.42857C6.42857 0.639593 5.78897 0 5 0C4.21103 0 3.57143 0.639593 3.57143 1.42857V2.85714H2.14286C0.95939 2.85714 0 3.81653 0 5V7.14286H20V5C20 3.81653 19.0406 2.85714 17.8571 2.85714H16.4287V1.42857C16.4287 0.639593 15.7891 0 15.0001 0C14.2112 0 13.5716 0.639593 13.5716 1.42857V2.85714H6.42857V1.42857ZM20 8.92857H0V17.8571C0 19.0406 0.95939 20 2.14286 20H17.8571C19.0406 20 20 19.0406 20 17.8571V8.92857Z"
                                fill="#1D2541"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_3493_5168">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                          <em className="ps-2">Date: </em>
                        </span>
                        <ReactDatePicker
                          showIcon={true}
                          selectsRange={true}
                          startDate={startDate}
                          endDate={endDate}
                          onChange={(update) => {
                            setDateRange(update);
                            if (update.every((item) => item !== null)) {
                              setActiveTab(0);
                              getAllLogs(moment(update[0]), moment(update[1]));
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </Container>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onHide} id="close-button">
            Close
          </Button>
          <ExcelExport
            excelData={excelData}
            fileName={
              activeTab === 30
                ? 'PRF_Logs_Monthly'
                : activeTab === 90
                ? 'PRF_Logs_Quarterly'
                : activeTab === 365
                ? 'PRF_Logs_Annualy'
                : 'PRF_Logs'
            }
          />
        </Modal.Footer>
      </Modal>
      <UnauthenticatedTemplate>
        <Navigate to="/" />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate></AuthenticatedTemplate>
    </>
  );
};

export default PrfLogTable;
