/* eslint-disable */

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from '@react-pdf/renderer';
import ml from '../assets/images/mobileliveLogo2.png';
import pp from '../assets/images/userPlaceHolder.png';
import phone from '../assets/images/phoneSVG.png';
import mail from '../assets/images/mailSVG.png';
import location from '../assets/images/locationSVG.png';
import work from '../assets/images/workSVG.png';
import skills from '../assets/images/skillSVG.png';
import education from '../assets/images/educationSVG.png';
import checkLogo from '../assets/images/checkLogo.png';
import LatoRegular from '../assets/fonts/Lato-Regular.ttf';
import LatoBold from '../assets/fonts/Lato-Bold.ttf';
import { useMemo } from 'react';

// Register the Lato font
Font.register({
  family: 'Lato',
  fonts: [{ src: LatoRegular }, { src: LatoBold, fontWeight: 'bold' }],
});

const GRAY = '#696B70';
const DARKGRAY = '#535559';
const ORANGE = '#E58853';

// Create styles
const styles = StyleSheet.create({
  background: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    width: '30%',
    backgroundColor: '#fff',
  },
  page: {
    flexDirection: 'column',
    color: GRAY,
    backgroundColor: '#F2F3F5',
    paddingBottom: 10,
    paddingTop: 40,
  },
  body: {
    paddingHorizontal: 10,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 40,
    paddingHorizontal: 20,
    marginTop: -20,
  },
  section: {
    display: 'flex',
    flexDirection: 'row',
  },
  sectionLeft: {
    flexBasis: '30%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  sectionLeftItems: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#fff',
    paddingTop: 5,
    paddingBottom: 5,
  },
  sectionRight: {
    flexBasis: '70%',
    fontSize: 12,
    paddingTop: 7,
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 20,
  },
  skillsSection: {
    display: 'flex',
    flexDirection: 'row',
    flexBasis: '70%',
    fontSize: 12,
    paddingTop: 7,
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 20,
  },
  profilePicture: {
    width: 100,
    height: 100,
    borderRadius: 50,
    border: `5px solid ${ORANGE}`,
  },
  name: {
    fontFamily: 'Lato',
    fontWeight: 'bold',
    fontSize: 18,
    color: ORANGE,
    marginBottom: 10,
  },
  icons: {
    width: 'auto',
    height: 15,
    marginRight: 10,
  },
  details: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 25,
  },
  workExperience: {
    marginBottom: 16,
  },
  summary: {
    fontSize: 11,
    marginTop: 8,
    lineHeight: 1.3,
  },
  qualifications: {
    marginBottom: 10,
  },
  workExperienceTitle: {
    fontFamily: 'Lato',
    fontWeight: 'bold',
    fontSize: 13,
    marginBottom: 8,
    color: DARKGRAY,
  },
  workExperienceContent: {
    marginTop: 5,
  },
  viewer: {
    width: '100%',
    height: '100vh',
  },
  logo: {
    width: 'auto',
    height: 15,
  },
  skillList: {
    display: 'flex',
    flexDirection: 'row',
    paddingRight: 20,
    marginBottom: 5,
  },
  column: {
    flex: 1,
  },
  boldText: {
    fontFamily: 'Lato',
    fontWeight: 'bold',
  },
  borderImage: {
    border: `5px solid ${ORANGE}`,
    borderRadius: '50%',
  },
  introSectionRoad: {
    border: `5px solid ${ORANGE}`,
    backgroundColor: ORANGE,
    position: 'absolute',
    marginTop: 105,
    height: '200vh',
  },
  leftSectionRoad: {
    border: `5px solid ${ORANGE}`,
    backgroundColor: ORANGE,
    position: 'absolute',
    height: '200vh',
  },
  leftSectionRoadDots: {
    borderLeft: '2px dashed #3C5C76',
    height: '100%',
    backgroundColor: ORANGE,
  },
  certifications: {
    backgroundColor: '#fff',
    position: 'absolute',
    width: 100,
    marginTop: 20,
  },
  certificationContent: {
    marginBottom: 5,
  },
});

export const LOCAL_STORAGE_KEYS = {
  LOGIN_IN_PROCESS: 'LOGIN_IN_PROCESS',
  LOGIN_EVENT: 'LOGIN_EVENT',
};

export const LOCAL_STORAGE_VALUES = {
  LOGIN_IN_PROCESS: 'true',
};

export const MSAL_SESSION_STORAGE_KEYS = {
  INTERATION_STATUS: 'interaction.status',
  ACCOUNT_KEYS: 'msal.account.keys',
};

export const MyDocument = ({ formData }) => {
  const roadheight = useMemo(() => {
    let heights = {
      intro: [formData.location, formData.phone, formData.email]
        .filter((f) => !!f)
        .reduce((p) => p + 35, 0),
      skills: 0,
      experience: 0,
      education: 0,
    };
    if (formData.skills) {
      heights.skills = 40 + Math.ceil(formData.skills.length / 2) * 170;
    }
    let multiplier = 0;
    if (formData.workExperience) {
      if (formData.workExperience.length > 2) {
        multiplier = 170;
      } else multiplier = 16;
      heights.experience +=
        60 +
        formData.workExperience.reduce((p, n) => {
          return p + 50 + (n.experience.length / 50) * multiplier;
        }, 0);
    }

    if (formData.certifications?.length > 0) {
      if (formData.qualifications) {
        heights.education = '125%';
      }
    }
    return heights;
  }, [formData]);
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.background} />
        <View style={styles.background} />
        <View style={styles.body}>
          <View style={styles.bodyPadding}>
            <View style={styles.header}>
              <Image style={styles.logo} src={ml} />
              <Image style={styles.logo} src={checkLogo} />
            </View>
            <View style={styles.section}>
              <View style={styles.sectionLeft}>
                <View style={styles.borderImage}>
                  <Image style={styles.profilePicture} src={pp} />
                </View>
                <View style={{ ...styles.introSectionRoad }}>
                  <View style={styles.leftSectionRoadDots}></View>
                </View>
              </View>
              <View style={styles.sectionRight}>
                <Text style={styles.name}>
                  {formData.firstName} {formData.lastName}
                </Text>
                <Text style={styles.details}>{formData.jobTitle}</Text>
                {formData.location ? (
                  <View style={styles.details}>
                    <Image style={styles.icons} src={location} />
                    <Text>{formData.location}</Text>
                  </View>
                ) : null}
              </View>
            </View>
            <View style={styles.section}>
              <View style={styles.sectionLeft}>
                <View style={{ ...styles.leftSectionRoad }}>
                  <View style={styles.leftSectionRoadDots}></View>
                </View>
                <View style={styles.sectionLeftItems}>
                  <Image style={styles.icons} src={skills} />
                  <Text style={styles.boldText}>Skills</Text>
                </View>
              </View>
              <View style={styles.skillsSection}>
                <View style={styles.column}>
                  {formData.skills
                    .slice(0, Math.ceil(formData.skills.length / 2))
                    .map((skill, index) => (
                      <View style={styles.skillList} key={index}>
                        <Text>• </Text>
                        <Text>{skill}</Text>
                      </View>
                    ))}
                </View>
                <View style={styles.column}>
                  {formData.skills
                    .slice(Math.ceil(formData.skills.length / 2))
                    .map((skill, index) => (
                      <View style={styles.skillList} key={index}>
                        <Text>• </Text>
                        <Text>{skill}</Text>
                      </View>
                    ))}
                </View>
              </View>
            </View>
            <View style={styles.section}>
              <View style={styles.sectionLeft}>
                <View
                  style={{
                    ...styles.leftSectionRoad,
                  }}
                >
                  <View style={styles.leftSectionRoadDots}></View>
                </View>
                <View style={styles.sectionLeftItems}>
                  <Image style={styles.icons} src={work} />
                  <Text style={styles.boldText}>Work Exp</Text>
                </View>
              </View>
              <View style={styles.sectionRight}>
                {formData.workExperience.map((experience, index) => (
                  <View wrap={false} key={index} style={styles.workExperience}>
                    <Text style={styles.workExperienceTitle}>
                      {experience.experience}
                    </Text>
                    {/* Only render this Text component if duration is not 'unknown' or 'Not available' */}
                    {experience.duration !== 'Unknown' &&
                      experience.duration !== 'Not Mentioned' &&
                      experience.duration !== 'N/A' &&
                      experience.duration !== 'Not Available' && (
                        <Text>{experience.duration}</Text>
                      )}
                    <Text style={styles.summary}>{experience.summary}</Text>
                  </View>
                ))}
              </View>
            </View>
            <View wrap={false} style={styles.section}>
              <View style={styles.sectionLeft}>
                {formData.certifications &&
                  formData.certifications.length > 0 && (
                    <View
                      style={{
                        ...styles.leftSectionRoad,
                      }}
                    >
                      <View style={styles.leftSectionRoadDots}></View>
                    </View>
                  )}
                <View style={styles.sectionLeftItems}>
                  <Image style={styles.icons} src={education} />
                  <Text style={styles.boldText}>Education</Text>
                </View>
              </View>
              <View style={styles.sectionRight}>
                {formData.qualifications.map((education, index) => (
                  <View wrap={false} key={index} style={styles.qualifications}>
                    <Text style={styles.workExperienceTitle}>
                      {education.education}
                    </Text>
                    <Text>{education.duration}</Text>
                    <Text style={styles.workExperienceContent}>
                      {education.institution}
                    </Text>
                  </View>
                ))}
              </View>
            </View>
            {formData.certifications.length > 0 ? (
              <View style={styles.section}>
                <View style={styles.sectionLeft}>
                  <View style={styles.sectionLeftItems}>
                    <Image style={styles.icons} src={skills} />
                    <Text style={styles.boldText}>Certifications</Text>
                  </View>
                </View>
                <View style={styles.sectionRight}>
                  {formData.certifications.map((certification, index) => (
                    <Text style={styles.certificationContent} key={index}>
                      • {certification}
                    </Text>
                  ))}
                </View>
              </View>
            ) : null}
          </View>
        </View>
      </Page>
    </Document>
  );
};
