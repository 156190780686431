/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { FaLongArrowAltUp, FaLongArrowAltDown } from 'react-icons/fa';
import { Form } from 'react-bootstrap';
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  getFilteredRowModel,
  getPaginationRowModel,
} from '@tanstack/react-table';
import { rankItem } from '@tanstack/match-sorter-utils';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import iconFilter from '../../assets/images/icon-filter.svg';
import Loader from '../../assets/images/loader.gif';
import { DebouncedInput } from './debounceInput';
import { COLUMNS } from './columns';
import { getFirstIndexPagination, getLastIndexPagination } from '../utils/util';

const fuzzyFilter = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value);
  addMeta({ itemRank });
  return itemRank.passed;
};

export const BasicTable = ({
  data,
  arePrfsLoading,
  isAccountManager,
  isDepartmentHead,
  // eslint-disable-next-line react/prop-types
  paragraph,
  // eslint-disable-next-line react/prop-types
  heading,
  // eslint-disable-next-line react/prop-types
  paragraph2,
}) => {
  const navigate = useNavigate();
  const [, setActivePageIndex] = useState(0);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  console.log(data, 'data');
  const [selectedStatuses, setSelectedStatuses] = useState([]); // State to store the selected statuses
  const [filteredData, setFilteredData] = useState(data); //
  const [showFilters, setShowFilters] = useState(false);

  useEffect(() => {
    if (selectedStatuses.length === 0) {
      setFilteredData(data);
    } else {
      const filtered = data.filter((item) => {
        const lastStatus =
          item.prf_statuses[item.prf_statuses.length - 1]?.status; // Get the last status object in the inner array
        return selectedStatuses.includes(lastStatus);
      });
      setFilteredData(filtered);
    }
  }, [data, selectedStatuses]);

  const handleCheckboxChange = (event) => {
    const status = parseInt(event.target.value);

    setSelectedStatuses((prevSelectedStatuses) => {
      if (prevSelectedStatuses.includes(status)) {
        return prevSelectedStatuses.filter((s) => s !== status);
      } else {
        return [...prevSelectedStatuses, status];
      }
    });
  };

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const table = useReactTable({
    data: filteredData, // Use filteredData instead of data
    columns: COLUMNS,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      sorting,
      globalFilter,
      pagination,
    },
    columnFilters: [],
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    debugTable: false,
    globalFilterFn: fuzzyFilter,
  });

  const handlePageClick = (event) => {
    setPagination((prev) => ({ ...prev, pageIndex: event.selected }));
    setActivePageIndex(event.selected);
    table.setPageIndex(event.selected);
  };

  return (
    <>
      <div className={'prfListHead'}>
        {(() => {
          if (isDepartmentHead) {
            return (
              <div className="prfListTitle">
                <h2>{heading}</h2>
                <p>{paragraph}</p>
              </div>
            );
          }
          if (isAccountManager) {
            return (
              <div className="prfListTitle">
                <h2>{heading}</h2>
                <p>{paragraph}</p>
              </div>
            );
          }
          return (
            <div className="prfListTitle">
              <h2>{heading}</h2>
              <p>{paragraph2}</p>
            </div>
          );
        })()}

        <div className="prfTableFilters d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <span>Show</span>
            <Form.Select
              className={'mx-2'}
              aria-label="Default select example"
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40].map((pageSize) => (
                <option key={`pageSize-${pageSize}`} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </Form.Select>
            <span>entries</span>
          </div>
          <div className="d-flex flex-row gap-2">
            <DebouncedInput
              value={globalFilter ?? ''}
              onChange={(value) => setGlobalFilter(String(value))}
            />
            <svg
              style={{ cursor: 'pointer' }}
              onClick={() => setShowFilters(!showFilters)}
              height={30}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15 10.5A3.502 3.502 0 0 0 18.355 8H21a1 1 0 1 0 0-2h-2.645a3.502 3.502 0 0 0-6.71 0H3a1 1 0 0 0 0 2h8.645A3.502 3.502 0 0 0 15 10.5zM3 16a1 1 0 1 0 0 2h2.145a3.502 3.502 0 0 0 6.71 0H21a1 1 0 1 0 0-2h-9.145a3.502 3.502 0 0 0-6.71 0H3z"
                  fill="#202540"
                ></path>
              </g>
            </svg>
          </div>
        </div>
        {showFilters && heading === 'All PRFs' && (
          <div class="status-container">
            <label>
              <input
                type="checkbox"
                value="1"
                checked={selectedStatuses.includes(1)}
                onChange={(event) => handleCheckboxChange(event)}
              />
              Initiated
            </label>
            <label>
              <input
                type="checkbox"
                value="8"
                checked={selectedStatuses.includes(8)}
                onChange={(event) => handleCheckboxChange(event)}
              />
              Internal Hire
            </label>
            <label>
              <input
                type="checkbox"
                value="3"
                checked={selectedStatuses.includes(3)}
                onChange={(event) => handleCheckboxChange(event)}
              />
              Forwarded
            </label>
            <label>
              <input
                type="checkbox"
                value="4"
                checked={selectedStatuses.includes(4)}
                onChange={(event) => handleCheckboxChange(event)}
              />
              Rejected
            </label>
            <label>
              <input
                type="checkbox"
                value="5"
                checked={selectedStatuses.includes(5)}
                onChange={(event) => handleCheckboxChange(event)}
              />
              Accepted
            </label>
            <label>
              <input
                type="checkbox"
                value="6"
                checked={selectedStatuses.includes(6)}
                onChange={(event) => handleCheckboxChange(event)}
              />
              Submitted
            </label>
            <label>
              <input
                type="checkbox"
                value="7"
                checked={selectedStatuses.includes(7)}
                onChange={(event) => handleCheckboxChange(event)}
              />
              Closed
            </label>
          </div>
        )}
      </div>
      <div className="tabelHolder">
        <table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={`headerGroup-${headerGroup.id}`}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={`header-${header.id}`}
                    style={{
                      width: header.getSize() ? header.getSize() : undefined,
                    }}
                  >
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? 'cursor-pointer'
                            : '',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                        {{
                          asc: <FaLongArrowAltUp className="ms-2" />,
                          desc: <FaLongArrowAltDown className="ms-2" />,
                        }[header.column.getIsSorted()] ?? (
                          <img
                            className="ms-2"
                            src={iconFilter}
                            alt="filter icon"
                          />
                        )}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody>
            {arePrfsLoading ? (
              <tr>
                <td
                  style={{ height: '400px', textAlign: 'center' }}
                  colSpan={9}
                >
                  <img src={Loader} width={50} height={50} />
                </td>
              </tr>
            ) : table.getPrePaginationRowModel().rows.length === 0 &&
              globalFilter ? (
              <tr>
                <td colSpan={'9'} className={'text-center bg-white'}>
                  <div className="noPRF d-flex flex-column text-center">
                    <p className="mb-0">No result found</p>
                  </div>
                </td>
              </tr>
            ) : table.getPrePaginationRowModel().rows.length === 0 ? (
              <tr>
                <td colSpan={'9'} className={'text-center bg-white'}>
                  <div className="noPRF d-flex flex-column text-center">
                    <p className="mb-0">No Data Available</p>
                  </div>
                </td>
              </tr>
            ) : (
              table.getRowModel().rows.map((row) => {
                // Assuming prf_statuses is sorted and the last item is the latest status
                const latestPrfStatus =
                  row.original.prf_statuses[
                    row.original.prf_statuses.length - 1
                  ]?.status;

                return (
                  <tr
                    key={`row-${row.original.id}`}
                    className={`status${latestPrfStatus}`} // Prepend "status" to the class name
                    onClick={() => navigate(`/edit/${row.original.id}`)}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td key={`cell-${cell.id}`}>
                        <div className="td_box">
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </div>
                      </td>
                    ))}
                  </tr>
                );
              })
            )}
          </tbody>
          {/* {data.length ?
            <tbody>
              {table.getRowModel().rows.map(row => (
                <tr key={`row-${row.original.id}`} onClick={() => window.location.href = `/edit/${row.original.id}`}>
                  {row.getVisibleCells().map(cell => (
                    <td key={`cell-${cell.id}`}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          :
          (arePrfsLoading) ?
            <tbody><tr><td style={{height:"400px"  , textAlign:"center" }} colSpan={9}><img src={Loader} width={50} height={50}/></td></tr></tbody>
          :
          ((table.getPrePaginationRowModel()?.rows.length) === 0) ?
            <tbody>
              <tr>
                  <td colspan={'9'} className={'text-center bg-white'}>
                    <div className="noPRF d-flex flex-column text-center">
                      <img className="mx-auto" src={'/noPRF.svg'} width={"237"} height={"155"} alt="no comments found"/>
                      <p className="mb-0">No result found</p>
                    </div>
                  </td>
              </tr>
            </tbody>
          :
            <tbody>
              <tr>
                  <td colspan={'9'} className={'text-center bg-white'}>
                    <div className="noPRF d-flex flex-column text-center">
                      <img className="mx-auto" src={'/noPRF.svg'} width={"237"} height={"155"} alt="no comments found"/>
                      <p className="mb-0">No Data Available</p>
                    </div>
                  </td>
              </tr>
            </tbody>
          } */}
        </table>
      </div>

      <div className="paginations d-flex justify-content-between align-items-center">
        <span>
          Showing {getFirstIndexPagination(pagination, table, filteredData)} to{' '}
          {getLastIndexPagination(pagination, table, filteredData)} of{' '}
          {filteredData.length} entries
        </span>

        {filteredData.length ? (
          <ReactPaginate
            containerClassName="pagination m-0"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            activeClassName="active"
            previousClassName="page-item previous"
            nextClassName="page-item next"
            disabledClassName="disabled"
            previousLinkClassName="page-link"
            nextLinkClassName="page-link"
            breakLabel="..."
            nextLabel="Next"
            onPageChange={handlePageClick}
            pageCount={Math.ceil(filteredData.length / pagination.pageSize)}
            previousLabel="Previous"
            renderOnZeroPageCount={null}
            pageRangeDisplayed="2"
            marginPagesDisplayed="2"
          />
        ) : null}
      </div>
      {/* <pre>{JSON.stringify(table.getState(), null, 2)}</pre> */}
    </>
  );
};

BasicTable.propTypes = {
  // The Data is used to show values in the table
  data: PropTypes.array,
  // Sets the loading true or false
  arePrfsLoading: PropTypes.bool,
  isAccountManager: PropTypes.bool,
  isDepartmentHead: PropTypes.bool,
};
