import { useState } from 'react';
import moment from 'moment';
import { useField, useFormikContext, ErrorMessage } from 'formik';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import dayjs from 'dayjs';
// import { date } from "yup";
import range from 'lodash/range';
import { getDay, getYear, getMonth } from 'date-fns';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';

const DatePickerRMS = ({
  name,
  label,
  disabled,
  placeholder,
  min,
  Asterisk,
}) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(name);
  const { validateField } = useFormikContext();
  const isWeekday = (date) => {
    const day = getDay(date);
    return day !== 0 && day !== 6;
  };

  const [, setStartDate] = useState(null);
  const years = range(1990, getYear(new Date()) + 1, 1);
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  return (
    <>
      <label className="detailLabel" htmlFor={name}>
        {label}
        {Asterisk && <span style={{ color: 'red' }}>*</span>}
      </label>
      <br />
      <DatePicker
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            style={{
              margin: 10,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              id="date-less-than-button"
              className="btncalander"
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
            >
              {'<'}
            </Button>
            <select
              value={getYear(date)}
              onChange={({ target: { value } }) => changeYear(value)}
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <select
              value={months[getMonth(date)]}
              onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
              }
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <Button
              id="date-greater-than-button"
              className="btncalander"
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
            >
              {'>'}
            </Button>
          </div>
        )}
        id={name}
        aria-labelledby={`label-${name}`}
        {...field}
        minDate={min}
        filterDate={isWeekday}
        // excludeDateIntervals={[{start: subDays(new Date(), 6), end: addDays(new Date(), 6) }]}
        disabled={disabled}
        // min={min}
        placeholderText={placeholder}
        value={field.value && dayjs(field.value).format('DD/MM/YYYY')}
        className="form-control datepickerrms"
        name={name}
        selected={(field.value && new Date(field.value)) || null}
        onChange={(val, date) => {
          setStartDate(date);
          validateField(name);
          setFieldValue(field.name, moment(val).format('YYYY/MM/DD'));
        }}
      />
      {field.value && !disabled && (
        <Button
          id="date-reset-button"
          disabled={disabled}
          variant="danger"
          className="CrossButton p-0 btn-clearDate"
          onClick={() => setFieldValue(field.name, '')} // remove a friend from the list
        >
          {/* <span className="CrossICon">X</span> */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            fill="currentColor"
            className="bi bi-x"
            viewBox="0 0 16 16"
          >
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
          </svg>
        </Button>
      )}
      <div className="errorMessage">
        <ErrorMessage name={name} />
      </div>
    </>
  );
};
DatePickerRMS.propTypes = {
  key: PropTypes.number,
  // Indicates the DateSelect is disabled
  disabled: PropTypes.bool,
  // An asterisk symbol to indicate a required field (if applicable).
  Asterisk: PropTypes.string,
  // The name attribute for the DateSelect input.
  label: PropTypes.string,
  // The placeholder text to display in the DateSelect input.
  name: PropTypes.string,
  // The placeholder text to display in the DateSelect input.
  placeholder: PropTypes.string,
  // Minimum Value
  min: PropTypes.number,
};
export default DatePickerRMS;
