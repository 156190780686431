import React, { useEffect, useRef } from 'react';
import { Field as FormikField, ErrorMessage } from 'formik';
import PropTypes from 'prop-types';

const RmsField = ({ label, name, type, Asterisk, disabled, min }) => {
  const formikRef = useRef(null);
  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.focus();
    }
  }, [formikRef]);
  return (
    <>
      <label className={`${type === 'radio' ? 'mb-0' : 'detailLabel-control'}`}>
        {label}
        <span style={{ color: 'red' }}>{Asterisk}</span>
      </label>
      <FormikField
        name={name}
        disabled={disabled}
        min={min}
        innerRef={name === 'requestedBy' ? formikRef : null}
        type={type}
        className={`${type === 'radio' ? 'ms-3' : 'form-control'}`}
        placeholder="Enter Details"
      />
      <div className="errorMessage">
        <ErrorMessage name={name} />
      </div>
    </>
  );
};
RmsField.propTypes = {
  // The label to display for the field.
  label: PropTypes.string,
  // The name attribute for the field.
  name: PropTypes.string,
  // The type is field type
  type: PropTypes.string,
  // An asterisk symbol to indicate a required field (if applicable).
  Asterisk: PropTypes.symbol,
  // Indicates the field is disabled
  disabled: PropTypes.bool,
  // The minimum allowed value for the field (if applicable).
  min: PropTypes.number,
};
export default RmsField;
