const loginConfig = (accessToken) => {
  const config = {
    method: 'post',
    url: `${process.env.REACT_APP_API_URL}auth/ms-login`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      idToken: accessToken,
    },
  };
  return config;
};

export default loginConfig;
