import React from 'react';
import PropTypes from 'prop-types';
import Statistic from './statistic';
import Loader from '../../assets/images/loader.gif';

const Statistics = ({
  prfStatusCount,
  loading,
  handleTabsClick,
  setTabPrfStatus,
  tabPrfStatus,
}) => {
  if (loading) {
    return (
      <div className="backdrop">
        <img src={Loader} width={50} height={50} alt="Loading" />
      </div>
    );
  }

  return (
    <>
      {Object.entries(prfStatusCount).map(([key, value]) => (
        <Statistic
          key={key}
          title={key}
          value={value}
          handleTabsClick={handleTabsClick}
          setTabPrfStatus={setTabPrfStatus}
          tabPrfStatus={tabPrfStatus}
        />
      ))}
    </>
  );
};

Statistics.propTypes = {
  prfStatusCount: PropTypes.any,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  handleTabsClick: PropTypes.func,
  setTabPrfStatus: PropTypes.func,
  tabPrfStatus: PropTypes.number,
};

export default Statistics;
