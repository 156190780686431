import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const Statistic = ({
  title,
  value,
  handleTabsClick,
  setTabPrfStatus,
  tabPrfStatus,
}) => {
  const [activeTab, setActiveTab] = useState(null);

  // Define the tooltip content for value.info
  const renderTooltip = (props) => (
    <Tooltip id="info-tooltip" {...props} className="custom-tooltip">
      {value.info}
    </Tooltip>
  );

  function removeUnderscoreAndAddSpace() {
    let keyText = title.replace(/_/g, ' ');
    if (keyText === 'Total PRFs') {
      keyText = 'Total Active PRFs';
    }
    if (keyText === 'Total Submitted PRFs') {
      keyText = 'Total Submitted Profiles';
    }
    if (keyText === 'Average Submitted Time') {
      keyText = 'Average Profile Submission Time';
    }
    if (keyText === 'Total Submitted Profiles') {
      keyText = 'Total PRFS (Profiles Submitted)';
    }
    return keyText;
  }

  const renderValue = () => {
    if (
      title === 'Average_Acceptance_Time' ||
      title === 'Average_Closure_Time' ||
      title === 'Average_Submitted_Time' ||
      title === 'Average_Forwarded_Time' ||
      title === 'Average_PRF_Resubmission_Time'
    ) {
      const roundedValue = Math.round(value); // Round to the nearest whole number
      return (
        <>
          {roundedValue}{' '}
          <span style={{ fontSize: 'smaller' }}>
            Day{roundedValue !== 1 ? 's' : ''}
          </span>
        </>
      );
    }
    return (
      <>
        {value.prf}{' '}
        <span style={{ fontSize: 'smaller' }}>({value.openings})</span>
      </>
    );
  };

  const keyText = removeUnderscoreAndAddSpace();
  const isSpecialTitle = keyText === 'Total PRFS (Profiles Submitted)';

  // Adjusted to conditionally include "(Openings)" based on title and special flag
  const statisticTitle = (
    <>
      {keyText}
      {!title.includes('Average') && !isSpecialTitle && ' (Openings)'}
    </>
  );

  const handleClick = () => {
    let status = 0;
    if (title === 'Total_New_PRFs') {
      status = 1;
    } else if (title === 'Total_Forwarded_PRFs') {
      status = 3;
    } else if (title === 'Total_Rejected_PRFs') {
      status = 4;
    } else if (title === 'Total_Accepted_PRFs') {
      status = 5;
    } else if (title === 'Total_Submitted_PRFs') {
      status = 6;
    } else if (title === 'Total_Closed_PRFs') {
      status = 7;
    } else if (title === 'Total_Internal_Hires_In_Progress') {
      status = 8;
    } else if (title === 'Total_PRFs' || title === 'Total_Active_PRFs') {
      status = 9;
    }
    if (status !== 0) {
      setTabPrfStatus(status);
      handleTabsClick(status);
      setActiveTab(status);
    }
  };

  return (
    <>
      <div
        className={`statistic ${title} mb-0 ${
          tabPrfStatus === activeTab && 'active'
        }`}
        onClick={handleClick}
      >
        <div className="d-flex flex-row">
          <h2>{statisticTitle}</h2>
          {!title.includes('Average') && (
            <h2>
              <OverlayTrigger placement="top" overlay={renderTooltip}>
                <span className="info_icon">
                  <svg
                    width={17}
                    height={17}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="gray"
                      d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"
                    />
                  </svg>
                </span>
              </OverlayTrigger>
            </h2>
          )}
        </div>
        <h3>{renderValue()}</h3>
      </div>
    </>
  );
};

Statistic.propTypes = {
  title: PropTypes.string,
  value: PropTypes.any,
  handleTabsClick: PropTypes.func,
  setTabPrfStatus: PropTypes.func,
  tabPrfStatus: PropTypes.number,
};

export default Statistic;
