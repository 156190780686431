import React from 'react';
import { Field, ErrorMessage } from 'formik';
import { Col } from 'react-bootstrap';
import PropTypes from 'prop-types';

const OtherClients = ({
  roles,
  selected,
  setShowOtherField,
  setFieldValue,
  disabled,
  values,
  handleInputChange,
}) => (
  <>
    <Col md={4} className="mb-4">
      <label
        data-testid="new-client-label"
        htmlFor="client"
        className="detailLabel"
      >
        Client<span style={{ color: 'red' }}>*</span>
      </label>
      <Field
        as="select"
        id="client"
        data-testid="new-client-input"
        className="postionDetail"
        disabled={disabled}
        onChange={(ev) => {
          setFieldValue('client', ev.target.value);
          setShowOtherField(ev.target.value === 'Others');
          handleInputChange(ev);
        }}
        value={values.client}
        name="client"
      >
        <option
          style={{ color: '#c3bfbf' }}
          value=""
          disabled
          selected={selected.client}
        >
          Enter Client
        </option>
        {roles &&
          !disabled &&
          roles.map((role, index) => (
            <>
              <option key={`${index}-${role.value}`} value={role.value}>
                {role.client}
              </option>
            </>
          ))}
        {!disabled ? (
          <option key={`${'Others'}`} value={'Others'}>
            Others
          </option>
        ) : (
          <option key={values.client} value={values.client}>
            {values.client}
          </option>
        )}
      </Field>
      <div className="errorMessage">
        <ErrorMessage name="client" />
      </div>
    </Col>

    {values.client === 'Others' && !disabled && (
      <Col md={4} className="mb-4">
        <label className="detailLabel">
          New Client<span style={{ color: 'red' }}>*</span>
        </label>
        <Field
          disabled={disabled}
          className="form-control"
          name="otherClient"
          placeholder="Enter other Client"
          value={values.otherClients}
          onChange={(e) => {
            setFieldValue('otherClient', e.target.value);
            handleInputChange(e);
          }}
        />
        <div className="errorMessage">
          <ErrorMessage name="otherClient" />
        </div>
      </Col>
    )}
  </>
);
OtherClients.propTypes = {
  // Indicates the Other-client field is disabled
  disabled: PropTypes.bool,
  // The setFieldValue used in onchange in Dropdown.
  setFieldValue: PropTypes.func,
  // The setShowOtherField used in onchange in Dropdown.
  setShowOtherField: PropTypes.func,
  // The values used in an array to set the value of the dropdown.
  values: PropTypes.object, // Assuming values is an object, not an array
  // The roles are set dropdown values.
  roles: PropTypes.array,
  // The Selected is used to true or false
  selected: PropTypes.object, // Assuming selected is an object, not a bool
  handleInputChange: PropTypes.func,
};

export default OtherClients;
