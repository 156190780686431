/* eslint-disable */

import React, { useEffect, useState, useContext } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import Form from 'react-bootstrap/Form';
import { Row, Col, Alert } from 'react-bootstrap';
import { useNavigate, Link, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import {
  Formik,
  Form as FormikForm,
  Field as FormikField,
  ErrorMessage,
} from 'formik';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';
// eslint-disable-next-line import/no-extraneous-dependencies
import { DocumentDownload } from 'grommet-icons';
import Accordion from '../../components/dashboard/accordion';
import { TextEditor } from '../../components/commons';
import AISection from '../../components/dashboard/aiSection';
import CommentSection from './commentSection';
import Dropdown from '../../components/dashboard/dropdown';
import Location from '../../components/dashboard/locationField';
import OtherClients from '../../components/dashboard/otherClients';
import Steps from '../steps';
import CloseButtonPRF from '../../components/dashboard/btnClose';
import Field from '../../components/form/field';
import AxiosInstance from '../../services/api';
import InternalHireModel from '../../components/dashboard/internalHireModel';
import AssignTobe from '../../components/dashboard/signToModel';

import {
  UPDATE_PRF,
  CREATE_PRF,
  GET_PRF_DATA,
  GET_PRF_FIELDS,
  GET_PRF_CLIENTS,
  POST_PRF_SPLIT,
  HUBSPOT_PRF,
  GET_PRF_DEPARTMENT,
  TALENT_PRF,
  POST_CATSONE,
  PRF_AI,
  PRF_GET_AI,
} from '../../services/routes/prf';
import PopUp from '../../components/dashboard/popup';
import Loader from '../../assets/images/loader.gif';
import {
  GET_USER_ROLES,
  // PRF_TYPEOFEMPLOYMENT,
  PRF_TYPEOFENGAGEMENT,
  PRF_HUBSPOTID,
  PRF_FIELDS,
  PRF_NUMOFHOUR,
} from '../../components/helper/constant';
import DateSelect from '../../components/dashboard/datepicker';
import PrfsDetails from '../details';
import { AppContext } from '../../context/appContext';
import Catsone from '../../components/dashboard/catsone';
import PresidentApproval from '../../components/dashboard/presidentApprovalModal';
import SubmitPopup from '../../components/dashboard/talentSubmissionPopup';

const {
  TALENT,
  ACCOUNT_MANAGER,
  DEPARTMENT_HEAD,
  SUPER_USER,
  TALENT_TEAM,
  PRESIDENT,
} = GET_USER_ROLES;
const { PRF_STATUS } = PRF_FIELDS;
const {
  initiated,
  rejected,
  accepted,
  closed,
  forward,
  submitted,
  // eslint-disable-next-line camelcase
  internal_resource,
} = PRF_STATUS;

// Getting a prfID means we are viewing a prf
// Added fetch prf and display values functionality
const PRFstep = () => {
  const { profile, getRole } = useContext(AppContext);
  const isTalent = getRole() === TALENT;
  const isTalentTeam = getRole() === TALENT_TEAM;
  const isAccounTManager = getRole() === ACCOUNT_MANAGER;
  const isDepartmentHead = getRole() === DEPARTMENT_HEAD;
  const isSuperUser = getRole() === SUPER_USER;
  const isPresident = getRole() === PRESIDENT;

  const [initialValues, setInitialValues] = useState({
    requestedBy: '',
    department: '',
    interviewTitle: '',
    interviewerName: '',
    projectName: '',
    typeOfHubspotID: 'new',
    hubspotid: '',
    typesOfEngagement: '',
    existingDealId: '',
    workSetting: 'Hybrid',
    hiringManager: '',
    jobTitle: '',
    typeOfOpening: 'NEW',
    numOfOpenings: '',
    seniorityLevel: 'junior (0 to 2 years)',
    typeOfEmployment: 'full-time',
    targetInterviewDate: '',
    targetStartDate: '',
    city: '',
    country: '',
    candidateLocation: 'Offshore',
    duration: '6 Months',
    billRate: '',
    payRate: '',
    priority: 'Medium',
    content: '',
    projectDetails: '',
    theRole: '',
    youWill: '',
    whoUR: '',
    client: '',
    otherClient: '',
    numberOfDays: '',
    numberOfHours: '',
  });
  const textOnlyRegExp = /^[^\d]*$/;
  const MIN_WORD_LIMIT_PROJECT = 20;
  const MIN_WORD_LIMIT_ROLE = 30;
  const MIN_WORD_LIMIT_WILL = 100;
  const MIN_WORD_LIMIT_YOU_ARE = 100;
  const prfDetailSchema = Yup.object().shape({
    workSetting: Yup.string().required('This is Required'),
    projectName: Yup.string().required('This is Required'),
    interviewerName: Yup.string()
      .matches(textOnlyRegExp, 'Only text (letters) are allowed')
      .required('This is Required'),
    interviewTitle: Yup.string()
      .matches(textOnlyRegExp, 'Only text (letters) are allowed')
      .required('This is Required'),
    requestedBy: Yup.string().required('This is Required'),
    // hiringManager: Yup.string().required('This is Required'),
    jobTitle: Yup.string()
      .matches(textOnlyRegExp, 'Only text (letters) are allowed')
      .required('This is Required'),
    typeOfEmployment: Yup.string().required('This is Required'),
    numOfOpenings: Yup.number()
      .required('This is Required')
      .min(1, 'Number of positions should be 1 atleast'),
    seniorityLevel: Yup.string().required('This is Required'),
    typeOfOpening: Yup.string().required('This is Required'),
    targetInterviewDate: Yup.date().required('This is Required'),
    targetStartDate: Yup.date().required('This is Required'),
    city: Yup.string().required('This is Required'),
    candidateLocation: Yup.string().required('This is Required'),
    duration: Yup.string().when('typeOfEmployment', {
      is: (e) => e === 'Contract',
      then: () => Yup.string().required('This is required'),
      otherwise: () => Yup.string().notRequired(),
    }),
    billRate: Yup.number().required('This is Required'),
    priority: Yup.string().required('This is Required'),
    typesOfEngagement: Yup.string().required('This is Required'),
    content: Yup.string().required('This is Required'),
    projectDetails: Yup.string()
      .required('This is Required')
      .test('min-words', 'Text must be at least 20 words', (value) => {
        if (!value) return true; // If the field is empty, validation passes
        const wordCount = value.trim().split(/\s+/).length;
        return wordCount >= MIN_WORD_LIMIT_PROJECT;
      }),
    hubspotid: Yup.string().when('typesOfEngagement', {
      is: (e) => e === 'overhead',
      then: () => Yup.string().notRequired(),
      otherwise: () => Yup.string().required('This is Required'),
    }),
    theRole: Yup.string()
      .required('This is Required')
      .test('min-words', 'Text must be at least 30 words', (value) => {
        if (!value) return true; // If the field is empty, validation passes
        const wordCount = value.trim().split(/\s+/).length;
        return wordCount >= MIN_WORD_LIMIT_ROLE;
      }),
    youWill: Yup.string()
      .required('This is Required')
      .test('min-words', 'Text must be at least 100 words', (value) => {
        if (!value) return true; // If the field is empty, validation passes
        const wordCount = value.trim().split(/\s+/).length;
        return wordCount >= MIN_WORD_LIMIT_WILL;
      }),
    whoUR: Yup.string()
      .required('This is Required')
      .test('min-words', 'Text must be at least 100 words', (value) => {
        if (!value) return true; // If the field is empty, validation passes
        const wordCount = value.trim().split(/\s+/).length;
        return wordCount >= MIN_WORD_LIMIT_YOU_ARE;
      }),
    department: Yup.string().required('This is Required'),
    client: Yup.string().required('This is Required'),
    otherClient: Yup.string().when('client', {
      is: (e) => e === 'Others',
      then: () =>
        Yup.string()
          .notOneOf(
            // eslint-disable-next-line no-use-before-define
            prfClient.map((c) => c.client),
            'This value is already add',
          )
          .notOneOf(
            // eslint-disable-next-line no-use-before-define
            prfClient.map((c) => c.client.toLowerCase()),
            'This client already exists. Please contact the Admin to get access.',
          )
          .required('This field is required'),
      otherwise: () => Yup.string().notRequired(),
    }),
    numberOfHours: Yup.number()
      .typeError('Please enter a valid numeric value')
      .required('This is Required')
      .min(10, 'No. of Working Hours per Week should be at least 10 hours')
      .max(60, 'No. of Working Hours per Week should be less than 60 hours'),
    numberOfDays: Yup.number()
      .typeError('Please enter a valid numeric value')
      .required('This is Required')
      .min(0, 'No. of Days Working Onsite, should be 0 days atleast')
      .max(5, 'No. of Days Working Onsite, should be 5 or less than 5 days'),
  });
  const [errors, setErrors] = useState({});
  const handleInputChange = (event) => {
    const { name, value } = event.target;

    // Update the form values
    setInitialValues((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Validate the updated field value with Yup
    prfDetailSchema
      .validateAt(name, { [name]: value })
      .then(() => {
        // No errors, clear the error for the field
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: '',
        }));
      })
      .catch((error) => {
        // Validation error occurred, set the error message for the field
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: error.message,
        }));
      });

    // Clear the error message if the value is not empty
    if (value.trim() !== '') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: '',
      }));
    }
  };

  const navigate = useNavigate();
  const [showOtherField, setShowOtherField] = useState(false);
  const { id } = useParams();
  const isClient = profile?.user?.client;

  useEffect(() => {
    if (isClient) {
      setInitialValues((prevState) => ({ ...prevState, client: isClient }));
    }
  }, [isClient]);
  const [key, setKey] = useState('prfDetail');
  const [locked, setLocked] = useState(false);
  const [lockedAi, setLockedAi] = useState(false);
  const [lockedAiKeyword, setLockedAikeyword] = useState(false);
  const [lockedAiSimplify, setLockedAiSimplify] = useState(false);
  const [apiResponseGenerate, setApiResponseGenerate] = useState('');
  const [show, setShow] = useState(false);
  const [showAI, setShowAI] = useState(false);
  const [, setDataSubmitting] = useState(false);
  const [isAiLoading, setAiLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('');
  const [singlePRFData, setSinglePRFData] = useState('');
  const [comments, setComments] = useState([]);
  const [prfClient, setPrfClient] = useState();
  const [numOfOpen, setNumOfOpen] = useState();
  const [getFields, setGetFeilds] = useState('');
  const [getAllTalent, setGetAllTalent] = useState('');
  const [getAllRequirement, setGetAllRequirement] = useState('');
  const [getAllSkills, setGetAllSkill] = useState('');
  const [getAllCandidates, setGetAllCandidates] = useState('');
  const [getAllQuestionA, setGetAllQuestionA] = useState('');
  const [selectedValue, setSelectedValue] = useState({
    id: '',
    firstName: '',
    lastName: '',
  });
  const [selectedDepartmentValue, setSelectedDepartmentValue] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [hubSpotID, setHubSpotID] = useState(null);
  const [selectedEngagementType, setSelectedEngagementType] = useState('');
  const [hiring, setHirings] = useState([]);
  const [responseHubSpotID, setResponseHubSpotID] = useState(null);
  const [content, setContent] = useState(''); // Separate state for content
  const loggedInUser = `${profile?.user?.firstName} ${profile?.user?.lastName}`;
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseAI = () => setShowAI(false);
  const handleShowAI = () => setShowAI(true);
  const prfCloseRequestVal = singlePRFData?.prfCloseRequest;
  const prfSubmitTalentTeamVal = singlePRFData?.prfSubmitTalentTeam;
  const aiResponse = singlePRFData?.aiResponse;
  const aiTab = singlePRFData?.aiTab;
  const availableCandidates = singlePRFData?.availableCandidates;
  const totalCandidates = singlePRFData?.totalCandidates;
  const matchedCandidates = singlePRFData?.matchedCandidates;

  useEffect(() => {}, [selectedDepartmentValue]);
  const fetchPrfClients = async () => {
    try {
      setLoading(true);
      const clients = await AxiosInstance.get(GET_PRF_CLIENTS);
      setPrfClient(clients?.data?.clients);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };
  useEffect(() => {
    // Use the fetchPrfClients function inside the useEffect
    fetchPrfClients();
  }, []);
  const getAllFields = async () => {
    try {
      setLoading(true);
      const users = await AxiosInstance.get(GET_PRF_FIELDS);
      setGetFeilds(() => users);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const getAllTalentTeam = async () => {
    try {
      setLoading(true);
      const users = await AxiosInstance.get(TALENT_PRF);
      setGetAllTalent(() => users);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };
  const getAllAI = async (prfId) => {
    // setLockedAi(true);
    try {
      setAiLoading(true);
      const ai = await AxiosInstance.get(PRF_GET_AI + prfId);
      const responseData = ai?.data?.response;

      // Update all state variables
      if (responseData) {
        setGetAllRequirement(responseData.requirements || []);
        setGetAllSkill(responseData.keywords || []);
        setLockedAikeyword(
          responseData.keywords[0]?.keyword &&
            responseData.keywords[0]?.keyword.length > 0,
        );
        setLockedAiSimplify(
          responseData.keywords[0]?.simplifiedString &&
            responseData.keywords[0]?.simplifiedString.length > 0,
        );
        setGetAllCandidates(responseData.candidates || []);
        setGetAllQuestionA(responseData.questions || []);
      } else {
        // Handle case when response data is empty
        setGetAllRequirement([]);
        setGetAllSkill([]);
        setGetAllCandidates([]);
        setGetAllQuestionA([]);
      }
    } catch (e) {
      console.error('Error fetching AI data:', e);
    } finally {
      setAiLoading(false);
    }
  };
  const handleTabChange = (k) => {
    setKey(k);
    if (k === 'Aisection' && !(getAllRequirement?.length > 1)) {
      getAllAI(id);
    }
  };
  useEffect(() => {
    getAllFields();
    getAllTalentTeam();
  }, []);
  const getPrf = async (prfId) => {
    try {
      setLoading(true);
      setLocked(true);
      const prf = await AxiosInstance.get(GET_PRF_DATA + prfId);
      setSinglePRFData(prf?.data);
      if (prf?.data?.comments) {
        const sortedComments = prf.data.comments.sort((a, b) => {
          // Assuming 'createdAt' is a timestamp; if it's a date string, you may need to parse it first
          return new Date(b.createdAt) - new Date(a.createdAt);
        });

        setComments(sortedComments);
      }
      const statuses = prf?.data?.prf_statuses;
      const lastIndex = statuses ? statuses.length - 1 : -1;
      const lastStatus = prf?.data?.prf_statuses[lastIndex]?.status; // Access the status of the last element
      setStatus(lastStatus); // Set the status to your function
      setNumOfOpen(prf?.data?.numOfOpenings);
      const prfData = { ...prf.data };
      const updatedPRF = { ...prfData };
      setInitialValues({ ...updatedPRF });
      setLoading(false);
      setLocked(true);
    } catch (e) {
      setLoading(false);
      setLocked(false);
    }
  };
  useEffect(() => {
    if (id) {
      getPrf(id);
    }
  }, [id]);
  function calculateCost(billRate, durationInMonths, numOfOpenings) {
    const numericValue = parseFloat(durationInMonths.replace(/[^0-9]/g, ''));
    const workingDaysPerMonth = 2000 / 12;
    const durationInDays = numericValue * workingDaysPerMonth;
    const totalCost = billRate * numOfOpenings * durationInDays;
    return totalCost;
  }
  function calculateProjectDuration(startDate, projectDurationInMonths) {
    const durationArr = projectDurationInMonths.split(' ');
    const duration = parseInt(durationArr[0], 10);
    const endDate = moment(startDate, 'YYYY-MM-DD')
      .add(duration, 'months')
      .format('YYYY-MM-DD');
    return endDate;
  }

  const importToCats = async () => {
    const priorityMap = {
      low: 1,
      medium: 2,
      high: 3,
    };

    const catsData = {
      jobTitle: initialValues.jobTitle,
      city: initialValues.city,
      targetStartDate: initialValues.targetStartDate,
      numOfOpenings: parseInt(initialValues.numOfOpenings, 10),
      whoUR: initialValues.whoUR,
      youWill: initialValues.youWill,
      theRole: initialValues.theRole,
      projectDetails: initialValues.projectDetails,
      client: initialValues.client,
      billRate: initialValues.billRate,
      interviewerName: initialValues.interviewerName,
      hiringManager: initialValues.hiringManagerName,
      priority: priorityMap[initialValues.priority.toLowerCase()],
      duration: initialValues.duration,
    };

    const toastId = toast.loading('Importing to CATS...');
    setLocked(true);
    try {
      // Make the Axios request
      await AxiosInstance.post(POST_CATSONE, catsData);
      const dataToSend = {
        catsoneFlag: true,
      };
      await rejectPRF(dataToSend);
      toast.update(toastId, {
        render: 'Import to CATS successful',
        type: 'success',
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: false,
      });
    } catch (error) {
      toast.update(toastId, {
        render: 'Error importing to CATS :(',
        type: 'error',
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: false,
      });
    }
  };

  const handleContentChange = (event) => {
    setContent(event.target.value);
  };

  const [isSubmittingState, setIsSubmittingState] = useState(false);

  const HubspotId = async () => {
    if (hubSpotID) {
      return hubSpotID;
    }

    const newType =
      initialValues.typesOfEngagement === 'fixed_price_project'
        ? 'Fixed Price Project'
        : 'Time and Material';

    const data = {
      jobTitle: initialValues.jobTitle,
      existingDealId: initialValues.existingDealId,
      numOfOpenings: parseInt(initialValues.numOfOpenings, 10),
      client: initialValues.client,
      typesOfEngagement: initialValues.typesOfEngagement,
      dealName: `${initialValues.projectName} - ${initialValues.jobTitle} - ${newType}`,
      type: `${newType}`,
      projectName: initialValues.projectName,
      salesPipeline: process.env.REACT_APP_SALEPIPELINE,
      dealType:
        initialValues.typeOfOpening === 'NEW'
          ? 'Existing Buyer, New Business'
          : 'existingbusiness',
      amount: calculateCost(
        initialValues.billRate,
        initialValues.duration,
        initialValues.numOfOpenings,
      ),
      sowStartDate: new Date(initialValues.targetStartDate),
      sowEndDate: calculateProjectDuration(
        initialValues.targetStartDate,
        initialValues.duration,
      ),
      businessSegment: 'Consulting',
      businessStream:
        initialValues.typesOfEngagement === 'fixed_price_project'
          ? 'Project'
          : 'Resource',
      technology: '',
      solutionDomain: '',
      department: initialValues.department,
      dealStage: process.env.REACT_APP_DEALSTAGE,
      dealOwner: loggedInUser,
      closeDate: new Date(initialValues.targetStartDate),
    };

    if (initialValues.typesOfEngagement !== 'overhead') {
      try {
        const res = await AxiosInstance.post(HUBSPOT_PRF, data);
        setHubSpotID(res.data.hubSpotId);
        setResponseHubSpotID(res.data.success);
        if (res && res.data.success) {
          setInitialValues((prevValues) => ({
            ...prevValues,
            hubspotid: res.data.hubSpotId,
          }));
          return res.data.hubSpotId;
        } else {
          console.log('HubSpot ID creation failed.');
          throw new Error('HubSpot ID creation failed.');
        }
      } catch (e) {
        console.error(e);
        throw e;
      }
    }
  };

  const handleSubmitWithHubspot = async (values) => {
    try {
      let hubspotId = hubSpotID;

      if (!id && !hubSpotID) {
        // Only generate HubSpot ID if `id` and `hubSpotID` do not exist
        hubspotId = await HubspotId();
      } else if (!hubSpotID) {
        // If the form is being re-submitted and `hubSpotID` does not exist, use the one in `values`
        hubspotId = values.hubspotid;
      }

      const data = {
        ...values,
        hubspotid: hubspotId,
        status: 1,
        type: 1,
        content, // Add the content from state
      };

      if (id) {
        await updatePrf(data);
      } else {
        await createPrf(data);
      }
    } catch (error) {
      console.error('Error during form submission:', error);
    }
  };

  const onSubmit = async (values) => {
    handleShow(); // Show the modal first
    await handleSubmitWithHubspot({ ...values, content }); // Then handle the Hubspot ID generation and form submission
  };

  // eslint-disable-next-line no-shadow
  const InternalHire = async (data) => {
    const toastId = toast.loading('PRF Updating');
    setLocked(true);

    try {
      // If not equal, send a POST request
      await AxiosInstance.post(POST_PRF_SPLIT, data);
      navigate('/prf');
      toast.update(toastId, {
        render: 'PRF submitted successfully',
        type: 'success',
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: false,
      });
      setDataSubmitting(false);
      setLocked(false);
    } catch (e) {
      toast.update(toastId, {
        render: 'Error submitting PRF :(',
        type: 'error',
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: false,
      });
      setLocked(false);
      setDataSubmitting(false);
    }
  };
  const noOfopeningValue = initialValues.numOfOpenings;

  const createPrf = async (data) => {
    const toastId = toast.loading('Submitting PRF');
    setLocked(true);

    try {
      // if (data.typeOfHubspotID === 'existing') {
      //   // Set hubspotid to the value of existingDealId
      //   // eslint-disable-next-line no-param-reassign
      //   data.hubspotid = data.existingDealId;
      // }
      const res = await AxiosInstance.post(CREATE_PRF, data);
      if (res.data.success) {
        // Access the PRF ID from the response
        const { prfId } = res.data;

        navigate(`/edit/${prfId}`);
        window.location.reload();

        // Display a success message
      } else {
        // Handle the case where the API call was not successful
        toast.error('Failed to save PRF');
      }
      toast.update(toastId, {
        render: 'PRF submitted successfully',
        type: 'success',
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: false,
      });
      setDataSubmitting(false);
      setLocked(false); // Assuming it should be setLocked(false) instead of setLocked(true)
    } catch (e) {
      toast.update(toastId, {
        render: 'Error submitting PRF :(',
        type: 'error',
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: false,
      });
      setLocked(false);
      setDataSubmitting(false);
    }
  };
  const ForwardPrf = async (data) => {
    setLocked(true);
    try {
      await AxiosInstance.patch(UPDATE_PRF + id, data);
      navigate('/prf');
      setDataSubmitting(false);
      setLocked(true);
    } catch (e) {
      setLocked(false);
      setDataSubmitting(false);
    }
  };
  const updateAIPrf = async (update) => {
    setLocked(true);
    try {
      await AxiosInstance.patch(UPDATE_PRF + id, update);
      setDataSubmitting(false);
      setLocked(true);
    } catch (e) {
      toast.update(toastId, {
        render: 'Error submitting PRF :(',
        type: 'error',
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: false,
      });
      setLocked(false);
      setDataSubmitting(false);
    }
  };
  const GenerateAI = async () => {
    const toastId = toast.loading('Generating Data');
    setLockedAi(true);
    try {
      const update = {
        aiTab: true,
      };
      const dataToSend = {
        projectDetails: initialValues.projectDetails.replace(/<[^>]+>/g, ''),
        theRole: initialValues.theRole.replace(/<[^>]+>/g, ''),
        youWill: initialValues.youWill.replace(/<[^>]+>/g, ''),
        whoUR: initialValues.whoUR.replace(/<[^>]+>/g, ''),
        jobTitle: initialValues.jobTitle,
        id,
      };
      const res = await AxiosInstance.post(PRF_AI, dataToSend);
      console.log(res.data.success, 'aiiiii');
      // if (res.data.success === true) {
      // }

      toast.update(toastId, {
        render: 'PRF updated successfully',
        type: 'success',
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: false,
      });
      setDataSubmitting(false);
      setLockedAi(true);
      setKey('Aisection');
      updateAIPrf(update);
      getAllAI(id);
      getPrf(id);
    } catch (error) {
      toast.update(toastId, {
        render: 'Error Generating Data PRF',
        type: 'error',
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: false,
      });
      console.error('Error:', error);
      setLocked(true);
      setDataSubmitting(false);
    }
  };
  const SubmitPRF = async (data) => {
    const toastId = toast.loading('updated PRF');
    setLocked(true);
    try {
      await AxiosInstance.patch(UPDATE_PRF + id, data);
      navigate(`/edit/${id}`);
      window.location.reload();
      toast.update(toastId, {
        render: 'PRF updated successfully',
        type: 'success',
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: false,
      });
      setDataSubmitting(false);
      setLocked(true);
    } catch (e) {
      toast.update(toastId, {
        render: 'Error updating PRF :(',
        type: 'error',
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: false,
      });
      setLocked(false);
      setDataSubmitting(false);
    }
  };
  const updatePrf = async (data) => {
    const toastId = toast.loading('updated PRF');
    setLocked(true);
    try {
      await AxiosInstance.patch(UPDATE_PRF + id, data);
      navigate(`/edit/${id}`);
      window.location.reload();
      toast.update(toastId, {
        render: 'PRF updated successfully',
        type: 'success',
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: false,
      });
      setDataSubmitting(false);
      setLocked(true);
    } catch (e) {
      toast.update(toastId, {
        render: 'Error updating PRF :(',
        type: 'error',
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: false,
      });
      setLocked(false);
      setDataSubmitting(false);
    }
  };

  const acceptPRF = async (data, content, type) => {
    const toastId = toast.loading('updated PRF');
    setLocked(true);
    setLoading(true);
    try {
      const body = { status: data, content, type };
      if (data === PRF_STATUS.accepted) {
        body.acceptedDate = new Date();
      }
      await AxiosInstance.patch(UPDATE_PRF + id, body);

      navigate(`/edit/${id}`);
      window.location.reload();
      toast.update(toastId, {
        render: 'PRF updated successfully',
        type: 'success',
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: false,
      });
      setLocked(true);
      setLoading(false);
    } catch (e) {
      setLocked(true);
      setLoading(false);
    }
  };
  const rejectPRF = async (data) => {
    const toastId = toast.loading('updated PRF');
    setLocked(true);
    setLoading(true);
    try {
      await AxiosInstance.patch(UPDATE_PRF + id, data);
      navigate(`/edit/${id}`);
      window.location.reload();
      toast.update(toastId, {
        render: 'PRF updated successfully',
        type: 'success',
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: false,
      });
      setLocked(true);
      setLoading(false);
    } catch (e) {
      toast.update(toastId, {
        render: 'Error submitting PRF :(',
        type: 'error',
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: false,
      });
      setLocked(true);
      setLoading(false);
    }
  };
  const handleSubmitTalent = async (data) => {
    const toastId = toast.loading('updated PRF');

    if (!selectedValue || !selectedValue.id) {
      return;
    }

    try {
      await AxiosInstance.patch(UPDATE_PRF + id, data);

      navigate(`/edit/${id}`);
      window.location.reload();
      toast.update(toastId, {
        render: 'PRF updated successfully',
        type: 'success',
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: false,
      });
    } catch (error) {
      toast.update(toastId, {
        render: 'Error submitting PRF :(',
        type: 'error',
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: false,
      });
      console.error('Failed to send request to the backend:', error);
    }
  };

  const closurePRFRequest = async (data) => {
    const toastId = toast.loading('updated PRF');
    setLocked(true);
    setLoading(true);
    try {
      // const body = { prfCloseRequest: data, content, type: 5 };
      await AxiosInstance.patch(UPDATE_PRF + id, data);

      navigate(`/edit/${id}`);
      window.location.reload();
      toast.update(toastId, {
        render: 'PRF updated successfully',
        type: 'success',
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: false,
      });
      setLocked(true);
      setLoading(false);
    } catch (e) {
      toast.update(toastId, {
        render: 'Error submitting PRF :(',
        type: 'error',
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: false,
      });
      setLocked(true);
      setLoading(false);
    }
  };

  const closePRF = async (body) => {
    const toastId = toast.loading('Closed PRF');
    setLocked(true);
    setLoading(true);
    try {
      // const body = {
      //   status: data,
      //   content,
      //   closedDate: new Date(),
      //   prfCloseRequest: false,
      // };

      await AxiosInstance.patch(UPDATE_PRF + id, body);

      navigate(`/edit/${id}`);
      window.location.reload();
      toast.update(toastId, {
        render: 'PRF Closed successfully',
        type: 'success',
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: false,
      });
      setLocked(true);
      setLoading(false);
    } catch (e) {
      toast.update(toastId, {
        render: 'Error submitting PRF :(',
        type: 'error',
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: false,
      });
      setLocked(true);
      setLoading(false);
    }
  };
  {
    !(key === 'Aisection') && (loading || isAiLoading) && (
      <div className="backdrop">
        <img src={Loader} width={50} height={50} />
      </div>
    );
  }

  const handleContactChange = (e, setFieldValue, setFieldError) => {
    const selectedContact = e.target.value;
    setFieldValue('typeOfEmployment', selectedContact);
    if (selectedContact === 'Contract') {
      setFieldError('duration', 'Duration is required');
    } else {
      setFieldError('duration', '');
    }
  };
  /// PRF Status
  // eslint-disable-next-line eqeqeq
  const Accepted = status == accepted;
  // eslint-disable-next-line eqeqeq
  const Rejected = status == rejected;
  // eslint-disable-next-line eqeqeq
  const Closed = status == closed.toString();
  // eslint-disable-next-line eqeqeq
  const Initiated = status == initiated.toString();
  // const Pending = status == pending.toString();
  // eslint-disable-next-line eqeqeq, camelcase
  const InternalResource = status === internal_resource;
  // eslint-disable-next-line eqeqeq
  const Forward = status == forward.toString();
  // eslint-disable-next-line eqeqeq, no-unused-vars
  const Submited = status == submitted;
  // LoggedInUser matched with Prf Creator
  const prfCreator = `${initialValues.userFirstName} ${initialValues?.userLastName}`;
  const isPrfCreatorMatched = loggedInUser === prfCreator || isSuperUser;
  /// PRF Dropdowns /////
  const GET_TALENT_TEAM = getAllTalent?.data?.data;
  const PRF_PRIORITY = getFields?.data?.PRF_FIELDS?.PRIORITY;
  const PRF_CLIENT = getFields?.data?.clients;
  const PRF_CANDIDATE_LOCATION =
    getFields?.data?.PRF_FIELDS?.CANDIDATE_LOCATION;
  const PRF_DURATION = getFields?.data?.PRF_FIELDS?.DURATION;
  const PRF_EXPERIENCE_LEVEL = getFields?.data?.PRF_FIELDS?.EXPERIENCE_LEVEL;
  const PRF_TYPE_OF_EMPLOYMENT =
    getFields?.data?.PRF_FIELDS?.TYPE_OF_EMPLOYMENT;
  const PRF_TYPE_OF_OPENING = getFields?.data?.PRF_FIELDS?.TYPE_OF_OPENING;
  const PRF_WORK_SETTING = getFields?.data?.PRF_FIELDS?.WORK_SETTING;
  const PRF_HIRINGMANAGER = hiring.data;
  // eslint-disable-next-line camelcase
  const PRF_All_HIRINGMANAGER = getFields?.data?.departmentHeads;
  const PRF_DEPARTMENTS = getFields?.data?.PRF_FIELDS?.DEPARTMENTS;
  const ButtonID =
    id === undefined ? 'submit-prf-button' : 'reSubmit-prf-button';
  const ButtonID1 =
    id === undefined ? 'submit-prf-button1' : 'reSubmit-prf-button1';

  const getFilteredPRFClient = () =>
    isDepartmentHead || isSuperUser ? prfClient : PRF_CLIENT;
  // eslint-disable-next-line no-unused-vars
  const calculateSelectedOpenings = (currentOpenings) =>
    numOfOpen - currentOpenings;
  const rolesToShow = getFilteredPRFClient();
  const handleClick = () => {
    if (!id) {
      /*       HubspotId();
       */ handleShow();
    } else {
      handleShow();
    }
  };
  const handleEngagementTypeChange = (e) => {
    const selectedType = e.target.value;
    setSelectedEngagementType(selectedType);
    // Additional logic if needed
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={prfDetailSchema}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={onSubmit}
      >
        {({
          handleSubmit,
          isSubmitting,
          onSubmit,
          values,
          setFieldValue,
          touched,
          setFieldTouched,
          errors,
          setFieldError,
        }) => (
          <FormikForm onSubmit={handleSubmit}>
            {/* Notification to TALENT Team */}
            {!(isDepartmentHead || isAccounTManager) &&
              prfCloseRequestVal === true && (
                <Alert
                  key={'warning'}
                  variant={'warning'}
                  className="notification d-flex justify-content-between align-items-center"
                >
                  <p className="mb-0 d-flex align-items-center">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_1717_23878)">
                        <path
                          d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 17H11V11H13V17ZM13 9H11V7H13V9Z"
                          fill="#FBBC05"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1717_23878">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <span className="ms-2 text-black">
                      Account Manager has requested to close this PRF.
                      {comments
                        .filter((comment) => comment.type === 5)
                        .map((closureComment, closureKey) => (
                          <strong
                            className="ps-3"
                            key={closureKey}
                          >{`"${closureComment.content}"`}</strong>
                        ))}
                    </span>
                  </p>
                  <div className="d-flex">
                    {/* <span className="prf-btn" onClick={() => setShowModel(true)}>Cancel</span> */}
                    <PopUp
                      ButtonName="Cancel"
                      commentHead="Reason of Cancel Request"
                      name="content"
                      className={'btnCancel'}
                      // variant=""
                      id={id}
                      buttonID="closure-button"
                      secondaryButtonID="closure-button-1"
                      SubmitButton="Cancel Request"
                      disabled={errors.content || loading}
                      isSubmitting={isSubmitting}
                      acceptPRF={() => {
                        const dataToSend = {
                          ...values,
                          denied: 'denied',
                          prfCloseRequest: false,
                          type: 5,
                        };
                        closurePRFRequest(dataToSend);
                      }}
                    />
                    <Button
                      id="Acceptprf-btn"
                      className="prf-btn ms-3 btnCancel"
                      onClick={() => {
                        const body = {
                          status: +closed,
                          content: values.content, // Assuming values is an object with a content property
                          closedDate: new Date(),
                          prfCloseRequest: false,
                        };
                        closePRF(body); // Assuming closePRF is a function that accepts the body as a parameter
                      }}
                    >
                      Accept
                    </Button>
                  </div>
                </Alert>
              )}
            {(isTalent || isSuperUser) &&
              Accepted &&
              prfSubmitTalentTeamVal === true && (
                <Alert
                  key={'warning'}
                  variant={'warning'}
                  className="notification d-flex justify-content-between align-items-center"
                >
                  <p className="mb-0 d-flex align-items-center">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_1717_23878)">
                        <path
                          d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 17H11V11H13V17ZM13 9H11V7H13V9Z"
                          fill="#FBBC05"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1717_23878">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <span className="ms-2 text-black">
                      Talent Team <strong>{initialValues.assignedTo}</strong>{' '}
                      has requested to submit this profile.
                      {comments
                        .filter((comment) => comment.type === 6)
                        .slice(-1) // Select only the last comment
                        .map((submitComment, submitKey) => (
                          <strong className="ps-3" key={submitKey}>
                            {`"${submitComment.content}"`}
                          </strong>
                        ))}
                    </span>
                  </p>
                  <div className="d-flex">
                    <Button
                      id="closeprf-btn"
                      className="prf-btn ms-3 btnCancel"
                      onClick={() => {
                        const body = {
                          cancel: 'cancel',
                          ...values,
                          prfSubmitTalentTeam: false,
                          // content: values.content, // Assuming values is an object with a content property
                          // closedDate: new Date(),
                          // prfCloseRequest: false,
                        };
                        closePRF(body); // Assuming closePRF is a function that accepts the body as a parameter
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      id="Acceptprf-btn"
                      className="prf-btn ms-3 btnCancel"
                      onClick={() => {
                        const body = {
                          ...values,
                          status: 6,
                          type: 6,
                          prfSubmitRequest: true,
                          prfSubmitTalentTeam: false,
                          accept: 'accept',
                        };
                        closePRF(body); // Assuming closePRF is a function that accepts the body as a parameter
                      }}
                    >
                      Accept
                    </Button>
                  </div>
                </Alert>
              )}
            {(isSuperUser || isTalent) &&
              initialValues.talentDisapproval === true &&
              Forward && (
                <Alert
                  key={'warning'}
                  variant={'warning'}
                  className="notification d-flex justify-content-between align-items-center"
                >
                  <p className="mb-0 d-flex align-items-center">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_1717_23878)">
                        <path
                          d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 17H11V11H13V17ZM13 9H11V7H13V9Z"
                          fill="#FBBC05"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1717_23878">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <span className="ms-2 text-black">
                      This PRF has been disapproved by Talent Team
                      {/* Talent Team <strong>{initialValues.assignedTo}</strong>{' '}
                  has requested to submit this PRF. */}
                      {comments
                        // .filter((comment) => comment.type === 6)
                        .slice(-1) // Select only the last comment
                        .map((submitComment, submitKey) => (
                          <strong className="ps-3" key={submitKey}>
                            {`"${submitComment.content}"`}
                          </strong>
                        ))}
                    </span>
                  </p>
                  <div className="d-flex">
                    {/* <Button
                  id="closeprf-btn"
                  className="prf-btn ms-3 btnCancel"
                  onClick={() => {
                    const body = {
                      cancel: 'cancel',
                      prfSubmitTalentTeam: false,
                      // content: values.content, // Assuming values is an object with a content property
                      // closedDate: new Date(),
                      // prfCloseRequest: false,
                    };
                    closePRF(body); // Assuming closePRF is a function that accepts the body as a parameter
                  }}
                >
                  Cancel
                </Button> */}
                    {/* <Button
                      id="Acceptprf-btn"
                      className="prf-btn ms-3 btnCancel"
                      onClick={() => {
                        const body = {
                          talentDisapproval: false,
                          // prfSubmitTalentTeam: false,
                        };
                        rejectPRF(body); // Assuming closePRF is a function that accepts the body as a parameter
                      }}
                    >
                      Acknowledge
                    </Button> */}
                  </div>
                </Alert>
              )}
            <div className="pageHead d-flex justify-content-between align-items-center">
              {!id ? (
                <div className="d-flex flex-column">
                  <h1 className="title mb-0">Create PRF</h1>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb mb-0">
                      <li className="breadcrumb-item">
                        <a href="/" className="breadcrumb-style">
                          Dashboard
                        </a>
                      </li>
                      <li className="breadcrumb-item active">Create PRF</li>
                    </ol>
                  </nav>
                </div>
              ) : (
                <div className="d-flex flex-column">
                  <h1 className="title mb-0">PRF Details</h1>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb mb-0">
                      <li className="breadcrumb-item">
                        <a href="/prf" className="breadcrumb-style">
                          PRFs
                        </a>
                      </li>
                      <li className="breadcrumb-item active">PRF Detail</li>
                    </ol>
                  </nav>
                </div>
              )}
              <div className="d-flex" style={{ gap: '0.5rem' }}>
                {!(isTalent || isAccounTManager || isTalentTeam) &&
                  Initiated && (
                    <div className="d-flex gap-2">
                      <PopUp
                        ButtonName="Reject"
                        commentHead="Reason of Reject"
                        name="content"
                        variant="danger"
                        id={id}
                        SubmitButton="Reject PRF"
                        disabled={errors.content || loading}
                        isSubmitting={isSubmitting}
                        buttonID="rejected-button"
                        secondaryButtonID="rejected-button-1"
                        acceptPRF={() => {
                          const dataToSend = {
                            ...values,
                            content: values.content,
                            status: rejected,
                            type: 3,
                            newStage: process.env.REACT_APP_DEALSTAGE,
                            hubspotid: values.hubspotid,
                            talentApproval: false,
                            talentDisapproval: false,
                          };
                          rejectPRF(dataToSend);
                        }}
                        img={
                          <svg
                            width="10"
                            height="10"
                            viewBox="0 0 10 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{ marginRight: '7px' }}
                          >
                            <path
                              d="M9.66683 1.27301L8.72683 0.333008L5.00016 4.05967L1.2735 0.333008L0.333496 1.27301L4.06016 4.99967L0.333496 8.72634L1.2735 9.66634L5.00016 5.93967L8.72683 9.66634L9.66683 8.72634L5.94016 4.99967L9.66683 1.27301Z"
                              fill="white"
                            />
                          </svg>
                        }
                      />
                      {!isPresident && (
                        <InternalHireModel
                          id={id}
                          isSubmitting={isSubmitting}
                          ButtonName="Forward"
                          name="content"
                          buttonID="button-forward"
                          secondaryButtonID="button-forward-1"
                          SubmitButton="Forward PRF"
                          SubmitButton1="Forward PRF1"
                          disabled={errors.content || loading}
                          commentHead="Reason of Forward"
                          noOfopening={noOfopeningValue}
                          nameNoofOpening="numOfOpenings"
                          ForwardApi={() => {
                            // Create a new object without 'comments', 'prf_statuses', 'user', and 'name'
                            const dataToSend = {
                              ...values,
                              status: 3,
                              type: 2,
                            };
                            // Call the InternalHire function with the modified data and status 8
                            ForwardPrf(dataToSend);
                          }}
                          InternalPrf={() => {
                            // Check if initialValues.numOfOpenings is equal to values.numOfOpenings
                            if (
                              initialValues.numOfOpenings ===
                              values.numOfOpenings
                            ) {
                              // Skip the InternalHire function
                              console.log('Skipping InternalHire');
                              return;
                            }
                            // Create a new object without 'comments', 'prf_statuses', 'user', and 'name'
                            const dataToSend = {
                              ...values,
                              name: undefined,
                              status: 8,
                              id: undefined,
                              updatedAt: undefined,
                              department: values.department,
                              numOfOpenings: calculateSelectedOpenings(
                                values.numOfOpenings,
                              ),
                              type: 8,
                            };
                            // Call the InternalHire function with the modified data and status 8
                            InternalHire(dataToSend);
                          }}
                          img={
                            <svg
                              width="15"
                              height="12"
                              viewBox="0 0 15 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1.67325 2.02L6.67992 4.16667L1.66659 3.5L1.67325 2.02ZM6.67325 7.83333L1.66659 9.98V8.5L6.67325 7.83333ZM0.339919 0L0.333252 4.66667L10.3333 6L0.333252 7.33333L0.339919 12L14.3333 6L0.339919 0Z"
                                fill="white"
                              />
                            </svg>
                          }
                        />
                      )}
                      {isPresident && (
                        <PresidentApproval
                          id={id}
                          isSubmitting={isSubmitting}
                          ButtonName="Approve"
                          variant="success"
                          name="content"
                          buttonID="button-forward"
                          secondaryButtonID="button-forward-1"
                          SubmitButton="Approve"
                          SubmitButton1="Approve"
                          disabled={errors.content || loading}
                          commentHead="Reason of Approval"
                          noOfopening={noOfopeningValue}
                          nameNoofOpening="numOfOpenings"
                          ForwardApi={() => {
                            // Create a new object without 'comments', 'prf_statuses', 'user', and 'name'
                            const dataToSend = {
                              ...values,
                              status: 3,
                              type: 2,
                            };
                            // Call the InternalHire function with the modified data and status 8
                            ForwardPrf(dataToSend);
                          }}
                          InternalPrf={() => {
                            // Check if initialValues.numOfOpenings is equal to values.numOfOpenings
                            if (
                              initialValues.numOfOpenings ===
                              values.numOfOpenings
                            ) {
                              // Skip the InternalHire function
                              return;
                            }
                            // Create a new object without 'comments', 'prf_statuses', 'user', and 'name'
                            const dataToSend = {
                              ...values,
                              name: undefined,
                              status: 8,
                              id: undefined,
                              updatedAt: undefined,
                              department: values.department,
                              numOfOpenings: calculateSelectedOpenings(
                                values.numOfOpenings,
                              ),
                              type: 8,
                            };
                            // Call the InternalHire function with the modified data and status 8
                            InternalHire(dataToSend);
                          }}
                          img={
                            <svg
                              width="15"
                              height="12"
                              viewBox="0 0 15 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1.67325 2.02L6.67992 4.16667L1.66659 3.5L1.67325 2.02ZM6.67325 7.83333L1.66659 9.98V8.5L6.67325 7.83333ZM0.339919 0L0.333252 4.66667L10.3333 6L0.333252 7.33333L0.339919 12L14.3333 6L0.339919 0Z"
                                fill="white"
                              />
                            </svg>
                          }
                        />
                      )}
                    </div>
                  )}
                {(isTalent || isSuperUser) && Forward && (
                  <div className="d-flex">
                    <PopUp
                      ButtonName="Reject PRF"
                      commentHead="Reason of Reject"
                      name="content"
                      className="cancelBtn"
                      variant="danger"
                      id={id}
                      buttonID="button-reject"
                      secondaryButtonID="button-reject-1"
                      SubmitButton="Reject PRF"
                      disabled1={prfCloseRequestVal === true}
                      disabled={errors.content || loading}
                      isSubmitting={isSubmitting}
                      acceptPRF={() => {
                        const dataToSend = {
                          ...values,
                          content: values.content,
                          status: rejected,
                          type: 3,
                          newStage: process.env.REACT_APP_DEALSTAGE,
                          hubspotid: values.hubspotid,
                          talentApproval: false,
                          talentDisapproval: false,
                        };
                        rejectPRF(dataToSend);
                      }}
                      img={
                        <svg
                          width="10"
                          height="10"
                          viewBox="0 0 10 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ marginRight: '7px' }}
                        >
                          <path
                            d="M9.66683 1.27301L8.72683 0.333008L5.00016 4.05967L1.2735 0.333008L0.333496 1.27301L4.06016 4.99967L0.333496 8.72634L1.2735 9.66634L5.00016 5.93967L8.72683 9.66634L9.66683 8.72634L5.94016 4.99967L9.66683 1.27301Z"
                            fill="white"
                          />
                        </svg>
                      }
                    />
                    <PopUp
                      id={id}
                      buttonID="button-accept"
                      secondaryButtonID="button-accept-1"
                      isSubmitting={isSubmitting}
                      ButtonName="Accept PRF"
                      variant="primary"
                      name="content"
                      SubmitButton="Accept PRF"
                      disabled1={prfCloseRequestVal === true}
                      disabled={errors.content || loading}
                      commentHead="Reason of Acceptance"
                      acceptPRF={() => {
                        const dataToSend = {
                          ...values,
                          content: values.content,
                          status: accepted,
                          type: 4,
                          newStage: process.env.REACT_APP_NEWSTAGE,
                          hubspotid: values.hubspotid,
                        };
                        rejectPRF(dataToSend);
                      }}
                      img={
                        <svg
                          width="12"
                          height="10"
                          viewBox="0 0 12 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ marginRight: '7px' }}
                        >
                          <path
                            d="M3.99993 7.80007L1.19993 5.00007L0.266602 5.9334L3.99993 9.66673L11.9999 1.66673L11.0666 0.733398L3.99993 7.80007Z"
                            fill="white"
                          />
                        </svg>
                      }
                    />
                  </div>
                )}
                {isSuperUser && (InternalResource || Submited) && (
                  <div className="d-flex">
                    <PopUp
                      ButtonName="Reject PRF"
                      commentHead="Reason of Reject"
                      name="content"
                      className="cancelBtn"
                      variant="danger"
                      id={id}
                      buttonID="button-reject"
                      secondaryButtonID="button-reject-1"
                      SubmitButton="Reject PRF"
                      disabled1={prfCloseRequestVal === true}
                      disabled={errors.content || loading}
                      isSubmitting={isSubmitting}
                      acceptPRF={() => {
                        const dataToSend = {
                          ...values,
                          content: values.content,
                          status: rejected,
                          type: 3,
                          newStage: process.env.REACT_APP_DEALSTAGE,
                          hubspotid: values.hubspotid,
                          talentApproval: false,
                          talentDisapproval: false,
                        };
                        rejectPRF(dataToSend);
                      }}
                      img={
                        <svg
                          width="10"
                          height="10"
                          viewBox="0 0 10 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ marginRight: '7px' }}
                        >
                          <path
                            d="M9.66683 1.27301L8.72683 0.333008L5.00016 4.05967L1.2735 0.333008L0.333496 1.27301L4.06016 4.99967L0.333496 8.72634L1.2735 9.66634L5.00016 5.93967L8.72683 9.66634L9.66683 8.72634L5.94016 4.99967L9.66683 1.27301Z"
                            fill="white"
                          />
                        </svg>
                      }
                    />
                  </div>
                )}

                {(isSuperUser || isPrfCreatorMatched) &&
                  InternalResource &&
                  locked && (
                    <Button
                      id="prf-edit-button"
                      onClick={() => setLocked(false)}
                      variant="primary"
                      size="sm"
                      className="d-flex align-items-center ms-2 justify-content-center btn-createPrf"
                    >
                      <span className="me-2">
                        <svg
                          width="15"
                          height="12"
                          viewBox="0 0 15 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.67301 2.02L6.67968 4.16667L1.66634 3.5L1.67301 2.02ZM6.67301 7.83333L1.66634 9.98V8.5L6.67301 7.83333ZM0.339674 0L0.333008 4.66667L10.333 6L0.333008 7.33333L0.339674 12L14.333 6L0.339674 0Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                      <span>Re-Submit</span>
                    </Button>
                  )}
                {(isDepartmentHead || isSuperUser) && Initiated && (
                  <div className="d-flex">
                    <InternalHireModel
                      id={id}
                      noOfopening={noOfopeningValue}
                      nameNoofOpening="numOfOpenings"
                      buttonID="button-submitted"
                      isSubmitting={isSubmitting}
                      ButtonName="Internal Resource"
                      secondaryButtonID="submit-profile-1"
                      variant="success"
                      name="content"
                      SubmitButton="Internal hire"
                      // disabled1={prfCloseRequestVal === true}
                      disabled={errors.content || loading}
                      commentHead="Reason of Internal hire"
                      ForwardApi={() => {
                        // Check if initialValues.numOfOpenings is equal to values.numOfOpenings
                        if (
                          initialValues.numOfOpenings === values.numOfOpenings
                        ) {
                          // Skip the ForwardPrf function
                          console.log('Skipping ForwardPrf');
                          return;
                        }

                        // Create a new object without 'comments', 'prf_statuses', 'user', and 'name'
                        const dataToSend = {
                          ...values,
                          status: 3,
                          numOfOpenings: calculateSelectedOpenings(
                            values.numOfOpenings,
                          ),
                          type: 2,
                        };

                        // Call the ForwardPrf function with the modified data and status 3
                        ForwardPrf(dataToSend);
                      }}
                      InternalPrf={() => {
                        // Create a new object without 'comments', 'prf_statuses', 'user', and 'name'
                        // Call the InternalHire function with the modified data and status 8
                        if (
                          initialValues.numOfOpenings === values.numOfOpenings
                        ) {
                          const dataToPost = {
                            ...values,
                            updatedAt: undefined,
                            // user: undefined,
                            name: initialValues.name,
                            status: 8,
                            id: undefined,
                            type: 8,
                            department: values.department,
                            numOfOpenings: values.numOfOpenings,
                          };
                          // If not equal, send a POST request
                          SubmitPRF(dataToPost, values.numOfOpenings);
                          // acceptPRF(internal_resource, ...values, 8);
                        } else {
                          const dataToPost = {
                            ...values,
                            updatedAt: undefined,
                            // user: undefined,
                            name: initialValues.name,
                            status: 8,
                            id: undefined,
                            type: 8,
                            department: values.department,
                          };
                          // If not equal, send a POST request
                          InternalHire(dataToPost, values.numOfOpenings);
                        }
                      }}
                      img={
                        <svg
                          width="15"
                          height="12"
                          viewBox="0 0 15 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.67301 2.02L6.67968 4.16667L1.66634 3.5L1.67301 2.02ZM6.67301 7.83333L1.66634 9.98V8.5L6.67301 7.83333ZM0.339674 0L0.333008 4.66667L10.333 6L0.333008 7.33333L0.339674 12L14.333 6L0.339674 0Z"
                            fill="white"
                          />
                        </svg>
                      }
                    />
                  </div>
                )}
                {(isTalent || isSuperUser) &&
                  Accepted &&
                  prfSubmitTalentTeamVal === false && (
                    <div className="d-flex" style={{ gap: '1rem' }}>
                      <SubmitPopup
                        id={id}
                        buttonID="button-submitted"
                        isSubmitting={isSubmitting}
                        ButtonName="Submit Profile"
                        secondaryButtonID="submit-profile-1"
                        variant="success"
                        name="content"
                        SubmitButton="Submit Profile"
                        disabled={errors.content || loading}
                        commentHead="Reason of Profile Submission"
                        acceptPRF={(payRateCategory, excessPayRate) => {
                          const dataToSend = {
                            ...values,
                            prfSubmitTalentTeam: true,
                            payRateCategory,
                            excessPayRate:
                              payRateCategory === 'above'
                                ? parseFloat(excessPayRate)
                                : null,
                          };
                          SubmitPRF(dataToSend);
                        }}
                        img={<DocumentDownload color="white" size="small" />}
                      />
                    </div>
                  )}
                {isTalentTeam && Accepted && (
                  <>
                    <div className="d-flex" style={{ gap: '1rem' }}>
                      <SubmitPopup
                        id={id}
                        buttonID="button-submitted"
                        isSubmitting={isSubmitting}
                        ButtonName="Submit Profile"
                        secondaryButtonID="submit-profile-1"
                        variant="success"
                        name="content"
                        SubmitButton="Submit Profile"
                        // disabled1={prfCloseRequestVal === true}
                        disabled={errors.content || loading}
                        commentHead="Reason of Profile Submission"
                        acceptPRF={(payRateCategory, excessPayRate) => {
                          const dataToSend = {
                            ...values,
                            prfSubmitTalentTeam: true,
                            talentSubmissionDate: new Date(),
                            payRateCategory,
                            excessPayRate:
                              payRateCategory === 'above'
                                ? parseFloat(excessPayRate)
                                : null,
                          };
                          SubmitPRF(dataToSend);
                        }}
                        img={<DocumentDownload color="white" size="small" />}
                      />
                    </div>
                  </>
                )}
                {(isTalent || isSuperUser) && Forward && (
                  <AssignTobe
                    role={GET_TALENT_TEAM}
                    handleSubmitTalent={() => {
                      handleSubmitTalent({
                        ...values,
                        userId: parseInt(selectedValue.id, 10),
                        talentApproval: false,
                        talentDisapproval: false,
                        assignedTo: `${selectedValue.firstName} ${selectedValue.lastName}`,
                        assignedEmail: selectedValue.email,
                      });
                    }}
                    setSelectedValue={setSelectedValue}
                    value={selectedValue}
                  />
                )}
                {isTalentTeam &&
                  Forward &&
                  !initialValues.talentDisapproval &&
                  !initialValues.talentApproval && (
                    <div className="d-flex">
                      {/* <Button variant="danger">Disagree</Button> */}
                      <PopUp
                        ButtonName="Disagree"
                        commentHead="Reason of Disagreeing"
                        name="content"
                        className="cancelBtn"
                        variant="danger"
                        id={id}
                        buttonID="button-disagree"
                        secondaryButtonID="button-disagree-1"
                        SubmitButton="Disagree"
                        // disabled1={prfCloseRequestVal === true}
                        disabled={errors.content || loading}
                        isSubmitting={isSubmitting}
                        acceptPRF={() => {
                          const dataToSend = {
                            ...values,
                            content: values.content,
                            // status: forward,
                            // type: 3,
                            talentDisapproval: true,
                            // newStage: process.env.REACT_APP_DEALSTAGE,
                            // hubspotid: values.hubspotid,
                          };
                          rejectPRF(dataToSend);
                        }}
                      />
                      <Button
                        onClick={() => {
                          const dataToSend = {
                            ...values,
                            talentApproval: true,
                            approvedDate: new Date(),
                            agreed: 'agreed',
                          };
                          rejectPRF(dataToSend);
                        }}
                        variant="success"
                      >
                        Agree
                      </Button>
                    </div>
                  )}

                <div style={{ display: 'flex' }}>
                  {/* Request Closure Button */}
                  {isPrfCreatorMatched &&
                    !Closed &&
                    id &&
                    prfCloseRequestVal !== true && (
                      <CloseButtonPRF
                        role={!(isTalent || isTalentTeam)}
                        disabled={errors.content || loading}
                        name="content"
                        locked={locked}
                        status={!Closed}
                        buttonID="button-closure"
                        secondaryButtonID="butto-closure-1"
                        onClick={() => {
                          const dataToSend = {
                            ...values,
                            prfCloseRequest: true,
                            type: 5,
                          };
                          closurePRFRequest(dataToSend);
                        }}
                      />
                    )}
                  {/* Edit Button for Re-submit PRF */}
                  {isPrfCreatorMatched &&
                    locked &&
                    !isSubmitting &&
                    Rejected &&
                    !(isTalent || isTalentTeam) && (
                      <Button
                        id="prf-edit-button"
                        onClick={() => setLocked(false)}
                        variant="primary"
                        size="sm"
                        className="d-flex align-items-center ms-2 justify-content-center btn-createPrf"
                      >
                        <span className="me-2">
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_1160_2199)">
                              <path
                                d="M9.37333 6.01333L9.98667 6.62667L3.94667 12.6667H3.33333V12.0533L9.37333 6.01333ZM11.7733 2C11.6067 2 11.4333 2.06667 11.3067 2.19333L10.0867 3.41333L12.5867 5.91333L13.8067 4.69333C14.0667 4.43333 14.0667 4.01333 13.8067 3.75333L12.2467 2.19333C12.1133 2.06 11.9467 2 11.7733 2ZM9.37333 4.12667L2 11.5V14H4.5L11.8733 6.62667L9.37333 4.12667Z"
                                fill="white"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_1160_2199">
                                <rect width="16" height="16" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </span>
                        <span>Edit</span>
                      </Button>
                    )}
                </div>
                {!(isTalent || isTalentTeam) && !locked && (
                  <>
                    <Link to="/">
                      <Button variant="light" id="cancelPrf-button">
                        Cancel
                      </Button>
                    </Link>
                    <Button
                      id={ButtonID}
                      variant="primary"
                      disabled={
                        errors.requestedBy ||
                        errors.department ||
                        errors.interviewerName ||
                        errors.projectName ||
                        errors.typesOfEngagement ||
                        errors.existingDealId ||
                        errors.workSetting ||
                        errors.hiringManager ||
                        errors.jobTitle ||
                        errors.typeOfOpening ||
                        errors.numOfOpenings ||
                        errors.seniorityLevel ||
                        errors.typeOfEmployment ||
                        errors.targetInterviewDate ||
                        errors.targetStartDate ||
                        errors.city ||
                        errors.country ||
                        errors.billRate ||
                        errors.priority ||
                        errors.projectDetails ||
                        errors.client ||
                        errors.existingDealId ||
                        errors.numberOfDays ||
                        errors.numberOfHours ||
                        errors.typeOfHubspotID
                      }
                      onClick={() => handleClick()}
                    >
                      <span>
                        {id ? (
                          <svg
                            width="15"
                            height="12"
                            viewBox="0 0 15 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.67301 2.02L6.67968 4.16667L1.66634 3.5L1.67301 2.02ZM6.67301 7.83333L1.66634 9.98V8.5L6.67301 7.83333ZM0.339674 0L0.333008 4.66667L10.333 6L0.333008 7.33333L0.339674 12L14.333 6L0.339674 0Z"
                              fill="white"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            fill="currentColor"
                            className="bi bi-check"
                            viewBox="0 0 16 16"
                          >
                            <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                          </svg>
                        )}
                      </span>
                      {id ? (
                        <span style={{ marginLeft: '8px' }}>Re Submit PRF</span>
                      ) : (
                        <span style={{ marginLeft: '5px' }}>Submit PRF</span>
                      )}
                    </Button>
                  </>
                )}
              </div>
              <Modal show={show} onHide={handleClose}>
                <Modal.Header className="modelHeader closeButton">
                  <Modal.Title>Add Comment</Modal.Title>
                </Modal.Header>
                <Form.Group
                  className="modelPopup"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>
                    Comments<span className="optional">*</span>
                  </Form.Label>
                  <FormikField
                    as="textarea"
                    autoFocus
                    name="content"
                    value={content}
                    onChange={handleContentChange}
                    placeholder="Write a comment"
                  />
                  <div
                    className="errorMessage mt-0 ms-3"
                    data-testid="error-message"
                  >
                    <ErrorMessage name="content" />
                  </div>
                </Form.Group>
                <Modal.Footer>
                  <Button
                    id="cancelPrf-button"
                    className="cancelBtn"
                    variant="secondary"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    id={ButtonID1}
                    disabled={!content || isSubmittingState}
                    variant="primary"
                    type="button"
                    onClick={async () => {
                      try {
                        setIsSubmittingState(true);
                        // Show the modal first
                        handleShow();
                        // Ensure the form submission waits for HubSpot ID creation if id doesn't exist
                        await handleSubmitWithHubspot({
                          ...initialValues,
                          content,
                        });
                        // Close the modal after form submission
                        setTimeout(() => {
                          handleClose();
                          setIsSubmittingState(false);
                        }, 3000);
                      } catch (error) {
                        console.error('Error during form submission:', error);
                        setIsSubmittingState(false);
                      }
                    }}
                  >
                    {id ? (
                      <>
                        <svg
                          width="15"
                          height="12"
                          viewBox="0 0 15 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.67301 2.02L6.67968 4.16667L1.66634 3.5L1.67301 2.02ZM6.67301 7.83333L1.66634 9.98V8.5L6.67301 7.83333ZM0.339674 0L0.333008 4.66667L10.333 6L0.333008 7.33333L0.339674 12L14.333 6L0.339674 0Z"
                            fill="white"
                          />
                        </svg>
                        <span style={{ marginLeft: '8px' }}>Re Submit PRF</span>
                      </>
                    ) : (
                      <>
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            fill="currentColor"
                            className="bi bi-check"
                            viewBox="0 0 16 16"
                          >
                            <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                          </svg>
                        </span>
                        <span style={{ marginLeft: '5px' }}>Submit PRF</span>
                      </>
                    )}
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
            {status && <PrfsDetails profile={profile} />}
            <div className="prfCreate">
              <div className="prfSteps d-flex justify-content-between align-items-center">
                <Steps
                  prfStatusesList={initialValues.prf_statuses}
                  isSubmitting={!status}
                  prfSubmitTalentTeam={initialValues.prfSubmitTalentTeam}
                />
              </div>
              <div className="d-flex flex-row gap-2 ai-buttons">
                {(isTalent || isSuperUser || isTalentTeam) && Accepted && (
                  <Catsone
                    id={id}
                    isSubmitting={isSubmitting}
                    disabled1={initialValues.catsoneFlag}
                    onClick={importToCats}
                  />
                )}
                {isTalentTeam && Forward && initialValues.talentApproval && (
                  <Catsone
                    id={id}
                    isSubmitting={isSubmitting}
                    disabled1={initialValues.catsoneFlag}
                    onClick={importToCats}
                  />
                )}
                {id && !isAccounTManager && (
                  <>
                    <Button
                      className="generate-button d-flex align-items-center"
                      // disabled={getAllRequirement.length > 1}
                      onClick={() => {
                        if (getAllRequirement?.length > 1) {
                          handleShowAI();
                        } else {
                          GenerateAI();
                          // {apiResponseGenerate === true &&
                          // updateAIPrf(update);
                          // }
                        }
                      }}
                    >
                      <span className="pe-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="none"
                          stroke-width="1.5"
                          class="h-6 w-6"
                          viewBox="-0.17090198558635983 0.482230148717937 41.14235318283891 40.0339509076386"
                        >
                          <text x="-9999" y="-9999">
                            ChatGPT
                          </text>
                          <path
                            d="M37.532 16.87a9.963 9.963 0 0 0-.856-8.184 10.078 10.078 0 0 0-10.855-4.835A9.964 9.964 0 0 0 18.306.5a10.079 10.079 0 0 0-9.614 6.977 9.967 9.967 0 0 0-6.664 4.834 10.08 10.08 0 0 0 1.24 11.817 9.965 9.965 0 0 0 .856 8.185 10.079 10.079 0 0 0 10.855 4.835 9.965 9.965 0 0 0 7.516 3.35 10.078 10.078 0 0 0 9.617-6.981 9.967 9.967 0 0 0 6.663-4.834 10.079 10.079 0 0 0-1.243-11.813zM22.498 37.886a7.474 7.474 0 0 1-4.799-1.735c.061-.033.168-.091.237-.134l7.964-4.6a1.294 1.294 0 0 0 .655-1.134V19.054l3.366 1.944a.12.12 0 0 1 .066.092v9.299a7.505 7.505 0 0 1-7.49 7.496zM6.392 31.006a7.471 7.471 0 0 1-.894-5.023c.06.036.162.099.237.141l7.964 4.6a1.297 1.297 0 0 0 1.308 0l9.724-5.614v3.888a.12.12 0 0 1-.048.103l-8.051 4.649a7.504 7.504 0 0 1-10.24-2.744zM4.297 13.62A7.469 7.469 0 0 1 8.2 10.333c0 .068-.004.19-.004.274v9.201a1.294 1.294 0 0 0 .654 1.132l9.723 5.614-3.366 1.944a.12.12 0 0 1-.114.01L7.04 23.856a7.504 7.504 0 0 1-2.743-10.237zm27.658 6.437l-9.724-5.615 3.367-1.943a.121.121 0 0 1 .113-.01l8.052 4.648a7.498 7.498 0 0 1-1.158 13.528v-9.476a1.293 1.293 0 0 0-.65-1.132zm3.35-5.043c-.059-.037-.162-.099-.236-.141l-7.965-4.6a1.298 1.298 0 0 0-1.308 0l-9.723 5.614v-3.888a.12.12 0 0 1 .048-.103l8.05-4.645a7.497 7.497 0 0 1 11.135 7.763zm-21.063 6.929l-3.367-1.944a.12.12 0 0 1-.065-.092v-9.299a7.497 7.497 0 0 1 12.293-5.756 6.94 6.94 0 0 0-.236.134l-7.965 4.6a1.294 1.294 0 0 0-.654 1.132l-.006 11.225zm1.829-3.943l4.33-2.501 4.332 2.5v5l-4.331 2.5-4.331-2.5V18z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                      <span>
                        {aiTab === true ? 'Re-Enhance' : 'Enhance with AI'}
                      </span>
                    </Button>
                    <Modal show={showAI} onHide={handleCloseAI}>
                      <Modal.Header closeButton>
                        <Modal.Title>Data Already Enhanced</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        Are you sure you want to enhance it again? If you
                        continue, your existing data will be overridden.
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseAI}>
                          Close
                        </Button>
                        <Button
                          className="generate-button-modal d-flex align-items-center"
                          onClick={() => {
                            const update = {
                              aiResponse: true,
                            };
                            handleCloseAI();
                            GenerateAI(update);
                            // {apiResponseGenerate === true &&
                            //   updateAIPrf(update);
                            //   }
                          }}
                        >
                          <span className="pe-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="none"
                              stroke-width="1.5"
                              class="h-6 w-6"
                              viewBox="-0.17090198558635983 0.482230148717937 41.14235318283891 40.0339509076386"
                            >
                              <text x="-9999" y="-9999">
                                ChatGPT
                              </text>
                              <path
                                d="M37.532 16.87a9.963 9.963 0 0 0-.856-8.184 10.078 10.078 0 0 0-10.855-4.835A9.964 9.964 0 0 0 18.306.5a10.079 10.079 0 0 0-9.614 6.977 9.967 9.967 0 0 0-6.664 4.834 10.08 10.08 0 0 0 1.24 11.817 9.965 9.965 0 0 0 .856 8.185 10.079 10.079 0 0 0 10.855 4.835 9.965 9.965 0 0 0 7.516 3.35 10.078 10.078 0 0 0 9.617-6.981 9.967 9.967 0 0 0 6.663-4.834 10.079 10.079 0 0 0-1.243-11.813zM22.498 37.886a7.474 7.474 0 0 1-4.799-1.735c.061-.033.168-.091.237-.134l7.964-4.6a1.294 1.294 0 0 0 .655-1.134V19.054l3.366 1.944a.12.12 0 0 1 .066.092v9.299a7.505 7.505 0 0 1-7.49 7.496zM6.392 31.006a7.471 7.471 0 0 1-.894-5.023c.06.036.162.099.237.141l7.964 4.6a1.297 1.297 0 0 0 1.308 0l9.724-5.614v3.888a.12.12 0 0 1-.048.103l-8.051 4.649a7.504 7.504 0 0 1-10.24-2.744zM4.297 13.62A7.469 7.469 0 0 1 8.2 10.333c0 .068-.004.19-.004.274v9.201a1.294 1.294 0 0 0 .654 1.132l9.723 5.614-3.366 1.944a.12.12 0 0 1-.114.01L7.04 23.856a7.504 7.504 0 0 1-2.743-10.237zm27.658 6.437l-9.724-5.615 3.367-1.943a.121.121 0 0 1 .113-.01l8.052 4.648a7.498 7.498 0 0 1-1.158 13.528v-9.476a1.293 1.293 0 0 0-.65-1.132zm3.35-5.043c-.059-.037-.162-.099-.236-.141l-7.965-4.6a1.298 1.298 0 0 0-1.308 0l-9.723 5.614v-3.888a.12.12 0 0 1 .048-.103l8.05-4.645a7.497 7.497 0 0 1 11.135 7.763zm-21.063 6.929l-3.367-1.944a.12.12 0 0 1-.065-.092v-9.299a7.497 7.497 0 0 1 12.293-5.756 6.94 6.94 0 0 0-.236.134l-7.965 4.6a1.294 1.294 0 0 0-.654 1.132l-.006 11.225zm1.829-3.943l4.33-2.501 4.332 2.5v5l-4.331 2.5-4.331-2.5V18z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          Re-Enhance
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </>
                )}
              </div>
              <Tabs
                className={isAccounTManager ? 'mt-3' : ''}
                id="controlled-tab-example"
                activeKey={key}
                onSelect={handleTabChange}
              >
                <Tab eventKey="prfDetail" title="PRF Detail">
                  <Accordion heading="Client Details">
                    <Col className="p-0" xl={10}>
                      <Row>
                        <Col md={4} className="mb-3 pb-3">
                          <Field
                            disabled={locked}
                            label="Requested By"
                            name="requestedBy"
                            onChange={(e) => {
                              handleInputChange(e);
                              setFieldValue('requestedBy', e.target.value);
                            }}
                            placeholder="Enter Details"
                            type="text"
                            Asterisk="*"
                          />
                        </Col>
                        <OtherClients
                          disabled={locked}
                          handleInputChange={handleInputChange}
                          setFieldValue={setFieldValue}
                          setShowOtherField={setShowOtherField}
                          showOtherField={showOtherField}
                          roles={rolesToShow}
                          values={values}
                          selected={touched}
                        />
                        <Col md={4} className="mb-3 pb-3">
                          <Field
                            disabled={locked}
                            label="Project / Team Name"
                            name="projectName"
                            onChange={(e) => {
                              handleInputChange(e);
                              setFieldValue('projectName', e.target.value);
                            }}
                            placeholder="Enter job title"
                            type="text"
                            Asterisk="*"
                          />
                        </Col>
                        <Col md={4} className="mb-3 pb-3">
                          <Field
                            disabled={locked}
                            name="interviewerName"
                            label="Interviewer Name"
                            placeholder="Enter Details"
                            touched={touched}
                            onChange={(e) => {
                              handleInputChange(e);
                              setFieldValue('interviewerName', e.target.value);
                            }}
                            type="text"
                            min="1"
                            Asterisk="*"
                          />
                        </Col>
                        <Col md={4} className="mb-3 pb-3">
                          <Field
                            disabled={locked}
                            name="interviewTitle"
                            label="Interviewer Title"
                            onChange={(e) => {
                              handleInputChange(e);
                              setFieldValue('interviewTitle', e.target.value);
                            }}
                            placeholder="Enter Details"
                            touched={touched}
                            type="text"
                            min="1"
                            Asterisk="*"
                          />
                        </Col>
                        <Col md={4} className="mb-3 pb-3">
                          <Dropdown
                            disabled={locked}
                            Asterisk="*"
                            onChange={(e) => {
                              setFieldValue(
                                'typesOfEngagement',
                                e.target.value,
                              );
                              handleInputChange(e);
                              handleEngagementTypeChange(e); // Update the selected engagement type
                            }}
                            label="Type of Engagement"
                            name="typesOfEngagement"
                            placeholder="Enter Type of engagement"
                            roles={PRF_TYPEOFENGAGEMENT}
                            selected={!(touched && touched.role)}
                          />
                        </Col>
                        <Col md={4} className="mb-3 pb-3">
                          <Dropdown
                            disabled={locked}
                            Asterisk="*"
                            onChange={async (e) => {
                              const selectedDepartment = e.target.value;
                              setFieldValue('department', selectedDepartment);
                              handleInputChange(e);
                              setSelectedDepartmentValue(selectedDepartment);

                              // Check if the selected department is not empty
                              if (selectedDepartment) {
                                try {
                                  const response = await AxiosInstance.get(
                                    `${GET_PRF_DEPARTMENT}?department=${selectedDepartment}`,
                                  );
                                  setHirings(response.data);
                                  // setDepartments([response.data.data]); // Consider whether you need to wrap response.data.data in an array
                                } catch (error) {
                                  console.error(
                                    'Failed to send request to the backend',
                                    error,
                                  );
                                }
                              }
                            }}
                            label="Select Department"
                            name="department"
                            roles={PRF_DEPARTMENTS}
                            selected={!(touched && touched.role)}
                          />
                        </Col>
                        {selectedEngagementType !== 'overhead' &&
                          initialValues.typesOfEngagement !== 'overhead' && (
                            <Col md={4} className="mb-3 pb-3">
                              <Dropdown
                                disabled={locked}
                                Asterisk="*"
                                onChange={async (e) => {
                                  setFieldValue(
                                    'hiringManager',
                                    e.target.value,
                                  );
                                  handleInputChange(e);
                                }}
                                label="Hiring Manager/Department Head"
                                name="hiringManager"
                                placeholder="Enter Hiring Manager"
                                // eslint-disable-next-line camelcase
                                roles={
                                  // eslint-disable-next-line camelcase
                                  locked
                                    ? PRF_All_HIRINGMANAGER
                                    : PRF_HIRINGMANAGER
                                }
                                selected={!(touched && touched.hiringManager)}
                              />
                            </Col>
                          )}
                        {!id &&
                          selectedEngagementType === 'fixed_price_project' && (
                            <Col md={4} className="mb-3 pb-3">
                              <Dropdown
                                disabled={locked}
                                Asterisk="*"
                                onChange={(e) => {
                                  setFieldValue(
                                    'typeOfHubspotID',
                                    e.target.value,
                                  );
                                  handleInputChange(e);
                                }}
                                label="Type of Hubspot"
                                name="typeOfHubspotID"
                                placeholder="Enter Type of Hubspot"
                                roles={[PRF_HUBSPOTID[1]]} // Show only 'New' for Fixed Price Project
                                selected={!(touched && touched.role)}
                              />
                            </Col>
                          )}
                        {!id &&
                          selectedEngagementType === 'time_and_material' && (
                            <Col md={4} className="mb-3 pb-3">
                              <Dropdown
                                disabled={locked}
                                Asterisk="*"
                                onChange={(e) => {
                                  setFieldValue(
                                    'typeOfHubspotID',
                                    e.target.value,
                                  );
                                  handleInputChange(e);
                                }}
                                label="Type of Hubspot"
                                name="typeOfHubspotID"
                                placeholder="Enter Type of Hubspot"
                                roles={PRF_HUBSPOTID} // Show both 'New' and 'Existing' for Time and Material
                                selected={!(touched && touched.role)}
                              />
                            </Col>
                          )}
                        {values.typeOfHubspotID === 'existing' ||
                          (selectedEngagementType === 'fixed_price_project' &&
                            !id && (
                              <Col md={4} className="mb-3 pb-3">
                                <Field
                                  disabled={locked}
                                  label="Existing Hubspot ID"
                                  name="existingDealId"
                                  onChange={(e) => {
                                    handleInputChange(e);
                                    setFieldValue(
                                      'existingDealId',
                                      e.target.value,
                                    );
                                  }}
                                  placeholder="Enter Existing Hubspot ID"
                                  type="number"
                                  Asterisk="*"
                                />
                              </Col>
                            ))}
                        {values.typeOfHubspotID === 'existing' && !id && (
                          <Col md={4} className="mb-3 pb-3">
                            <Field
                              disabled={locked}
                              label="Existing Hubspot ID"
                              name="existingDealId"
                              onChange={(e) => {
                                handleInputChange(e);
                                setFieldValue('existingDealId', e.target.value);
                              }}
                              placeholder="Enter Existing Hubspot ID"
                              type="number"
                              Asterisk="*"
                            />
                          </Col>
                        )}
                        {values.typeOfHubspotID !== 'existing' &&
                          selectedEngagementType !== 'fixed_price_project' &&
                          selectedEngagementType !== 'overhead' &&
                          initialValues.typesOfEngagement !== 'overhead' && (
                            <Col md={4} className="mb-3 pb-3">
                              <Field
                                disabled="disabled"
                                label="Hubspot ID"
                                name="hubspotid"
                                placeholder="Enter Hubspot ID"
                                type="text"
                                min="1"
                                Asterisk="*"
                              />
                            </Col>
                          )}
                      </Row>
                    </Col>
                  </Accordion>
                  <Accordion heading="Position Details">
                    <Col className="p-0" xl={10}>
                      <Row>
                        <Col md={4} className="mb-3 pb-3">
                          <Field
                            disabled={locked}
                            label="Job Title"
                            name="jobTitle"
                            onChange={(e) => {
                              handleInputChange(e);
                              setFieldValue('jobTitle', e.target.value);
                            }}
                            placeholder="Enter job title"
                            type="text"
                            Asterisk="*"
                          />
                        </Col>
                        <Col md={4} className="mb-3 pb-3">
                          <Field
                            disabled={locked}
                            name="numOfOpenings"
                            label="No. of Openings"
                            placeholder="Enter no. of positions"
                            touched={touched}
                            onChange={(e) => {
                              handleInputChange(e);
                              setFieldValue('numOfOpenings', e.target.value);
                            }}
                            type="number"
                            min="1"
                            Asterisk="*"
                          />
                        </Col>
                        <Col md={4} className="mb-3 pb-3">
                          <Dropdown
                            disabled={locked}
                            Asterisk="*"
                            onChange={(e) => {
                              setFieldValue('seniorityLevel', e.target.value);
                              handleInputChange(e);
                            }}
                            label="Seniority Level"
                            name="seniorityLevel"
                            placeholder="Enter seniority level"
                            roles={PRF_EXPERIENCE_LEVEL}
                            selected={!(touched && touched.role)}
                          />
                        </Col>
                        <Col md={4} className="mb-3 pb-3">
                          <Dropdown
                            disabled={locked}
                            Asterisk="*"
                            onChange={(e) => {
                              setFieldValue('typeOfOpening', e.target.value);
                              handleInputChange(e);
                            }}
                            label="Type of Opening"
                            name="typeOfOpening"
                            placeholder="Enter Type of Opening"
                            roles={PRF_TYPE_OF_OPENING}
                            selected={!(touched && touched.role)}
                          />
                        </Col>
                        <Col md={4} className="mb-3 pb-3">
                          <Dropdown
                            disabled={locked}
                            Asterisk="*"
                            label="Type of Employment"
                            onChange={(e) => {
                              setFieldValue('typeOfEmployment', e.target.value);
                              handleInputChange(e);
                              handleContactChange(
                                e,
                                setFieldValue,
                                setFieldError,
                              );
                            }}
                            name="typeOfEmployment"
                            placeholder="Enter type of employment"
                            selected={!(touched && touched.role)}
                            roles={PRF_TYPE_OF_EMPLOYMENT}
                          />
                        </Col>
                        <Col md={4} className="mb-3 pb-3">
                          <Dropdown
                            disabled={locked}
                            Asterisk="*"
                            onChange={(e) => {
                              setFieldValue('duration', e.target.value);
                              setFieldTouched('duration', true);
                              handleInputChange(e);
                            }}
                            label="Project Duration"
                            name="duration"
                            placeholder="Enter duration"
                            roles={PRF_DURATION}
                            nulls={null}
                            selected={!(touched && touched.role)}
                          />
                        </Col>
                        <Col
                          md={4}
                          className="mb-3 pb-3 position-relative z-3 d-flex flex-column"
                        >
                          <DateSelect
                            key="1"
                            disabled={locked}
                            label="Target Interview Date"
                            name="targetInterviewDate"
                            placeholder="Enter Details"
                            handleInputChange={handleInputChange}
                            min={moment(new Date()).add('day', 7).toDate()}
                            Asterisk="*"
                            // min={new Date()}
                          />
                        </Col>
                        <Col
                          md={4}
                          className="mb-3 pb-3 position-relative z-3 d-flex flex-column"
                        >
                          <DateSelect
                            key="2"
                            disabled={
                              values?.targetInterviewDate === ''
                                ? 'disabled'
                                : locked
                            }
                            handleInputChange={handleInputChange}
                            label="Target Start Date"
                            name="targetStartDate"
                            placeholder="Enter Details"
                            Asterisk="*"
                            min={
                              values?.targetInterviewDate
                                ? moment(values.targetInterviewDate)
                                    .add('day', 14)
                                    .toDate()
                                : new Date()
                            }
                          />
                        </Col>
                        <Col md={4} className="mb-3 pb-3">
                          <Dropdown
                            disabled={locked}
                            Asterisk="*"
                            onChange={(e) => {
                              setFieldValue(
                                'candidateLocation',
                                e.target.value,
                              );
                              handleInputChange(e);
                            }}
                            label="Job Location"
                            name="candidateLocation"
                            roles={PRF_CANDIDATE_LOCATION}
                            selected={!(touched && touched.role)}
                          />
                        </Col>
                        <Col md={4} className="mb-3 pb-3">
                          <Location
                            label="Job Site"
                            disabled={locked}
                            field1="city"
                            onChange2={(e) => {
                              handleInputChange(e);
                              setFieldValue('country', e.target.value);
                            }}
                            onChange1={(e) => {
                              handleInputChange(e);
                              setFieldValue('city', e.target.value);
                            }}
                            placeholder1="Enter City"
                            placeholder2="Enter Country"
                            field2="country"
                            type="text"
                          />
                        </Col>
                        <Col md={4} className="mb-3 pb-3">
                          <Dropdown
                            disabled={locked}
                            Asterisk="*"
                            onChange={(e) => {
                              setFieldValue('workSetting', e.target.value);
                              handleInputChange(e);
                            }}
                            label="Work Setting"
                            name="workSetting"
                            roles={PRF_WORK_SETTING}
                            selected={!(touched && touched.role)}
                          />
                        </Col>
                        <Col md={4} className="mb-3 pb-3">
                          <Dropdown
                            disabled={locked}
                            Asterisk="*"
                            onChange={(e) => {
                              setFieldValue('priority', e.target.value);
                              handleInputChange(e);
                            }}
                            label="Priority"
                            name="priority"
                            placeholder="Enter priority"
                            roles={PRF_PRIORITY}
                            selected={!(touched && touched.role)}
                          />
                        </Col>
                        {!isTalentTeam && (
                          <Col md={4} className="mb-3 pb-3">
                            <Field
                              disabled={locked}
                              label={
                                selectedEngagementType === 'overhead' ||
                                initialValues.typesOfEngagement === 'overhead'
                                  ? 'Salary'
                                  : 'Bill Rate'
                              }
                              name="billRate"
                              type="number"
                              onChange={(e) => {
                                const billRate = parseFloat(e.target.value);
                                const payRate = Math.round(billRate * 0.7);
                                handleInputChange(e);
                                setFieldValue('billRate', billRate);
                                setFieldValue('payRate', payRate);
                              }}
                              min="1"
                              Asterisk="*"
                            />
                          </Col>
                        )}
                        {!(isAccounTManager || isDepartmentHead) && (
                          <Col md={4} className="mb-3 pb-3">
                            <Field
                              disabled
                              label="Pay Rate"
                              name="payRate"
                              type="number"
                              min="1"
                              Asterisk="*"
                            />
                          </Col>
                        )}

                        <Col md={4} className="mb-3 pb-3">
                          <Dropdown
                            disabled={locked}
                            Asterisk="*"
                            onChange={(e) => {
                              setFieldValue('numberOfHours', e.target.value);
                              handleInputChange(e);
                            }}
                            label="Number of Working Hours per Week"
                            name="numberOfHours"
                            roles={PRF_NUMOFHOUR}
                            selected={!(touched && touched.role)}
                          />
                        </Col>
                        <Col md={4} className="mb-3 pb-3">
                          <Field
                            disabled={locked}
                            name="numberOfDays"
                            label="Number of Days Working Onsite,"
                            placeholder="Enter Number of Days Working Onsite,"
                            touched={touched}
                            onChange={(e) => {
                              handleInputChange(e);
                              setFieldValue('numberOfDays', e.target.value);
                            }}
                            type="number"
                            min="0"
                            Asterisk="*"
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Accordion>

                  <Accordion heading="Job Description">
                    <div className="textEditorContainer">
                      <div className="editorHolder">
                        <h3>
                          Project Details{' '}
                          <span style={{ color: 'red' }}>*</span>
                        </h3>
                        <TextEditor
                          disabled={locked}
                          value={values.projectDetails}
                          onChange={(value) => {
                            setFieldValue('projectDetails', value);
                            handleInputChange({
                              target: { name: 'projectDetails', value },
                            });
                          }}
                        />
                      </div>
                      <div
                        style={{
                          color: 'red',
                          marginBottom: '15px',
                          marginTop: '-12px',
                        }}
                      >
                        {errors.projectDetails && (
                          <div>{errors.projectDetails}</div>
                        )}
                      </div>

                      <div className="editorHolder">
                        <h3>
                          The Role <span style={{ color: 'red' }}>*</span>
                        </h3>
                        <TextEditor
                          disabled={locked}
                          value={values.theRole}
                          onChange={(value) => {
                            setFieldValue('theRole', value);
                            handleInputChange({
                              target: { name: 'theRole', value },
                            });
                          }}
                        />
                      </div>
                      <div
                        style={{
                          color: 'red',
                          marginBottom: '15px',
                          marginTop: '-12px',
                        }}
                      >
                        {errors.theRole && <div>{errors.theRole}</div>}
                      </div>
                      <div className="editorHolder">
                        <h3>
                          You will <span style={{ color: 'red' }}>*</span>
                        </h3>
                        <TextEditor
                          disabled={locked}
                          value={values.youWill}
                          onChange={(value) => {
                            setFieldValue('youWill', value);
                            handleInputChange({
                              target: { name: 'youWill', value },
                            });
                          }}
                        />
                      </div>
                      <div
                        style={{
                          color: 'red',
                          marginBottom: '15px',
                          marginTop: '-12px',
                        }}
                      >
                        {errors.youWill && <div>{errors.youWill}</div>}
                      </div>

                      <div className="editorHolder">
                        <h3>
                          Who You Are <span style={{ color: 'red' }}>*</span>
                        </h3>
                        <TextEditor
                          disabled={locked}
                          value={values.whoUR}
                          onChange={(value) => {
                            setFieldValue('whoUR', value);
                            handleInputChange({
                              target: { name: 'whoUR', value },
                            });
                          }}
                        />
                      </div>
                      <div
                        style={{
                          color: 'red',
                          marginBottom: '15px',
                          marginTop: '-12px',
                        }}
                      >
                        {errors.whoUR && <div>{errors.whoUR}</div>}
                      </div>
                    </div>
                  </Accordion>
                </Tab>
                <Tab eventKey="cmtsection" title="Comments">
                  <CommentSection
                    comments={comments}
                    initialValues={initialValues}
                  />
                </Tab>
                {!isAccounTManager &&
                  (aiTab === true || getAllRequirement.length > 0) && (
                    <Tab eventKey="Aisection" title={'AI'}>
                      <AISection
                        getAllRequirement={getAllRequirement}
                        getAllSkills={getAllSkills}
                        id={id}
                        setAiLoading={setAiLoading}
                        isAiLoading={isAiLoading}
                        getAllCandidates={getAllCandidates}
                        getAllQuestionA={getAllQuestionA}
                        getAllAI={getAllAI}
                        setGetAllSkill={setGetAllSkill}
                        getPrf={getPrf}
                        setKey={setKey}
                        locked={lockedAi}
                        setLocked={setLockedAi}
                        setLockedAiSimplify={setLockedAiSimplify}
                        setLockedAikeyword={setLockedAikeyword}
                        lockedKeyword={lockedAiKeyword}
                        lockedSimplify={lockedAiSimplify}
                        availableCandidates={availableCandidates}
                        matchedCandidates={matchedCandidates}
                        totalCandidates={totalCandidates}
                        valuesJobtitle={initialValues.jobTitle}
                        updateAIPrf={() => {
                          const update = {
                            aiResponse: false,
                          };
                          updateAIPrf(update);
                        }}
                        updateAIPrfSkill={() => {
                          const update = {
                            aiResponse: true,
                          };
                          updateAIPrf(update);
                        }}
                        aiResponse={aiResponse}
                      />
                    </Tab>
                  )}
              </Tabs>
            </div>
          </FormikForm>
        )}
      </Formik>
    </>
  );
};

export default PRFstep;
