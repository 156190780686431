import React, { useContext, useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { FiLogOut } from 'react-icons/fi';
import { MdKeyboardArrowDown } from 'react-icons/md';
import Button from 'react-bootstrap/Button';
import { AppContext } from '../context/appContext';
import Loader from '../assets/images/loader.gif';
import LogoWhite from '../assets/images/mlLogoWhite.svg';
import EmployeeBanner from '../assets/images/employee.svg';
import Lock from '../assets/images/lock.svg';

// const { ADMIN, TALENT, ACCOUNT_MANAGER, EMPLOYEE } = GET_USER_ROLES;

const Employee = () => {
  const navigate = useNavigate();
  const { profile, logOut } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000); // Simulating a 2-second delay
  }, []);

  if (isLoading) {
    return (
      <div className="backdrop">
        <img src={Loader} width={50} height={50} />
      </div>
    );
  }

  const { id } = useParams();

  /* const [dProfile, setDProfile] = useState({});
  useEffect(() => {
    const decoded_profile = jwt_decode(profile.token);
    setDProfile(decoded_profile);
  }, []);

  const isTalent = dProfile?.user?.roles[0].role === TALENT
  const isAccounTManager = dProfile?.user?.roles[0].role === ACCOUNT_MANAGER
  const isAdmin = dProfile?.user?.roles[0].role === ADMIN
  const isEmployee = dProfile?.user?.roles[0].role === EMPLOYEE */

  return (
    <main className={`employee flex-grow-1 d-flex`}>
      <Container fluid className="flex-shrink-1 p-0 d-flex">
        <div
          className="employeeBanner d-flex flex-column"
          data-testid="employee-banner"
        >
          <span>
            <img src={LogoWhite} alt="logo" />
          </span>
          <figure className="m-0 d-flex justify-content-center flex-grow-1">
            <img src={EmployeeBanner} width={500} alt="banner" />
          </figure>
        </div>
        <div className="flex-grow-1 employeeContent">
          <div className="align-items-center">
            <div
              className="dropdown userDropdown"
              style={{ marginLeft: '80%', marginTop: '10px' }}
            >
              <button
                id="dropdown-header-button"
                className="dropdown-toggle border-0 bg-transparent d-flex align-items-center p-0"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <strong className="text-capitalize roleDropdown">
                  Role: {profile?.user?.roles[0]?.displayName}
                </strong>
                <figure className="mb-0">
                  <img
                    className="rounded-circle"
                    src={profile?.user?.picture}
                    width={'32'}
                    height={'32'}
                    alt="user image"
                  />
                </figure>
                <MdKeyboardArrowDown className="ms-2" />
              </button>
              <div className="dropdown-menu">
                <Button
                  id="myProfile-button"
                  style={{ color: 'black', background: 'white' }}
                  className="border-0 d-flex align-items-center\"
                  onClick={() =>
                    id ? navigate(`/rms/myrms`) : navigate(`/rmsForm`)
                  }
                >
                  <span>My Profile</span>
                </Button>
                <Button
                  id="logout-button"
                  style={{ color: 'red', background: 'white' }}
                  className="border-0 d-flex align-items-center"
                  onClick={logOut}
                  data-testid="log-out-button"
                >
                  <FiLogOut className="me-2" />
                  <span>Log out</span>
                </Button>
              </div>
            </div>
          </div>
          <div className="contentContainer m-0 d-flex justify-content-center flex-grow-1 align-items-center">
            <div className="contentArea d-flex justify-content-center align-items-center bg-white">
              <div className="content text-center">
                <figure className="d-flex justify-content-center flex-grow-1">
                  <img src={Lock} width={108} height={120} alt="locked" />
                </figure>
                <h1>Access Restricted</h1>
                <p>Were working on getting you access!</p>
                <p>While you wait, Make sure your profile is complete</p>
                <p>For changes related to role, contact the Admin.</p>
                <div className="text-center d-flex justify-content-center">
                  <Button
                    id="myProfile-button"
                    className="border-0 d-flex align-items-center Myprofile mt-2"
                    onClick={() =>
                      id ? navigate(`/rms/myrms`) : navigate(`/rmsForm`)
                    }
                  >
                    <span>My Profile</span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </main>
  );
};

export default Employee;
