import React from 'react';
import { Field } from 'formik';
import PropTypes from 'prop-types';

const DropdownAnalytics = ({ roles, label, disabled, onChange, option }) => (
  <div style={{ display: 'flex', alignItems: 'baseline' }}>
    <label className="detailLabel" style={{ marginRight: 5 }}>
      {label}
    </label>
    <Field
      as="select"
      className="positionDetail"
      disabled={disabled}
      onChange={onChange}
    >
      <option value="">{option}</option>
      {roles &&
        roles.map((role, index) => (
          <option
            key={`${index}-${role.value}`}
            value={role.client || role.value || ''}
          >
            {role.text}
            {role.client}
          </option>
        ))}
    </Field>
  </div>
);

DropdownAnalytics.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  roles: PropTypes.array,
  selected: PropTypes.bool,
  option: PropTypes.string,
};

export default DropdownAnalytics;
