/* eslint-disable*/
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import { Formik, Form, Field } from 'formik';
import Accordion from './accordion';
import AxiosInstance from '../../services/api';
import { PRF_JOB, PRF_AI_PATCH, PRF_SIMPIFY } from '../../services/routes/prf';
import { BasicTableJob } from '../jobsTable/basicTable';
import myImage from '../../assets/images/catsone.png';
import Loader from '../../assets/images/loader.gif';

const AISection = ({
  getAllRequirement,
  getAllSkills,
  id,
  getAllCandidates,
  setAiLoading,
  getPrf,
  getAllAI,
  setKey,
  getAllQuestionA,
  updateAIPrf,
  aiResponse,
  availableCandidates,
  updateAIPrfSkill,
  locked,
  setLocked,
  matchedCandidates,
  totalCandidates,
  isAiLoading,
  lockedKeyword,
  lockedSimplify,
  setLockedAiSimplify,
  setLockedAikeyword,
  valuesJobtitle,
}) => {
  const [candidatesLoading, setCandidatesLoading] = useState(false);
  const [allSkillsLoading, setallSkillsLoading] = useState(false);
  const keywordString =
    getAllSkills && getAllSkills?.length > 0 ? getAllSkills[0]?.keyword : '';
  const cleanedString = keywordString.replace(/ AND | OR /g, ', ');
  const jsonArray = JSON.stringify(
    cleanedString.split(', ').map((keyword) => keyword.replace(/[()""]/g, '')),
  );
  const [apiResponse, setApiResponse] = useState('');
  // eslint-disable-next-line no-shadow
  const getHeadingContent = (availableCandidates) => {
    if (availableCandidates < 1) {
      return (
        <>
          <span
            className="matchResourse"
            style={{ backgroundColor: 'red' }}
          ></span>
          <span>Available candidates on Catsone</span>
        </>
      );
    } else if (availableCandidates < 5) {
      return (
        <>
          <span
            className="matchResourse"
            style={{ backgroundColor: 'yellow' }}
          ></span>
          <span>Available candidates on Catsone</span>
        </>
      );
    } else {
      return (
        <>
          <span
            className="matchResourse"
            style={{ backgroundColor: 'green' }}
          ></span>
          <span>Available candidates on Catsone</span>
        </>
      );
    }
  };
  const handleSimlifyUpdate = async (values) => {
    const toastId = toast.loading('Generating Data');
    setLocked(true);
    setallSkillsLoading(true);
    try {
      const response = await AxiosInstance.patch(
        PRF_SIMPIFY + id, // Adjusted endpoint with `values.id`
        { newSimplifiedString: values.simplifiedString },
      );
      toast.update(toastId, {
        render: 'PRF updated successfully',
        type: 'success',
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: false,
      });
      setLocked(true);
      setallSkillsLoading(false);
      await getPrf(id);
      await getAllAI(id);
      setKey('Aisection');
    } catch (error) {
      setallSkillsLoading(false);
      console.error('Error:', error);
      toast.update(toastId, {
        render: 'Failed to update PRF',
        type: 'error',
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: false,
      });
    }
  };
  const handleSimlifyDelete = async (values) => {
    const toastId = toast.loading('Generating Data');
    setLocked(true);
    setallSkillsLoading(true);
    try {
      const response = await AxiosInstance.delete(
        PRF_SIMPIFY + id, // Adjusted endpoint with `values.id`
        { newSimplifiedString: values.simplifiedString },
      );
      toast.update(toastId, {
        render: 'PRF updated successfully',
        type: 'success',
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: false,
      });
      setLocked(true);
      setallSkillsLoading(false);
      await getPrf(id);
      await getAllAI(id);
      setKey('Aisection');
    } catch (error) {
      setallSkillsLoading(false);
      console.error('Error:', error);
      toast.update(toastId, {
        render: 'Failed to update PRF',
        type: 'error',
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: false,
      });
    }
  };
  const handleSkillUpdate = async (values) => {
    const toastId = toast.loading('Generating Data');
    setLocked(true);
    setallSkillsLoading(true);
    try {
      const response = await AxiosInstance.patch(
        PRF_AI_PATCH + id, // Adjusted endpoint with `values.id`
        { keyword: values.keyword },
      );
      toast.update(toastId, {
        render: 'PRF updated successfully',
        type: 'success',
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: false,
      });
      setLocked(true);
      setallSkillsLoading(false);
      await getPrf(id);
      await getAllAI(id);
      setKey('Aisection');
    } catch (error) {
      setallSkillsLoading(false);
      console.error('Error:', error);
      toast.update(toastId, {
        render: 'Failed to update PRF',
        type: 'error',
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: false,
      });
    }
  };
  const getAllFields = async () => {
    const toastId = toast.loading('Update PRF');
    setCandidatesLoading(true); // Set loading state when fetching candidates begins
    setLocked(true);
    try {
      const response = await AxiosInstance.get(PRF_JOB + id);
      setApiResponse(response?.data?.response);
      toast.update(toastId, {
        render: 'PRF updated successfully',
        type: 'success',
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: false,
      });
      setCandidatesLoading(false); // Set loading state when fetching candidates ends
      setAiLoading(false);
      setLocked(true);
      if (response?.data?.response === 'candidate found') {
        updateAIPrf();
        getPrf(id);
        getAllAI(id);
        setKey('Aisection');
        setLocked(true);
      }
    } catch (error) {
      setCandidatesLoading(false); // Set loading state if there's an error
      console.error('Error fetching jobs:', error);
    }
  };
  const getSimlify = async () => {
    const toastId = toast.loading('Update PRF');
    setLocked(true);
    try {
      const response = await AxiosInstance.get(PRF_SIMPIFY + id);
      setApiResponse(response?.data?.response);
      toast.update(toastId, {
        render: 'PRF updated successfully',
        type: 'success',
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: false,
      });
      setAiLoading(false);
      setLocked(true);
      await getPrf(id);
      await getAllAI(id);
      setKey('Aisection');
      setLocked(true);
    } catch (error) {
      setCandidatesLoading(false); // Set loading state if there's an error
      console.error('Error fetching jobs:', error);
    }
  };
  return (
    <div className="ai-section">
      <Accordion heading="Top 6 Requirements">
        <div className="columnsTop8R">
          {isAiLoading ? (
            <div className="loadingContainer">
              <img src={Loader} width={50} height={50} />
            </div>
          ) : Array.isArray(getAllRequirement) ? (
            <ul className="gridContainer" style={{ paddingLeft: '0' }}>
              {getAllRequirement.map((item) => (
                <li className="gridItem pb-3" key={item?.id}>
                  {item?.requirement}
                </li>
              ))}
            </ul>
          ) : (
            <div>Error: Data is not available or in the expected format</div>
          )}
        </div>
      </Accordion>

      <Accordion heading="Required Tech Stack" loading={allSkillsLoading}>
        <div className="columnsTop8R">
          {allSkillsLoading || isAiLoading ? (
            <div className="loadingContainer">
              <img src={Loader} width={50} height={50} />
            </div>
          ) : Array.isArray(getAllSkills) ? (
            <ul className="gridContainer" style={{ paddingLeft: '0' }}>
              {JSON.parse(jsonArray).map((item, index) => (
                <li className="gridItem pb-3" key={index}>
                  {item}
                </li>
              ))}
            </ul>
          ) : (
            <div>Error: Data is not available or in the expected format</div>
          )}
        </div>
      </Accordion>
      <Accordion
        heading={`Boolean string for ${valuesJobtitle}`}
        loading={allSkillsLoading}
      >
        <div className="columnsTop8R">
          {isAiLoading ? (
            <div className="loadingContainer">
              <img src={Loader} width={50} height={50} />
            </div>
          ) : (
            getAllSkills[0]?.keyword && (
              <Formik
                initialValues={{
                  keyword: Array.isArray(getAllSkills)
                    ? getAllSkills[0]?.keyword
                    : '',
                  id,
                  simplifiedString: Array.isArray(getAllSkills)
                    ? getAllSkills[0]?.simplifiedString
                    : '',
                  id,
                }}
              >
                {({ values }) => (
                  <Form>
                    <Field
                      type="text"
                      name="keyword"
                      value={values.keyword}
                      className="form-control"
                      disabled={lockedKeyword} // Add lockedKeyword to control disabled status
                    />
                    <div className="d-flex justify-content-end mt-2 mb-3">
                      {!lockedKeyword && (
                        <Button
                          style={{
                            backgroundColor: '#1d2541',
                          }}
                          onClick={(event) => {
                            event.preventDefault();
                            handleSkillUpdate(values);
                            updateAIPrfSkill();
                          }}
                        >
                          Update
                        </Button>
                      )}
                      {lockedKeyword && (
                        <>
                          <Button
                            id="prf-edit-button"
                            onClick={() => getSimlify()}
                            variant="primary"
                            size="sm"
                            className="d-flex align-items-center ms-2 justify-content-center btn-createPrf updateSkill"
                          >
                            <span style={{ marginRight: '7px' }}>
                              <svg
                                width="14px"
                                height="14px"
                                viewBox="0 0 100 100"
                                aria-hidden="true"
                                role="img"
                                className="iconify iconify--gis"
                                preserveAspectRatio="xMidYMid meet"
                              >
                                <g fill="#ffff">
                                  <path d="M2.437 39.798c-3.445 5.966-1.373 13.7 4.594 17.144c3.365 1.944 7.291 2.13 10.694.852l13.06 19.234a12.33 12.33 0 0 0-1.11 1.59c-3.444 5.967-1.372 13.7 4.595 17.145c5.966 3.445 13.698 1.372 17.143-4.595c3.325-5.76 1.473-13.127-4.025-16.732a3.5 3.5 0 0 0-.568-.41a3.5 3.5 0 0 0-.64-.288c-3.077-1.55-6.545-1.705-9.632-.683L23.336 53.6c.3-.398.583-.81.838-1.251c2.306-3.996 2.119-8.762-.046-12.512l12.98-13.067c5.53 2.113 11.968.039 15.154-5.11l21.997 4.61c.421 3.853 2.586 7.472 6.171 9.542c5.967 3.445 13.698 1.372 17.143-4.595c3.326-5.76 1.473-13.127-4.024-16.732a3.5 3.5 0 0 0-.57-.41a3.5 3.5 0 0 0-.638-.288c-5.871-2.958-13.18-.88-16.505 4.88c-.133.232-.245.469-.362.705L54.11 14.893c-.085-4.034-2.104-7.95-5.666-10.285a3.5 3.5 0 0 0-.568-.41a3.5 3.5 0 0 0-.64-.287c-5.87-2.959-13.178-.88-16.504 4.88c-2.55 4.416-2.07 9.796.768 13.693l-12.407 12.49a3.5 3.5 0 0 0-.15-.056c-5.872-2.959-13.18-.88-16.505 4.88zm6.062 3.5c1.553-2.69 4.891-3.584 7.581-2.03c2.69 1.553 3.585 4.89 2.031 7.58c-1.553 2.69-4.89 3.586-7.58 2.032c-2.69-1.553-3.585-4.892-2.032-7.582zM36.793 12.29c1.553-2.69 4.891-3.584 7.582-2.03c2.69 1.553 3.584 4.89 2.03 7.58c-1.553 2.69-4.89 3.586-7.58 2.032c-2.69-1.553-3.585-4.892-2.032-7.582zm-1.055 69.827c1.553-2.69 4.891-3.583 7.582-2.03c2.69 1.554 3.584 4.89 2.03 7.58c-1.553 2.69-4.89 3.586-7.58 2.033c-2.69-1.553-3.585-4.892-2.032-7.583zm46.16-59.952c1.554-2.69 4.891-3.583 7.582-2.03c2.69 1.554 3.584 4.89 2.03 7.58c-1.552 2.69-4.89 3.586-7.58 2.033c-2.69-1.553-3.585-4.892-2.032-7.583z"></path>
                                  <path d="M19.771 20.746c-.997.28-1.084.586-1.392 1.504l-1.692 5.033l4.74 1.592l1.335-3.967l3.283-1.396l-1.957-4.602z"></path>
                                  <path d="M50.172 29.054l6.93 7.209l3.603-3.465l-6.93-7.209z"></path>
                                  <path d="M63.043 33.971l2.281 4.45l8.899-4.561l-2.281-4.45z"></path>
                                  <path d="M7.977 72.288l4.976.48l.963-9.954l-4.978-.48z"></path>
                                  <path d="M15.294 78.188l9.119 4.101l2.053-4.558l-9.12-4.104z"></path>
                                </g>
                              </svg>
                            </span>
                            <span>Simplify</span>
                          </Button>
                          <Button
                            id="prf-edit-button"
                            onClick={() => setLockedAikeyword(false)}
                            variant="primary"
                            size="sm"
                            className="d-flex align-items-center ms-2 justify-content-center btn-createPrf"
                          >
                            <span className="me-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="1em"
                                viewBox="0 0 512 512"
                              >
                                <path
                                  fill="white"
                                  d="M441 58.9L453.1 71c9.4 9.4 9.4 24.6 0 33.9L424 134.1 377.9 88 407 58.9c9.4-9.4 24.6-9.4 33.9 0zM209.8 256.2L344 121.9 390.1 168 255.8 302.2c-2.9 2.9-6.5 5-10.4 6.1l-58.5 16.7 16.7-58.5c1.1-3.9 3.2-7.5 6.1-10.4zM373.1 25L175.8 222.2c-8.7 8.7-15 19.4-18.3 31.1l-28.6 100c-2.4 8.4-.1 17.4 6.1 23.6s15.2 8.5 23.6 6.1l100-28.6c11.8-3.4 22.5-9.7 31.1-18.3L487 138.9c28.1-28.1 28.1-73.7 0-101.8L474.9 25C446.8-3.1 401.2-3.1 373.1 25zM88 64C39.4 64 0 103.4 0 152V424c0 48.6 39.4 88 88 88H360c48.6 0 88-39.4 88-88V312c0-13.3-10.7-24-24-24s-24 10.7-24 24V424c0 22.1-17.9 40-40 40H88c-22.1 0-40-17.9-40-40V152c0-22.1 17.9-40 40-40H200c13.3 0 24-10.7 24-24s-10.7-24-24-24H88z"
                                />
                              </svg>
                            </span>
                            <span>Edit</span>
                          </Button>
                        </>
                      )}
                    </div>
                    {getAllSkills[0]?.simplifiedString === null ? (
                      ''
                    ) : (
                      <>
                        <h6 className="pt-3">
                          {' '}
                          A simplified version of the above boolean String.
                        </h6>
                        <Field
                          type="text"
                          name="simplifiedString"
                          value={values.simplifiedString}
                          className="form-control"
                          disabled={lockedSimplify} // Add lockedSimplify to control disabled status
                        />
                      </>
                    )}
                    <div className="d-flex justify-content-end mt-2">
                      {!lockedSimplify &&
                        getAllSkills[0]?.simplifiedString !== null && (
                          <Button
                            style={{
                              backgroundColor: '#1d2541',
                            }}
                            onClick={(event) => {
                              event.preventDefault();
                              handleSimlifyUpdate(values);
                              updateAIPrfSkill();
                            }}
                          >
                            Update
                          </Button>
                        )}
                      {lockedSimplify && (
                        <>
                          <Button
                            onClick={(event) => {
                              event.preventDefault();
                              handleSimlifyDelete(values);
                              updateAIPrfSkill();
                            }}
                            className="btn btn-danger"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="1em"
                              viewBox="0 0 448 512"
                            >
                              <path
                                fill="white"
                                d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"
                              />
                            </svg>
                          </Button>
                          <Button
                            id="prf-edit-button"
                            onClick={() => setLockedAiSimplify(false)}
                            variant="primary"
                            size="sm"
                            className="d-flex align-items-center ms-2 justify-content-center btn-createPrf"
                          >
                            <span className="me-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="1em"
                                viewBox="0 0 512 512"
                              >
                                <path
                                  fill="white"
                                  d="M441 58.9L453.1 71c9.4 9.4 9.4 24.6 0 33.9L424 134.1 377.9 88 407 58.9c9.4-9.4 24.6-9.4 33.9 0zM209.8 256.2L344 121.9 390.1 168 255.8 302.2c-2.9 2.9-6.5 5-10.4 6.1l-58.5 16.7 16.7-58.5c1.1-3.9 3.2-7.5 6.1-10.4zM373.1 25L175.8 222.2c-8.7 8.7-15 19.4-18.3 31.1l-28.6 100c-2.4 8.4-.1 17.4 6.1 23.6s15.2 8.5 23.6 6.1l100-28.6c11.8-3.4 22.5-9.7 31.1-18.3L487 138.9c28.1-28.1 28.1-73.7 0-101.8L474.9 25C446.8-3.1 401.2-3.1 373.1 25zM88 64C39.4 64 0 103.4 0 152V424c0 48.6 39.4 88 88 88H360c48.6 0 88-39.4 88-88V312c0-13.3-10.7-24-24-24s-24 10.7-24 24V424c0 22.1-17.9 40-40 40H88c-22.1 0-40-17.9-40-40V152c0-22.1 17.9-40 40-40H200c13.3 0 24-10.7 24-24s-10.7-24-24-24H88z"
                                />
                              </svg>
                            </span>
                            <span>Edit</span>
                          </Button>
                        </>
                      )}
                    </div>
                  </Form>
                )}
              </Formik>
            )
          )}
        </div>
      </Accordion>
      <Accordion heading="Q & As">
        {/* Render questions and answers */}
        <div className="columnsTop8R">
          {Array.isArray(getAllQuestionA) ? (
            <ul className="columns-question" style={{ paddingLeft: '0' }}>
              {getAllQuestionA.map((item, index) => (
                <li
                  className="gridItemQuestions pb-3 d-flex flex-column"
                  key={item?.id}
                >
                  <div>
                    <div>
                      <p>
                        <b>
                          Question # {index + 1} : {item?.question}
                        </b>
                      </p>
                    </div>
                  </div>
                  <div>
                    <b>
                      <p>Answer :</p>
                    </b>
                    <p>{item?.answer}</p>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <div>Error: Data is not available or in the expected format</div>
          )}
        </div>
      </Accordion>
      <div className="d-flex justify-content-end">
        <Button
          variant="dark"
          onClick={async () => {
            if (!(getAllCandidates?.length > 0)) {
              await getAllFields(); // Await getAllFields call
            } else {
              await getAllFields(); // Await getAllFields call
            }
          }}
          className="d-flex align-items-center text-white border-0 hover-button getJob"
          disabled={!(getAllCandidates?.length > 0) ? '' : aiResponse === false}
        >
          <span className="pe-2">
            <img src={myImage} height={20} width={20} alt="Icon" />
          </span>
          <span>Find Profiles from CATS</span>
        </Button>
      </div>
      <Accordion
        heading={getHeadingContent(availableCandidates)}
        loading={candidatesLoading} // Pass loading state to Accordion
      >
        {candidatesLoading ? (
          <div className="loadingContainer">
            <img src={Loader} alt="Loading" width={50} height={50} />
          </div>
        ) : getAllCandidates?.length > 0 ? (
          <BasicTableJob
            data={getAllCandidates}
            setLoading={setAiLoading}
            totalCandidates={totalCandidates}
            matchedCandidates={matchedCandidates}
          />
        ) : (
          apiResponse && (
            <div style={{ textAlign: 'center', marginTop: '10px' }}>
              {apiResponse}
            </div>
          )
        )}
      </Accordion>
    </div>
  );
};

AISection.propTypes = {
  getAllRequirement: PropTypes.array,
  getAllSkills: PropTypes.array,
  getAllQuestionA: PropTypes.array,
  id: PropTypes.number,
  getAllCandidates: PropTypes.array,
  setAiLoading: PropTypes.bool,
  getPrf: PropTypes.func,
  getAllAI: PropTypes.func,
  setKey: PropTypes.string,
  updateAIPrf: PropTypes.func,
  aiResponse: PropTypes.bool,
  availableCandidates: PropTypes.array,
  updateAIPrfSkill: PropTypes.func,
};

export default AISection;
