import React, { useContext, useEffect } from 'react';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from '@azure/msal-react';
import { Container } from 'react-bootstrap';
import { Navigate, useNavigate } from 'react-router-dom';
import Layout from '../../components/layout/layout';
import RmsTable from '../../components/rms/users';
import { AppContext } from '../../context/appContext';
import { GET_USER_ROLES } from '../../components/helper/constant';

const { SUPER_USER, DEPARTMENT_HEAD, ACCOUNT_MANAGER, TALENT, TALENT_TEAM } =
  GET_USER_ROLES;

const RMS = () => {
  const { instance } = useMsal();
  const navigate = useNavigate();
  const { profile, getRole } = useContext(AppContext);
  const isAuthenticated = useIsAuthenticated();
  useEffect(() => {
    if (instance && isAuthenticated && profile) {
      const userRole = getRole();
      if (
        ![
          SUPER_USER,
          DEPARTMENT_HEAD,
          TALENT,
          ACCOUNT_MANAGER,
          TALENT_TEAM,
        ].includes(userRole)
      ) {
        navigate('/');
      }
    }
  }, [instance, isAuthenticated, profile]);
  return (
    <>
      <UnauthenticatedTemplate>
        <Navigate to="/" />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <Layout>
          <main className={`flex-grow-1 d-flex dashboard`}>
            <Container fluid className="d-flex flex-column p-0">
              <div className="prfList">
                <RmsTable></RmsTable>
              </div>
            </Container>
          </main>
        </Layout>
      </AuthenticatedTemplate>
    </>
  );
};

export default RMS;
