/* eslint-disable react/prop-types */
import { pdf } from '@react-pdf/renderer';
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';
import { MyDocument } from '../../utils/constants';

const DownloadButton = ({ formData }) => {
  const handleDownload = async () => {
    try {
      const toastId = toast.loading('Downloading Resume...');
      const blob = await pdf(<MyDocument formData={formData} />).toBlob();
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${formData.firstName}_${formData.lastName}.pdf`;
      link.click();
      toast.update(toastId, {
        type: 'success',
        isLoading: false,
        autoClose: 5000,
      });
    } catch (error) {
      const toastId = toast.loading('Error uploading resume :(');
      toast.update(toastId, {
        render: `Error uploading resume: ${
          error.response?.data?.message || error.message
        }`,
        type: 'error',
        isLoading: false,
        autoClose: 5000,
      });
      console.error('Error generating PDF:', error);
    }
  };

  return (
    <Button
      id="button-download-resume"
      variant="success"
      onClick={handleDownload}
    >
      Download Resume
    </Button>
  );
};

export default DownloadButton;
