/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import AxiosInstance from '../../services/api';
import {
  GET_ALL_RESUME,
  CREATE_RESUME,
  UPDATE_RESUME,
} from '../../services/routes/prf';
import DownloadButton from './resumeTemplate';

const ManualButton = ({
  editID,
  setEditID,
  getAllResumes,
  downloadID,
  setDownloadID,
}) => {
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    jobTitle: '',
    location: '',
    phone: '',
    email: '',
    skills: [],
    workExperience: [],
    duration: '',
    overallSummary: '',
    // eslint-disable-next-line no-dupe-keys
    location: '',
    summary: '',
    certifications: [],
    qualifications: [],
    educationDuration: '',
    educationInstitution: '',
  });

  // States

  const [skillInput, setSkillInput] = useState('');
  const [editingSkillIndex, setEditingSkillIndex] = useState(null);
  const [certificationInput, setCertificationInput] = useState('');
  const [editingCertificationIndex, setEditingCertificationIndex] =
    useState(null);
  const [experienceInput, setExperienceInput] = useState('');
  const [editingExperienceIndex, setEditingExperienceIndex] = useState(null);
  const [durationInput, setDurationInput] = useState('');
  const [locationInput, setLocationInput] = useState('');
  const [summaryInput, setSummaryInput] = useState('');
  const [educationInput, setEducationInput] = useState('');
  const [editingEducationIndex, setEditingEducationIndex] = useState(null);
  const [educationDurationInput, setEducationDurationInput] = useState('');
  const [educationInstitutionInput, setEducationInstitutionInput] =
    useState('');
  const [resumeData, setResumeDate] = useState([]);
  const [skillsError, setSkillsError] = useState('');
  const [workExperienceError, setWorkExperienceError] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [emailError, setEmailError] = useState('');

  // Functions to handle the modal

  const handleReset = () => {
    setFormData({
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      skills: [],

      overallSummary: '',
      workExperience: [],
      certifications: [],
      qualifications: [],
      location: '',
      jobTitle: '',
      educationDuration: '',
      educationInstitution: '',
    });
  };

  const handleClose = () => {
    setShow(false);
    handleReset();
    setFirstNameError('');
    setLastNameError('');
    setPhoneError('');
    setSkillsError('');
    setWorkExperienceError('');
    setEmailError('');
  };

  const handleShow = () => setShow(true);

  // Functions for Skills

  const handleSkillUpdate = (event) => {
    event.preventDefault();
    if (skillInput.trim() === '') {
      return;
    }

    if (editingSkillIndex !== null) {
      const newSkills = [...formData.skills];
      newSkills[editingSkillIndex] = skillInput;
      setFormData({ ...formData, skills: newSkills });
      setEditingSkillIndex(null);
      setSkillInput('');
    }
  };

  const handleSkillAdd = () => {
    if (skillInput.trim() === '') {
      return;
    }

    setFormData({
      ...formData,
      skills: [...formData.skills, skillInput],
    });
    setSkillInput('');
  };

  const handleSkillChange = (e) => {
    setSkillInput(e.target.value);
  };

  // Functions for Certifications
  const handleCertificationUpdate = (event) => {
    event.preventDefault();
    if (certificationInput.trim() === '') {
      return;
    }

    if (editingCertificationIndex !== null) {
      const newCertifications = [...formData.certifications];
      newCertifications[editingCertificationIndex] = certificationInput;
      setFormData({ ...formData, certifications: newCertifications });
      setEditingCertificationIndex(null);
      setCertificationInput('');
    }
  };

  const handleCertificationChange = (e) => {
    setCertificationInput(e.target.value);
  };

  const handleCertificationAdd = () => {
    if (certificationInput.trim() === '') {
      return;
    }

    setFormData({
      ...formData,
      certifications: [...formData.certifications, certificationInput],
    });
    setCertificationInput('');
  };

  // Functions for Experience

  const handleExperienceUpdate = (event) => {
    event.preventDefault();

    if (editingExperienceIndex !== null) {
      const newExperiences = [...formData.workExperience];
      newExperiences[editingExperienceIndex] = experienceInput;
      setFormData({ ...formData, workExperience: newExperiences });
      setEditingExperienceIndex(null);
      setExperienceInput({
        experience: '',
        duration: '',
        location: '',
        summary: '',
      });
    }
  };

  const handleExperienceChange = (event, field) => {
    setExperienceInput({
      ...experienceInput,
      [field]: event.target.value,
    });
  };

  const handleExperienceAdd = () => {
    if (!experienceInput.experience) {
      return;
    }
    const newExperience = {
      experience: experienceInput.experience,
      duration: experienceInput.duration,
      location: experienceInput.location,
      summary: experienceInput.summary,
    };

    setFormData((prevState) => ({
      ...prevState,
      workExperience: [...prevState.workExperience, newExperience],
    }));

    setExperienceInput('');
    setDurationInput('');
    setLocationInput('');
    setSummaryInput('');
  };

  // Functions for Education

  const handleEducationUpdate = (event) => {
    event.preventDefault();

    if (editingEducationIndex !== null) {
      const newEducation = [...formData.qualifications];
      newEducation[editingEducationIndex] = educationInput;
      setFormData({ ...formData, qualifications: newEducation });
      setEditingEducationIndex(null);
      setEducationInput({
        education: '',
        duration: '',
        institution: '',
      });
    }
  };

  const handleEducationChange = (event, field) => {
    setEducationInput({
      ...educationInput,
      [field]: event.target.value,
    });
  };

  const handleEducationAdd = () => {
    if (!educationInput.education) {
      return;
    }
    const newEducation = {
      education: educationInput.education,
      duration: educationInput.duration,
      institution: educationInput.institution,
    };

    setFormData((prevState) => ({
      ...prevState,
      qualifications: [...prevState.qualifications, newEducation],
    }));

    setEducationInput('');
    setEducationDurationInput('');
    setEducationInstitutionInput('');
  };

  // Validations

  const validateSkills = () => {
    if (formData.skills.length === 0) {
      setSkillsError('At least one skill is required');
      return false;
    }
    setSkillsError('');
    return true;
  };

  const validateWorkExperience = () => {
    if (formData.workExperience.length === 0) {
      setWorkExperienceError('At least one experience is required');
      return false;
    }
    setWorkExperienceError('');
    return true;
  };

  const validateFirstName = () => {
    if (formData.firstName.trim() === '') {
      setFirstNameError('First name is required');
      return false;
    }
    setFirstNameError('');
    return true;
  };

  const validateLastName = () => {
    if (formData.lastName.trim() === '') {
      setLastNameError('Last name is required');
      return false;
    }
    setLastNameError('');
    return true;
  };

  // Functions for Form

  const getResumes = async () => {
    try {
      const result = await AxiosInstance.get(GET_ALL_RESUME);
      setResumeDate(result.data.resumes);
    } catch (e) {
      console.log(e);
    }
  };

  const resetID = () => {
    setEditID(null);
    setDownloadID(null);
  };

  function handleDelete(event, index, field) {
    event.preventDefault();
    const newArray = [...formData[field]];
    newArray.splice(index, 1);
    setFormData({ ...formData, [field]: newArray });
  }

  const addKeyToArrayElements = (array, key) =>
    array.map((element) => ({
      [key]: element,
    }));

  const handleChange = (e) => {
    if (e.target.name === 'skills') {
      const skillsArray = e.target.value.split(',').map((skill, index) => ({
        id: index + 1,
        skill: skill.trim(),
      }));

      setFormData({
        ...formData,
        [e.target.name]: skillsArray,
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const toastId = toast.loading('Creating Resume...');
    const isFirstNameValid = validateFirstName();
    const isLastNameValid = validateLastName();
    const areSkillsValid = validateSkills();
    const isWorkExperienceValid = validateWorkExperience();

    if (
      !isFirstNameValid ||
      !isLastNameValid ||
      !areSkillsValid ||
      !isWorkExperienceValid
    ) {
      toast.update(toastId, {
        render: 'Please fill in all required fields',
        type: 'error',
        isLoading: false,
        autoClose: 5000,
      });
      return;
    }

    const modifiedPayload = {
      ...formData,
      skills: addKeyToArrayElements(formData.skills, 'skill'),
      certifications: addKeyToArrayElements(
        formData.certifications,
        'certification',
      ),
      workExperience: formData.workExperience,
      qualifications: formData.qualifications,
    };
    try {
      await AxiosInstance.post(CREATE_RESUME, modifiedPayload);
      await getAllResumes();
      toast.update(toastId, {
        render: 'Resume Created successfully',
        type: 'success',
        isLoading: false,
        autoClose: 5000,
      });
      handleClose();
      handleReset();
      window.location.reload();
    } catch (error) {
      toast.update(toastId, {
        render: `Error Creating resume: ${
          error.response?.data?.message || error.message
        }`,
        type: 'error',
        isLoading: false,
        autoClose: 5000,
      });
    }
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    const toastId = toast.loading('Downloading Resume...');
    const isFirstNameValid = validateFirstName();
    const isLastNameValid = validateLastName();
    const areSkillsValid = validateSkills();
    const isWorkExperienceValid = validateWorkExperience();

    if (
      !isFirstNameValid ||
      !isLastNameValid ||
      !areSkillsValid ||
      !isWorkExperienceValid
    ) {
      toast.update(toastId, {
        render: 'Please fill in all required fields',
        type: 'error',
        isLoading: false,
        autoClose: 5000,
      });
      return;
    }

    const modifiedPayload = {
      ...formData,
      skills: addKeyToArrayElements(formData.skills, 'skill'),
      certifications: addKeyToArrayElements(
        formData.certifications,
        'certification',
      ),
      workExperience: formData.workExperience,
      qualifications: formData.qualifications,
    };
    try {
      const url = UPDATE_RESUME.replace(':id', formData.id);
      await AxiosInstance.patch(url, modifiedPayload);
      toast.update(toastId, {
        render: 'Resume Downloaded successfully',
        type: 'success',
        isLoading: false,
        autoClose: 5000,
      });
      getAllResumes();
      handleClose();
      handleReset();
    } catch (error) {
      toast.update(toastId, {
        render: `Error Downloading resume: ${
          error.response?.data?.message || error.message
        }`,
        type: 'error',
        isLoading: false,
        autoClose: 5000,
      });
    }
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    const toastId = toast.loading('Editing Resume...');
    const isFirstNameValid = validateFirstName();
    const isLastNameValid = validateLastName();
    const areSkillsValid = validateSkills();
    const isWorkExperienceValid = validateWorkExperience();

    if (
      !isFirstNameValid ||
      !isLastNameValid ||
      !areSkillsValid ||
      !isWorkExperienceValid
    ) {
      toast.update(toastId, {
        render: 'Please fill in all required fields',
        type: 'error',
        isLoading: false,
        autoClose: 5000,
      });
      return;
    }

    const modifiedPayload = {
      ...formData,
      skills: addKeyToArrayElements(formData.skills, 'skill'),
      certifications: addKeyToArrayElements(
        formData.certifications,
        'certification',
      ),
      workExperience: formData.workExperience,
      qualifications: formData.qualifications,
    };
    try {
      const url = UPDATE_RESUME.replace(':id', formData.id);
      await AxiosInstance.patch(url, modifiedPayload);
      toast.update(toastId, {
        render: 'Resume Edited successfully',
        type: 'success',
        isLoading: false,
        autoClose: 5000,
      });
      getAllResumes();
      handleClose();
      handleReset();
    } catch (error) {
      toast.update(toastId, {
        render: `Error Editing resume: ${
          error.response?.data?.message || error.message
        }`,
        type: 'error',
        isLoading: false,
        autoClose: 5000,
      });
    }
  };

  useEffect(() => {
    if (editID !== undefined) {
      let resume = {};
      if (editID) {
        handleShow();
        resume = resumeData.find((resumeItem) => resumeItem.id === editID);
        setFormData((prevState) => ({
          ...prevState,
          id: resume.id,
          firstName: resume.firstName,
          overallSummary: resume.overallSummary,
          lastName: resume.lastName,
          jobTitle: resume.jobTitle,
          location: resume.location,
          phone: resume.phone,
          email: resume.email,
          skills: resume.resumeSkills.map((skill) => skill.skill),
          certifications: resume.certifications.map(
            (certification) => certification.certification,
          ),
          workExperience: resume.resumeExperiences.map((experience) => ({
            experience: experience.experience,
            duration: experience.duration,
            location: experience.location,
            summary: experience.summary,
          })),
          qualifications: resume.qualifications.map((education) => ({
            education: education.education,
            institution: education.institution,
            duration: education.duration,
          })),
        }));
      }
      resetID();
    }
    getResumes();
  }, [editID]);

  return (
    <>
      <Button
        id="button-manual-details"
        variant="secondary"
        onClick={handleShow}
      >
        Enter Resume Details
      </Button>

      <Modal size="lg" show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Provide Details</Modal.Title>
        </Modal.Header>
        <Form onSubmit={formData.id ? handleEditSubmit : handleSubmit}>
          <Modal.Body>
            <div className="d-flex flex-row">
              <Form.Group className="mb-3 w-100">
                <Form.Label>
                  First Name <span style={{ color: 'red' }}>*</span>
                </Form.Label>
                <Form.Control
                  className={firstNameError ? 'border border-danger' : ''}
                  type="text"
                  name="firstName"
                  placeholder="Enter First Name"
                  value={formData.firstName}
                  onChange={handleChange}
                  id="first-name-input"
                />
                {firstNameError && (
                  <p className="text-danger m-2">{firstNameError}</p>
                )}
              </Form.Group>
              <Form.Group className="mb-3 w-100 mx-2">
                <Form.Label>
                  Last Name <span style={{ color: 'red' }}>*</span>
                </Form.Label>
                <Form.Control
                  className={lastNameError ? 'border border-danger' : ''}
                  type="text"
                  name="lastName"
                  placeholder="Enter Last Name"
                  value={formData.lastName}
                  onChange={handleChange}
                  id="last-name-input"
                />
                {lastNameError && (
                  <p className="text-danger m-2">{lastNameError}</p>
                )}
              </Form.Group>
            </div>
            <div className="d-flex flex-row">
              <Form.Group className="mb-3 w-100">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  className={emailError ? 'border border-danger' : ''}
                  type="text"
                  name="email"
                  placeholder="Enter Email"
                  value={formData.email}
                  onChange={handleChange}
                  id="email-input"
                />
                {emailError && <p className="text-danger m-2">{emailError}</p>}
              </Form.Group>
              <Form.Group className="mb-3 w-100 mx-2">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  className={phoneError ? 'border border-danger' : ''}
                  type="text"
                  name="phone"
                  placeholder="Enter Phone"
                  value={formData.phone}
                  onChange={handleChange}
                  id="phone-input"
                />
                {phoneError && <p className="text-danger m-2">{phoneError}</p>}
              </Form.Group>
            </div>
            <div className="d-flex flex-row">
              <Form.Group className="mb-3 w-100 ">
                <Form.Label>Job Title</Form.Label>
                <Form.Control
                  type="text"
                  name="jobTitle"
                  placeholder="Enter Job Title"
                  value={formData.jobTitle}
                  onChange={handleChange}
                  id="job-title-input"
                />
              </Form.Group>
              <Form.Group className="mb-3 w-100 mx-2">
                <Form.Label>Location</Form.Label>
                <Form.Control
                  type="text"
                  name="location"
                  placeholder="Enter Location"
                  value={formData.location}
                  onChange={handleChange}
                  id="location-input"
                />
              </Form.Group>
            </div>

            <Form.Group className="mb-2">
              <div className="col">
                <Form.Label>
                  Summary
                  {/* <span style={{ color: 'red' }}>*</span> */}
                </Form.Label>
                <p> {formData.overallSummary}</p>
              </div>
              <Form.Label>
                Skills <span style={{ color: 'red' }}>*</span>
              </Form.Label>
              <div className="row">
                <div className="col">
                  <Form.Control
                    className={skillsError ? 'border border-danger' : ''}
                    type="text"
                    placeholder="Enter Skills"
                    value={skillInput}
                    onChange={handleSkillChange}
                    id="skills-input"
                  />
                </div>
                <div className="col-auto">
                  <Button
                    id="resume-skill-add"
                    className="btn-primary"
                    onClick={
                      editingSkillIndex !== null
                        ? handleSkillUpdate
                        : handleSkillAdd
                    }
                  >
                    {editingSkillIndex !== null ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="white"
                          d="M441 58.9L453.1 71c9.4 9.4 9.4 24.6 0 33.9L424 134.1 377.9 88 407 58.9c9.4-9.4 24.6-9.4 33.9 0zM209.8 256.2L344 121.9 390.1 168 255.8 302.2c-2.9 2.9-6.5 5-10.4 6.1l-58.5 16.7 16.7-58.5c1.1-3.9 3.2-7.5 6.1-10.4zM373.1 25L175.8 222.2c-8.7 8.7-15 19.4-18.3 31.1l-28.6 100c-2.4 8.4-.1 17.4 6.1 23.6s15.2 8.5 23.6 6.1l100-28.6c11.8-3.4 22.5-9.7 31.1-18.3L487 138.9c28.1-28.1 28.1-73.7 0-101.8L474.9 25C446.8-3.1 401.2-3.1 373.1 25zM88 64C39.4 64 0 103.4 0 152V424c0 48.6 39.4 88 88 88H360c48.6 0 88-39.4 88-88V312c0-13.3-10.7-24-24-24s-24 10.7-24 24V424c0 22.1-17.9 40-40 40H88c-22.1 0-40-17.9-40-40V152c0-22.1 17.9-40 40-40H200c13.3 0 24-10.7 24-24s-10.7-24-24-24H88z"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 448 512"
                      >
                        <path
                          fill="white"
                          d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"
                        />
                      </svg>
                    )}
                  </Button>
                </div>
                {skillsError && (
                  <p className="text-danger m-2">{skillsError}</p>
                )}
                <div className="m-2">
                  {formData.skills.map((skills, index) => (
                    <div key={index}>
                      <div className="d-flex flex-row">
                        <div>
                          <svg
                            className="mx-2"
                            onClick={(e) => handleDelete(e, index, 'skills')}
                            xmlns="http://www.w3.org/2000/svg"
                            height="1em"
                            viewBox="0 0 512 512"
                          >
                            <path
                              fill="#d6716d"
                              d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM184 232H328c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24z"
                            />
                          </svg>
                        </div>
                        <div
                          onClick={() => {
                            setEditingSkillIndex(index);
                            setSkillInput(skills);
                          }}
                        >
                          {skills}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Experiences <span style={{ color: 'red' }}>*</span>
              </Form.Label>
              <div className="row">
                <div className="col">
                  <Form.Control
                    className={
                      workExperienceError ? 'border border-danger' : ''
                    }
                    type="text"
                    placeholder="Enter Experiences"
                    value={experienceInput.experience}
                    onChange={(event) =>
                      handleExperienceChange(event, 'experience')
                    }
                    id="experience-input"
                  />
                  <div className="d-flex flex-row">
                    <Form.Control
                      className="w-100 mt-2"
                      type="text"
                      placeholder="Enter Duration"
                      value={experienceInput.duration}
                      onChange={(event) =>
                        handleExperienceChange(event, 'duration')
                      }
                      id="duration-input"
                    />
                    <Form.Control
                      className="mx-2 w-100 mt-2"
                      type="text"
                      placeholder="Enter Location"
                      value={experienceInput.location}
                      onChange={(event) =>
                        handleExperienceChange(event, 'location')
                      }
                      id="location-input"
                    />
                  </div>
                  <Form.Control
                    className="w-100 mt-2"
                    type="text"
                    placeholder="Enter Summary"
                    value={experienceInput.setSummaryInput}
                    onChange={(event) =>
                      handleExperienceChange(event, 'summary')
                    }
                    id="summary-input"
                  />
                </div>
                <div className="col-auto">
                  <Button
                    id="resume-experience-add"
                    className="btn-primary"
                    onClick={
                      editingExperienceIndex !== null
                        ? handleExperienceUpdate
                        : handleExperienceAdd
                    }
                  >
                    {editingExperienceIndex !== null ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="white"
                          d="M441 58.9L453.1 71c9.4 9.4 9.4 24.6 0 33.9L424 134.1 377.9 88 407 58.9c9.4-9.4 24.6-9.4 33.9 0zM209.8 256.2L344 121.9 390.1 168 255.8 302.2c-2.9 2.9-6.5 5-10.4 6.1l-58.5 16.7 16.7-58.5c1.1-3.9 3.2-7.5 6.1-10.4zM373.1 25L175.8 222.2c-8.7 8.7-15 19.4-18.3 31.1l-28.6 100c-2.4 8.4-.1 17.4 6.1 23.6s15.2 8.5 23.6 6.1l100-28.6c11.8-3.4 22.5-9.7 31.1-18.3L487 138.9c28.1-28.1 28.1-73.7 0-101.8L474.9 25C446.8-3.1 401.2-3.1 373.1 25zM88 64C39.4 64 0 103.4 0 152V424c0 48.6 39.4 88 88 88H360c48.6 0 88-39.4 88-88V312c0-13.3-10.7-24-24-24s-24 10.7-24 24V424c0 22.1-17.9 40-40 40H88c-22.1 0-40-17.9-40-40V152c0-22.1 17.9-40 40-40H200c13.3 0 24-10.7 24-24s-10.7-24-24-24H88z"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 448 512"
                      >
                        <path
                          fill="white"
                          d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"
                        />
                      </svg>
                    )}
                  </Button>
                </div>
                {workExperienceError && (
                  <p className="text-danger m-2">{workExperienceError}</p>
                )}
                <div className="m-2">
                  {formData.workExperience.map((item, index) => (
                    <div key={index}>
                      <div className="d-flex flex-row">
                        <div>
                          <svg
                            className="mx-2"
                            onClick={(e) =>
                              handleDelete(e, index, 'workExperience')
                            }
                            xmlns="http://www.w3.org/2000/svg"
                            height="1em"
                            viewBox="0 0 512 512"
                          >
                            <path
                              fill="#d6716d"
                              d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM184 232H328c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24z"
                            />
                          </svg>
                        </div>
                        <div
                          onClick={() => {
                            setEditingExperienceIndex(index);
                            setExperienceInput(item);
                          }}
                        >
                          <p>
                            <strong>{item.experience}</strong> - {item.duration}{' '}
                            - {item.location} <br /> {item.summary}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Form.Group>
            <Form.Group className="mt-3">
              <Form.Label>Education</Form.Label>
              <div className="row">
                <div className="col">
                  <Form.Control
                    type="text"
                    placeholder="Enter Education"
                    value={educationInput.education}
                    onChange={(event) =>
                      handleEducationChange(event, 'education')
                    }
                    id="education-input"
                  />
                  <div className="d-flex flex-row">
                    <Form.Control
                      className="w-100 mt-2"
                      type="text"
                      placeholder="Enter Duration"
                      value={educationInput.duration}
                      onChange={(event) =>
                        handleEducationChange(event, 'duration')
                      }
                    />
                    <Form.Control
                      className="mx-2 w-100 mt-2"
                      type="text"
                      placeholder="Enter Institution"
                      value={educationInput.institution}
                      onChange={(event) =>
                        handleEducationChange(event, 'institution')
                      }
                      id="institution-input"
                    />
                  </div>
                </div>
                <div className="col-auto">
                  <Button
                    id="resume-education-add"
                    className="btn-primary"
                    onClick={
                      editingEducationIndex !== null
                        ? handleEducationUpdate
                        : handleEducationAdd
                    }
                  >
                    {editingEducationIndex !== null ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="white"
                          d="M441 58.9L453.1 71c9.4 9.4 9.4 24.6 0 33.9L424 134.1 377.9 88 407 58.9c9.4-9.4 24.6-9.4 33.9 0zM209.8 256.2L344 121.9 390.1 168 255.8 302.2c-2.9 2.9-6.5 5-10.4 6.1l-58.5 16.7 16.7-58.5c1.1-3.9 3.2-7.5 6.1-10.4zM373.1 25L175.8 222.2c-8.7 8.7-15 19.4-18.3 31.1l-28.6 100c-2.4 8.4-.1 17.4 6.1 23.6s15.2 8.5 23.6 6.1l100-28.6c11.8-3.4 22.5-9.7 31.1-18.3L487 138.9c28.1-28.1 28.1-73.7 0-101.8L474.9 25C446.8-3.1 401.2-3.1 373.1 25zM88 64C39.4 64 0 103.4 0 152V424c0 48.6 39.4 88 88 88H360c48.6 0 88-39.4 88-88V312c0-13.3-10.7-24-24-24s-24 10.7-24 24V424c0 22.1-17.9 40-40 40H88c-22.1 0-40-17.9-40-40V152c0-22.1 17.9-40 40-40H200c13.3 0 24-10.7 24-24s-10.7-24-24-24H88z"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 448 512"
                      >
                        <path
                          fill="white"
                          d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"
                        />
                      </svg>
                    )}
                  </Button>
                </div>
                <div className="m-2">
                  {formData.qualifications.map((item, index) => (
                    <div key={index}>
                      <div className="d-flex flex-row">
                        <div>
                          <svg
                            className="mx-2"
                            onClick={(e) =>
                              handleDelete(e, index, 'qualifications')
                            }
                            xmlns="http://www.w3.org/2000/svg"
                            height="1em"
                            viewBox="0 0 512 512"
                          >
                            <path
                              fill="#d6716d"
                              d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM184 232H328c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24z"
                            />
                          </svg>
                        </div>
                        <div
                          onClick={() => {
                            setEditingEducationIndex(index);
                            setEducationInput(item);
                          }}
                        >
                          <p>
                            <strong>{item.education}</strong> - {item.duration}{' '}
                            - {item.institution}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Form.Group>
            <Form.Group className="mb-2 mt-2">
              <Form.Label>Certifications</Form.Label>
              <div className="row">
                <div className="col">
                  <Form.Control
                    type="text"
                    placeholder="Enter Certfications"
                    value={certificationInput}
                    onChange={handleCertificationChange}
                    id="certification-input"
                  />
                </div>
                <div className="col-auto">
                  <Button
                    id="resume-certification-add"
                    className="btn-primary"
                    onClick={
                      editingCertificationIndex !== null
                        ? handleCertificationUpdate
                        : handleCertificationAdd
                    }
                  >
                    {editingCertificationIndex !== null ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="white"
                          d="M441 58.9L453.1 71c9.4 9.4 9.4 24.6 0 33.9L424 134.1 377.9 88 407 58.9c9.4-9.4 24.6-9.4 33.9 0zM209.8 256.2L344 121.9 390.1 168 255.8 302.2c-2.9 2.9-6.5 5-10.4 6.1l-58.5 16.7 16.7-58.5c1.1-3.9 3.2-7.5 6.1-10.4zM373.1 25L175.8 222.2c-8.7 8.7-15 19.4-18.3 31.1l-28.6 100c-2.4 8.4-.1 17.4 6.1 23.6s15.2 8.5 23.6 6.1l100-28.6c11.8-3.4 22.5-9.7 31.1-18.3L487 138.9c28.1-28.1 28.1-73.7 0-101.8L474.9 25C446.8-3.1 401.2-3.1 373.1 25zM88 64C39.4 64 0 103.4 0 152V424c0 48.6 39.4 88 88 88H360c48.6 0 88-39.4 88-88V312c0-13.3-10.7-24-24-24s-24 10.7-24 24V424c0 22.1-17.9 40-40 40H88c-22.1 0-40-17.9-40-40V152c0-22.1 17.9-40 40-40H200c13.3 0 24-10.7 24-24s-10.7-24-24-24H88z"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 448 512"
                      >
                        <path
                          fill="white"
                          d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"
                        />
                      </svg>
                    )}
                  </Button>
                </div>
                <div className="m-2">
                  {formData.certifications &&
                    formData.certifications.map((certifications, index) => (
                      <div key={index}>
                        <div className="d-flex flex-row">
                          <div>
                            <svg
                              className="mx-2"
                              onClick={(e) =>
                                handleDelete(e, index, 'certifications')
                              }
                              xmlns="http://www.w3.org/2000/svg"
                              height="1em"
                              viewBox="0 0 512 512"
                            >
                              <path
                                fill="#d6716d"
                                d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM184 232H328c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24z"
                              />
                            </svg>
                          </div>
                          <div
                            onClick={() => {
                              setEditingCertificationIndex(index);
                              setCertificationInput(certifications);
                            }}
                          >
                            {certifications}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex justify-content-end">
              <Button
                className="mx-1"
                variant="secondary"
                onClick={handleClose}
              >
                Close
              </Button>
              {formData.id ? (
                <Button
                  onClick={handleEdit}
                  id="button-resume-form-submit"
                  variant="primary"
                  type="submit"
                  className="mx-1"
                >
                  Save
                </Button>
              ) : null}
              {formData.id ? (
                <DownloadButton formData={formData} />
              ) : (
                <Button
                  className="mx-1"
                  id="button-resume-form-submit"
                  type="submit"
                >
                  Submit
                </Button>
              )}
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default ManualButton;
