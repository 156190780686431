import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import PropTypes from 'prop-types';

const FilepreView = ({ selectedImage }) => {
  if (!selectedImage) return null;
  const fileExtension = selectedImage.name.split('.').pop().toLowerCase();

  if (fileExtension === 'pdf') {
    return (
      <iframe
        disabled
        src={URL.createObjectURL(selectedImage)}
        type="application/pdf"
        style={{
          width: '100%',
          maxWidth: '300px',
          overflow: 'hidden',
          pointerEvents: 'none',
        }}
      />
    );
  }
  if (fileExtension === 'doc' || fileExtension === 'docx') {
    return (
      <div>
        <p>Selected Document: {selectedImage.name}</p>
      </div>
    );
  }
  if (
    fileExtension === 'jpg' ||
    fileExtension === 'jpeg' ||
    fileExtension === 'png'
  ) {
    return (
      <img
        disabled
        src={URL.createObjectURL(selectedImage)}
        alt="Selected"
        style={{ width: '100%', maxWidth: '300px', height: '300px' }}
      />
    );
  }

  return <p>File type not supported.</p>;
};

FilepreView.propTypes = {
  selectedImage: PropTypes.any,
};

export default FilepreView;
