import { useContext, useState } from 'react';
import { Nav } from 'react-bootstrap';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { FiLogOut } from 'react-icons/fi';
import Button from 'react-bootstrap/Button';
import { GET_USER_ROLES } from '../helper/constant';
import { AppContext } from '../../context/appContext';
import mobileLive from '../../assets/images/mobileLive.svg';
import mobileLiveFull from '../../assets/images/mlLogo.svg';
import Menu from '../../assets/images/menu.svg';

const {
  ADMIN,
  EMPLOYEE,
  ACCOUNT_MANAGER,
  TALENT,
  DEPARTMENT_HEAD,
  TALENT_TEAM,
  PRESIDENT,
} = GET_USER_ROLES;
const Header = () => {
  const { profile, getRole, logOut } = useContext(AppContext);
  const { id } = useParams();
  const [isActive, setActive] = useState(false);
  const [imageHandler, setImageHandler] = useState(false);
  const isAdmin = getRole() === ADMIN;
  const isDepartmentHead = getRole() === DEPARTMENT_HEAD;
  const isTalent = getRole() === TALENT;
  const isTalentTeam = getRole() === TALENT_TEAM;
  const isEmploye = getRole() === EMPLOYEE;
  const isAccountManager = getRole() === ACCOUNT_MANAGER;
  const isPresident = getRole() === PRESIDENT;
  // const id = profile?.user?.id;
  const navigate = useNavigate();
  const handleToggle = () => {
    setActive(!isActive);
    setTimeout(() => setImageHandler((prev) => !prev), 300);
  };
  const inActive = (path) => {
    // Ensure that location and location.pathname are defined
    if (
      typeof window !== 'undefined' &&
      window.location &&
      window.location.pathname
    ) {
      return window.location.pathname === path;
    }
    return false;
  };

  const logout = () => {
    logOut();
    // Reload the window after logout
    navigate('/');
  };
  return (
    <header id="header" className={`header ${isActive ? 'fullView' : ''}`}>
      <div className="topbar bg-white d-flex justify-content-between align-items-center position-fixed top-0 start-0 w-100">
        <button
          id="icon-button"
          className={
            'btn-menu d-flex justify-content-center align-items-center p-0'
          }
          onClick={handleToggle}
        >
          <img className="d-block" src={Menu} alt="user image" />
        </button>

        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center">
            <strong className="text-capitalize">
              Role: {profile?.user?.roles[0]?.displayName}
            </strong>
            <div className="dropdown ms-3 userDropdown">
              <button
                id="headerDropdown-button"
                className="dropdown-toggle border-0 bg-transparent d-flex align-items-center p-0"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <figure className="mb-0">
                  <img
                    className="rounded-circle"
                    src={profile?.user?.picture}
                    width={'32'}
                    height={'32'}
                    alt="user image"
                  />
                </figure>
                <MdKeyboardArrowDown className="ms-2" />
              </button>
              <div className="dropdown-menu">
                <Button
                  id="myProfile-button"
                  style={{ color: 'black', background: 'white' }}
                  className="border-0 d-flex align-items-center"
                  onClick={() =>
                    id ? navigate(`/rms/myrms`) : navigate(`/rmsForm`)
                  }
                >
                  <span>My Profile</span>
                </Button>
                <Button
                  id="logout-button"
                  style={{ color: 'red', background: 'white' }}
                  className="border-0 d-flex align-items-center"
                  onClick={logout}
                >
                  <FiLogOut className="me-2" />
                  <span>Log out</span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <aside
        className={`sidebarMenu text-white d-flex flex-column overflow-hidden`}
      >
        <span className="d-flex justify-content-center">
          <a href="/" className="position-relative">
            {imageHandler ? (
              <img
                className="logoFull"
                src={mobileLiveFull}
                height={'30'}
                alt="mobileLive logo"
              />
            ) : (
              <img
                className="logoIcon"
                src={mobileLive}
                width={'24'}
                height={'24'}
                alt="mobileLive logo"
              />
            )}
          </a>
        </span>
        {!isEmploye && (
          <span>
            {!(isAdmin || isPresident) ? (
              <nav>
                <Nav variant="pills" className="flex-column" as={'ul'}>
                  {!isTalent && (
                    <>
                      <Nav.Item as={'li'} id="dashboard-nav">
                        <Link
                          className={`d-flex align-items-center text-center fs-sm nav-link ${
                            inActive('/') ? 'active' : ''
                          }`}
                          to="/"
                        >
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="16"
                              width="16"
                              viewBox="0 0 512 512"
                            >
                              <path
                                fill="currentColor"
                                d="M64 64c0-17.7-14.3-32-32-32S0 46.3 0 64V400c0 44.2 35.8 80 80 80H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H80c-8.8 0-16-7.2-16-16V64zm406.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L320 210.7l-57.4-57.4c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L240 221.3l57.4 57.4c12.5 12.5 32.8 12.5 45.3 0l128-128z"
                              />
                            </svg>
                          </span>
                          <span>Dashboard</span>
                        </Link>
                      </Nav.Item>
                    </>
                  )}
                  {!(
                    isAccountManager ||
                    isDepartmentHead ||
                    isTalent ||
                    isTalentTeam
                  ) && (
                    <>
                      <Nav.Item as={'li'} id="analytics-nav">
                        {/* <Nav.Link */}
                        <Link
                          className={`d-flex align-items-center text-center fs-sm nav-link ${
                            inActive('/analytics') ? 'active' : ''
                          }`}
                          to="/analytics"
                        >
                          <span>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clipPath="url(#clip0_3826_68)">
                                <path
                                  d="M1.25 17H4.75C4.8163 17 4.87989 16.9737 4.92678 16.9268C4.97366 16.8799 5 16.8163 5 16.75V14.75C5 14.4848 4.89464 14.2304 4.70711 14.0429C4.51957 13.8554 4.26522 13.75 4 13.75H2C1.73478 13.75 1.48043 13.8554 1.29289 14.0429C1.10536 14.2304 1 14.4848 1 14.75V16.75C1 16.8163 1.02634 16.8799 1.07322 16.9268C1.12011 16.9737 1.1837 17 1.25 17Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M8 8.75C7.73478 8.75 7.48043 8.85536 7.29289 9.04289C7.10536 9.23043 7 9.48478 7 9.75V16.75C7 16.8163 7.02634 16.8799 7.07322 16.9268C7.12011 16.9737 7.1837 17 7.25 17H10.75C10.8163 17 10.8799 16.9737 10.9268 16.9268C10.9737 16.8799 11 16.8163 11 16.75V9.75C11 9.48478 10.8946 9.23043 10.7071 9.04289C10.5196 8.85536 10.2652 8.75 10 8.75H8Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M14 10.75C13.7348 10.75 13.4804 10.8554 13.2929 11.0429C13.1054 11.2304 13 11.4848 13 11.75V16.75C13 16.8163 13.0263 16.8799 13.0732 16.9268C13.1201 16.9737 13.1837 17 13.25 17H16.75C16.8163 17 16.8799 16.9737 16.9268 16.9268C16.9737 16.8799 17 16.8163 17 16.75V11.75C17 11.4848 16.8946 11.2304 16.7071 11.0429C16.5196 10.8554 16.2652 10.75 16 10.75H14Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M19.25 17H22.75C22.8163 17 22.8799 16.9737 22.9268 16.9268C22.9737 16.8799 23 16.8163 23 16.75V4.75C23 4.48478 22.8946 4.23043 22.7071 4.04289C22.5196 3.85536 22.2652 3.75 22 3.75H20C19.7348 3.75 19.4804 3.85536 19.2929 4.04289C19.1054 4.23043 19 4.48478 19 4.75V16.75C19 16.8163 19.0263 16.8799 19.0732 16.9268C19.1201 16.9737 19.1837 17 19.25 17Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M1 20.25H23C23.2652 20.25 23.5196 20.1446 23.7071 19.9571C23.8946 19.7696 24 19.5152 24 19.25C24.0004 19.0626 23.9481 18.8788 23.8491 18.7197C23.75 18.5606 23.6083 18.4325 23.44 18.35C23.3023 18.2852 23.1522 18.2511 23 18.25H1C0.847822 18.2511 0.697689 18.2852 0.560002 18.35C0.391699 18.4325 0.249958 18.5606 0.150943 18.7197C0.051929 18.8788 -0.000373071 19.0626 2.00323e-06 19.25C2.00323e-06 19.5152 0.105359 19.7696 0.292895 19.9571C0.480432 20.1446 0.734786 20.25 1 20.25Z"
                                  fill="currentColor"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_3826_68">
                                  <rect width="24" height="24" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </span>
                          <span>Analytics</span>
                        </Link>
                        {/* </Nav.Link> */}
                      </Nav.Item>
                    </>
                  )}
                  {isTalent && (
                    <>
                      <Nav.Item as={'li'} id="analytics-nav">
                        {/* <Nav.Link */}
                        <Link
                          className={`d-flex align-items-center text-center fs-sm nav-link ${
                            inActive('/') ? 'active' : ''
                          }`}
                          to="/analytics"
                        >
                          <span>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clipPath="url(#clip0_3826_68)">
                                <path
                                  d="M1.25 17H4.75C4.8163 17 4.87989 16.9737 4.92678 16.9268C4.97366 16.8799 5 16.8163 5 16.75V14.75C5 14.4848 4.89464 14.2304 4.70711 14.0429C4.51957 13.8554 4.26522 13.75 4 13.75H2C1.73478 13.75 1.48043 13.8554 1.29289 14.0429C1.10536 14.2304 1 14.4848 1 14.75V16.75C1 16.8163 1.02634 16.8799 1.07322 16.9268C1.12011 16.9737 1.1837 17 1.25 17Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M8 8.75C7.73478 8.75 7.48043 8.85536 7.29289 9.04289C7.10536 9.23043 7 9.48478 7 9.75V16.75C7 16.8163 7.02634 16.8799 7.07322 16.9268C7.12011 16.9737 7.1837 17 7.25 17H10.75C10.8163 17 10.8799 16.9737 10.9268 16.9268C10.9737 16.8799 11 16.8163 11 16.75V9.75C11 9.48478 10.8946 9.23043 10.7071 9.04289C10.5196 8.85536 10.2652 8.75 10 8.75H8Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M14 10.75C13.7348 10.75 13.4804 10.8554 13.2929 11.0429C13.1054 11.2304 13 11.4848 13 11.75V16.75C13 16.8163 13.0263 16.8799 13.0732 16.9268C13.1201 16.9737 13.1837 17 13.25 17H16.75C16.8163 17 16.8799 16.9737 16.9268 16.9268C16.9737 16.8799 17 16.8163 17 16.75V11.75C17 11.4848 16.8946 11.2304 16.7071 11.0429C16.5196 10.8554 16.2652 10.75 16 10.75H14Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M19.25 17H22.75C22.8163 17 22.8799 16.9737 22.9268 16.9268C22.9737 16.8799 23 16.8163 23 16.75V4.75C23 4.48478 22.8946 4.23043 22.7071 4.04289C22.5196 3.85536 22.2652 3.75 22 3.75H20C19.7348 3.75 19.4804 3.85536 19.2929 4.04289C19.1054 4.23043 19 4.48478 19 4.75V16.75C19 16.8163 19.0263 16.8799 19.0732 16.9268C19.1201 16.9737 19.1837 17 19.25 17Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M1 20.25H23C23.2652 20.25 23.5196 20.1446 23.7071 19.9571C23.8946 19.7696 24 19.5152 24 19.25C24.0004 19.0626 23.9481 18.8788 23.8491 18.7197C23.75 18.5606 23.6083 18.4325 23.44 18.35C23.3023 18.2852 23.1522 18.2511 23 18.25H1C0.847822 18.2511 0.697689 18.2852 0.560002 18.35C0.391699 18.4325 0.249958 18.5606 0.150943 18.7197C0.051929 18.8788 -0.000373071 19.0626 2.00323e-06 19.25C2.00323e-06 19.5152 0.105359 19.7696 0.292895 19.9571C0.480432 20.1446 0.734786 20.25 1 20.25Z"
                                  fill="currentColor"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_3826_68">
                                  <rect width="24" height="24" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </span>
                          <span>Analytics</span>
                        </Link>
                        {/* </Nav.Link> */}
                      </Nav.Item>
                    </>
                  )}
                  <Nav.Item as={'li'} id="prf-nav">
                    {/* <Nav.Link */}
                    <Link
                      className={`d-flex align-items-center text-center fs-sm nav-link ${
                        inActive('/prf') ? 'active' : ''
                      }`}
                      to="/prf"
                    >
                      <span>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M20.702 0.749998H5.53104C5.3237 0.749998 5.15604 0.917966 5.15604 1.125V2.98124H3.2998C3.09246 2.98124 2.9248 3.14921 2.9248 3.35624V18.988C2.9248 18.9985 2.93004 19.0083 2.93082 19.0188C2.93309 19.0466 2.93871 19.0736 2.94691 19.1002C2.95215 19.1163 2.95629 19.1321 2.96379 19.1475C2.9784 19.1779 2.99754 19.206 3.02043 19.2323C3.02605 19.2387 3.02832 19.2473 3.03434 19.2533L6.92114 23.1401C6.95715 23.1761 6.99989 23.2034 7.04598 23.2222C7.06957 23.232 7.09512 23.2323 7.11988 23.2368C7.14199 23.2409 7.16301 23.2499 7.18629 23.2499H18.4705C18.6778 23.2499 18.8455 23.082 18.8455 22.8749V21.0187H20.7017C20.9091 21.0187 21.0767 20.8507 21.0767 20.6437L21.0767 1.12488C21.0767 0.917838 20.909 0.749878 20.7017 0.749878L20.702 0.749998ZM4.20624 19.3624H6.81324V21.9698L4.20624 19.3628V19.3624ZM18.0958 22.5H7.56324V18.9874C7.56324 18.7804 7.39559 18.6124 7.18824 18.6124H3.67484V3.7314H18.0958V22.5ZM20.327 20.2688H18.8458V3.3564C18.8458 3.14936 18.6781 2.9814 18.4708 2.9814H5.9062V1.50016H20.3272L20.327 20.2688Z"
                            fill="currentColor"
                          />
                          <path
                            d="M5.81445 9.5448H15.9537C16.161 9.5448 16.3287 9.37683 16.3287 9.1698C16.3287 8.96276 16.161 8.7948 15.9537 8.7948H5.81445C5.60711 8.7948 5.43945 8.96277 5.43945 9.1698C5.43945 9.37684 5.60711 9.5448 5.81445 9.5448Z"
                            fill="currentColor"
                          />
                          <path
                            d="M15.9544 10.5626H14.0659C13.8586 10.5626 13.6909 10.7306 13.6909 10.9376C13.6909 11.1446 13.8586 11.3126 14.0659 11.3126H15.9544C16.1618 11.3126 16.3294 11.1446 16.3294 10.9376C16.3294 10.7306 16.1618 10.5626 15.9544 10.5626Z"
                            fill="currentColor"
                          />
                          <path
                            d="M5.81445 11.3126H12.4531C12.6604 11.3126 12.8281 11.1446 12.8281 10.9376C12.8281 10.7306 12.6604 10.5626 12.4531 10.5626H5.81445C5.60711 10.5626 5.43945 10.7306 5.43945 10.9376C5.43945 11.1446 5.60711 11.3126 5.81445 11.3126Z"
                            fill="currentColor"
                          />
                          <path
                            d="M15.9546 12.333H8.88477C8.67743 12.333 8.50977 12.501 8.50977 12.708C8.50977 12.915 8.67742 13.083 8.88477 13.083H15.9546C16.1619 13.083 16.3296 12.915 16.3296 12.708C16.3296 12.501 16.1619 12.333 15.9546 12.333Z"
                            fill="currentColor"
                          />
                          <path
                            d="M5.81445 13.083H7.36321C7.57055 13.083 7.73821 12.915 7.73821 12.708C7.73821 12.501 7.57056 12.333 7.36321 12.333H5.81445C5.60711 12.333 5.43945 12.501 5.43945 12.708C5.43945 12.915 5.60711 13.083 5.81445 13.083Z"
                            fill="currentColor"
                          />
                          <path
                            d="M15.9543 14.103H13.9443C13.737 14.103 13.5693 14.271 13.5693 14.478C13.5693 14.685 13.737 14.853 13.9443 14.853H15.9543C16.1617 14.853 16.3293 14.685 16.3293 14.478C16.3293 14.271 16.1617 14.103 15.9543 14.103Z"
                            fill="currentColor"
                          />
                          <path
                            d="M12.6653 14.478C12.6653 14.271 12.4976 14.103 12.2903 14.103H5.81445C5.60711 14.103 5.43945 14.271 5.43945 14.478C5.43945 14.685 5.60711 14.853 5.81445 14.853H12.2907C12.498 14.853 12.6657 14.685 12.6657 14.478H12.6653Z"
                            fill="currentColor"
                          />
                          <path
                            d="M15.9537 16.6508H12.6641C12.4567 16.6508 12.2891 16.8188 12.2891 17.0258C12.2891 17.2328 12.4567 17.4008 12.6641 17.4008H15.9537C16.161 17.4008 16.3287 17.2328 16.3287 17.0258C16.3287 16.8188 16.161 16.6508 15.9537 16.6508Z"
                            fill="currentColor"
                          />
                          <path
                            d="M8.66064 7.379H13.0556C13.263 7.379 13.4306 7.21103 13.4306 7.004C13.4306 6.79696 13.263 6.629 13.0556 6.629H8.66064C8.4533 6.629 8.28564 6.79697 8.28564 7.004C8.28564 7.21104 8.4533 7.379 8.66064 7.379Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                      <span>PRFs</span>
                    </Link>
                    {/* </Nav.Link> */}
                  </Nav.Item>
                  <Nav.Item as={'li'} id="resume-nav">
                    {/* <Nav.Link */}
                    <Link
                      className={`d-flex align-items-center text-center fs-sm nav-link ${
                        inActive('/resume') ? 'active' : ''
                      }`}
                      to="/resume"
                    >
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="20"
                          width="20"
                          viewBox="0 0 384 512"
                        >
                          <path
                            fill="currentColor"
                            d="M64 464c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16H224v80c0 17.7 14.3 32 32 32h80V448c0 8.8-7.2 16-16 16H64zM64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V154.5c0-17-6.7-33.3-18.7-45.3L274.7 18.7C262.7 6.7 246.5 0 229.5 0H64zm56 256c-13.3 0-24 10.7-24 24s10.7 24 24 24H264c13.3 0 24-10.7 24-24s-10.7-24-24-24H120zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24H264c13.3 0 24-10.7 24-24s-10.7-24-24-24H120z"
                          />
                        </svg>
                      </span>
                      <span>RMS</span>
                    </Link>
                    {/* </Nav.Link> */}
                  </Nav.Item>
                  {!(isAccountManager || isTalentTeam) && (
                    <>
                      <Nav.Item as={'li'} id="profiles-nav">
                        {/* <Nav.Link */}
                        <Link
                          className={`d-flex align-items-center text-center fs-sm nav-link ${
                            inActive('/rms') ? 'active' : ''
                          }`}
                          to="/rms"
                        >
                          <span>
                            <svg
                              width="18"
                              height="22"
                              viewBox="0 0 18 22"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9.00017 10.243C11.6852 10.243 13.8678 8.06061 13.8678 5.38301C13.8678 2.70541 11.6854 0.515411 9.00017 0.515411C6.31497 0.515411 4.13257 2.69781 4.13257 5.38301C4.13257 8.06821 6.31497 10.243 9.00017 10.243Z"
                                fill="currentColor"
                              />
                              <path
                                d="M9.00014 21.4846C14.5501 21.4846 17.6101 20.0896 17.6101 17.5546C17.6101 14.847 13.8451 11.697 9.00014 11.697C4.15514 11.697 0.390137 14.847 0.390137 17.5546C0.390137 20.0896 3.45014 21.4846 9.00014 21.4846Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          <span>Profiles</span>
                        </Link>
                        {/* </Nav.Link> */}
                      </Nav.Item>
                    </>
                  )}
                  {!(
                    isAccountManager ||
                    isDepartmentHead ||
                    isTalent ||
                    isAdmin ||
                    isTalentTeam
                  ) && (
                    <>
                      <Nav.Item as={'li'} id="admin-nav">
                        {/* <Nav.Link */}
                        <Link
                          className={`d-flex align-items-center text-center fs-sm nav-link ${
                            inActive('/admin') ? 'active' : ''
                          }`}
                          to="/admin"
                        >
                          <span>
                            <svg
                              width="20"
                              height="14"
                              viewBox="0 0 20 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7 8.75C4.66 8.75 0 9.92 0 12.25V14H14V12.25C14 9.92 9.34 8.75 7 8.75ZM2.34 12C3.18 11.42 5.21 10.75 7 10.75C8.79 10.75 10.82 11.42 11.66 12H2.34ZM7 7C8.93 7 10.5 5.43 10.5 3.5C10.5 1.57 8.93 0 7 0C5.07 0 3.5 1.57 3.5 3.5C3.5 5.43 5.07 7 7 7ZM7 2C7.83 2 8.5 2.67 8.5 3.5C8.5 4.33 7.83 5 7 5C6.17 5 5.5 4.33 5.5 3.5C5.5 2.67 6.17 2 7 2ZM14.04 8.81C15.2 9.65 16 10.77 16 12.25V14H20V12.25C20 10.23 16.5 9.08 14.04 8.81ZM13 7C14.93 7 16.5 5.43 16.5 3.5C16.5 1.57 14.93 0 13 0C12.46 0 11.96 0.13 11.5 0.35C12.13 1.24 12.5 2.33 12.5 3.5C12.5 4.67 12.13 5.76 11.5 6.65C11.96 6.87 12.46 7 13 7Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          <span>Admin</span>
                        </Link>
                        {/* </Nav.Link> */}
                      </Nav.Item>
                    </>
                  )}
                </Nav>
              </nav>
            ) : isPresident ? (
              <nav>
                <Nav className="flex-column" as={'ul'} activeKey="/">
                  <Nav.Item as={'li'} id="prf-nav">
                    {/* <Nav.Link */}
                    <Link
                      className={`d-flex align-items-center text-center fs-sm nav-link ${
                        inActive('/') ? 'active' : ''
                      }`}
                      to="/"
                      // eventKey="link-4"
                    >
                      <span>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M20.702 0.749998H5.53104C5.3237 0.749998 5.15604 0.917966 5.15604 1.125V2.98124H3.2998C3.09246 2.98124 2.9248 3.14921 2.9248 3.35624V18.988C2.9248 18.9985 2.93004 19.0083 2.93082 19.0188C2.93309 19.0466 2.93871 19.0736 2.94691 19.1002C2.95215 19.1163 2.95629 19.1321 2.96379 19.1475C2.9784 19.1779 2.99754 19.206 3.02043 19.2323C3.02605 19.2387 3.02832 19.2473 3.03434 19.2533L6.92114 23.1401C6.95715 23.1761 6.99989 23.2034 7.04598 23.2222C7.06957 23.232 7.09512 23.2323 7.11988 23.2368C7.14199 23.2409 7.16301 23.2499 7.18629 23.2499H18.4705C18.6778 23.2499 18.8455 23.082 18.8455 22.8749V21.0187H20.7017C20.9091 21.0187 21.0767 20.8507 21.0767 20.6437L21.0767 1.12488C21.0767 0.917838 20.909 0.749878 20.7017 0.749878L20.702 0.749998ZM4.20624 19.3624H6.81324V21.9698L4.20624 19.3628V19.3624ZM18.0958 22.5H7.56324V18.9874C7.56324 18.7804 7.39559 18.6124 7.18824 18.6124H3.67484V3.7314H18.0958V22.5ZM20.327 20.2688H18.8458V3.3564C18.8458 3.14936 18.6781 2.9814 18.4708 2.9814H5.9062V1.50016H20.3272L20.327 20.2688Z"
                            fill="currentColor"
                          />
                          <path
                            d="M5.81445 9.5448H15.9537C16.161 9.5448 16.3287 9.37683 16.3287 9.1698C16.3287 8.96276 16.161 8.7948 15.9537 8.7948H5.81445C5.60711 8.7948 5.43945 8.96277 5.43945 9.1698C5.43945 9.37684 5.60711 9.5448 5.81445 9.5448Z"
                            fill="currentColor"
                          />
                          <path
                            d="M15.9544 10.5626H14.0659C13.8586 10.5626 13.6909 10.7306 13.6909 10.9376C13.6909 11.1446 13.8586 11.3126 14.0659 11.3126H15.9544C16.1618 11.3126 16.3294 11.1446 16.3294 10.9376C16.3294 10.7306 16.1618 10.5626 15.9544 10.5626Z"
                            fill="currentColor"
                          />
                          <path
                            d="M5.81445 11.3126H12.4531C12.6604 11.3126 12.8281 11.1446 12.8281 10.9376C12.8281 10.7306 12.6604 10.5626 12.4531 10.5626H5.81445C5.60711 10.5626 5.43945 10.7306 5.43945 10.9376C5.43945 11.1446 5.60711 11.3126 5.81445 11.3126Z"
                            fill="currentColor"
                          />
                          <path
                            d="M15.9546 12.333H8.88477C8.67743 12.333 8.50977 12.501 8.50977 12.708C8.50977 12.915 8.67742 13.083 8.88477 13.083H15.9546C16.1619 13.083 16.3296 12.915 16.3296 12.708C16.3296 12.501 16.1619 12.333 15.9546 12.333Z"
                            fill="currentColor"
                          />
                          <path
                            d="M5.81445 13.083H7.36321C7.57055 13.083 7.73821 12.915 7.73821 12.708C7.73821 12.501 7.57056 12.333 7.36321 12.333H5.81445C5.60711 12.333 5.43945 12.501 5.43945 12.708C5.43945 12.915 5.60711 13.083 5.81445 13.083Z"
                            fill="currentColor"
                          />
                          <path
                            d="M15.9543 14.103H13.9443C13.737 14.103 13.5693 14.271 13.5693 14.478C13.5693 14.685 13.737 14.853 13.9443 14.853H15.9543C16.1617 14.853 16.3293 14.685 16.3293 14.478C16.3293 14.271 16.1617 14.103 15.9543 14.103Z"
                            fill="currentColor"
                          />
                          <path
                            d="M12.6653 14.478C12.6653 14.271 12.4976 14.103 12.2903 14.103H5.81445C5.60711 14.103 5.43945 14.271 5.43945 14.478C5.43945 14.685 5.60711 14.853 5.81445 14.853H12.2907C12.498 14.853 12.6657 14.685 12.6657 14.478H12.6653Z"
                            fill="currentColor"
                          />
                          <path
                            d="M15.9537 16.6508H12.6641C12.4567 16.6508 12.2891 16.8188 12.2891 17.0258C12.2891 17.2328 12.4567 17.4008 12.6641 17.4008H15.9537C16.161 17.4008 16.3287 17.2328 16.3287 17.0258C16.3287 16.8188 16.161 16.6508 15.9537 16.6508Z"
                            fill="currentColor"
                          />
                          <path
                            d="M8.66064 7.379H13.0556C13.263 7.379 13.4306 7.21103 13.4306 7.004C13.4306 6.79696 13.263 6.629 13.0556 6.629H8.66064C8.4533 6.629 8.28564 6.79697 8.28564 7.004C8.28564 7.21104 8.4533 7.379 8.66064 7.379Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                      <span>PRFs</span>
                    </Link>
                    {/* </Nav.Link> */}
                  </Nav.Item>
                </Nav>
              </nav>
            ) : (
              <nav>
                <Nav className="flex-column" as={'ul'} activeKey="/admin">
                  <Nav.Item as={'li'} id="admin-nav">
                    {/* <Nav.Link */}
                    <Link
                      className={`d-flex align-items-center text-center fs-sm nav-link ${
                        inActive('/') ? 'active' : ''
                      }`}
                      to="/"
                      // eventKey="link-4"
                    >
                      <span>
                        <svg
                          width="20"
                          height="14"
                          viewBox="0 0 20 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7 8.75C4.66 8.75 0 9.92 0 12.25V14H14V12.25C14 9.92 9.34 8.75 7 8.75ZM2.34 12C3.18 11.42 5.21 10.75 7 10.75C8.79 10.75 10.82 11.42 11.66 12H2.34ZM7 7C8.93 7 10.5 5.43 10.5 3.5C10.5 1.57 8.93 0 7 0C5.07 0 3.5 1.57 3.5 3.5C3.5 5.43 5.07 7 7 7ZM7 2C7.83 2 8.5 2.67 8.5 3.5C8.5 4.33 7.83 5 7 5C6.17 5 5.5 4.33 5.5 3.5C5.5 2.67 6.17 2 7 2ZM14.04 8.81C15.2 9.65 16 10.77 16 12.25V14H20V12.25C20 10.23 16.5 9.08 14.04 8.81ZM13 7C14.93 7 16.5 5.43 16.5 3.5C16.5 1.57 14.93 0 13 0C12.46 0 11.96 0.13 11.5 0.35C12.13 1.24 12.5 2.33 12.5 3.5C12.5 4.67 12.13 5.76 11.5 6.65C11.96 6.87 12.46 7 13 7Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                      <span>Admin</span>
                    </Link>
                    {/* </Nav.Link> */}
                  </Nav.Item>
                </Nav>
              </nav>
            )}
          </span>
        )}
      </aside>
    </header>
  );
};

export default Header;
