/* eslint-disable */
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import PropTypes from 'prop-types';

const StepData = ({
  Step1,
  Step2,
  icon1,
  Step3,
  icon2,
  icon3,
  style1,
  style2,
  style3,
  title,
  textWhite,
  LoadingIcon,
}) => (
  <>
    <div className="d-flex flex-row">
      <div className="step-styles" style={style1}>
        <div
          className={`arrow-content d-flex justify-content-between align-items-center ${textWhite}`}
        >
          <span className="d-flex flex-column">
            <strong>{title ? title : 'Step 1'}</strong>
            <strong>{Step1}</strong>
          </span>
          {LoadingIcon && LoadingIcon}
          {icon1 && <img src={icon1} alt="Icon" />}
        </div>
      </div>
      {Step2 && (
        <div className="step-styles" style={style2}>
          <div className="arrow-content d-flex justify-content-between align-items-center">
            <span className="d-flex flex-column">
              <strong>{'Step 2'}</strong>
              <strong>{Step2}</strong>
            </span>
            {icon2 ? <img src={icon2} alt="Icon" /> : null}
          </div>
        </div>
      )}
      {Step3 && (
        <div className="step-styles" style={style3}>
          <div className="arrow-content d-flex justify-content-between align-items-center">
            <span className="d-flex flex-column">
              <strong>{'Step 3'}</strong>
              <strong>{Step3}</strong>
            </span>
            {icon3 ? <img src={icon3} alt="Icon" /> : null}
          </div>
        </div>
      )}
    </div>
  </>
);
StepData.propTypes = {
  Step1: PropTypes.string,
  Step2: PropTypes.string,
  Step3: PropTypes.string,
  icon1: PropTypes.string,
  icon2: PropTypes.string,
  icon3: PropTypes.string,
  style1: PropTypes.string,
  style2: PropTypes.string,
  style3: PropTypes.string,
};
export default StepData;
