import React, { useRef, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import io from 'socket.io-client';
import PropTypes from 'prop-types';
import AxiosInstance from '../../services/api';
import { GET_ALL_RESUME } from '../../services/routes/prf';

function UploadResume({ getAllResumes }) {
  const navigate = useNavigate();
  const location = useLocation();
  const fileInputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [socket, setSocket] = useState(null);
  const [, setProgress] = useState(0);
  const socketRef = useRef(socket);

  useEffect(() => {
    // socket configuration
    const newSocket = io(process.env.REACT_APP_ENV_URL, {
      transports: ['websocket'],
      timeout: 300000,
    });

    newSocket.on('connect', () => {
      console.info('Socket connected successfully');
    });

    newSocket.on('connect_error', (error) => {
      console.error('Error connecting to socket:', error.message);
    });

    newSocket.on('progress_update', ({ progress: updatedProgress }) => {
      setProgress(updatedProgress);
    });

    newSocket.on('disconnect', (reason) => {
      console.info('Socket disconnected:', reason);
    });

    setSocket((prevSocket) => {
      socketRef.current = newSocket;
      return prevSocket;
    });

    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, []);

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(event.target.files[0]);
      const formData = new FormData();
      formData.append('resume', file);
      const toastId = toast.loading(
        'Uploading Resume. This may take some time.',
      );

      try {
        const socketId = socketRef.current.id;
        // Emit 'start-task' event to notify the client that a task is starting
        socketRef.current.emit('start-task', { socketId });
        await AxiosInstance.post(GET_ALL_RESUME, formData);
        toast.update(toastId, {
          render: 'Resume uploaded successfully. Organizing Resumes...',
          type: 'success',
          isLoading: false,
          autoClose: 5000,
        });
        setTimeout(() => {
          getAllResumes();
          if (location.pathname !== '/resume') {
            navigate('/resume');
          }
          window.location.reload();
        }, 5000);
      } catch (e) {
        console.error('Error:', e.response || e);
        toast.update(toastId, {
          render: `Error uploading resume: ${
            e.response?.data?.message || e.message
          }`,
          type: 'error',
          isLoading: false,
          autoClose: 5000,
        });
      }
    }
  };

  return (
    <div>
      <div className="text-center">
        <label
          id="button-resume-upload"
          htmlFor="fileUpload"
          className="fileUpload"
        >
          <input
            id="fileUpload"
            type="file"
            name="attachments"
            ref={fileInputRef}
            accept=".pdf, .docx"
            className=""
            onChange={handleFileUpload}
          />
          {selectedImage ? (
            <div className="d-flex flex-column text-center">
              <h6 className="select-file">
                <a>Upload Resume</a>
              </h6>
            </div>
          ) : (
            <div className="d-flex flex-column text-center">
              <h6 className="select-file">
                <a>Upload Resume</a>
              </h6>
            </div>
          )}
        </label>
      </div>
    </div>
  );
}

UploadResume.propTypes = {
  getAllResumes: PropTypes.func,
};

export default UploadResume;
