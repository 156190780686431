/*  eslint-disable */

import { useContext } from 'react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { checkExistence } from '../components/utils/util';
import { GET_USER_ROLES } from '../components/helper/constant';
import { AppContext } from '../context/appContext';

const { ACCOUNT_MANAGER } = GET_USER_ROLES;

const Details = ({ response, profile }) => {
  const { getRole } = useContext(AppContext);
  const isAccountManager = getRole() === ACCOUNT_MANAGER;

  // const finalStatus =
  //   response?.prf_statuses[response.prf_statuses.length - 1]?.status;
  let userDetails;
  let name;
  let Title;
  if (!response) {
    const profileString = JSON.parse(profile);
    const { user } = profileString;
    userDetails = user;
    name = `${userDetails.firstName || ' '} ${userDetails.lastName || ' '}`;
    Title = userDetails?.title;
  } else {
    userDetails = response;
    name = `${response?.userFirstName} ${response?.userLastName}`;
    Title = response?.userTitle;
  }
  const details = [
    { 'Created By': checkExistence(name) },
    { Title: checkExistence(Title || 'Engineer') },
    { Client: checkExistence(userDetails?.client) },
    {
      'PRF Created Date': checkExistence(
        userDetails?.createdAt
          ? format(new Date(userDetails?.createdAt), 'dd/MM/yy')
          : null,
      ),
    },
  ];

  if (response?.prf_statuses.length > 0) {
    response?.prf_statuses.reduce((result, prf, currentIndex) => {
      if (currentIndex === 0) {
        return result;
      }
      const key =
        prf.status === 4
          ? 'PRF Rejected Date'
          : prf.status === 1
          ? 'PRF Re-submitted Date'
          : prf.status === 8
          ? 'PRF Interal Hire Date'
          : prf.status === 5
          ? 'PRF Accepted Date'
          : null;
      if (key) {
        details.push({ [key]: format(new Date(prf.createdAt), 'dd/MM/yy') });
      }
      return result;
    }, []);
  }

  if (userDetails?.assignedTo && !isAccountManager) {
    details.push(
      userDetails?.assignedTo
        ? { 'Assigned To': checkExistence(userDetails?.assignedTo) }
        : null,
    );
  }
  if (userDetails?.talentDisapproval) {
    details.push(
      userDetails?.talentDisapproval
        ? {
            'Talent Team Approval': (
              <div style={{ textAlign: 'center' }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="25"
                  height="25"
                  viewBox="0 0 448 512"
                >
                  <path
                    fill="#d9534f"
                    d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm79 143c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z"
                  />
                </svg>
              </div>
            ),
          }
        : null,
    );
  }
  if (userDetails?.talentApproval) {
    details.push(
      userDetails?.talentApproval
        ? {
            'Talent Team Approval': (
              <div style={{ textAlign: 'center' }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="25"
                  height="25"
                  viewBox="0 0 48 48"
                >
                  <path
                    fill="#c8e6c9"
                    d="M36,42H12c-3.314,0-6-2.686-6-6V12c0-3.314,2.686-6,6-6h24c3.314,0,6,2.686,6,6v24C42,39.314,39.314,42,36,42z"
                  ></path>
                  <path
                    fill="#4caf50"
                    d="M34.585 14.586L21.014 28.172 15.413 22.584 12.587 25.416 21.019 33.828 37.415 17.414z"
                  ></path>
                </svg>
              </div>
            ),
          }
        : null,
    );
  }
  if (userDetails?.prfSubmitRequest === true) {
    details.push(
      {
        'Profile Submitted': (
          <div style={{ textAlign: 'center' }}>
            {userDetails?.payRateCategory === 'above' ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="25"
                height="25"
                viewBox="0 0 48 48"
              >
                <path
                  fill="#de5769"
                  d="M36,42H12c-3.314,0-6-2.686-6-6V12c0-3.314,2.686-6,6-6h24c3.314,0,6,2.686,6,6v24C42,39.314,39.314,42,36,42z"
                ></path>
                <path
                  fill="#fcfcfc"
                  d="M34.585 14.586L21.014 28.172 15.413 22.584 12.587 25.416 21.019 33.828 37.415 17.414z"
                ></path>
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="25"
                height="25"
                viewBox="0 0 48 48"
              >
                <path
                  fill="#c8e6c9"
                  d="M36,42H12c-3.314,0-6-2.686-6-6V12c0-3.314,2.686-6,6-6h24c3.314,0,6,2.686,6,6v24C42,39.314,39.314,42,36,42z"
                ></path>
                <path
                  fill="#4caf50"
                  d="M34.585 14.586L21.014 28.172 15.413 22.584 12.587 25.416 21.019 33.828 37.415 17.414z"
                ></path>
              </svg>
            )}
          </div>
        ),
      },
      {
        'PRF Closed Date': checkExistence(
          userDetails?.closedDate
            ? format(new Date(userDetails?.closedDate), 'dd/MM/yy')
            : null,
        ),
      },
    );
  } else {
    details.push(
      {
        'PRF Closed Date': checkExistence(
          userDetails?.closedDate
            ? format(new Date(userDetails?.closedDate), 'dd/MM/yy')
            : null,
        ),
      },
      {
        'Profile Submitted': '---',
      },
    );
  }

  return (
    <>
      <div className="infoBar d-flex flex-wrap py-2">
        {details.map((detail) => (
          <>
            {Object.entries(detail).map(([key, value]) => (
              <div
                key={`prf-${key}`}
                className="details-content py-3 px-3 d-flex flex-column"
              >
                <span className="pb-2">{key}</span>
                <strong>{value}</strong>
              </div>
            ))}
          </>
        ))}
      </div>
    </>
  );
};
Details.propTypes = {
  // The respones to check the prf response.
  response: PropTypes.object,
  profile: PropTypes.array,
};
export default Details;
