import React, { createContext, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

import cookie from 'react-cookies';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import axios from 'axios';
import { loginRequest } from '../authConfig';
import { EXPIRY_DATE, GET_USER_ROLES } from '../components/helper/constant';
import loginConfig from '../services/api/apiConfig';
import {
  LOCAL_STORAGE_KEYS,
  LOCAL_STORAGE_VALUES,
  MSAL_SESSION_STORAGE_KEYS,
} from '../utils/constants';
import LoaderComp from '../components/global/loaderComp';
import AxiosInstance from '../services/api';
import { GET_PRF_CHART } from '../services/routes/prf';

const AppContext = createContext();
const { SUPER_USER } = GET_USER_ROLES;

function AppProvider({ children }) {
  const { instance } = useMsal();

  const isAuthenticated = useIsAuthenticated();
  const [profile, setProfile] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [, setError] = useState(null);
  const [isSignedIn, setIsSignedIn] = useState(!!(profile && isAuthenticated));
  const [prfChartData, setPrfChartData] = useState(null);

  const logOut = () => {
    try {
      cookie.remove('token', { path: '/' });
      sessionStorage.clear();
      localStorage.clear();
      setProfile(undefined);
      setIsSignedIn(false);
      setLoading(false);
    } catch (error) {
      setError(true);
    }
  };

  const getRole = () => {
    if (profile) {
      if (
        Array.isArray(profile?.user?.roles) &&
        profile?.user?.roles.length > 0
      ) {
        return profile?.user?.roles[0]?.role;
      }
      logOut();
    }
    // else if (cookie.load('token')?.token) {
    //   return jwt_decode(cookie.load('token')?.token)?.user?.roles[0]?.role;
    // }
    // TODO: error handling can be improved here
    return null;
  };

  const fetchPrfChartData = useCallback(async () => {
    const activeRole = getRole();
    // Return cached data if available and user is a super user
    if (prfChartData && activeRole === SUPER_USER) {
      prfChartData.isAnimated = false;
      return prfChartData;
    }

    setLoading(true);
    try {
      const response = await AxiosInstance.get(GET_PRF_CHART);
      if (response.data.data) {
        response.data.data.isAnimated = true;
      }
      if (activeRole === SUPER_USER) {
        console.log('ok');
      }
      // Process data here if necessary
      setPrfChartData(response?.data?.data);
      return response?.data?.data; // Return the fetched data
    } catch (e) {
      console.error('Error fetching PRF chart data:', e);
      return null; // Return null when an error occurs
      // Set error state here if necessary
    } finally {
      setLoading(false);
    }
  }, [prfChartData]); // Add other dependencies if necessary

  useEffect(() => {
    const lc = cookie.load('token');

    if (lc && lc.token) {
      setLoading(true);
      setProfile({
        ...jwt_decode(cookie.load('token')?.token),
        token: cookie.load('token')?.token,
      });
    } else {
      setLoading(false);
      const isLoginNetworkRequestStuckWhileRedirecting =
        !sessionStorage.getItem(MSAL_SESSION_STORAGE_KEYS.INTERATION_STATUS) &&
        localStorage.getItem(LOCAL_STORAGE_KEYS.LOGIN_IN_PROCESS) &&
        !!Object.values(sessionStorage).find(
          (el) => el === process.env.REACT_APP_MS_REDIRECT_URL,
        );
      const isLoginNetworkRequestNeverCalled =
        !sessionStorage.getItem(MSAL_SESSION_STORAGE_KEYS.INTERATION_STATUS) &&
        localStorage.getItem(LOCAL_STORAGE_KEYS.LOGIN_IN_PROCESS) &&
        !Object.values(sessionStorage).find(
          (el) => el === process.env.REACT_APP_MS_REDIRECT_URL,
        );
      // EDGE CASES for login request to fail
      if (
        isLoginNetworkRequestNeverCalled ||
        isLoginNetworkRequestStuckWhileRedirecting
      ) {
        localStorage.removeItem(LOCAL_STORAGE_KEYS.LOGIN_IN_PROCESS);
      }
    }
  }, []);

  useEffect(() => {
    if (instance && isAuthenticated && !profile && !loading) {
      instance
        .acquireTokenSilent(loginRequest)
        .then((response) => {
          setLoading(true);
          const accessToken = response?.accessToken;
          axios(loginConfig(accessToken))
            .then((responseForProfile) => {
              // eslint-disable-next-line camelcase
              const decoded_profile = jwt_decode(
                JSON.stringify(responseForProfile?.data),
              );
              // eslint-disable-next-line no-undef
              cookie.save('token', JSON.stringify(responseForProfile.data), {
                path: '/',
                maxAge: EXPIRY_DATE,
              }); // 8h expiry
              setProfile({
                // eslint-disable-next-line camelcase
                ...decoded_profile,
                token: JSON.stringify(responseForProfile.data),
              });
            })
            .catch((error) => {
              console.log('error', error);
              logOut();
            });
        })
        .catch((tokenSilentError) => {
          console.log('acquire token silent error', tokenSilentError);
          logOut();
        });
    }
  }, [instance, isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated && profile) {
      setLoading(false);
      setIsSignedIn(true);
      localStorage.removeItem(LOCAL_STORAGE_KEYS.LOGIN_IN_PROCESS);
    } else if (profile) {
      setLoading(false);
    }
  }, [isAuthenticated, profile]);

  return (
    <AppContext.Provider
      value={{
        profile,
        getRole,
        logOut,
        setProfile,
        isSignedIn,
        setLoading,
        prfChartData,
        fetchPrfChartData,
      }}
    >
      {children}
      <LoaderComp
        load={
          loading ||
          localStorage.getItem(LOCAL_STORAGE_KEYS.LOGIN_IN_PROCESS) ===
            LOCAL_STORAGE_VALUES.LOGIN_IN_PROCESS
        }
      />
    </AppContext.Provider>
  );
}
AppProvider.propTypes = {
  children: PropTypes.any,
};
export { AppProvider, AppContext };
