import React from 'react';
import { Container } from 'react-bootstrap';
import Users from '../../components/adminDashboard/users';
import Layout from '../../components/layout/layout';

const AdminDashboard = () => (
  <Layout>
    <main className={`flex-grow-1 d-flex dashboard`}>
      <Container fluid className="d-flex flex-column p-0">
        <div className="pageHead d-flex justify-content-between align-items-center">
          <h1 className="title mb-0">User Management</h1>
        </div>
        <div className="prfList">
          <Users data-testid="users-component" />
        </div>
      </Container>
    </main>
  </Layout>
);

export default AdminDashboard;
