export const Styles = {
  Darkgray: {
    color: 'white',
    backgroundColor: '#7081B9',
  },
  Darkgreen: {
    color: 'white',
    backgroundColor: '#34A853',
  },
  LightBlue: {
    color: '#0F62FE',
    backgroundColor: '#E4EEFE',
  },
  LightRed: {
    color: '#F16E6E',
    backgroundColor: '#FEF0F0',
  },
  LightGray: {
    color: '#000000',
    backgroundColor: '#F0F2F7',
  },
};
