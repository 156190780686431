export const CREATE_PRF = '/prfs/';
export const ADD_COMMENT = '/comments/';
export const HUBSPOT_PRF = '/hubSpot/';
export const GET_PRF_DATA = '/prfs/';
export const GET_ALL_COMMENT = '/comments/';
export const GET_ALL_PRFS = '/prfs';
export const PRF_AI = '/ai/';
export const PRF_AI_PATCH = '/ai/';
export const PRF_GET_AI = '/ai/';
export const PRF_JOB = 'ai/jobs/';
export const PRF_SIMPIFY = 'ai/simplify/';
export const UPDATE_PRF = '/prfs/';
export const TALENT_PRF = '/prfs/talent/';
export const GET_PRF_CLIENTS = '/clients/';
export const POST_PRF_CLIENTS = '/clients/';
export const GET_PRF_CHART = '/prfs/dashboard';
export const POST_PRF_SPLIT = '/prfs/split';
export const GET_PRF_FIELDS = '/prfs/fields/';
export const GET_ANALYTICS = '/prfs/analytics';
export const GET_ALL_LOGS = '/prfs/data';
export const GET_ALL_RESUME = '/resume/';
export const CREATE_RESUME = '/resume/create';
export const LINK_RESUME = '/resume/original';
export const DOWNLOAD_RESUME = '/resume/:id';
export const DELETE_RESUME = '/resume/:id';
export const UPDATE_RESUME = '/resume/:id';
export const GET_RESUME_BY_ID = '/resume/:id';
export const GET_TABS_DATA = 'prfs/tabs';
export const GET_PRF_DEPARTMENT = '/prfs/manager/';
export const GET_PRF_DEPARTMENT_DATA = '/prfs/department';
export const POST_CATSONE = '/catsone';
