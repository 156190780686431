/* eslint-disable react/prop-types */

import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Cell,
  ResponsiveContainer,
} from 'recharts';
import { colorMapping } from '../helper/constant';

const AverageTimeToForwarded = ({ data }) => (
  <div className="d-flex flex-column">
    {Object.entries(data).map(([department, departmentData]) => {
      const chartData = Object.entries(departmentData)
        .map(([name, Days]) => ({
          name,
          Days,
        }))
        .filter(({ Days }) => Days !== 0);
      const total = chartData.reduce((sum, item) => sum + item.Days, 0);

      const allValuesAreZero = Object.values(departmentData).every(
        (value) => value === 0,
      );

      if (!Object.keys(departmentData).length || allValuesAreZero) {
        return (
          <div className="prfDepartmentData" key={departmentData}>
            <h2>Average Time To Forwarded Per Account</h2>
            <div className="noDataFound">
              <div className="d-flex flex-column text-center">
                <p className="mb-0">No Data Available</p>
              </div>
            </div>
          </div>
        );
      }

      return (
        <div key={department} className="prfAccountData">
          <div className="d-flex flex-column">
            <h2>Average Time To Forwarded Per Account</h2>
          </div>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={500}
              height={326}
              data={chartData}
              barSize={20}
              margin={{ top: 20, right: 10, bottom: 60, left: -20 }}
            >
              <XAxis
                dataKey="name"
                angle={-45}
                textAnchor="end"
                interval={0}
                height={60}
              />
              <YAxis domain={[0, total]} />
              <Tooltip />
              <Bar dataKey="Days" fill="rgb(112, 129, 185)">
                {chartData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={colorMapping[entry.name] || '#7081B9'}
                  />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      );
    })}
  </div>
);

export default AverageTimeToForwarded;
