import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import { useContext } from 'react';
import Loader from '../assets/images/loader.gif';
import { AppContext } from '../context/appContext';
import { GET_USER_ROLES } from '../components/helper/constant';
import AdminDashboard from './adminDashboard';
import Employee from './employee';
import Dashboard from './dashboard/index';
import Analytics from './analytics';
import Login from './login';
import Dashboard2 from './dashboard';

const {
  ADMIN,
  EMPLOYEE,
  SUPER_USER,
  ACCOUNT_MANAGER,
  DEPARTMENT_HEAD,
  TALENT,
  TALENT_TEAM,
  PRESIDENT,
} = GET_USER_ROLES;
const LandingPageHandler = () => {
  const { getRole, isSignedIn } = useContext(AppContext);
  const userRole = getRole();
  let landingPage;
  switch (userRole) {
    case ADMIN:
      landingPage = <AdminDashboard data-testid="admin-dashboard" />;
      break;
    case EMPLOYEE:
      landingPage = <Employee data-testid="employee-page" />;
      break;
    case TALENT:
      landingPage = <Analytics data-testid="employee-page" />;
      break;
    case DEPARTMENT_HEAD:
      landingPage = <Analytics data-testid="employee-page" />;
      break;
    case TALENT_TEAM:
      landingPage = <Analytics data-testid="employee-page" />;
      break;
    case PRESIDENT:
      landingPage = <Dashboard2 />;
      break;
    case SUPER_USER:
    case ACCOUNT_MANAGER:
      landingPage = <Dashboard data-testid="dashboard" />;
      break;
    default:
      landingPage = isSignedIn ? (
        <div className="backdrop">
          <img alt="loading" src={Loader} width={50} height={50} />
        </div>
      ) : (
        <Login data-testid="login-page" />
      );
  }

  return (
    <>
      <UnauthenticatedTemplate>
        <Login />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>{landingPage}</AuthenticatedTemplate>
    </>
  );
};

export default LandingPageHandler;
