import React from 'react';
// eslint-disable-next-line import/no-named-default
import { default as BootStrapAccordion } from 'react-bootstrap/Accordion';
import PropTypes from 'prop-types';

const Accordion = ({ children, heading, optional }) => (
  <div className="positionDetailHead">
    <BootStrapAccordion defaultActiveKey="0">
      <BootStrapAccordion.Item eventKey="0" className="rounded-0 border-0">
        <BootStrapAccordion.Header>
          {heading}
          <span className="optional">{optional}</span>
        </BootStrapAccordion.Header>
        <BootStrapAccordion.Body>{children}</BootStrapAccordion.Body>
      </BootStrapAccordion.Item>
    </BootStrapAccordion>
  </div>
);
Accordion.propTypes = {
  // The heading is used to heading for the accordion section.
  heading: PropTypes.string,
  // The optional is used to heading for its optional or not in accordion section.
  optional: PropTypes.string,
  children: PropTypes.any,
};

export default Accordion;
