import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import PropTypes from 'prop-types';
import formats from './formats';
import modules from './modules';

const TextEditor = ({ value, onChange, disabled }) => (
  <ReactQuill
    readOnly={disabled}
    className="textEditor"
    theme="snow"
    value={value}
    onChange={onChange}
    formats={formats}
    modules={modules}
  />
);

TextEditor.propTypes = {
  // The Proptypes for Layout
  value: PropTypes.any,
  onChange: PropTypes.any,
  disabled: PropTypes.any,
};

export default TextEditor;
