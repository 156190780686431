/* eslint-disable */

import { Button, Col, Container, Row } from 'react-bootstrap';
import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { Formik, Form as FormikForm } from 'formik';
import 'react-dropdown/style.css';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
  useIsAuthenticated,
} from '@azure/msal-react';
import { Navigate, useNavigate } from 'react-router-dom';
import ReactDatePicker from 'react-datepicker';
import AxiosInstance from '../services/api';
import {
  GET_ANALYTICS,
  GET_PRF_FIELDS,
  GET_TABS_DATA,
  GET_PRF_CLIENTS,
  GET_PRF_DEPARTMENT_DATA,
} from '../services/routes/prf';
import Layout from '../components/layout/layout';
import TotalPrfPerAccount from '../components/analytics/totalPrfPerAccount';
import TotalPrfperDepartmentNormal from '../components/analytics/totalPrfperDepartmentNormal';
import PrfClosureData from '../components/analytics/averagePrfClosurePerAccount';
import PrfSubmissionData from '../components/analytics/totalPrfSubmissionData';
import Statistics from '../components/analytics/statistics';
import { AppContext } from '../context/appContext';
import { GET_USER_ROLES } from '../components/helper/constant';
import DropdownAnalytics from '../components/dashboard/dropdownAnalytics';
import { BasicTable } from '../components/prfTable/basicTable';
import ProfileSubmissionTimePerRole from '../components/analytics/averageProfileSubmission';
import DepartmentRoleChart from '../components/userDashboard/activePrfDepartmentChart';
import DepartmentEngagementTypeCount from '../components/userDashboard/departmentEngagementType';
import DepartmentSeniorityTypeCount from '../components/userDashboard/departmentSeniorityType';
import DepartmentLocationChart from '../components/userDashboard/departmentLocationChart';
import AverageTimeToForwarded from '../components/userDashboard/averageTimeForwarded';
import DepartmentPrfClosed from '../components/userDashboard/departmentPrfClosed';
import DepartmentInternalHire from '../components/userDashboard/departmentInternalHire';
import InternalResourceClient from '../components/analytics/totalInternalResourceStatus';
import InternalResourceDepartment from '../components/analytics/totalInternalResourceStatusDepartmen';
import PrfReSubmittedData from '../components/analytics/totalProfileResubmitData';
import PrfClosureDataDepartment from '../components/analytics/prfClosurePerDepartment';
import PrfClosureDataDepartmentCompany from '../components/analytics/prfClosurePerDepartmentCompany';
import PrfClosureDataCompany from '../components/analytics/prfClosureCompany';
import ProfileSubmissionTimePerAccountTalent from '../components/analytics/averageProfileSubmissionAccountTalent';
import ProfileSubmissionTimePerRoleTalent from '../components/analytics/averageProfileSubmissionRoleTalent';
import ProfileSubmissionTimePerAccountTalentCompany from '../components/analytics/avgProfileSubmissionAccountCompany';
import ProfileSubmissionTimePerRoleTalentCompany from '../components/analytics/avgProfileSubmissionRoleCompany';
import ClosedStatusCountClient from '../components/userDashboard/closedStatusCountClient';
import RejectedStatusCountClient from '../components/userDashboard/rejectedStatusCountClient';
import ClosedStatusClient from '../components/analytics/totalClosedStatusClient';
import ClosedStatusDepartment from '../components/analytics/totalClosedStatusDepartment';
import RejectedStatusClient from '../components/analytics/totalRejectedStatusClient';
import RejectedStatusDepartment from '../components/analytics/totalRejectedStatusDepartment';
import ClosedChart from '../components/userDashboard/closedChart';
import PayRateCountClient from '../components/analytics/payRateCountClient';
import PayRateCountDepartment from '../components/analytics/payRateCountDepartment';

const { SUPER_USER, DEPARTMENT_HEAD, ACCOUNT_MANAGER, TALENT, TALENT_TEAM } =
  GET_USER_ROLES;

const Analytics = () => {
  const { getRole, profile } = useContext(AppContext);
  const { instance } = useMsal();
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const [loading, setLoading] = useState(false);
  const [prfAccountData, setPrfAccountData] = useState();
  const [prfDepartmentData, setPrfDepartmentData] = useState();
  const [prfClosureCount, setPrfClosureCount] = useState();
  const [prfClosureCountCompany, setPrfClosureCountCompany] = useState();
  const [prfClosureCountDepartment, setPrfClosureCountDepartment] = useState();
  const [
    prfClosureCountDepartmentCompany,
    setPrfClosureCountDepartmentCompany,
  ] = useState();
  const [prfSubmissionCount, setPrfSubmissionCount] = useState();
  const [prfResubmissionCount, setPrfResubmissionCount] = useState();
  const [prfStatusCount, setPrfStatusCount] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [activeTab, setActiveTab] = useState(14);
  const [getFields, setGetFeilds] = useState('');
  const [selectedClientValue, setSelectedClientValue] = useState('');
  const [selectedDepartmentValue, setSelectedDepartmentValue] = useState('');
  const [startingDate, setStartingDate] = useState('');
  const [endingDate, setEndingDate] = useState('');
  const [showTable, setShowTable] = useState(false);
  const [prfs, setPrfs] = useState([]);
  const [tabPrfStatus, setTabPrfStatus] = useState(0);
  const [prfClient, setPrfClient] = useState();
  const isTalent = getRole() === TALENT;
  const isTalentTeam = getRole() === TALENT_TEAM;
  const isDepartmentHead = getRole() === DEPARTMENT_HEAD;
  const isSuperUser = getRole() === SUPER_USER;
  const [roles, setRoles] = useState([]);
  const [prfPerAccountData, setPrfPerAccountData] = useState();
  const [departmentEngagementTypeCounts, setDepartmentEngagementTypeCounts] =
    useState();
  const [departmentSeniorityTypeCounts, setDepartmentSeniorityTypeCounts] =
    useState();
  const [departmentClientLocationCounts, setDepartmentClientLocationCounts] =
    useState();
  const [departmentSubmissionData, setDepartmentSubmissionData] = useState();
  const [internalHireClientData, setInternalHireClientData] = useState();
  const [internalHireDepartmentData, setInternalHireDepartmentData] =
    useState();
  const [averageTimeForwarded, setAverageTimeForwarded] = useState();
  const [prfsClosed, setPrfsClosed] = useState();
  const [departmentInternalHire, setDepartmentInternalHire] = useState();
  const [rejectedStatusCountClient, setRejectedStatusCountClient] = useState();
  const [closedStatusCountClient, setClosedStatusCountClient] = useState();
  const [closedStatusCountDepartment, setClosedStatusCountDepartment] =
    useState();
  const [rejectedStatusCountDepartment, setRejectedStatusCountDepartment] =
    useState();
  const [rejectedStatusCountClientTalent, setRejectedStatusCountClientTalent] =
    useState();
  const [closedStatusCountClientTalent, setClosedStatusCountClientTalent] =
    useState();
  const [
    closedStatusCountDepartmentTalent,
    setClosedStatusCountDepartmentTalent,
  ] = useState();
  const [
    rejectedStatusCountDepartmentTalent,
    setRejectedStatusCountDepartmentTalent,
  ] = useState();
  const [profileSubmissionAccountTalent, setProfileSubmissionAccountTalent] =
    useState();
  const [profileSubmissionRoleTalent, setProfileSubmissionRoleTalent] =
    useState();
  const [profileSubmissionAccountCompany, setProfileSubmissionAccountCompany] =
    useState();
  const [profileSubmissionRoleCompany, setProfileSubmissionRoleCompany] =
    useState();
  const [payCategoryClient, setPayCategoryClient] = useState('');
  const [payRateCountDepartment, setPayRateCountDepartment] = useState('');
  const [payCategoryClientTalent, setPayCategoryClientTalent] = useState('');
  const [payRateCountDepartmentTalent, setPayRateCountDepartmentTalent] =
    useState('');
  const [payCategoryClientTalentCompany, setPayCategoryClientTalentCompany] =
    useState('');
  const [
    payRateCountDepartmentTalentCompany,
    setPayRateCountDepartmentTalentCompany,
  ] = useState('');
  const [resetKey, setResetKey] = useState(0);

  const getAllFields = async () => {
    try {
      setLoading(true);
      const users = await AxiosInstance.get(GET_PRF_FIELDS);
      setGetFeilds(() => users);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllFields();
  }, []);

  const getDepartment = async () => {
    try {
      setLoading(true);
      const response = await AxiosInstance.post(GET_PRF_DEPARTMENT_DATA);
      setRoles([response?.data?.data]);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDepartment();
  }, []);

  const PRF_DEPARTMENTS = getFields?.data?.PRF_FIELDS?.DEPARTMENTS;

  const fetchPrfClients = async () => {
    try {
      setLoading(true);
      const clients = await AxiosInstance.get(GET_PRF_CLIENTS);
      setPrfClient(clients?.data?.clients);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPrfClients();
  }, []);

  const handleTabsClick = async (sts) => {
    setShowTable(true);
    const data = {
      status: sts || tabPrfStatus,
      client: selectedClientValue,
      department: selectedDepartmentValue,
    };
    try {
      const allPrfs = await AxiosInstance.post(
        `${GET_TABS_DATA}?startDate=${startingDate.format(
          'YYYY-MM-DD',
        )}&endDate=${endingDate.format('YYYY-MM-DD')}`,
        data,
      );
      setPrfs(allPrfs?.data?.prfs);
    } catch (e) {
      console.log(e);
    }
  };

  const getAnalytics = async (sDate, eDate) => {
    try {
      setLoading(true);
      const res = await AxiosInstance.get(
        `${GET_ANALYTICS}?startDate=${sDate.format(
          'YYYY-MM-DD',
        )}&endDate=${eDate.format('YYYY-MM-DD')}`,
      );
      moment(startDate).add('day', 1).toDate();
      setPrfAccountData(res.data.prfAccountData);
      setPrfDepartmentData(res.data.prfDepartmentData);
      setPrfClosureCount(res.data.prfAccountsClosureData);
      setPrfClosureCountDepartment(res.data.prfDepartmentClosureData);
      setPrfClosureCountCompany(res.data.prfAccountsClosureDataForCompany);
      setPrfClosureCountDepartmentCompany(
        res.data.prfDepartmentClosureDataForCompany,
      );
      setPrfSubmissionCount(res.data.prfAccountsSubmissionData);
      setPrfResubmissionCount(res.data.prfAccountsResubmissionData);
      setPrfStatusCount(res.data.prfStatusCount);
      setPrfPerAccountData(res.data.departmentClientCount);
      setDepartmentEngagementTypeCounts(
        res.data.departmentEngagementTypeCounts,
      );
      setDepartmentSeniorityTypeCounts(res.data.departmentSeniorityTypeCounts);
      setDepartmentClientLocationCounts(
        res.data.departmentClientLocationCounts,
      );
      setDepartmentSubmissionData(res.data.departmentSubmissionData);
      setAverageTimeForwarded(res.data.avgForwardTimePerClient);
      setPrfsClosed(res.data.closedStatusCountByClient);
      setDepartmentInternalHire(res.data.internalResourceStatusCountByClient);
      setClosedStatusCountClient(res.data.closedStatusCountByClient);
      setClosedStatusCountDepartment(res.data.closedStatusCountByDepartment);
      setRejectedStatusCountClient(res.data.rejectedStatusCountByClient);
      setRejectedStatusCountDepartment(
        res.data.rejectedStatusCountByDepartment,
      );
      setClosedStatusCountClientTalent(
        res.data.closedStatusCountByClientForCompany,
      );
      setClosedStatusCountDepartmentTalent(
        res.data.closedStatusCountByDepartmentForCompany,
      );
      setRejectedStatusCountClientTalent(
        res.data.rejectedStatusCountByClientForCompany,
      );
      setRejectedStatusCountDepartmentTalent(
        res.data.rejectedStatusCountByDepartmentForCompany,
      );
      setInternalHireClientData(res.data.internalResourceStatusCountByClient);
      setInternalHireDepartmentData(
        res.data.internalResourceStatusCountByDepartment,
      );
      setProfileSubmissionAccountTalent(res.data.prfAccountsSubmissionData);
      setProfileSubmissionRoleTalent(res.data.departmentSubmissionData);
      setProfileSubmissionAccountCompany(
        res.data.prfAccountsSubmissionDataForCompany,
      );
      setProfileSubmissionRoleCompany(
        res.data.departmentSubmissionDataForCompany,
      );
      setPayCategoryClient(res.data.payCategoryCountByClient);
      setPayRateCountDepartment(res.data.payCategoryCountByDepartment);
      setPayCategoryClientTalent(res.data.payCategoryCountByClientForTalent);
      setPayRateCountDepartmentTalent(
        res.data.payCategoryCountByDepartmentForTalent,
      );
      setPayCategoryClientTalentCompany(
        res.data.payCategoryCountByClientForCompany,
      );
      setPayRateCountDepartmentTalentCompany(
        res.data.payCategoryCountByDepartmentForCompany,
      );
      setStartingDate(sDate);
      setEndingDate(eDate);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const getClientCounts = async (clientValue, departmentValue) => {
    try {
      const res = await AxiosInstance.get(
        `${GET_ANALYTICS}?startDate=${startingDate.format(
          'YYYY-MM-DD',
        )}&endDate=${endingDate.format(
          'YYYY-MM-DD',
        )}&client=${clientValue}&department=${departmentValue}`,
      );
      handleTabsClick();
      setPrfStatusCount(res.data.prfStatusCount);
    } catch (e) {
      setLoading(false);
      console.error('Error in getClientCounts:', e);
    }
  };

  const getByDays = async (days) => {
    const endDateParam = moment();
    const startDateParam = endDateParam.clone().subtract(days, 'days');
    setActiveTab(days);
    setDateRange([startDateParam.toDate(), endDateParam.toDate()]);
    // Reset client and department filters
    setSelectedClientValue('');
    setSelectedDepartmentValue('');
    // Force re-render by updating the resetKey
    await getAnalytics(startDateParam, endDateParam);
  };

  useEffect(() => {
    if (instance && isAuthenticated && profile) {
      const userRole = getRole();
      if (
        ![
          SUPER_USER,
          DEPARTMENT_HEAD,
          TALENT,
          ACCOUNT_MANAGER,
          TALENT_TEAM,
        ].includes(userRole)
      ) {
        navigate('/');
      }
      getByDays(14);
    }
  }, [instance, isAuthenticated, profile]);

  return (
    <>
      <Formik>
        {() => (
          <FormikForm>
            <UnauthenticatedTemplate>
              <Navigate to="/" />
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
              <Layout>
                <main className={`flex-grow-1 d-flex dashboard`}>
                  <Container fluid className="d-flex flex-column p-0">
                    <div className="pageHead d-flex justify-content-between align-items-center">
                      <h1 className="title mb-0">Analytics</h1>
                    </div>
                    <div className="analyticsContent">
                      <div className="filters d-flex flex-column flex-md-row justify-content-between align-items-center">
                        <ul className="filterList d-flex list-unstyled mb-3 mb-md-0">
                          <li>
                            <Button
                              id="weekly-analytics-button"
                              onClick={() => getByDays(7)}
                              type="button"
                              className={`${activeTab === 7 ? 'active' : ''}`}
                            >
                              Weekly
                            </Button>
                          </li>
                          <li>
                            <Button
                              id="bi-Weekly-analytics-button"
                              onClick={() => getByDays(14)}
                              type="button"
                              className={`${activeTab === 14 ? 'active' : ''}`}
                            >
                              Bi-Weekly
                            </Button>
                          </li>
                          <li>
                            <Button
                              id="monthly-analytics-button"
                              onClick={() => getByDays(30)}
                              type="button"
                              className={`${activeTab === 30 ? 'active' : ''}`}
                            >
                              Monthly
                            </Button>
                          </li>
                        </ul>
                        <div className="dateArea">
                          <span className="d-flex align-items-center">
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clipPath="url(#clip0_3493_5168)">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M6.42857 1.42857C6.42857 0.639593 5.78897 0 5 0C4.21103 0 3.57143 0.639593 3.57143 1.42857V2.85714H2.14286C0.95939 2.85714 0 3.81653 0 5V7.14286H20V5C20 3.81653 19.0406 2.85714 17.8571 2.85714H16.4287V1.42857C16.4287 0.639593 15.7891 0 15.0001 0C14.2112 0 13.5716 0.639593 13.5716 1.42857V2.85714H6.42857V1.42857ZM20 8.92857H0V17.8571C0 19.0406 0.95939 20 2.14286 20H17.8571C19.0406 20 20 19.0406 20 17.8571V8.92857Z"
                                  fill="#1D2541"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_3493_5168)">
                                  <rect width="20" height="20" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                            <em className="ps-2">Date: </em>
                          </span>
                          <ReactDatePicker
                            showIcon={true}
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(update) => {
                              setDateRange(update);
                              if (update.every((item) => item !== null)) {
                                setActiveTab(0);
                                getAnalytics(
                                  moment(update[0]),
                                  moment(update[1]),
                                );
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="mt-4 rounded">
                        {!isDepartmentHead && (
                          <Row>
                            <Col
                              xs={12}
                              xl={6}
                              xxl={4}
                              className="mb-2 mb-xxl-0"
                            >
                              <TotalPrfPerAccount
                                loading={loading}
                                prfAccountData={prfAccountData}
                              />
                            </Col>
                            <Col
                              xs={12}
                              xl={6}
                              xxl={4}
                              className="mb-2 mb-xxl-0"
                            >
                              <TotalPrfperDepartmentNormal
                                prfDepartmentData={prfDepartmentData}
                                loading={loading}
                              />
                            </Col>
                            <Col
                              xs={12}
                              xl={6}
                              xxl={4}
                              className="mb-2 mb-xxl-0"
                            >
                              <PrfClosureData
                                loading={loading}
                                prfAccountData={prfClosureCount}
                              />
                            </Col>
                            <Col
                              xs={12}
                              xl={6}
                              xxl={4}
                              className="mb-2 mb-xxl-0"
                            >
                              <PrfClosureDataDepartment
                                loading={loading}
                                prfAccountData={prfClosureCountDepartment}
                              />
                            </Col>
                            {isSuperUser && (
                              <>
                                <Col
                                  xs={12}
                                  xl={6}
                                  xxl={4}
                                  className="mb-2 mb-xxl-0"
                                >
                                  <PayRateCountClient
                                    loading={loading}
                                    prfAccountData={payCategoryClient}
                                    heading={'Pay Rate Category By Client'}
                                  />
                                </Col>
                                <Col
                                  xs={12}
                                  xl={6}
                                  xxl={4}
                                  className="mb-2 mb-xxl-0"
                                >
                                  <PayRateCountDepartment
                                    loading={loading}
                                    prfAccountData={payRateCountDepartment}
                                  />
                                </Col>
                              </>
                            )}
                            {isTalentTeam && (
                              <>
                                <Col
                                  xs={12}
                                  xl={6}
                                  xxl={4}
                                  className="mb-2 mb-xxl-0"
                                >
                                  <PayRateCountClient
                                    loading={loading}
                                    prfAccountData={payCategoryClientTalent}
                                    heading={'Pay Rate Category By Client'}
                                  />
                                </Col>
                                <Col
                                  xs={12}
                                  xl={6}
                                  xxl={4}
                                  className="mb-2 mb-xxl-0"
                                >
                                  <PayRateCountClient
                                    loading={loading}
                                    prfAccountData={
                                      payCategoryClientTalentCompany
                                    }
                                    heading={
                                      'Pay Rate Category By Client ( Company Wide )'
                                    }
                                  />
                                </Col>
                                <Col
                                  xs={12}
                                  xl={6}
                                  xxl={4}
                                  className="mb-2 mb-xxl-0"
                                >
                                  <PayRateCountClient
                                    loading={loading}
                                    prfAccountData={
                                      payRateCountDepartmentTalent
                                    }
                                    heading={'Pay Rate Category By Department'}
                                  />
                                </Col>
                                <Col
                                  xs={12}
                                  xl={6}
                                  xxl={4}
                                  className="mb-2 mb-xxl-0"
                                >
                                  <PayRateCountClient
                                    loading={loading}
                                    prfAccountData={
                                      payRateCountDepartmentTalentCompany
                                    }
                                    heading={
                                      'Pay Rate Category By Department ( Company Wide )'
                                    }
                                  />
                                </Col>
                                <Col
                                  xs={12}
                                  xl={6}
                                  xxl={4}
                                  className="mb-2 mb-xxl-0"
                                >
                                  <ProfileSubmissionTimePerAccountTalentCompany
                                    loading={loading}
                                    prfAccountData={
                                      profileSubmissionAccountCompany
                                    }
                                  />
                                </Col>
                                <Col
                                  xs={12}
                                  xl={6}
                                  xxl={4}
                                  className="mb-2 mb-xxl-0"
                                >
                                  <PrfClosureDataCompany
                                    loading={loading}
                                    prfAccountData={prfClosureCountCompany}
                                  />
                                </Col>
                                <Col
                                  xs={12}
                                  xl={6}
                                  xxl={4}
                                  className="mb-2 mb-xxl-0"
                                >
                                  <PrfClosureDataDepartmentCompany
                                    loading={loading}
                                    prfAccountData={
                                      prfClosureCountDepartmentCompany
                                    }
                                  />
                                </Col>
                                <Col
                                  xs={12}
                                  xl={6}
                                  xxl={4}
                                  className="mb-2 mb-xxl-0"
                                >
                                  <ProfileSubmissionTimePerAccountTalent
                                    loading={loading}
                                    prfAccountData={
                                      profileSubmissionAccountTalent
                                    }
                                  />
                                </Col>
                                <Col
                                  xs={12}
                                  xl={6}
                                  xxl={4}
                                  className="mb-2 mb-xxl-0"
                                >
                                  <ProfileSubmissionTimePerRoleTalent
                                    loading={loading}
                                    prfAccountData={profileSubmissionRoleTalent}
                                  />
                                </Col>
                                <Col
                                  xs={12}
                                  xl={6}
                                  xxl={4}
                                  className="mb-2 mb-xxl-0"
                                >
                                  <ProfileSubmissionTimePerRoleTalentCompany
                                    loading={loading}
                                    prfAccountData={
                                      profileSubmissionRoleCompany
                                    }
                                  />
                                </Col>
                                <Col
                                  xs={12}
                                  xl={6}
                                  xxl={4}
                                  className="mb-2 mb-xxl-0"
                                >
                                  <ClosedStatusClient
                                    loading={loading}
                                    prfAccountData={closedStatusCountClient}
                                    heading={'Number Of PRFs Closed Per Client'}
                                  />
                                </Col>
                                <Col
                                  xs={12}
                                  xl={6}
                                  xxl={4}
                                  className="mb-2 mb-xxl-0"
                                >
                                  <ClosedStatusClient
                                    loading={loading}
                                    prfAccountData={
                                      closedStatusCountClientTalent
                                    }
                                    heading={
                                      'Number Of PRFs Closed Per Client ( Company Wide )'
                                    }
                                  />
                                </Col>
                                <Col
                                  xs={12}
                                  xl={6}
                                  xxl={4}
                                  className="mb-2 mb-xxl-0"
                                >
                                  <ClosedStatusDepartment
                                    loading={loading}
                                    prfAccountData={closedStatusCountDepartment}
                                    heading={
                                      'Number Of PRFs Closed Per Department'
                                    }
                                  />
                                </Col>
                                <Col
                                  xs={12}
                                  xl={6}
                                  xxl={4}
                                  className="mb-2 mb-xxl-0"
                                >
                                  <ClosedStatusDepartment
                                    loading={loading}
                                    prfAccountData={
                                      closedStatusCountDepartmentTalent
                                    }
                                    heading={
                                      'Number Of PRFs Closed Per Department ( Company Wide )'
                                    }
                                  />
                                </Col>
                                <Col
                                  xs={12}
                                  xl={6}
                                  xxl={4}
                                  className="mb-2 mb-xxl-0"
                                >
                                  <RejectedStatusClient
                                    loading={loading}
                                    prfAccountData={rejectedStatusCountClient}
                                    heading={
                                      'Number Of PRFs Rejected Per Client'
                                    }
                                  />
                                </Col>
                                <Col
                                  xs={12}
                                  xl={6}
                                  xxl={4}
                                  className="mb-2 mb-xxl-0"
                                >
                                  <RejectedStatusClient
                                    loading={loading}
                                    prfAccountData={
                                      rejectedStatusCountClientTalent
                                    }
                                    heading={
                                      'Number Of PRFs Rejected Per Client ( Company Wide )'
                                    }
                                  />
                                </Col>
                                <Col
                                  xs={12}
                                  xl={6}
                                  xxl={4}
                                  className="mb-2 mb-xxl-0"
                                >
                                  <RejectedStatusDepartment
                                    loading={loading}
                                    prfAccountData={
                                      rejectedStatusCountDepartment
                                    }
                                    heading={
                                      'Number Of PRFs Rejected Per Department'
                                    }
                                  />
                                </Col>
                                <Col
                                  xs={12}
                                  xl={6}
                                  xxl={4}
                                  className="mb-2 mb-xxl-0"
                                >
                                  <RejectedStatusDepartment
                                    loading={loading}
                                    prfAccountData={
                                      rejectedStatusCountDepartmentTalent
                                    }
                                    heading={
                                      'Number Of PRFs Rejected Per Department ( Company Wide )'
                                    }
                                  />
                                </Col>
                              </>
                            )}
                            {(isTalent || isSuperUser) && (
                              <>
                                <Col
                                  xs={12}
                                  xl={6}
                                  xxl={4}
                                  className="mb-2 mb-xxl-0"
                                >
                                  <InternalResourceClient
                                    loading={loading}
                                    prfAccountData={internalHireClientData}
                                  />
                                </Col>
                                <Col
                                  xs={12}
                                  xl={6}
                                  xxl={4}
                                  className="mb-2 mb-xxl-0"
                                >
                                  <InternalResourceDepartment
                                    loading={loading}
                                    prfAccountData={internalHireDepartmentData}
                                  />
                                </Col>
                                <Col
                                  xs={12}
                                  xl={6}
                                  xxl={4}
                                  className="mb-2 mb-xxl-0"
                                >
                                  <RejectedStatusClient
                                    loading={loading}
                                    prfAccountData={rejectedStatusCountClient}
                                    heading={
                                      'Number Of PRFs Rejected Per Client'
                                    }
                                  />
                                </Col>
                                <Col
                                  xs={12}
                                  xl={6}
                                  xxl={4}
                                  className="mb-2 mb-xxl-0"
                                >
                                  <RejectedStatusDepartment
                                    loading={loading}
                                    prfAccountData={
                                      rejectedStatusCountDepartment
                                    }
                                    heading={
                                      'Number Of PRFs Rejected Per Department'
                                    }
                                  />
                                </Col>
                                <Col
                                  xs={12}
                                  xl={6}
                                  xxl={4}
                                  className="mb-2 mb-xxl-0"
                                >
                                  <ClosedStatusClient
                                    loading={loading}
                                    prfAccountData={closedStatusCountClient}
                                    heading={'Number Of PRFs Closed Per Client'}
                                  />
                                </Col>
                                <Col
                                  xs={12}
                                  xl={6}
                                  xxl={4}
                                  className="mb-2 mb-xxl-0"
                                >
                                  <ClosedStatusDepartment
                                    loading={loading}
                                    prfAccountData={closedStatusCountDepartment}
                                    heading={
                                      'Number Of PRFs Closed Per Department'
                                    }
                                  />
                                </Col>
                              </>
                            )}
                            {!isTalentTeam && (
                              <>
                                <Col
                                  xs={12}
                                  xl={6}
                                  xxl={4}
                                  className="mb-2 mb-xxl-0"
                                >
                                  <PrfReSubmittedData
                                    loading={loading}
                                    prfAccountData={prfResubmissionCount}
                                  />
                                </Col>
                                <Col
                                  xs={12}
                                  xl={6}
                                  xxl={4}
                                  className="mb-2 mb-xxl-0"
                                >
                                  <PrfSubmissionData
                                    loading={loading}
                                    prfAccountData={prfSubmissionCount}
                                  />
                                </Col>
                              </>
                            )}
                            {isTalent ||
                              (isSuperUser && (
                                <Col
                                  xs={12}
                                  xl={6}
                                  xxl={4}
                                  className="mb-2 mb-xxl-0"
                                >
                                  <ProfileSubmissionTimePerRole
                                    loading={loading}
                                    prfAccountData={departmentSubmissionData}
                                  />
                                </Col>
                              ))}
                          </Row>
                        )}
                        {isDepartmentHead && (
                          <Row>
                            <Col
                              xs={12}
                              xl={6}
                              xxl={4}
                              className="mb-2 mb-xxl-0"
                            >
                              <DepartmentRoleChart
                                data={prfPerAccountData || {}}
                              />
                            </Col>
                            <Col
                              xs={12}
                              xl={6}
                              xxl={4}
                              className="mb-2 mb-xxl-0"
                            >
                              <DepartmentLocationChart
                                data={departmentClientLocationCounts || {}}
                              />
                            </Col>
                            <Col
                              xs={12}
                              xl={6}
                              xxl={4}
                              className="mb-2 mb-xxl-0"
                            >
                              <DepartmentEngagementTypeCount
                                data={departmentEngagementTypeCounts || {}}
                              />
                            </Col>
                            <Col
                              xs={12}
                              xl={6}
                              xxl={4}
                              className="mb-2 mb-xxl-0"
                            >
                              <DepartmentSeniorityTypeCount
                                data={departmentSeniorityTypeCounts || {}}
                              />
                            </Col>
                            <Col
                              xs={12}
                              xl={6}
                              xxl={4}
                              className="mb-2 mb-xxl-0"
                            >
                              <AverageTimeToForwarded
                                data={averageTimeForwarded || {}}
                              />
                            </Col>
                            <Col
                              xs={12}
                              xl={6}
                              xxl={4}
                              className="mb-2 mb-xxl-0"
                            >
                              <DepartmentPrfClosed data={prfsClosed || {}} />
                            </Col>
                            <Col
                              xs={12}
                              xl={6}
                              xxl={4}
                              className="mb-2 mb-xxl-0"
                            >
                              <DepartmentInternalHire
                                data={departmentInternalHire || {}}
                              />
                            </Col>

                            <Col
                              xs={12}
                              xl={6}
                              xxl={4}
                              className="mb-2 mb-xxl-0"
                            >
                              <ClosedStatusCountClient
                                data={closedStatusCountClient || {}}
                              />
                            </Col>
                            <Col
                              xs={12}
                              xl={6}
                              xxl={4}
                              className="mb-2 mb-xxl-0"
                            >
                              <RejectedStatusCountClient
                                data={rejectedStatusCountClient || {}}
                              />
                            </Col>
                          </Row>
                        )}
                      </div>
                      {!isTalentTeam && (
                        <div className="p-4 rounded statisticsBox">
                          <div className="mb-4">
                            <ul className="filterList d-flex list-unstyled mb-3 mb-md-0 p-2 bg-white">
                              {(isDepartmentHead || isSuperUser) && (
                                <li>
                                  <DropdownAnalytics
                                    option="All"
                                    onChange={(e) => {
                                      const clientValue = e.target.value;
                                      setSelectedClientValue(clientValue);
                                      getClientCounts(
                                        clientValue,
                                        selectedDepartmentValue,
                                      );
                                    }}
                                    label="Select Client"
                                    placeholder="Enter Hiring Manager"
                                    roles={prfClient}
                                    value={selectedClientValue} // Ensure value is controlled
                                  />
                                </li>
                              )}
                              {isDepartmentHead && (
                                <li>
                                  <DropdownAnalytics
                                    option="All"
                                    onChange={(e) => {
                                      const departmentValue = e.target.value;
                                      setSelectedDepartmentValue(
                                        departmentValue,
                                      );
                                      getClientCounts(
                                        selectedClientValue,
                                        departmentValue,
                                      );
                                    }}
                                    label="Select Department"
                                    placeholder="Enter Hiring Manager"
                                    roles={roles}
                                    value={selectedDepartmentValue} // Ensure value is controlled
                                  />
                                </li>
                              )}
                              {!isDepartmentHead && (
                                <li>
                                  <DropdownAnalytics
                                    option="All"
                                    onChange={(e) => {
                                      const departmentValue = e.target.value;
                                      setSelectedDepartmentValue(
                                        departmentValue,
                                      );
                                      getClientCounts(
                                        selectedClientValue,
                                        departmentValue,
                                      );
                                    }}
                                    label="Select Department"
                                    placeholder="Enter Hiring Manager"
                                    roles={PRF_DEPARTMENTS}
                                    value={selectedDepartmentValue} // Ensure value is controlled
                                  />
                                </li>
                              )}
                            </ul>
                          </div>
                          <div className="statistics">
                            <Statistics
                              loading={loading}
                              prfStatusCount={prfStatusCount}
                              handleTabsClick={handleTabsClick}
                              setTabPrfStatus={setTabPrfStatus}
                              tabPrfStatus={tabPrfStatus}
                            />
                          </div>
                        </div>
                      )}
                      {showTable && <BasicTable data={prfs} heading="PRFs" />}
                    </div>
                  </Container>
                </main>
              </Layout>
            </AuthenticatedTemplate>
          </FormikForm>
        )}
      </Formik>
    </>
  );
};

export default Analytics;
