import React from 'react';
import { Field, ErrorMessage } from 'formik';
// import { Form } from "react-bootstrap";
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';

const Location = ({
  label,
  field1,
  disabled,
  type,
  placeholder1,
  className,
  onChange1,
}) => (
  <div className="payRange">
    <label className="detailLabel">
      {label}
      <span style={{ color: 'red' }}>*</span>
    </label>
    <Row className="position-relative">
      <Col>
        <div className={`${className} position-relative`}>
          <Field
            className="form-control"
            disabled={disabled}
            type={type}
            onChange={onChange1}
            name={field1}
            placeholder={placeholder1}
            data-testid="location-field1" // Add this data-testid
          />
        </div>
        <div className="errorMessage">
          <ErrorMessage name={field1} />
        </div>
      </Col>
    </Row>
  </div>
);

Location.propTypes = {
  // Indicates the pay-range is disabled
  disabled: PropTypes.bool,
  // The label to display for the pay-range.
  label: PropTypes.string,
  // The rangeMin attribute for the field.
  field1: PropTypes.string,
  // The rangeMax attribute for the field.
  field2: PropTypes.string,
  // The type is field type (text)
  type: PropTypes.string,
  // The placeholder1 are use Filed 1 placeHolder
  placeholder1: PropTypes.string,
  // The placeholder2 are use Filed 2 placeHolder
  placeholder2: PropTypes.string,
  // pass style classNmae
  className: PropTypes.string,
  onChange2: PropTypes.func,
  onChange1: PropTypes.func,
};

export default Location;
