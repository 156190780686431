/* eslint-disable */
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import { Field, ErrorMessage } from 'formik';

const InternalHireModel = ({
  name,
  ButtonName,
  variant,
  commentHead,
  InternalPrf,
  className,
  img,
  disabled,
  SubmitButton,
  disabled1,
  buttonID,
  secondaryButtonID,
  noOfopening,
  // eslint-disable-next-line react/prop-types
  nameNoofOpening,
  ForwardApi,
}) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dropdownOptions = Array.from(
    { length: noOfopening },
    (_, index) => index + 1,
  )
    .filter((value) => value <= noOfopening)
    .map((value) => ({ label: value.toString(), value }));

  return (
    <>
      <Button
        id={buttonID}
        variant={variant}
        className={`${className} d-flex align-items-center`}
        onClick={handleShow}
        disabled={disabled1}
      >
        <span className="pe-1">{img}</span>
        <span>{ButtonName}</span>
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="modelHeader" closeButton>
          <Modal.Title>Add Comment</Modal.Title>
        </Modal.Header>
        <Form.Group
          className="modelPopup"
          controlId="exampleForm.ControlTextarea1"
        >
          <Form.Label>
            Select Number Of Openings
            <span className="optional">*</span>
          </Form.Label>
          <Field
            as="select"
            className="internalHireDropdown"
            name={nameNoofOpening}
            data-testid={`dropdown-${name}`}
          >
            <option style={{ color: '#c3bfbf' }} value="" disabled>
              -Select-
            </option>
            {dropdownOptions.map((role, index) => (
              <option key={`${index}-${role.value}`} value={role.value}>
                {role.label}
              </option>
            ))}
          </Field>
          <Form.Label>
            {commentHead}
            <span className="optional">*</span>
          </Form.Label>
          <Field
            as="textarea"
            name={name}
            autoFocus
            placeholder="Write a comment"
          />
          <div
            className="errorMessage mt-0 ms-3 mb-1"
            data-testid="error-message"
          >
            <ErrorMessage name={name} />
          </div>
        </Form.Group>
        <Modal.Footer>
          <Button
            id="cancel-button"
            variant="secondary"
            className="cancelBtn"
            onClick={handleClose}
          >
            Close
          </Button>
          <Button
            type="button"
            variant={variant}
            disabled={disabled}
            onClick={() => {
              // Call InternalPrf API
              InternalPrf();
              // Call ForwardApi API
              ForwardApi();
            }}
            id={secondaryButtonID}
          >
            <span className="pe-1">{img}</span>
            <span>{SubmitButton}</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

InternalHireModel.propTypes = {
  variant: PropTypes.string,
  name: PropTypes.string,
  ButtonName: PropTypes.string,
  commentHead: PropTypes.string,
  className: PropTypes.string,
  img: PropTypes.string,
  disabled: PropTypes.string,
  disabled1: PropTypes.string,
  SubmitButton: PropTypes.string,
  InternalPrf: PropTypes.any,
  ForwardApi: PropTypes.any,
  buttonID: PropTypes.string,
  secondaryButtonID: PropTypes.string,
  noOfopening: PropTypes.number,
};

export default InternalHireModel;
