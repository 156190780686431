/* eslint-disable react/prop-types */

import { useEffect, useState } from 'react';
// import Loader from "../../assets/Loader.gif"
import PropTypes from 'prop-types';
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
  Cell,
} from 'recharts';
import { colorMapping } from '../helper/constant';

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip bg-white p-2 border">
        <p className="label">{`Time : ${payload[0].value} days`}</p>
      </div>
    );
  }

  return null;
};

const PrfClosureDataDepartmentCompany = ({ prfAccountData = {} }) => {
  const [XAxisMax, setXAxisMax] = useState(0);
  const [prfAccountFormatedData, setPrfAccountFormatedData] = useState();

  useEffect(() => {
    if (Object.keys(prfAccountData)?.length) {
      const prfAccountFd = Object.keys(prfAccountData)
        .map((o) => ({
          key: o,
          value: prfAccountData[o],
          color: colorMapping[o] || 'rgb(112, 129, 185)',
        }))
        .filter((o) => o.value !== 0); // Filter out objects with a value of 0
      const maxXVal = Math.max(...Object.values(prfAccountData), 4);
      setXAxisMax(maxXVal + 2);
      setPrfAccountFormatedData(prfAccountFd);
    }
  }, [prfAccountData]);

  const allValuesAreZero = Object.values(prfAccountData).every(
    (value) => value === 0,
  );

  if (!Object.keys(prfAccountData).length || allValuesAreZero) {
    return (
      <div className="prfDepartmentData">
        <h2>Average Closure Time per Role ( Company Wide )</h2>
        <div className="noDataFound">
          <div className="d-flex flex-column text-center">
            <p className="mb-0">No Data Available</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="prfAccountData">
      <h2>Average Closure Time per Role ( Company Wide )</h2>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          layout="horizontal" // Change layout to "horizontal"
          width={500}
          height={326}
          data={prfAccountFormatedData}
          margin={{ top: 20, right: 10, bottom: 60, left: -10 }}
          barSize={16}
        >
          <XAxis
            dataKey="key" // Change dataKey to "key"
            type="category" // Change type to "category"
            data-testid="x-axis"
            angle={-45}
            textAnchor="end"
            interval={0}
            height={60}
          />
          <YAxis
            domain={[0, XAxisMax]}
            type="number" // Change type to "number"
            allowDataOverflow={true}
            tick={{ fill: '#000' }}
            tickFormatter={(tick) => `${tick} Days`}
            strokeWidth="2px"
            style={{
              fontSize: '10px',
              fontWeight: '400',
              lineHeight: '14px',
            }}
          />
          <Tooltip content={<CustomTooltip />} />
          {prfAccountFormatedData && (
            <Bar dataKey="value" fill="#00a0fc" name="Custom Text">
              {prfAccountFormatedData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Bar>
          )}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

PrfClosureDataDepartmentCompany.propTypes = {
  prfAccountData: PropTypes.any,
};

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
};

export default PrfClosureDataDepartmentCompany;
