/* eslint-disable react/prop-types */

import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

const colors = {
  'junior (0 to 2 years)': '#436850',
  'intermediate (3 to 5 years)': '#304D30',
  'senior (5 + years)': '#163020',
  lead: '#1B4242',
  manager: '#092635',
};

const DepartmentCharts = ({ data, isAnimated }) => {
  // Determine which levels have non-zero openings across all companies in a department
  const getActiveLevels = (departmentData) => {
    const allLevels = {};
    Object.values(departmentData).forEach((company) => {
      Object.entries(company).forEach(([level, { openings }]) => {
        if (openings > 0) {
          allLevels[level] = true;
        }
      });
    });
    return allLevels;
  };

  // Prepare the chart data for each department
  const prepareChartData = (departmentData) => {
    const activeLevels = getActiveLevels(departmentData);
    return Object.entries(departmentData).map(([company, levels]) => {
      const entry = { name: company };
      Object.entries(levels).forEach(([level, { openings }]) => {
        if (activeLevels[level]) {
          // Only include levels that are active
          entry[`${level} Openings`] = openings;
        }
      });
      return entry;
    });
  };

  return (
    <div>
      {Object.entries(data).map(([department, departmentData]) => {
        const chartData = prepareChartData(departmentData);
        return (
          <div key={department} className="prfAccountData">
            <div className="d-flex flex-column">
              <h2>Client Department Type Based On Seniority ({department})</h2>
            </div>
            <ResponsiveContainer width="100%" height={400}>
              <BarChart
                width={500}
                height={326}
                data={chartData}
                barSize={20}
                margin={{ top: 20, right: 10, bottom: 35, left: -20 }}
              >
                <XAxis
                  dataKey="name"
                  angle={-45}
                  textAnchor="end"
                  interval={0}
                  height={60}
                />
                <YAxis />
                <Tooltip />
                {Object.keys(
                  departmentData[Object.keys(departmentData)[0]],
                ).map((level) => (
                  <React.Fragment key={level}>
                    <Bar
                      dataKey={`${level} Openings`}
                      stackId="openings"
                      fill={colors[level]}
                      name={`${level} Openings`}
                      isAnimationActive={isAnimated}
                    />
                  </React.Fragment>
                ))}
              </BarChart>
            </ResponsiveContainer>
          </div>
        );
      })}
    </div>
  );
};

export default DepartmentCharts;
