import { createColumnHelper } from '@tanstack/react-table';
// import { Fragment } from "react";

const columnHelper = createColumnHelper();

export const COLUMNS = [
  columnHelper.accessor((row) => (row.firstName ? row.firstName : '------'), {
    header: () => 'First Name',
    type: () => 'string',
    id: () => 'firstName',
  }),
  columnHelper.accessor((row) => (row.lastName ? row.lastName : '------'), {
    id: () => 'lastName',
    header: () => 'Last Name',
    type: () => 'string',
  }),
  // Job Title
  columnHelper.accessor((row) => (row.jobTitle ? row.jobTitle : '------'), {
    id: () => 'jobTitle',
    header: () => 'Job Title',
    type: () => 'string',
  }),
  columnHelper.accessor(
    (row) => (row.resourceType ? row.resourceType : '------'),
    {
      header: () => 'Resource Type',
      type: () => 'string',
      id: () => 'resourceTyp',
      enableColumnFilter: false,
      enableGlobalFilter: false,
    },
  ),
  columnHelper.accessor((row) => (row.email ? row.email : '------'), {
    header: () => 'Email ',
    type: () => 'string',
    id: () => 'email',
    enableColumnFilter: false,
    enableGlobalFilter: false,
  }),
  columnHelper.accessor((row) => (row.location ? row.location : '------'), {
    header: () => 'Location',
    type: () => 'string',
    id: () => 'location',
    enableColumnFilter: false,
    enableGlobalFilter: false,
  }),
  columnHelper.accessor(
    (row) => (row.yearsExperience ? row.yearsExperience : '------'),
    {
      header: () => 'Years of Experience ',
      type: () => 'string',
      id: () => 'yearsExperience',
      enableColumnFilter: false,
      enableGlobalFilter: false,
    },
  ),
  columnHelper.accessor((row) => (row.workAuth ? row.workAuth : '------'), {
    header: () => 'Work Authorization',
    type: () => 'string',
    id: () => 'workAuth',
    enableColumnFilter: false,
    enableGlobalFilter: false,
  }),
];
