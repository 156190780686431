import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Step from './step';
import AxiosInstance from '../services/api';
import { GET_PRF_DATA } from '../services/routes/prf';
import Loader from '../assets/images/loader.gif';

const Steps = ({ isSubmitting, prfSubmitTalentTeam, prfStatusesList }) => {
  const { id } = useParams();
  const [currentStep, setCurrentStep] = useState();
  const [currentStatus, setCurrentStatus] = useState('');
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (id) {
      AxiosInstance.get(GET_PRF_DATA + id).then((response) => {
        const step = response?.data?.prf_statuses;
        const lastIndexstep = step ? step.length - 1 : -1;
        const lastStep = response?.data?.prf_statuses[lastIndexstep]?.step; // Access the status of the last element
        setCurrentStep(lastStep);
        const statuses = response?.data?.prf_statuses;
        const lastIndex = statuses ? statuses.length - 1 : -1;
        const lastStatus = response?.data?.prf_statuses[lastIndex]?.status; // Access the status of the last element
        setCurrentStatus(lastStatus); // Set the status to your function
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [id]);
  if (loading) {
    return (
      <div className="backdrop">
        <img src={Loader} width={50} height={50} />
      </div>
    );
  }

  return (
    <div className="d-flex">
      <Step
        currentStep={currentStep}
        currentStatus={currentStatus}
        isSubmitting={isSubmitting}
        prfSubmitTalentTeam={prfSubmitTalentTeam}
        prfStatusesList={prfStatusesList}
      />
    </div>
  );
};
export default Steps;

Steps.propTypes = {
  isSubmitting: PropTypes.bool,
  prfSubmitTalentTeam: PropTypes.bool,
  prfStatusesList: PropTypes.array,
};
