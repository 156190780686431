export const checkExistence = (value) => {
  if (value) {
    return value;
  }
  return '-------';
};

export const getFirstIndexPagination = (pagination, table) => {
  if (pagination.pageIndex * pagination.pageSize === 0) {
    if (table.getRowModel().rows.length) {
      return 1;
    }
    return 0;
  }
  return pagination.pageIndex * pagination.pageSize + 1;
};

export const getLastIndexPagination = (pagination, table, data) => {
  if (pagination.pageIndex * pagination.pageSize === 0) {
    if (table.getRowModel().rows.length) {
      if (pagination.pageSize > data.length) {
        return data.length;
      }
      return pagination.pageSize;
    }
    return 0;
  }
  if (
    pagination.pageIndex * pagination.pageSize + pagination.pageSize >
    data.length
  ) {
    return data.length;
  }
  return pagination.pageIndex * pagination.pageSize + pagination.pageSize;
};

export const getPrfStatus = (status) => {
  switch (status) {
    case 1:
      return 'Initiated';
    case 2:
      return 'Pending';
    case 3:
      return 'Forwarded';
    case 4:
      return 'Rejected';
    case 5:
      return 'Accepted';
    case 6:
      return 'Submitted';
    case 7:
      return 'Closed';
    case 8:
      return 'Internal Resource';
    default:
      return '';
  }
};

export const getRmsInitialValues = (isFirstName, isLastName, isEmail) => ({
  resourceAvailable: '',
  resourceType: '',
  prefix: '',
  firstName: isFirstName,
  lastName: isLastName,
  email: isEmail,
  address: '',
  location: '',
  city: '',
  zipCode: '',
  state: '',
  phoneNo: '',
  workAuth: '',
  yearsExperience: '',
  jobTitle: '',
  languages: [
    {
      language: '',
      languageFluency: '',
    },
  ],
  academics: [
    {
      school: '',
      degree: '',
      startDate: '',
      endDate: '',
    },
  ],
  skills: [
    {
      skill: '',
      devExperience: '',
    },
  ],
  summary: '',
  attachments: null,
});

export const formatDateToDDMMYYYY = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};
