import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { formatDateToDDMMYYYY } from '../utils/util';

const ExcelExport = ({ excelData, fileName }) => {
  // Set the width of the columns in the excel sheet
  const wscols = Array(50).fill({ wch: 25 });

  // Iterate through the excelData array and format date properties
  const formattedExcelData = excelData.map((item) => {
    const formattedItem = {};
    Object.keys(item).forEach((key) => {
      if (key.includes('Date') && item[key]) {
        formattedItem[key] = formatDateToDDMMYYYY(item[key]);
      } else {
        formattedItem[key] = item[key];
      }
    });
    return formattedItem;
  });

  const columnOrder = [
    'Hubspot ID',
    'Client',
    'Types of Engagement',
    'Role',
    'Project / Team Name',
    'Created by',
    'Created Date',
    'Internal Hire Date',
    'Forwarded Date',
    'Approved Date',
    'Rejected Date',
    'ReSubmitted Date',
    'PRF Closed Date',
    'Reason for Closure',
  ];
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8';
  const fileExtension = '.xlsx';
  const exportToExcel = async () => {
    const ws = XLSX.utils.json_to_sheet(formattedExcelData, {
      header: columnOrder,
    });
    const headingCellStyle = {
      fill: {
        fgColor: { rgb: 'B3E0FF' },
      },
    };

    const headingCells = Object.keys(ws).filter((cell) =>
      cell.match(/^[A-Z]+1$/),
    );
    headingCells.forEach((cell) => {
      ws[cell].s = headingCellStyle;
    });
    ws['!cols'] = wscols;
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <>
      <Button
        id="excelSheet-download-button"
        style={{ backgroundColor: '#34A853', border: 'none' }}
        className="d-flex align-items-center justify-content-center ms-2"
        onClick={() => exportToExcel(fileName)}
      >
        <span className="me-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            viewBox="0 0 512 512"
          >
            <path
              d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z"
              fill="white"
            />
          </svg>
        </span>
        <span>Download Logs</span>
      </Button>
    </>
  );
};

ExcelExport.propTypes = {
  excelData: PropTypes.array,
  fileName: PropTypes.string,
};

export default ExcelExport;
