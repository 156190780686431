import Axios from 'axios';
import cookie from 'react-cookies';

const AxiosInstance = Axios.create({
  baseURL: process.env.REACT_APP_API_URL, // Need to replaced hardcoded endpoint with env variable
});

AxiosInstance.interceptors.request.use(
  // Add any headers or other config options you want here
  (config) => {
    const accessToken = cookie.load('token');
    if (accessToken) {
      const modifiedConfig = { ...config };
      modifiedConfig.headers.token = accessToken.token;
      return modifiedConfig;
    }
    return config;
  },
);

export default AxiosInstance;
