export const GET_USER_ROLES = {
  EMPLOYEE: 'employee',
  ADMIN: 'admin',
  DEPARTMENT_HEAD: 'department_head',
  ACCOUNT_MANAGER: 'account_manager',
  TALENT: 'talent',
  TALENT_TEAM: 'talent_team',
  PRESIDENT: 'president',
  SUPER_USER: 'super_user',
};
export const PRF_TYPEOFEMPLOYMENT = {
  FULL_TIME: { text: 'Full-Time', value: 'full-time' },
  CONTRACT: { text: 'Contract', value: 'Contract' },
};
export const PRF_TYPEOFENGAGEMENT = [
  { text: 'Fixed Price Project', value: 'fixed_price_project' },
  { text: 'Time and Material', value: 'time_and_material' },
  { text: 'Overhead', value: 'overhead' },
];
export const PRF_HUBSPOTID = [
  { text: 'New', value: 'new' },
  { text: 'Existing', value: 'existing' },
];
export const PRF_NUMOFHOUR = [
  { text: '37.5', value: '37.5' },
  { text: '40', value: '40' },
];
export const PRF_FIELDS = {
  PRF_STATUS: {
    initiated: 1,
    pending: 2,
    forward: 3,
    rejected: 4,
    accepted: 5,
    submitted: 6,
    closed: 7,
    internal_resource: 8,
    // closure: 8,
    // averageAcceptance: 9,
  },
  PRF_STATUS_NAME: {
    INITIATED: 1,
    FORWARD: 2,
    REJECTED: 3,
    ACCEPTED: 4,
    SUBMITTED: 6,
    REQUEST_CLOSURE: 5,
    INTERNAL_HIRE: 8,
  },
  PRF_STATUS_LABLE: {
    1: 'PRF Initiated ',
    3: 'PRF Forwarded ',
    4: 'PRF Rejected ',
    5: 'PRF Accepted ',
    6: 'Profile Submitted ',
    7: 'PRF Closed',
    8: 'PRF Internal Hire ',
  },
  PRF_STATUS_COLOR: {
    1: '#1e7aed',
    3: '#ed893e',
    4: '#f23047',
    5: '#34a853',
    6: '#506898',
    7: '#99a7b9',
    8: '#8338ec',
  },

  PRF_STATUS_GRADIENT: {
    1: 'linear-gradient(45deg, #1e7aed, #519bfe)', // Example gradient for status 1
    3: 'linear-gradient(45deg, #ed893e, #ffb77a)', // Example gradient for status 3
    4: 'linear-gradient(45deg, #f23047, #ff647a)', // Example gradient for status 4
    5: 'linear-gradient(45deg, #34a853, #67c57e)', // Example gradient for status 5
    6: 'linear-gradient(45deg, #506898, #7c8ab7)', // Example gradient for status 6
    7: 'linear-gradient(45deg, #99a7b9, #c2ccd3)', // Example gradient for status 7
    8: 'linear-gradient(45deg, #8338ec, #b263f3)', // Example gradient for status 8
  },

  REPEATED_STATUS_GRADIENT: {
    1: 'linear-gradient(45deg, #333333, #519bfe)', // Example gradient for status 1
    3: 'linear-gradient(45deg, #333333, #ffb77a)', // Gradient for repeated status 3
    4: 'linear-gradient(45deg, #333333, #ff647a)', // Gradient for repeated status 4
    5: 'linear-gradient(45deg, #58c78e, #81f3a4)', // Gradient for repeated status 5
    6: 'linear-gradient(45deg, #7b8dbb, #a3c1d1)', // Gradient for repeated status 6
    7: 'linear-gradient(45deg, #99a7b9, #c2ccd3)', // Example gradient for status 7
    8: 'linear-gradient(45deg, #8338ec, #b263f3)', // Example gradient for status 8
  },
  PRF_PENDING_LABLE: {
    1: 'Initiated TO BE PRF',
    3: 'Forward TO BE PRF',
    4: 'Reject TO BE PRF',
    5: 'Accepted TO BE PRF',
    6: 'Submitted TO BE PRF',
    7: 'Closed TO BE PRF',
    8: 'Internal Hire TO BE PRF',
  },
  PRF_STEPS: {
    Step1: 1,
    Step2: 2,
    Step3: 3,
    Step4: 4,
  },
};
export const RMS = {
  LANGUAGE_FLUENCY: [
    {
      text: 'Not at all Proficient',
      value: 'Not at all Proficient',
    },
    {
      text: 'Slightly Proficient',
      value: 'Slightly Proficient',
    },
    {
      text: 'Moderately Proficient',
      value: 'Moderately Proficient',
    },
    {
      text: 'Very Proficient',
      value: 'Very Proficient',
    },
    {
      text: 'Extremely Proficient',
      value: 'Extremely Proficient',
    },
  ],
};

export const USER_ROLES = {
  userRoles: [
    {
      text: 'Employee',
      value: 'employee',
    },
    {
      text: 'Admin',
      value: 'admin',
    },
    {
      text: 'Department Head',
      value: 'department_head',
    },
    {
      text: 'Talent Head',
      value: 'talent',
    },
    {
      text: 'Talent Team',
      value: 'talent_team',
    },
    {
      text: 'Account Manager',
      value: 'account_manager',
    },
    {
      text: 'President',
      value: 'president',
    },
    {
      text: 'Super User',
      value: 'super_user',
    },
  ],
  userDepartments: [
    {
      text: 'Data,AI & Analytics',
      value: 'Data,AI',
    },
    {
      text: 'Development',
      value: 'Development',
    },
    {
      text: 'Design',
      value: 'Design',
    },
    {
      text: 'Solution Architecture',
      value: 'Solution Architecture',
    },
    {
      text: 'Project Management',
      value: 'Project Management',
    },
    {
      text: 'Product Management',
      value: 'Product Management',
    },
    {
      text: 'Lab',
      value: 'Lab',
    },
    {
      text: 'Marketing',
      value: 'Marketing',
    },
    {
      text: 'Organization',
      value: 'Organization',
    },
    {
      text: 'Finance',
      value: 'Finance',
    },
    {
      text: 'Talent',
      value: 'Talent',
    },
    {
      text: 'Sales',
      value: 'Sales',
    },
    {
      text: 'HR',
      value: 'HR',
    },
  ],
  accountManagerDepartment: [
    {
      text: 'Sales',
      value: 'Sales',
    },
  ],
};
export const EXPIRY_DATE = 60 * 60 * 8;

export const colorMapping = {
  Telus: 'rgb(43, 128, 0)',
  Rogers: '#da291c',
  Bell: '#00549a ',
  CIBC: '#c41f3e',
  Loblaws: '#e89639',
  Staples: 'red',
  'MobileLIVE Inc.': '#F48245 ', // Using underscores instead of spaces
  'AT&T': '#00A8E0 ',
  BMO: '#0075be',
  Kobo: '#bf0000',
  'Rakuten Kobo': '#bf0000',
  'RAB Design': '#0C285D',
};

export const colorMappingDepartment = {
  'Data,AI & Analytics': '#225568',
  Design: '#265e74',
  Development: '#2a6a83',
  Finance: '#2f7691',
  HR: '#44849c',
  Lab: '#5991a7',
  Marketing: '#6d9fb2',
  'Product Management': '#82adbd',
  'Project Management': '#97bbc8',
  Organization: '#581845',
  Sales: '#acc8d3',
  'Solution Architecture': '#b4ced7', // Using underscores instead of spaces
  Talent: '#bcd3db',
};
