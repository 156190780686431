import React, { useEffect, useState } from 'react';
import { BasicTable } from './basicTable';
import AxiosInstance from '../../services/api';
import { GET_ALL_USERS } from '../../services/routes/dashboard';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [areUsersLoading, setUsersLoading] = useState(false);

  const getAllUsers = async () => {
    try {
      setUsersLoading(true);
      const allUsers = await AxiosInstance.get(GET_ALL_USERS);
      setUsers(allUsers?.data?.users);
      setUsersLoading(false);
    } catch (e) {
      setUsersLoading(false);
    }
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  return (
    <BasicTable
      data={users}
      areUsersLoading={areUsersLoading}
      getAllUsers={getAllUsers}
    />
  );
};

export default Users;
