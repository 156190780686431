import { createColumnHelper } from '@tanstack/react-table';
import { toast } from 'react-toastify';
import { FaCopy } from 'react-icons/fa';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const columnHelper = createColumnHelper();

export const COLUMNS = [
  columnHelper.accessor((row) => (row.name ? row.name : '------'), {
    header: () => 'Full Name',
    type: () => 'string',
    id: () => 'name',
    className: 'fullName-job',

    enableColumnFilter: false,
    enableGlobalFilter: false,
  }),
  columnHelper.accessor((row) => (row.title ? row.title : '------'), {
    header: () => 'Job Title',
    type: () => 'string',
    id: () => 'title',
    className: 'jobTitlecol-class',

    enableColumnFilter: false,
    enableGlobalFilter: false,
  }),
  columnHelper.accessor((row) => (row.phone ? row.phone : '------'), {
    id: () => 'phone',
    header: () => 'Phone ',
    type: () => 'string',
    className: 'phone-job',
  }),
  columnHelper.accessor((row) => (row.email ? row.email : '------'), {
    id: () => 'email',
    header: () => 'Email',
    type: () => 'string',
    className: 'email-job',
  }),
  columnHelper.accessor(
    (row) => (row && row.notes ? row.notes : '------'),
    {
      header: () => 'Notes',
      type: () => 'string',
      id: () => 'notes',
      className: 'overallSummary-class',
      enableSorting: false, // Disable sorting for the Summary column
      cell: (info) => {
        const summary = info.row.original.notes
          ? info.row.original.notes
          : '------';

        const handleCopySummary = () => {
          navigator.clipboard.writeText(summary);
          toast.success('Notes copied to clipboard');
        };

        // Function to render the tooltip
        const renderTooltip = (props) => (
          <Tooltip id="button-tooltip" {...props} className="custom-tooltip">
            {summary}
          </Tooltip>
        );

        return (
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip}
          >
            <div className="overallSummary">
              <span className={info.row.original.notes ? 'summarySection' : ''}>
                {summary}
              </span>
              {info.row.original.notes && (
                <button
                  size="sm"
                  className="ml-2 copyBtn mx-1"
                  id="button-resume-edit"
                  onClick={handleCopySummary}
                >
                  <FaCopy className="copy-icon" />
                </button>
              )}
            </div>
          </OverlayTrigger>
        );
      },
    },
    {
      // Render nothing for sorting icons
      asc: null,
      desc: null,
    },
  ),

  columnHelper.accessor((row) => (row.link ? row.link : '------'), {
    header: () => 'Profile link',
    type: () => 'string',
    id: () => 'link',
    className: 'link-job',
  }),
];
