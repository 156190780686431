import React, { useEffect, useState } from 'react';
import { BasicTable } from './basicTable';
import AxiosInstance from '../../services/api';
import { GET_ALL_RESUME } from '../../services/routes/prf';

const ResumeTable = () => {
  const [resumeData, setResumeData] = useState([]);
  const [resumeLoading, setResumeLoading] = useState(false);
  const getAllResumes = async () => {
    try {
      setResumeLoading(true);
      const result = await AxiosInstance.get(GET_ALL_RESUME);
      setResumeData(result.data.resumes);
      setResumeLoading(false);
    } catch (e) {
      setResumeLoading(false);
    }
  };

  useEffect(() => {
    getAllResumes();
  }, []);

  return (
    <BasicTable
      data={resumeData}
      getAllResumes={getAllResumes}
      resumesLoading={resumeLoading}
    />
  );
};

export default ResumeTable;
