/*eslint-disable*/

import React, { useState, useEffect } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
  ResponsiveContainer,
} from 'recharts';

const DepartmentEngagementTypeCount = ({ data, isAnimated }) => {
  return (
    <div className="d-flex flex-column">
      {Object.entries(data).map(([department, departmentData]) => {
        const chartData = Object.entries(departmentData)
          .map(([name, { time_and_material, fixed_price_project }]) => ({
            name,
            time_and_materialOpenings: time_and_material.openings,
            fixed_price_projectOpenings: fixed_price_project.openings,
          }))
          .filter(
            ({ time_and_materialOpenings, fixed_price_projectOpenings }) =>
              time_and_materialOpenings !== 0 ||
              fixed_price_projectOpenings !== 0,
          );

        return (
          <div key={department} className="prfAccountData">
            <div className="d-flex flex-column gap-2">
              <h2>Client Department Engagement Type Counts ({department})</h2>
            </div>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                width={500}
                height={326}
                data={chartData}
                barSize={20}
                margin={{ top: 20, right: 10, bottom: 60, left: -20 }}
              >
                <XAxis
                  dataKey="name"
                  angle={-45}
                  textAnchor="end"
                  interval={0}
                  height={60}
                />
                <YAxis tickFormatter={(tick) => Math.abs(tick)} />
                <Tooltip />
                <ReferenceLine y={0} stroke="#000" />
                <Bar
                  dataKey="time_and_materialOpenings"
                  fill="#413ea0"
                  name="Time & Material Openings"
                  stackId="b"
                  isAnimationActive={isAnimated}
                />
                <Bar
                  dataKey="fixed_price_projectOpenings"
                  fill="#4ca64c"
                  name="Fixed Price Projects Openings"
                  stackId="b"
                  isAnimationActive={isAnimated}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        );
      })}
    </div>
  );
};

export default DepartmentEngagementTypeCount;
