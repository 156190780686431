import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RoutesHandler from '../routes/routesHandler';

const PageLayout = () => (
  <>
    <RoutesHandler />
    <ToastContainer />
  </>
);

export default PageLayout;
