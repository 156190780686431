import PropTypes from 'prop-types';
import Header from '../global/headerComp';

const Layout = ({ children }) => (
  <>
    <Header />
    {children}
  </>
);

Layout.propTypes = {
  // The Proptypes for Layout
  children: PropTypes.any,
};

export default Layout;
