import React, { useContext, useEffect, useState } from 'react';
import { BasicTable } from '../prfTable/basicTable';
import AxiosInstance from '../../services/api';
import { GET_ALL_PRFS } from '../../services/routes/prf';
import { AppContext } from '../../context/appContext';
import { GET_USER_ROLES } from '../helper/constant';

const { TALENT, ACCOUNT_MANAGER, DEPARTMENT_HEAD, SUPER_USER } = GET_USER_ROLES;

const PRFList = () => {
  const { getRole } = useContext(AppContext);
  // const isAdmin = getRole() === ADMIN;
  const isTalent = getRole() === TALENT;
  // const isEmployee = getRole() === EMPLOYEE
  const isSuperUser = getRole() === SUPER_USER;
  const isAccounTManager = getRole() === ACCOUNT_MANAGER;
  const isDepartmentHead = getRole() === DEPARTMENT_HEAD;
  const [prfs, setPrfs] = useState([]);
  const [arePrfsLoading, setPrfsLoading] = useState(false);

  const getAllPrfs = async () => {
    try {
      setPrfsLoading(true);
      const allPrfs = await AxiosInstance.get(GET_ALL_PRFS);
      setPrfs(allPrfs?.data?.prfs);
      setPrfsLoading(false);
    } catch (e) {
      setPrfsLoading(false);
    }
  };

  useEffect(() => {
    getAllPrfs();
  }, []);

  return (
    <BasicTable
      heading="All PRFs"
      paragraph="Here is the PRF list initiated by account managers shown below "
      paragraph2="Here is the all PRFs list"
      arePrfsLoading={arePrfsLoading}
      data={prfs}
      isTalent={isTalent}
      isAccounTManager={isAccounTManager}
      isDepartmentHead={isDepartmentHead}
      isSuperUser={isSuperUser}
    />
  );
};

export default PRFList;
