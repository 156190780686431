import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Cell,
  ResponsiveContainer,
} from 'recharts';
import { Row, Col } from 'react-bootstrap';
import { colorMappingDepartment } from '../helper/constant';

// eslint-disable-next-line react/prop-types
const RejectedChart = ({ data, isAnimated }) => (
  <div className="d-flex flex-column">
    <h4>Rejected PRFs</h4>
    <Row>
      {Object.keys(data).map((client) => {
        const chartData = Object.entries(data[client]).map(
          ([name, { prf, openings }]) => ({
            name,
            prf,
            openings,
          }),
        );

        const chartWidth = Math.max(Math.min(chartData.length * 80, 600), 250);

        return (
          <Col key={client} xs={12} xl={6} xxl={6}>
            <div className="prfAccountData">
              <h5>{client}</h5>
              <ResponsiveContainer width="100%" height={400}>
                <BarChart
                  width={chartWidth}
                  height={450}
                  data={chartData}
                  barSize={20}
                  margin={{ top: 5, right: 30, left: 20, bottom: 50 }}
                >
                  <XAxis
                    dataKey="name"
                    angle={-45}
                    textAnchor="end"
                    interval={0}
                    height={60}
                  />
                  <YAxis />
                  <Tooltip />
                  <Bar
                    dataKey="prf"
                    fill="#7081B9"
                    minPointSize={5}
                    name="PRFs"
                    isAnimationActive={isAnimated}
                  >
                    {chartData.map((entry, index) => (
                      <Cell
                        key={`cell-openings-${client}-${index}`}
                        fill={colorMappingDepartment[entry.name] || '#7081B9'}
                      />
                    ))}
                  </Bar>
                  <Bar
                    dataKey="openings"
                    fill="#7081B9"
                    minPointSize={5}
                    name="Openings"
                    isAnimationActive={isAnimated}
                  >
                    {chartData.map((entry, index) => (
                      <Cell
                        key={`cell-openings-${client}-${index}`}
                        fill={colorMappingDepartment[entry.name] || '#7081B9'}
                      />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Col>
        );
      })}
    </Row>
  </div>
);

export default RejectedChart;
