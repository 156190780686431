import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import { Field } from 'formik';

const AssignTobe = ({
  role,
  // eslint-disable-next-line react/prop-types
  handleSubmitTalent,
  buttonID,
  variant,
  // eslint-disable-next-line react/prop-types
  value,
  // eslint-disable-next-line react/prop-types
  setSelectedValue,
  className,
  // onChange,
}) => {
  const [show, setShow] = useState(false);
  const handleSelectChange = (e) => {
    const selectedOption = role.find(
      (talent) => talent.id === parseInt(e.target.value, 10),
    );
    setSelectedValue(selectedOption);
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button
        id={buttonID}
        variant={variant}
        className={`${className} d-flex align-items-center`}
        onClick={handleShow}
      >
        <span>Assign to Talent Team</span>
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="modelHeader" closeButton>
          <Modal.Title>Assign to be</Modal.Title>
        </Modal.Header>
        <Form.Group
          className="modelPopup"
          controlId="exampleForm.ControlTextarea1"
        >
          <Form.Label>
            Assign to Talent team User
            <span className="optional">*</span>
          </Form.Label>
          <Field
            as="select"
            onChange={handleSelectChange}
            // eslint-disable-next-line react/prop-types
            value={value.id}
            className="postionDetail"
          >
            <option style={{ color: '#c3bfbf' }} value="" disabled>
              -Select-
            </option>
            {role &&
              Array.isArray(role) &&
              role.map((talent, index) => (
                <option key={index} value={talent.id}>
                  {talent.firstName} {talent.lastName}
                </option>
              ))}
          </Field>
        </Form.Group>
        <Modal.Footer>
          <Button
            id="cancel-button"
            variant="secondary"
            className="cancelBtn"
            onClick={handleClose}
          >
            Close
          </Button>
          <Button
            // eslint-disable-next-line react/prop-types
            disabled={!value.id}
            type="button"
            variant={variant}
            onClick={() => {
              handleSubmitTalent();
              handleClose();
            }}
          >
            <span>Submit</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

AssignTobe.propTypes = {
  variant: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  img: PropTypes.string,
  disabled1: PropTypes.string,
  buttonID: PropTypes.string,
  role: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
  ),
  handleSelectChange: PropTypes.func,
  nameNoofOpening: PropTypes.string,
  onChange: PropTypes.func, // Ensure onChange prop is defined in propTypes
};

export default AssignTobe;
