import { Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

const ResumeModal = ({ show, onHide }) => (
  <Modal
    show={show}
    onHide={onHide}
    size="lg"
    aria-labelledby="resume-modal"
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title id="resume-modal">Upload Resume</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <h4>Select Flow</h4>
      <div className="d-flex flex-row justify-content-between">
        <Button>Enter Details Manually</Button>
        <p>Or</p>
        <Button>Enter Details Manually</Button>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button>Close</Button>
    </Modal.Footer>
  </Modal>
);

export default ResumeModal;

ResumeModal.propTypes = {
  // The Data is used to show values in the table
  show: PropTypes.bool,
  // Sets the loading true or false
  onHide: PropTypes.bool,
};
