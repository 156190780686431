import { RxCross2 } from 'react-icons/rx';
import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form, FormLabel } from 'react-bootstrap';

export const DebouncedInput = ({ value: initialValue, onChange, ...props }) => {
  const searchRef = useRef(null);
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, 500);
    return () => clearTimeout(timeout);
  }, [value]);

  const searchHandler = () => {
    setValue((searchRef.current.value = ''));
  };

  return (
    <Form.Group className="m-0 d-flex align-items-center formSearch position-relative">
      <FormLabel className="m-0">Search</FormLabel>
      <Form.Control
        {...props}
        ref={searchRef}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder="Search by First Name, Last Name and Job Title "
      />
      {value && (
        <span onClick={searchHandler} className="btn-clear">
          <RxCross2 />
        </span>
      )}
    </Form.Group>
  );
};

DebouncedInput.propTypes = {
  // The Proptypes for Comment props
  value: PropTypes.array,
  onChange: PropTypes.string,
};
