import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import PRFList from '../components/dashboard/prfList';
import Layout from '../components/layout/layout';
import { GET_USER_ROLES } from '../components/helper/constant';
import { AppContext } from '../context/appContext';
import PrfLogTable from '../components/prfLogs/users';
import ResumeModal from '../components/dashboard/resumeUpload';

const { TALENT, SUPER_USER, TALENT_TEAM, PRESIDENT } = GET_USER_ROLES;

const Dashboard = () => {
  const { getRole } = React.useContext(AppContext);
  const [modalShow, setModalShow] = useState(false);
  const [resumeModal, setResumeModal] = useState(false);
  const isTalent = getRole() === TALENT;
  const isTalentTeam = getRole() === TALENT_TEAM;
  const isSuperUser = getRole() === SUPER_USER;
  const isPresident = getRole() === PRESIDENT;

  return (
    <Layout>
      <main className={`flex-grow-1 d-flex dashboard`}>
        <Container fluid className="d-flex flex-column p-0">
          <div className="pageHead d-flex justify-content-between align-items-center">
            <h1 className="title mb-0">PRFs</h1>
            <div className="d-flex">
              {(isTalent || isSuperUser) && (
                <>
                  <Button
                    id="logs-download-button"
                    style={{
                      backgroundColor: '#34A853',
                      border: 'none',
                      color: 'white',
                    }}
                    to="/prf-logs"
                    className="btn mx-2 btn-sm d-flex align-items-center justify-content-center btn-createPrf"
                    onClick={() => setModalShow(true)}
                  >
                    <span className="me-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 512 512"
                      >
                        <path
                          d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z"
                          fill="white"
                        />
                      </svg>
                    </span>
                    <span>Download Logs</span>
                  </Button>
                  <PrfLogTable
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                  />
                  <ResumeModal
                    show={resumeModal}
                    onHide={() => setResumeModal(false)}
                  />
                </>
              )}
              {!(isTalent || isTalentTeam || isPresident) && (
                <Link
                  id="create-prf-button"
                  to="/create-prf"
                  className="btn btn-primary btn-sm d-flex align-items-center justify-content-center btn-createPrf"
                >
                  <span className="me-2">
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.1429 6.85714H6.85714V11.1429C6.85714 11.6143 6.47143 12 6 12C5.52857 12 5.14286 11.6143 5.14286 11.1429V6.85714H0.857143C0.385714 6.85714 0 6.47143 0 6C0 5.52857 0.385714 5.14286 0.857143 5.14286H5.14286V0.857143C5.14286 0.385714 5.52857 0 6 0C6.47143 0 6.85714 0.385714 6.85714 0.857143V5.14286H11.1429C11.6143 5.14286 12 5.52857 12 6C12 6.47143 11.6143 6.85714 11.1429 6.85714Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                  <span>Create PRF</span>
                </Link>
              )}
            </div>
          </div>
          <div className="prfList">
            <PRFList />
          </div>
        </Container>
      </main>
    </Layout>
  );
};

export default Dashboard;
