import Button from 'react-bootstrap/Button';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
  useIsAuthenticated,
} from '@azure/msal-react';
import { useState, useEffect, useContext, useRef } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import {
  Formik,
  Form as FormikForm,
  Field as FormikField,
  FieldArray,
  ErrorMessage,
} from 'formik';
import cookie from 'react-cookies';
import { useNavigate, useParams, Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
import * as Yup from 'yup';
import PhoneInput from 'react-phone-input-2';
import Accordion from '../../components/dashboard/accordion';
import Dropdown from '../../components/dashboard/dropdown';
import Layout from '../../components/layout/layout';
import AxiosInstance from '../../services/api';
import { TextEditor } from '../../components/commons';
import RmsField from '../../components/form/rmsField';
import {
  CREATE_RMS,
  GET_RMS,
  UPDATE_RMS,
  GET_MY_RMS,
  // eslint-disable-next-line import/named
  LINK_RESUME,
} from '../../services/routes/rms';
import { AppContext } from '../../context/appContext';
import Loader from '../../assets/images/loader.gif';
import { RMS, GET_USER_ROLES } from '../../components/helper/constant';
import DatePickerRMS from './datepickerRms';
import 'react-phone-input-2/lib/bootstrap.css';
import FilepreView from './previewfile';
import { getRmsInitialValues } from '../../components/utils/util';

const { ADMIN, ACCOUNT_MANAGER, EMPLOYEE } = GET_USER_ROLES;
const { LANGUAGE_FLUENCY } = RMS;
const RmsForm = () => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const { profile, getRole } = useContext(AppContext);
  const navigate = useNavigate();
  const { id } = useParams();
  const fileInputRef = useRef(null);
  const isFirstName = profile?.user?.firstName;
  const isLastName = profile?.user?.lastName;
  const isEmail = profile?.user?.email;

  const isAdmin = getRole() === ADMIN;
  // const isTalent = getRole() === TALENT
  const isEmployee = getRole() === EMPLOYEE;
  const isAccounTManager = getRole() === ACCOUNT_MANAGER;
  // const isDepartmentHead = getRole() === DEPARTMENT_HEAD
  const textOnlyRegExp = /^[^\d]*$/;
  const rmsDetailSchema = Yup.object().shape({
    resourceAvailable: Yup.string().required('This is Required'),
    resourceType: Yup.string().required('This is Required'),
    prefix: Yup.string().required('This is Required'),
    firstName: Yup.string().required('This is Required'),
    lastName: Yup.string().required('This is Required'),
    email: Yup.string().required('This is Required'),
    address: Yup.string().required('This is Required'),
    location: Yup.string().required('This is Required'),
    city: Yup.string().required('This is Required'),
    zipCode: Yup.string().required('This is Required'),
    state: Yup.string().required('This is Required'),
    phoneNo: Yup.string()
      .min(11, 'Phone number must be at least 11 digits')
      .max(15, 'Phone number must be at most 15 digits')
      .required('This is required'),
    workAuth: Yup.string()
      .matches(textOnlyRegExp, 'Only text (letters) are allowed')
      .required('This is Required'),
    languages: Yup.array().of(
      Yup.object().shape({
        language: Yup.string()
          .matches(textOnlyRegExp, 'Only text (letters) are allowed')
          .required('This is Required'),
        languageFluency: Yup.string().required('This is Required'),
      }),
    ),
    academics: Yup.array().of(
      Yup.object().shape({
        school: Yup.string(),
        degree: Yup.string().when('school', {
          is: (value) => value && value.length > 0,
          then: () => Yup.string().required('This is Requried'),
          otherwise: () => Yup.string().notRequired(),
        }),
        startDate: Yup.string().when('degree', {
          is: (value) => value && value.length > 0,
          then: () => Yup.string().required('This is Requried'),
          otherwise: () => Yup.string().notRequired(),
        }),
        endDate: Yup.string().when('startDate', {
          is: (value) => value && value.length > 0,
          then: () => Yup.string().required('This is Requried'),
          otherwise: () => Yup.string().notRequired(),
        }),
      }),
    ),
    skills: Yup.array().of(
      Yup.object().shape({
        skill: Yup.string()
          .matches(textOnlyRegExp, 'Only text (letters) are allowed')
          .required('This is Required'),
        devExperience: Yup.string().required('This is Required'),
      }),
    ),
    yearsExperience: Yup.string().required('This is Required'),
    jobTitle: Yup.string()
      .matches(textOnlyRegExp, 'Only text (letters) are allowed')
      .required('This is Required'),
    summary: Yup.string().required('This is Required'),
    attachments: Yup.mixed()
      .required('File is required')
      .test('fileSize', 'File size is too large', (value) => {
        if (!value) return true; // Allow empty files
        if (value?.size) {
          return value.size <= 10000000; // 10MB in bytes
        }
        return true;
      }),
  });
  const [initialValues, setInitialValues] = useState({
    resourceAvailable: '',
    resourceType: '',
    prefix: '',
    firstName: isFirstName,
    lastName: isLastName,
    email: isEmail,
    address: '',
    location: '',
    city: '',
    zipCode: '',
    state: '',
    phoneNo: '',
    workAuth: '',
    yearsExperience: '',
    jobTitle: '',
    languages: [
      {
        language: '',
        languageFluency: '',
      },
    ],
    academics: [
      {
        school: '',
        degree: '',
        startDate: '',
        endDate: '',
      },
    ],
    skills: [
      {
        skill: '',
        devExperience: '',
      },
    ],
    summary: '',
    attachments: null,
  });
  const loggedInUser = `${profile?.user?.firstName} ${profile?.user?.lastName}`;
  const prfCreator = `${initialValues?.firstName} ${initialValues?.lastName}`;
  const isPrfCreatorMatched = loggedInUser === prfCreator;
  const [loading, setLoading] = useState(false);
  const [locked, setLocked] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const removeImage = (setFieldValue) => {
    setSelectedImage(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    setFieldValue('attachments', null);
  };

  const getRms = async (rmsId) => {
    try {
      setLoading(true);
      // setLocked(true);
      const rms = await AxiosInstance.get(GET_RMS + rmsId);
      if (rms && rms?.data === null) {
        setInitialValues(getRmsInitialValues(isFirstName, isLastName, isEmail));
        // navigate('/rmsForm');
        setLocked(false);
        setLoading(false);
      } else {
        setInitialValues(rms?.data);
        setLoading(false);
        setLocked(true);
      }
    } catch (e) {
      setLoading(false);
      setLocked(false);
    }
  };

  useEffect(() => {
    if (isAuthenticated && instance && profile) {
      if (id) {
        if (Number.isInteger(parseInt(id, 10)) || id === 'myrms') {
          getRms(id);
        }
        if (!Number.isInteger(parseInt(id, 10)) && id !== 'myrms') {
          navigate('/invalid_path');
        }
      }
    } else if (
      isAuthenticated &&
      instance &&
      !profile &&
      !cookie.load('token')
    ) {
      navigate('/');
    }
  }, [id, instance, isAuthenticated, profile]);

  /* GETTING MY RMS DATA */
  // eslint-disable-next-line no-unused-vars
  const getMyRms = async () => {
    try {
      setLoading(true);
      // setLocked(true);
      const rms = await AxiosInstance.get(GET_MY_RMS);
      if (rms.data === null) {
        navigate('/rmsForm');
      } else {
        setInitialValues(rms?.data);
        setLoading(false);
        setLocked(true);
      }
    } catch (e) {
      // setLoading(false);
      setLocked(false);
    }
  };
  const createRms = async (values) => {
    const toastId = toast.loading('Submitting RMS');
    setLocked(true);
    try {
      const { attachments, ...restValues } = values;
      const rmsData = await AxiosInstance.post(CREATE_RMS, restValues);
      // file attachment
      const rmsId = await rmsData.data.rmsId;
      const formData = new FormData();
      formData.append('attachments', attachments);
      await AxiosInstance.patch(UPDATE_RMS + rmsId, formData);

      if (isEmployee || isAdmin) {
        navigate('/');
      } else {
        navigate('/rms');
      }

      toast.update(toastId, {
        render: 'RMS submitted successfully',
        type: 'success',
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: false,
      });
      setLoading(false);
      setLocked(true);
    } catch (e) {
      toast.update(toastId, {
        render: 'Error submitting RMS :(',
        type: 'error',
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: false,
      });
    }
  };
  const myrmsid = initialValues?.id;
  const updateRms = async (values) => {
    const toastId = toast.loading('Updating RMS');
    setLocked(true);
    try {
      const { attachments, ...restValues } = values;
      await AxiosInstance.patch(UPDATE_RMS + myrmsid, restValues);

      const formData = new FormData();
      formData.append('attachments', attachments);
      await AxiosInstance.patch(UPDATE_RMS + myrmsid, formData);

      if (isEmployee || isAdmin || isAccounTManager) {
        navigate('/');
      } else {
        navigate('/rms');
      }

      toast.update(toastId, {
        render: 'RMS updated successfully',
        type: 'success',
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: false,
      });
      setLoading(false);
      setLocked(true);
    } catch (e) {
      toast.update(toastId, {
        render: 'Error Update RMS :(',
        type: 'error',
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: false,
      });
      setLoading(false);
      setLocked(false);
    }
  };
  const sendKeyToAPI = async (key) => {
    try {
      const response = await AxiosInstance.post(LINK_RESUME, { key });
      const url = response.data;

      if (url) {
        window.open(url);
      } else {
        console.error('Error: URL is empty or undefined');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  if (loading) {
    return (
      <div className="backdrop">
        <img src={Loader} width={50} height={50} />
      </div>
    );
  }
  return (
    <>
      <UnauthenticatedTemplate>
        <Navigate to="/" />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <Layout>
          <main className={`flex-grow-1 d-flex dashboard`}>
            <Container fluid className="d-flex flex-column p-0">
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={rmsDetailSchema}
                onSubmit={id ? updateRms : createRms}
              >
                {({
                  handleSubmit,
                  onSubmit,
                  isSubmitting,
                  values,
                  setFieldValue,
                  handleBlur,
                  touched,
                  setFieldTouched,
                  errors,
                  setFieldError,
                }) => (
                  <FormikForm onSubmit={handleSubmit}>
                    <div className="pageHead d-flex justify-content-between align-items-center">
                      <div className="d-flex flex-column">
                        <h1 className="title mb-0">
                          {initialValues?.firstName} {initialValues?.lastName}{' '}
                          Profile
                        </h1>
                        <nav aria-label="breadcrumb">
                          <ol className="breadcrumb mb-0">
                            <li className="breadcrumb-item">
                              {isEmployee || isAdmin || isAccounTManager ? (
                                <a href="/" className="breadcrumb-style">
                                  Dashboard
                                </a>
                              ) : (
                                <a href="/rms" className="breadcrumb-style">
                                  Dashboard RMS
                                </a>
                              )}
                            </li>
                            <li className="breadcrumb-item active">
                              {initialValues?.firstName}{' '}
                              {initialValues?.lastName} Profile
                            </li>
                          </ol>
                        </nav>
                      </div>
                      {isPrfCreatorMatched && (
                        <span>
                          <div className="d-flex" style={{ gap: '0.5rem' }}>
                            {id && locked && !isSubmitting && (
                              <Button
                                id="edit-rms-button"
                                onClick={() => setLocked(false)}
                                variant="primary"
                                size="sm"
                                className="d-flex align-items-center ms-2 justify-content-center btn-createPrf gap-2"
                              >
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g clipPath="url(#clip0_1160_2199)">
                                    <path
                                      d="M9.37333 6.01333L9.98667 6.62667L3.94667 12.6667H3.33333V12.0533L9.37333 6.01333ZM11.7733 2C11.6067 2 11.4333 2.06667 11.3067 2.19333L10.0867 3.41333L12.5867 5.91333L13.8067 4.69333C14.0667 4.43333 14.0667 4.01333 13.8067 3.75333L12.2467 2.19333C12.1133 2.06 11.9467 2 11.7733 2ZM9.37333 4.12667L2 11.5V14H4.5L11.8733 6.62667L9.37333 4.12667Z"
                                      fill="white"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_1160_2199">
                                      <rect
                                        width="16"
                                        height="16"
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                                <span style={{ paddingRight: '4px' }}>
                                  Edit
                                </span>
                              </Button>
                            )}
                            <Button
                              id={`${id} ? 'update-rms-button' : 'save-rms-button'`}
                              disabled={locked}
                              variant="primary"
                              type="submit"
                              className="gap-1 d-flex align-items-center"
                              onClick={() => handleSubmit(onSubmit)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="22"
                                height="22"
                                fill="currentColor"
                                className="bi bi-check"
                                viewBox="0 0 16 16"
                              >
                                <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                              </svg>
                              <span style={{ paddingRight: '7px' }}>
                                {id ? 'Update' : 'Save'}
                              </span>
                            </Button>
                          </div>
                        </span>
                      )}
                    </div>
                    <div className="rmsDetail">
                      <div className="d-flex flex-column pt-4 px-4 pb-0">
                        <div className="d-flex flex-column pb-4">
                          <strong className="pb-2 mb-1">
                            Is Resource Available ?
                            <span style={{ color: 'red' }}>*</span>
                          </strong>
                          <FormikField
                            component="div"
                            name="resourceAvailable"
                            className="d-flex"
                          >
                            <span className="d-flex align-items-center pe-5">
                              <input
                                type="radio"
                                id="radioOne"
                                disabled={locked}
                                defaultChecked={
                                  values?.resourceAvailable === false
                                }
                                name="resourceAvailable"
                                value="false"
                                onChange={(e) => {
                                  setFieldValue(
                                    'resourceAvailable',
                                    e.target.value,
                                  );
                                }}
                              />
                              <label className="mb-0 ms-2" htmlFor="radioOne">
                                No
                              </label>
                            </span>
                            <span className="d-flex align-items-center">
                              <input
                                type="radio"
                                id="radioTwo"
                                disabled={locked}
                                defaultChecked={
                                  values?.resourceAvailable === true
                                }
                                name="resourceAvailable"
                                value="true"
                                onChange={(e) => {
                                  setFieldValue(
                                    'resourceAvailable',
                                    e.target.value,
                                  );
                                }}
                              />
                              <label className="mb-0 ms-2" htmlFor="radioTwo">
                                Yes
                              </label>
                            </span>
                          </FormikField>
                          <div className="errorMessage">
                            <ErrorMessage name="resourceAvailable" />
                          </div>
                          {/* <FormikForm>
                      </FormikForm> */}
                        </div>
                        <div className="d-flex flex-column pb-2">
                          <strong className="pb-2 mb-1">
                            Resource Type<span style={{ color: 'red' }}>*</span>
                          </strong>
                          <FormikField
                            component="div"
                            name="resourceType"
                            className="d-flex"
                          >
                            <span className="d-flex align-items-center pe-5">
                              <input
                                type="radio"
                                id="radioOne"
                                disabled={locked}
                                defaultChecked={
                                  values?.resourceType === 'Full-Time'
                                }
                                name="resourceType"
                                value="Full-Time"
                                onChange={(e) => {
                                  setFieldValue('resourceType', e.target.value);
                                }}
                              />
                              <label className="mb-0 ms-2" htmlFor="radioOne">
                                Full Time
                              </label>
                            </span>
                            <span className="d-flex align-items-center">
                              <input
                                type="radio"
                                id="radioTwo"
                                disabled={locked}
                                defaultChecked={
                                  values?.resourceType === 'Contract'
                                }
                                name="resourceType"
                                value="Contract"
                                onChange={(e) => {
                                  setFieldValue('resourceType', e.target.value);
                                }}
                              />
                              <label className="mb-0 ms-2" htmlFor="radioTwo">
                                Contract
                              </label>
                            </span>
                          </FormikField>
                          <div className="errorMessage">
                            <ErrorMessage name="resourceType" />
                          </div>
                          {/* <FormikForm>
                      </FormikForm> */}
                        </div>
                      </div>
                      <Accordion heading="Personal Information">
                        <Col className="p-0" xl={11}>
                          <Row>
                            <Col md={3} className="mb-3 pb-3">
                              <RmsField
                                disabled={locked}
                                label="Prefix"
                                name="prefix"
                                placeholder="Enter Details"
                                type="text"
                                Asterisk="*"
                              />
                            </Col>
                            <Col md={3} className="mb-3 pb-3">
                              <RmsField
                                disabled="disabled"
                                label="First Name"
                                name="firstName"
                                placeholder="Enter Details"
                                type="text"
                                Asterisk="*"
                                // value={isFirstName}
                              />
                            </Col>
                            <Col md={3} className="mb-3 pb-3">
                              <RmsField
                                disabled="disabled"
                                label="Last Name"
                                name="lastName"
                                placeholder="Enter Details"
                                type="text"
                                // value={isLastName}
                                Asterisk="*"
                              />
                            </Col>
                            <Col md={3} className="mb-3 pb-3">
                              <RmsField
                                disabled="disabled"
                                label="Email Address"
                                name="email"
                                placeholder="Enter Details"
                                type="Email"
                                // value={isEmail}
                                Asterisk="*"
                              />
                            </Col>
                            <Col md={3} className="mb-3 pb-3">
                              <RmsField
                                disabled={locked}
                                label="Address"
                                name="address"
                                placeholder="Enter Details"
                                type="text"
                                // min="1"
                                Asterisk="*"
                              />
                            </Col>
                            <Col md={3} className="mb-3 pb-3">
                              <RmsField
                                disabled={locked}
                                label="Location"
                                name="location"
                                placeholder="Enter Details"
                                type="text"
                                // min="1"
                                Asterisk="*"
                              />
                            </Col>
                            <Col md={3} className="mb-3 pb-3">
                              <RmsField
                                disabled={locked}
                                label="City"
                                name="city"
                                placeholder="Enter Details"
                                type="text"
                                // min="1"
                                Asterisk="*"
                              />
                            </Col>
                            <Col md={3} className="mb-3 pb-3">
                              <RmsField
                                disabled={locked}
                                label="Zip Code"
                                name="zipCode"
                                placeholder="Enter Details"
                                type="number"
                                // min="1"
                                Asterisk="*"
                              />
                            </Col>
                            <Col md={3} className="mb-3 pb-3">
                              <RmsField
                                disabled={locked}
                                label="State / Province"
                                name="state"
                                placeholder="Enter Details"
                                type="text"
                                // min="1"
                                Asterisk="*"
                              />
                            </Col>
                            <Col md={3} className="mb-3 pb-3">
                              <label>
                                Phone<span style={{ color: 'red' }}>*</span>
                              </label>
                              <div>
                                <PhoneInput
                                  disabled={locked}
                                  country={'ca'}
                                  enableSearch={true}
                                  value={values?.phoneNo}
                                  onBlur={() => handleBlur('phoneNo')}
                                  onChange={(value) =>
                                    setFieldValue('phoneNo', value)
                                  }
                                />
                                <div className="errorMessage">
                                  <ErrorMessage name="phoneNo" />
                                </div>
                              </div>
                            </Col>
                            <Col md={3} className="mb-3 pb-3">
                              <RmsField
                                disabled={locked}
                                label="Work Authorization"
                                name="workAuth"
                                placeholder="Enter Details"
                                type="text"
                                // min="1"
                                Asterisk="*"
                              />
                            </Col>

                            <Col md={3} className="mb-3 pb-3">
                              <RmsField
                                disabled={locked}
                                label="Years of Experience"
                                name="yearsExperience"
                                placeholder="Enter Details"
                                type="number"
                                Asterisk="*"
                              />
                            </Col>
                            <Col md={3} className="mb-3 pb-3">
                              <RmsField
                                disabled={locked}
                                label="Job Title"
                                name="jobTitle"
                                placeholder="Enter Details"
                                type="text"
                                Asterisk="*"
                              />
                            </Col>
                            <Col className="" xs={12}>
                              <FieldArray
                                name="languages"
                                render={(arrayHelpers) => (
                                  <>
                                    {values?.languages &&
                                    values?.languages.length > 0
                                      ? values?.languages.map(
                                          (language, index) => (
                                            <Row key={index}>
                                              <Col md={3} className="mb-3 pb-3">
                                                <RmsField
                                                  name={`languages.${index}.language`}
                                                  disabled={locked}
                                                  label="Language"
                                                  placeholder="Enter Details"
                                                  type="text"
                                                  Asterisk="*"
                                                />
                                              </Col>
                                              <Col md={3} className="mb-3 pb-3">
                                                <Dropdown
                                                  Asterisk="*"
                                                  disabled={locked}
                                                  onChange={(e) => {
                                                    setFieldValue(
                                                      `languages.${index}.languageFluency`,
                                                      e.target.value,
                                                    );
                                                  }}
                                                  label="Language Fluency"
                                                  name={`languages.${index}.languageFluency`}
                                                  placeholder="Enter Details"
                                                  roles={LANGUAGE_FLUENCY}
                                                  selected={
                                                    !(touched && touched.role)
                                                  }
                                                />
                                              </Col>
                                              <Col>
                                                {index > 0 &&
                                                  locked === false && (
                                                    <Button
                                                      id="language-rms-close-button"
                                                      variant="danger"
                                                      className="CrossButton p-0"
                                                      onClick={() =>
                                                        arrayHelpers.remove(
                                                          index,
                                                        )
                                                      } // remove a friend from the list
                                                    >
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="32"
                                                        height="32"
                                                        fill="currentColor"
                                                        className="bi bi-x"
                                                        viewBox="0 0 16 16"
                                                      >
                                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                      </svg>
                                                    </Button>
                                                  )}
                                              </Col>
                                            </Row>
                                          ),
                                        )
                                      : null}
                                    {locked === false && (
                                      <>
                                        {values?.languages?.length < 4 && (
                                          <Button
                                            id="language-rms-add-button"
                                            className="d-flex align-items-center m-0 gap-2"
                                            type="button"
                                            variant="primary"
                                            size="sm"
                                            disabled={errors.languages}
                                            onClick={() =>
                                              arrayHelpers.push({
                                                language: '',
                                                languageFluency: '',
                                              })
                                            } // insert an empty string at a position
                                          >
                                            <svg
                                              width="14"
                                              height="15"
                                              viewBox="0 0 14 15"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M14 8.8999H8V14.8999H6V8.8999H0V6.8999H6V0.899902H8V6.8999H14V8.8999Z"
                                                fill="currentColor"
                                              />
                                            </svg>
                                            <span>Add Language</span>
                                          </Button>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Accordion>

                      <Accordion
                        heading="Academic Information"
                        optional="(Optional)"
                      >
                        <Col className="p-0" xl={11}>
                          <FieldArray
                            name="academics"
                            render={(arrayHelpers) => (
                              <div>
                                {values?.academics &&
                                values?.academics.length > 0
                                  ? values?.academics.map((academic, index) => (
                                      <Row
                                        key={index}
                                        className="position-relative pe-5"
                                      >
                                        <Col md={3} className="mb-3 pb-3">
                                          <RmsField
                                            disabled={locked}
                                            name={`academics.${index}.school`}
                                            label="Institute"
                                            placeholder="Enter Details"
                                            type="text"
                                            // Asterisk="*"
                                          />
                                        </Col>
                                        <Col md={3} className="mb-3 pb-3">
                                          <label className="detailLabel-control">
                                            Degree
                                            {academic?.school && (
                                              <span style={{ color: 'red' }}>
                                                *
                                              </span>
                                            )}
                                          </label>
                                          <FormikField
                                            name={`academics.${index}.degree`}
                                            disabled={
                                              locked || !academic.school
                                            }
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Details"
                                          />
                                          <div className="errorMessage">
                                            <ErrorMessage
                                              name={`academics.${index}.degree`}
                                            />
                                          </div>
                                        </Col>
                                        <Col md={3} className="mb-3 pb-3">
                                          <div className="position-relative">
                                            <DatePickerRMS
                                              key="1"
                                              disabled={
                                                locked || !academic.degree
                                              }
                                              Asterisk={academic.degree}
                                              label="Start Date"
                                              name={`academics.${index}.startDate`}
                                              placeholder="Enter Details"
                                              // min={moment(new Date()).add("day", 7).toDate()}
                                              // min={new Date()}
                                            />
                                          </div>
                                        </Col>
                                        <Col md={3} className="mb-3 pb-3">
                                          <div className="position-relative">
                                            <DatePickerRMS
                                              key="2"
                                              disabled={
                                                locked || !academic.startDate
                                              }
                                              Asterisk={academic.startDate}
                                              label="End Date"
                                              name={`academics.${index}.endDate`}
                                              placeholder="Enter Details"
                                              // Asterisk="*"
                                              min={
                                                moment(
                                                  values?.academics[index]
                                                    ?.startDate,
                                                )
                                                  ? moment(
                                                      values?.academics[index]
                                                        ?.startDate,
                                                    )
                                                      .add('day', 1)
                                                      .toDate()
                                                  : new Date()
                                              }
                                            />
                                          </div>
                                        </Col>
                                        {index > 0 && locked === false && (
                                          <Button
                                            id="academics-rms-close-button"
                                            variant="danger"
                                            className="btn-cross CrossButton p-0 position-absolute top-0 end-0"
                                            onClick={() =>
                                              arrayHelpers.remove(index)
                                            }
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="32"
                                              height="32"
                                              fill="currentColor"
                                              className="bi bi-x"
                                              viewBox="0 0 16 16"
                                            >
                                              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                            </svg>
                                          </Button>
                                        )}
                                      </Row>
                                    ))
                                  : null}
                                {locked === false && (
                                  <span>
                                    {values?.academics?.length < 4 && (
                                      <Button
                                        id="academics-rms-add-button"
                                        className="d-flex align-items-center m-0 gap-2"
                                        // type="button"
                                        variant="primary"
                                        disabled={errors?.academics}
                                        onClick={() =>
                                          arrayHelpers.push({
                                            school: '',
                                            degree: '',
                                            discipline: '',
                                            startDate: '',
                                            endDate: '',
                                          })
                                        } // insert an empty string at a position
                                      >
                                        <svg
                                          width="14"
                                          height="15"
                                          viewBox="0 0 14 15"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M14 8.8999H8V14.8999H6V8.8999H0V6.8999H6V0.899902H8V6.8999H14V8.8999Z"
                                            fill="currentColor"
                                          />
                                        </svg>
                                        <span>Add Education</span>
                                      </Button>
                                    )}
                                  </span>
                                )}
                              </div>
                            )}
                          />
                        </Col>
                      </Accordion>

                      <Accordion heading="Technical Skills">
                        <Col className="p-0" xl={11}>
                          <FieldArray
                            name="skills"
                            render={(arrayHelpers) => (
                              <div>
                                {values?.skills && values?.skills?.length > 0
                                  ? values?.skills.map((skill, index) => (
                                      <div key={index}>
                                        <Row>
                                          <Col md={3} className="mb-4">
                                            <RmsField
                                              name={`skills.${index}.skill`}
                                              disabled={locked}
                                              label="Skill"
                                              placeholder="Enter Details"
                                              type="text"
                                              Asterisk="*"
                                            />
                                          </Col>
                                          <Col md={3} className="mb-4">
                                            <RmsField
                                              name={`skills.${index}.devExperience`}
                                              disabled={locked}
                                              label="Experience"
                                              placeholder="Enter Details"
                                              type="text"
                                              Asterisk="*"
                                            />
                                          </Col>
                                          <Col>
                                            {index > 0 && locked === false && (
                                              <Button
                                                id="skills-rms-close-button"
                                                variant="danger"
                                                className="CrossButton p-0"
                                                onClick={() =>
                                                  arrayHelpers.remove(index)
                                                } // remove a friend from the list
                                              >
                                                {/* <span className="CrossICon">X</span> */}
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="32"
                                                  height="32"
                                                  fill="currentColor"
                                                  className="bi bi-x"
                                                  viewBox="0 0 16 16"
                                                >
                                                  {' '}
                                                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />{' '}
                                                </svg>
                                              </Button>
                                            )}
                                          </Col>
                                        </Row>
                                      </div>
                                    ))
                                  : null}
                                {locked === false && (
                                  <Button
                                    id="skills-rms-add-button"
                                    className="d-flex align-items-center m-0 gap-2"
                                    variant="primary"
                                    disabled={errors.skills}
                                    onClick={() =>
                                      arrayHelpers.push({
                                        langProficiency: '',
                                        devExperience: '',
                                        frameworkKnowledge: '',
                                      })
                                    } // insert an empty string at a position
                                  >
                                    <svg
                                      width="14"
                                      height="15"
                                      viewBox="0 0 14 15"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M14 8.8999H8V14.8999H6V8.8999H0V6.8999H6V0.899902H8V6.8999H14V8.8999Z"
                                        fill="currentColor"
                                      />
                                    </svg>
                                    <span>Add Skill</span>
                                  </Button>
                                )}
                              </div>
                            )}
                          />
                        </Col>
                      </Accordion>
                      <Accordion heading="Professional Summary">
                        <div className="textEditorContainer">
                          <div className="editorHolder">
                            <TextEditor
                              disabled={locked}
                              value={values?.summary}
                              onChange={(value) =>
                                setFieldValue('summary', value)
                              }
                            />
                          </div>
                          <div className="errorMessage">
                            <ErrorMessage name="summary" />
                          </div>
                        </div>
                      </Accordion>

                      <Accordion heading="Your full resume">
                        {/* <div className="textEditorContainer">
                      <div className="editorHolder">
                        <TextEditor
                          disabled={locked}
                          value={values.attachments}
                          onChange={(value) => setFieldValue("attachments", value)}
                        />
                      </div>
                    </div> */}
                        {/* <div className="uploadArea d-flex justify-content-center">
                  </div> */}
                        <div className="text-center">
                          {!locked && (
                            <label
                              htmlFor="fileUpload"
                              className="themeFileUpload"
                            >
                              <input
                                id="fileUpload"
                                type="file"
                                name="attachments"
                                ref={fileInputRef}
                                accept=".png, .jpeg, .jpg, .pdf, .doc, .docx"
                                className=""
                                onBlur={() => handleBlur('attachments')}
                                onChange={(e) => {
                                  if (e.currentTarget.files) {
                                    setFieldValue(
                                      'attachments',
                                      e.currentTarget.files[0],
                                    );
                                    setSelectedImage(e.target.files[0]);
                                    setFieldError('attachments', '');
                                    setFieldTouched('attachments', true, false);
                                  }
                                }}
                              />
                              <span>
                                <svg
                                  width="33"
                                  height="28"
                                  viewBox="0 0 33 28"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M22.1933 19.4462L16.6189 13.8718L11.0446 19.4462"
                                    stroke="black"
                                    strokeOpacity="0.4"
                                    strokeWidth="1.3936"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M16.619 13.8718V26.4142"
                                    stroke="black"
                                    strokeOpacity="0.4"
                                    strokeWidth="1.3936"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M28.3111 22.777C29.6704 22.036 30.7441 20.8635 31.3629 19.4444C31.9817 18.0254 32.1104 16.4407 31.7285 14.9404C31.3467 13.4401 30.4761 12.1098 29.2541 11.1592C28.0322 10.2087 26.5285 9.69219 24.9804 9.69116H23.2245C22.8027 8.0596 22.0165 6.54489 20.925 5.26091C19.8335 3.97693 18.4652 2.9571 16.9228 2.27808C15.3804 1.59906 13.7042 1.27852 12.0201 1.34057C10.3361 1.40262 8.68799 1.84563 7.19978 2.63631C5.71157 3.42699 4.42197 4.54475 3.42793 5.90557C2.4339 7.26639 1.76129 8.83485 1.46067 10.493C1.16006 12.1512 1.23927 13.856 1.69234 15.4791C2.14541 17.1023 2.96055 18.6016 4.07648 19.8644"
                                    stroke="black"
                                    strokeOpacity="0.4"
                                    strokeWidth="1.3936"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M22.1933 19.4462L16.6189 13.8718L11.0446 19.4462"
                                    stroke="black"
                                    strokeOpacity="0.4"
                                    strokeWidth="1.3936"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </span>
                              <div className="d-flex flex-column text-center">
                                <span>Select a file or drag and drop here</span>
                                <span>
                                  JPG, PNG or PDF, file size no more than 10MB
                                </span>
                              </div>
                              {selectedImage ? (
                                <div className="d-flex flex-column text-center">
                                  <h6 className="select-file">
                                    <a>SELECTED</a>
                                  </h6>
                                </div>
                              ) : (
                                <div className="d-flex flex-column text-center">
                                  <h6 className="select-file">
                                    <a>SELECT FILE</a>
                                  </h6>
                                </div>
                              )}
                            </label>
                          )}
                          {touched.attachments && errors.attachments && (
                            <div className="errorMessage text-center py-3">
                              <ErrorMessage name="attachments" />
                            </div>
                          )}
                          {initialValues?.attachments && !selectedImage && (
                            <div className="uploadList mx-auto">
                              <a
                                // href={initialValues?.attachments}
                                onClick={() =>
                                  sendKeyToAPI(initialValues?.attachments)
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Click here to download the attachment.
                                <span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="currentColor"
                                    className="bi bi-download"
                                    viewBox="0 0 16 16"
                                  >
                                    {' '}
                                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />{' '}
                                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />{' '}
                                  </svg>
                                </span>
                              </a>
                            </div>
                          )}
                        </div>
                        {selectedImage && !errors.attachments && (
                          <div className="uploadList position-relative mx-auto mt-4">
                            <div className="btn-actions position-absolute top-0 start-0 w-100 h-100">
                              <Button
                                id="remove-image-attachment-button"
                                onClick={() => removeImage(setFieldValue)}
                                className="imageremove"
                                variant="danger"
                              >
                                {/* <span className="CrossICon">X</span> */}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  fill="currentColor"
                                  className="bi bi-x"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                </svg>
                              </Button>
                            </div>
                            <div className="imgHolder">
                              <FilepreView selectedImage={selectedImage} />
                            </div>
                          </div>
                        )}
                      </Accordion>
                    </div>
                  </FormikForm>
                )}
              </Formik>
            </Container>
          </main>
        </Layout>
      </AuthenticatedTemplate>
    </>
  );
};

export default RmsForm;
