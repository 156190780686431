import React, { useContext } from 'react';

import { Button, Container } from 'react-bootstrap';
import { FiLogOut } from 'react-icons/fi';
import { AppContext } from '../context/appContext';

import img404 from '../assets/images/404-new.svg';
import logoMLBlack from '../assets/images/logoBlack.svg';

const NotFound = () => {
  const { logOut } = useContext(AppContext);

  return (
    <main className={`flex-grow-1 d-flex align-items-center main404`}>
      <div className="topbar404">
        <a href="/" className="position-relative">
          <img
            className="d-block"
            src={logoMLBlack}
            width={'150'}
            height={'34'}
            alt="mobileLive logo"
          />
        </a>
        <Button
          id="logout-button"
          style={{
            color: '#000',
            background: 'white',
            padding: '0',
            fontSize: '20px',
          }}
          className="border-0 d-flex align-items-center"
          onClick={logOut}
        >
          <FiLogOut className="me-2" />
          <span>Log out</span>
        </Button>
      </div>
      <Container className="d-flex flex-column p-0">
        <div className="content404 text-center">
          <figure className="mb-0 d-flex justify-content-center">
            <img src={img404} width={501} height={500} alt="404" />
          </figure>
          <strong>Oops !</strong>
          <h2>
            This Page does not exist or was removed! We suggest you try again a
            while.
          </h2>
        </div>
      </Container>
    </main>
  );
};

export default NotFound;
