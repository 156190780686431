import PropTypes from 'prop-types';
import Loader from '../../assets/images/loader.gif';

const LoaderComp = ({ load }) =>
  load ? (
    <div className="backdrop">
      <img alt="loading" src={Loader} width={50} height={50} />
    </div>
  ) : null;

LoaderComp.propTypes = {
  load: PropTypes.bool,
};
export default LoaderComp;
