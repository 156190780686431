/* eslint-disable no-unused-vars */
/* eslint-disable import/named */
import { createColumnHelper } from '@tanstack/react-table';
import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { DELETE_USER } from '../../services/routes/dashboard';
import AxiosInstance from '../../services/api';

const columnHelper = createColumnHelper();

export const COLUMNS = (getAllUsers) => {
  const columns = [
    columnHelper.accessor('email', {
      header: () => 'Email Address',
      type: () => 'number',
      enableColumnFilter: false,
      enableGlobalFilter: false,
    }),
    columnHelper.accessor((row) => `${row.firstName} ${row.lastName}`, {
      id: () => 'fullName',
      header: () => 'Name',
      type: () => 'string',
    }),
    columnHelper.accessor((row) => row.roles.map((role) => role.displayName), {
      id: () => 'role',
      header: () => 'Role',
      type: () => 'number',
      enableColumnFilter: false,
      enableGlobalFilter: false,
    }),
    columnHelper.accessor((row) => (row.title ? row.title : '------'), {
      header: () => 'Title',
      type: () => 'string',
      id: () => 'title',
    }),
    columnHelper.accessor(
      (row) => row.userClients.map((role) => role.client).join(', '),
      {
        header: () => 'Client',
        type: () => 'string',
        id: () => 'client',
      },
    ),
    columnHelper.accessor(
      (row) => (row.department ? row.department : '------'),
      {
        header: () => 'Department',
        type: () => 'string',
        id: () => 'department',
        enableColumnFilter: false,
        enableGlobalFilter: false,
      },
    ),
  ];

  // Pushing the action column accessor and its configuration
  columns.push(
    columnHelper.accessor(
      (row) => (row.id ? row.id : '------'),
      {
        id: () => 'id',
        header: () => 'Actions',
        enableSorting: false,
        className: 'deleteAction-class',
        cell: (info) => {
          const [showModal, setShowModal] = useState(false);
          const [deleteId, setDeleteId] = useState(null);
          const idx = info.getValue();
          const handleOpenModal = (id) => {
            setDeleteId(id);
            setShowModal(true);
          };

          const handleCloseModal = () => {
            setDeleteId(null);
            setShowModal(false);
          };

          const handleConfirmDelete = async () => {
            try {
              const url = DELETE_USER.replace(':id', deleteId);
              await AxiosInstance.delete(url);
              getAllUsers();
            } catch (error) {
              console.error(error);
            }
            handleCloseModal();
          };
          return (
            <>
              <Button
                onClick={(e) => {
                  e.stopPropagation(); // Stop event propagation to prevent tr onClick from being triggered
                  handleOpenModal(idx);
                }}
                className="mx-2 btn btn-danger"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 448 512"
                >
                  <path
                    fill="white"
                    d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"
                  />
                </svg>
                {/* <span className="mx-3">Delete</span> */}
              </Button>
              <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                  <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Are you sure you want to delete this User?
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    id="button-delete-cancel"
                    variant="secondary"
                    onClick={(e) => {
                      e.stopPropagation(); // Stop event propagation to prevent tr onClick from being triggered
                      handleCloseModal();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    id="button-delete-confirm"
                    variant="danger"
                    onClick={(e) => {
                      e.stopPropagation(); // Stop event propagation to prevent tr onClick from being triggered
                      handleConfirmDelete();
                    }}
                  >
                    Delete
                  </Button>
                </Modal.Footer>
              </Modal>
            </>
          );
        },
      },
      {
        asc: null, // Removing the sorting icon for action column
        desc: null, // Removing the sorting icon for action column
      },
    ),
  );

  return columns;
};
