/*eslint-disable*/

import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

const DepartmentCharts = ({ data, isAnimated }) => {
  // Prepare the chart data for each department
  const prepareChartData = (departmentData) =>
    Object.entries(departmentData)
      .map(([company, { onshore, offshore }]) => ({
        name: company,
        onshoreOpenings: onshore.openings,
        offshoreOpenings: offshore.openings,
      }))
      .filter(
        ({ onshoreOpenings, offshoreOpenings }) =>
          onshoreOpenings !== 0 || offshoreOpenings !== 0,
      );

  return (
    <div>
      {Object.entries(data).map(([department, departmentData]) => {
        const chartData = prepareChartData(departmentData);
        return (
          <div key={department} className="prfAccountData">
            <div className="d-flex flex-column">
              <h2>Client Location ({department})</h2>
            </div>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                width={500}
                height={326}
                data={chartData}
                barSize={20}
                margin={{ top: 20, right: 10, bottom: 60, left: -20 }}
              >
                <XAxis
                  dataKey="name"
                  angle={-45}
                  textAnchor="end"
                  interval={0}
                  height={60}
                />
                <YAxis />
                <Tooltip />
                <Bar
                  dataKey="onshoreOpenings"
                  fill="#413ea0"
                  name="Onshore Openings"
                  stackId="b"
                  isAnimationActive={isAnimated}
                />
                <Bar
                  dataKey="offshoreOpenings"
                  fill="#4ca64c"
                  name="Offshore Openings"
                  stackId="b"
                  isAnimationActive={isAnimated}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        );
      })}
    </div>
  );
};

export default DepartmentCharts;
