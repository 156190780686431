import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
// eslint-disable-next-line import/no-extraneous-dependencies
import PropTypes from 'prop-types';
import { Field, ErrorMessage } from 'formik';
import { propTypes } from 'react-bootstrap/esm/Image';

const PopUp = ({
  name,
  ButtonName,
  variant,
  commentHead,
  acceptPRF,
  className,
  img,
  disabled,
  SubmitButton,
  disabled1,
  buttonID,
  secondaryButtonID,
}) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Button
        id={buttonID}
        variant={variant}
        className={`${className} d-flex align-items-center`}
        onClick={handleShow}
        disabled={disabled1}
      >
        <span className="pe-1">{img}</span>
        <span>{ButtonName}</span>
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="modelHeader" closeButton>
          <Modal.Title>Add Comment</Modal.Title>
        </Modal.Header>
        <Form.Group
          className="modelPopup"
          controlId="exampleForm.ControlTextarea1"
        >
          <Form.Label>
            {commentHead}
            <span className="optional">*</span>
          </Form.Label>
          <Field
            as="textarea"
            name={name}
            autoFocus
            placeholder="Write a comment"
          />
          <div
            className="errorMessage mt-0 ms-3 mb-1"
            data-testid="error-message"
          >
            <ErrorMessage name={name} />
          </div>
        </Form.Group>
        <Modal.Footer>
          <Button
            id="cancel-button"
            variant="secondary"
            className="cancelBtn"
            onClick={handleClose}
          >
            Close
          </Button>
          <Button
            type="submit"
            variant={variant}
            disabled={disabled}
            onClick={acceptPRF}
            id={secondaryButtonID}
          >
            <span className="pe-1">{img}</span>
            <span>{SubmitButton}</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
PopUp.propTypes = {
  variant: PropTypes.string,
  name: PropTypes.string,
  ButtonName: PropTypes.string,
  commentHead: PropTypes.string,
  className: PropTypes.string,
  img: PropTypes.string,
  disabled: PropTypes.string,
  disabled1: PropTypes.string,
  SubmitButton: PropTypes.string,
  acceptPRF: PropTypes.any,
  buttonID: propTypes.string,
  secondaryButtonID: propTypes.string,
};

export default PopUp;
