import React from 'react';
import moment from 'moment';
import { useField, useFormikContext, ErrorMessage } from 'formik';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import dayjs from 'dayjs';
// import { date } from "yup";
import { getDay } from 'date-fns';
import PropTypes from 'prop-types';

const DateSelect = ({
  name,
  label,
  disabled,
  placeholder,
  min,
  Asterisk,
  handleInputChange,
}) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(name);
  const { validateField } = useFormikContext();
  const isWeekday = (date) => {
    const day = getDay(date);
    return day !== 0 && day !== 6;
  };
  return (
    <>
      <label className="detailLabel">
        {label}
        <span style={{ color: 'red' }}>{Asterisk}</span>
      </label>
      <DatePicker
        {...field}
        minDate={min}
        filterDate={isWeekday}
        // excludeDateIntervals={[{start: subDays(new Date(), 6), end: addDays(new Date(), 6) }]}
        disabled={disabled}
        placeholderText={placeholder}
        value={field.value && dayjs(field.value).format('DD/MM/YYYY')}
        className="form-control"
        name={name}
        selected={field.value && new Date(field.value) && null}
        onChange={(val) => {
          validateField(name);
          setFieldValue(name, moment(val).format('YYYY-MM-DD'));
          if (handleInputChange) {
            handleInputChange({
              target: { name, value: moment(val).format('YYYY-MM-DD') },
            });
          }
        }}
      />
      <div className="errorMessage">
        <ErrorMessage name={name} />
      </div>
    </>
  );
};
DateSelect.propTypes = {
  // Indicates the DateSelect is disabled
  disabled: PropTypes.bool,
  // The label to display for the DateSelect.
  label: PropTypes.string,
  // The name attribute for the DateSelect input.
  name: PropTypes.string,
  // The placeholder text to display in the DateSelect input.
  placeholder: PropTypes.string,
  // The minimum allowed value for the DateSelect (if applicable).
  min: PropTypes.number,
  // An asterisk symbol to indicate a required field (if applicable).
  Asterisk: PropTypes.symbol,
  handleInputChange: PropTypes.symbol,
};
export default DateSelect;
