import { createColumnHelper } from '@tanstack/react-table';
import { useState, useContext } from 'react';
import { pdf } from '@react-pdf/renderer';
import { toast } from 'react-toastify';
import { FaCopy } from 'react-icons/fa';
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import {
  DELETE_RESUME,
  GET_RESUME_BY_ID,
  LINK_RESUME,
} from '../../services/routes/prf';
import AxiosInstance from '../../services/api';
import { MyDocument } from '../../utils/constants';
import { AppContext } from '../../context/appContext';
import { GET_USER_ROLES } from '../helper/constant';
import Menu from '../../assets/images/menu.svg';

const columnHelper = createColumnHelper();

export const COLUMNS = (setEditID, getResumes) => {
  const { TALENT, SUPER_USER } = GET_USER_ROLES;
  const { getRole } = useContext(AppContext);
  const isSuperUser = getRole() === SUPER_USER;
  const isTalent = getRole() === TALENT;

  const columns = [
    columnHelper.accessor(
      (row) => {
        const firstName = row.firstName ? row.firstName : '------';
        const lastName = row.lastName ? row.lastName : '------';
        return `${firstName} ${lastName}`;
      },
      {
        header: () => 'Full Name',
        type: () => 'string',
        id: () => 'fullName',
        className: 'fullName-class', // Define class for column 1
      },
    ),

    columnHelper.accessor((row) => (row.jobTitle ? row.jobTitle : '------'), {
      header: () => 'Job Title',
      type: () => 'string',
      id: () => 'jobTitle',
      className: 'jobTitle-class', // Define class for column 1
    }),
    columnHelper.accessor((row) => (row.phone ? row.phone : '------'), {
      header: () => 'Phone',
      type: () => 'string',
      id: () => 'phone',
      className: 'phone-class', // Define class for column 1
    }),
    columnHelper.accessor(
      (row) => (row && row.overallSummary ? row.overallSummary : '------'),
      {
        header: () => 'Summary',
        type: () => 'string',
        id: () => 'overallSummary',
        className: 'overallSummary-class',
        enableSorting: false, // Disable sorting for the Summary column
        cell: (info) => {
          const summary = info.row.original.overallSummary
            ? info.row.original.overallSummary
            : '------';

          const handleCopySummary = () => {
            navigator.clipboard.writeText(summary);
            toast.success('Summary copied to clipboard');
          };

          // Function to render the tooltip
          const renderTooltip = (props) => (
            <Tooltip id="button-tooltip" {...props} className="custom-tooltip">
              {summary}
            </Tooltip>
          );

          return (
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip}
            >
              <div className="overallSummary">
                <span className="summarySection">{summary}</span>
                {info.row.original.overallSummary && (
                  <button
                    size="sm"
                    className="ml-2 copyBtn mx-1"
                    id="button-resume-edit"
                    onClick={handleCopySummary}
                  >
                    <FaCopy className="copy-icon" />
                  </button>
                )}
              </div>
            </OverlayTrigger>
          );
        },
      },
      {
        // Render nothing for sorting icons
        asc: null,
        desc: null,
      },
    ),
    columnHelper.accessor(
      (row) => {
        if (!row.createdAt) return '------';

        // Parse the date string
        const date = new Date(row.createdAt);

        // Create a date formatter (you can adjust 'en-US' to your locale and the options as needed)
        const formatter = new Intl.DateTimeFormat('en-US', {
          year: 'numeric',
          month: 'long',
          day: '2-digit',
        });

        // Format the date
        return formatter.format(date);
      },
      {
        header: () => 'Created Date',
        type: () => 'string',
        id: () => 'createdAt',
        className: 'createdat-class', // Define class for column 1
      },
    ),
  ];

  if (isSuperUser || isTalent) {
    columns.push(
      columnHelper.accessor(
        (row) => {
          const firstName = row.userFirstName ? row.userFirstName : '------';
          const lastName = row.userLastName ? row.userLastName : '------';
          return `${firstName} ${lastName}`;
        },
        {
          header: () => 'Uploaded By',
          type: () => 'string',
          id: () => 'createdBy',
          enableSorting: true, // Enable sorting
          className: 'createdBy-class', // Define class for column 1
        },
      ),
    );
  }
  columns.push(
    columnHelper.accessor((row) => (row.id ? row.id : '------'), {
      id: () => 'id',
      header: () => 'Actions',
      enableSorting: false,
      className: 'actions-class', // Define class for column 1
      cell: (info) => {
        const [showModal, setShowModal] = useState(false);
        const [deleteId, setDeleteId] = useState(null);
        const idx = info.getValue();
        const key = info?.row?.original?.key;

        const handleOpenModal = (id) => {
          setDeleteId(id);
          setShowModal(true);
        };

        const handleCloseModal = () => {
          setDeleteId(null);
          setShowModal(false);
        };

        const handleConfirmDelete = async () => {
          try {
            const url = DELETE_RESUME.replace(':id', deleteId);
            await AxiosInstance.delete(url);
            getResumes();
          } catch (error) {
            console.error(error);
          }
          handleCloseModal();
        };

        const handleDownload = async (formData, toastId) => {
          try {
            const blob = await pdf(<MyDocument formData={formData} />).toBlob();
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `${formData.firstName}_${formData.lastName}.pdf`;
            link.click();
            toast.update(toastId, {
              type: 'success',
              isLoading: false,
              autoClose: 5000,
            });
          } catch (error) {
            toast.update(toastId, {
              render: `Error uploading resume: ${
                error.response?.data?.message || error.message
              }`,
              type: 'error',
              isLoading: false,
              autoClose: 5000,
            });
            console.error('Error generating PDF:', error);
          }
        };

        const downloadID = async (id) => {
          try {
            const toastId = toast.loading('Downloading Resume...');
            const url = GET_RESUME_BY_ID.replace(':id', id);
            const result = await AxiosInstance.get(url);
            const { data } = result;
            const payload = {
              id: data.id,
              firstName: data.firstName,
              lastName: data.lastName,
              jobTitle: data.jobTitle,
              location: data.location,
              phone: data.phone,
              email: data.email,
              skills: data.resumeSkills.map((skill) => skill.skill),
              certifications: data.certifications.map(
                (certification) => certification.certification,
              ),
              workExperience: data.resumeExperiences.map((experience) => ({
                experience: experience.experience,
                duration: experience.duration,
                location: experience.location,
                summary: experience.summary,
              })),
              qualifications: data.qualifications.map((education) => ({
                education: education.education,
                institution: education.institution,
                duration: education.duration,
              })),
            };
            handleDownload(payload, toastId);
            getResumes();
          } catch (error) {
            console.error(error);
          }
        };

        // eslint-disable-next-line no-shadow
        const sendKeyToAPI = async (key) => {
          try {
            const response = await AxiosInstance.post(LINK_RESUME, { key });
            const url = response.data;

            if (url) {
              window.open(url);
            } else {
              console.error('Error: URL is empty or undefined');
            }
          } catch (error) {
            console.error('Error:', error);
          }
        };

        return (
          <div className="d-flex">
            <Button
              id="button-resume-edit"
              variant="primary"
              className="mx-1"
              onClick={() => {
                setEditID(idx);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 512 512"
              >
                <path
                  fill="white"
                  d="M441 58.9L453.1 71c9.4 9.4 9.4 24.6 0 33.9L424 134.1 377.9 88 407 58.9c9.4-9.4 24.6-9.4 33.9 0zM209.8 256.2L344 121.9 390.1 168 255.8 302.2c-2.9 2.9-6.5 5-10.4 6.1l-58.5 16.7 16.7-58.5c1.1-3.9 3.2-7.5 6.1-10.4zM373.1 25L175.8 222.2c-8.7 8.7-15 19.4-18.3 31.1l-28.6 100c-2.4 8.4-.1 17.4 6.1 23.6s15.2 8.5 23.6 6.1l100-28.6c11.8-3.4 22.5-9.7 31.1-18.3L487 138.9c28.1-28.1 28.1-73.7 0-101.8L474.9 25C446.8-3.1 401.2-3.1 373.1 25zM88 64C39.4 64 0 103.4 0 152V424c0 48.6 39.4 88 88 88H360c48.6 0 88-39.4 88-88V312c0-13.3-10.7-24-24-24s-24 10.7-24 24V424c0 22.1-17.9 40-40 40H88c-22.1 0-40-17.9-40-40V152c0-22.1 17.9-40 40-40H200c13.3 0 24-10.7 24-24s-10.7-24-24-24H88z"
                />
              </svg>
            </Button>
            <Button
              className="mx-1"
              id="button-resume-download"
              variant="success"
              onClick={() => {
                downloadID(idx);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 512 512"
              >
                <path
                  fill="white"
                  d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z"
                />
              </svg>
            </Button>
            <Dropdown drop="down">
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
                className="mx-1"
              >
                <img
                  src={Menu}
                  alt="Dropdown Toggle"
                  className="dropdown-toggle mx-1"
                />
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdownmenu">
                <Dropdown.Item>
                  <span onClick={() => sendKeyToAPI(key)}>
                    <img
                      width="24"
                      height="24"
                      src="https://img.icons8.com/material-rounded/24/link--v1.png"
                      alt="link--v1"
                    />
                    <span className="mx-1">Download original resume</span>
                  </span>
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item>
                  <div className="dropdown-content">
                    <span onClick={() => handleOpenModal(idx)} className="mx-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 448 512"
                      >
                        <path
                          fill="black"
                          d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"
                        />
                      </svg>
                      <span className="mx-3">Delete</span>
                    </span>
                    <Modal show={showModal} onHide={handleCloseModal}>
                      <Modal.Header closeButton>
                        <Modal.Title>Confirm Deletion</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        Are you sure you want to delete this Resume?
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          id="button-delete-cancel"
                          variant="secondary"
                          onClick={handleCloseModal}
                        >
                          Cancel
                        </Button>
                        <Button
                          id="button-delete-confirm"
                          variant="danger"
                          onClick={handleConfirmDelete}
                        >
                          Delete
                        </Button>
                      </Modal.Footer>
                    </Modal>
                    {/* Add your modal and other dropdown items here */}
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        );
      },
    }),
  );

  return columns;
};
