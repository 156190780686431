import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { Field, ErrorMessage } from 'formik';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';
import { propTypes } from 'react-bootstrap/esm/Image';

const CloseButtonPRF = ({
  role,
  onClick,
  locked,
  status,
  name,
  disabled,
  buttonID,
  secondaryButtonID,
}) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div>
      <span>
        {role && locked && status && (
          <Button
            id={buttonID}
            onClick={handleShow}
            variant="danger"
            className="d-flex align-items-center justify-content-center btn-createPrf"
          >
            <span className="me-2">
              <svg
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.66634 1.27398L8.72634 0.333984L4.99967 4.06065L1.27301 0.333984L0.333008 1.27398L4.05967 5.00065L0.333008 8.72732L1.27301 9.66732L4.99967 5.94065L8.72634 9.66732L9.66634 8.72732L5.93967 5.00065L9.66634 1.27398Z"
                  fill="white"
                />
              </svg>
            </span>
            <span>Request Closure</span>
          </Button>
        )}
        <Modal show={show} onHide={handleClose}>
          <Modal.Header className="modelHeader" closeButton>
            <Modal.Title>Add Comment</Modal.Title>
          </Modal.Header>
          <Form.Group
            className="modelPopup"
            controlId="exampleForm.ControlTextarea1"
          >
            <Form.Label>
              Reason for Close-PRF<span className="optional">*</span>
            </Form.Label>
            <Field
              as="textarea"
              name={name}
              autoFocus
              placeholder="Write a comment"
            />
            <div
              className="errorMessage mt-0 ms-3 mb-1"
              data-testid="error-message"
            >
              <ErrorMessage name={name} />
            </div>
          </Form.Group>
          <Modal.Footer>
            <Button
              id="cancel-button"
              variant="secondary"
              className="cancelBtn"
              onClick={handleClose}
            >
              Close
            </Button>
            <Button
              type="submit"
              disabled={disabled}
              onClick={onClick}
              variant="danger"
              id={secondaryButtonID}
              className="d-flex align-items-center justify-content-center ms-2 btn-createPrf"
            >
              <span className="me-2">
                <svg
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.66634 1.27398L8.72634 0.333984L4.99967 4.06065L1.27301 0.333984L0.333008 1.27398L4.05967 5.00065L0.333008 8.72732L1.27301 9.66732L4.99967 5.94065L8.72634 9.66732L9.66634 8.72732L5.93967 5.00065L9.66634 1.27398Z"
                    fill="white"
                  />
                </svg>
              </span>
              <span>Request Closure</span>
            </Button>
          </Modal.Footer>
        </Modal>
      </span>
    </div>
  );
};
CloseButtonPRF.propTypes = {
  // It appears to be used to determine the role of the user (talent or non-talent)
  role: PropTypes.bool,
  // The onclick to call the api to closePrf
  onClick: PropTypes.func,
  // They are show to this is in status or not
  status: PropTypes.number,
  // The name attribute for the textArea input.
  name: PropTypes.string,
  // Indicates the ClosePrf Button is disabled
  disabled: PropTypes.bool,
  locked: PropTypes.bool,
  buttonID: propTypes.string,
  secondaryButtonID: propTypes.string,
};
export default CloseButtonPRF;
