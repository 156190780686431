import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Details from './requestDetail';
import AxiosInstance from '../services/api';
import { GET_PRF_DATA } from '../services/routes/prf';
import Loader from '../assets/images/loader.gif';
import { AppContext } from '../context/appContext';

const PrfsDetails = () => {
  const { profile } = useContext(AppContext);
  const [prfs, setPrfs] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      AxiosInstance.get(GET_PRF_DATA + id).then((response) => {
        const data = response?.data;
        setPrfs(data);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    /**/
  }, [profile?.token]);

  if (loading) {
    return (
      <div className="backdrop">
        <img src={Loader} width={50} height={50} />
      </div>
    );
  }
  return <Details response={prfs} />;
};
export default PrfsDetails;
