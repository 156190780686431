import React from 'react';
import PropTypes from 'prop-types';
import microsoftLogoImg from '../../assets/images/microsoftLogo.png';

const MicrosoftLoginButton = ({ buttonTheme, buttonClassName, onClick }) => {
  const [, short] = buttonTheme.split('_');
  const styleObj = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    gap: '1rem',
    padding: '0.5rem',
    // borderRadius: '0.5rem',
    background: 'white',
  };

  if (short) {
    return (
      <button
        style={styleObj}
        className={buttonClassName}
        onClick={onClick}
        id="signIn-button"
      >
        <img src={microsoftLogoImg} alt="microsoft logo" />
        <span>Sign in with Microsoft</span>
      </button>
    );
  }
  return (
    <button
      style={styleObj}
      className={buttonClassName}
      onClick={onClick}
      id="signIn-button"
    >
      <img src={microsoftLogoImg} alt="microsoft logo" />
      <span>Sign in with Microsoft</span>
    </button>
  );
};

MicrosoftLoginButton.propTypes = {
  // The Proptypes for Debounced Input
  buttonTheme: PropTypes.string,
  buttonClassName: PropTypes.string,
  onClick: PropTypes.any,
};

export default MicrosoftLoginButton;
