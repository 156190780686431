import React, { useState, useEffect, lazy, useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import { AppContext } from '../../context/appContext';
import { GET_USER_ROLES } from '../helper/constant';
import Loader from '../../assets/images/loader.gif';
import DepartmentEngagementTypeCount from './departmentEngagementType';
import DepartmentSeniorityTypeCount from './departmentSeniorityType';
import InternalHireChart from './internalHireChart';
import RejectedChart from './rejectedChart';
import ClosedChart from './closedChart';

const { SUPER_USER, ACCOUNT_MANAGER } = GET_USER_ROLES;

// Lazy-loaded components
const ActivePrfChart = lazy(() => import('./activePrfChart'));
const DepartmentChart = lazy(() => import('./departmentChart'));
const LocationChart = lazy(() => import('./locationChart'));
const DepartmentRoleChart = lazy(() => import('./activePrfDepartmentChart'));
const DepartmentLocationChart = lazy(() => import('./departmentLocationChart'));
const SubmissionDataClient = lazy(() => import('./talentChart'));
const SubmissionDataDeparment = lazy(() => import('./talentChartDepartment'));

const UserStats = () => {
  const { getRole } = React.useContext(AppContext);
  const isSuperUser = getRole() === SUPER_USER;
  const isAccountManager = getRole() === ACCOUNT_MANAGER;
  const [loading, setLoading] = useState(true);
  const [getData, setGetData] = useState([]);
  const { fetchPrfChartData } = useContext(AppContext);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchPrfChartData();
      setGetData(data);
      setLoading(false);
    };

    fetchData();
  }, []);

  // Your component structure remains the same from here

  console.log('getData', getData);

  if (loading) {
    return (
      <div className="backdrop">
        <img src={Loader} width={50} height={50} alt="loading" />
      </div>
    );
  }

  return (
    <div className="d-flex flex-row gap-5 flex-wrap">
      {(isAccountManager || isSuperUser) && (
        <div className="d-flex flex-column">
          <ActivePrfChart
            data={getData?.accountManagerData?.clientPrfCounts || {}}
            isAnimated={getData?.isAnimated}
          />
          <DepartmentChart
            data={getData?.accountManagerData?.clientDepartmentCounts || {}}
            isAnimated={getData?.isAnimated}
          />
          <LocationChart
            data={getData || {}}
            isAnimated={getData?.isAnimated}
          />
          <InternalHireChart
            data={getData?.accountManagerData?.internalHiresInProgress || {}}
            isAnimated={getData?.isAnimated}
          />
          {isAccountManager && (
            <>
              <RejectedChart
                data={getData?.accountManagerData?.rejectedPrfs || {}}
                isAnimated={getData?.isAnimated}
              />
              <ClosedChart
                data={getData?.accountManagerData?.closedPrfs || {}}
                isAnimated={getData?.isAnimated}
              />
            </>
          )}
          {!isAccountManager && (
            <>
              <SubmissionDataClient
                data={
                  getData?.talentTeamData?.talentTeamSubmissionDataPerClient
                }
                isAnimated={getData?.isAnimated}
              />
              <SubmissionDataDeparment
                data={
                  getData?.talentTeamData
                    ?.talentTeamSubmissionDataPerDepartment || {}
                }
                isAnimated={getData?.isAnimated}
              />
            </>
          )}

          <Row>
            <Col xs={12} xl={6} xxl={6} className="mb-2 mb-xxl-0">
              <DepartmentRoleChart
                data={getData?.departmentHeadData?.departmentClientCount || {}}
                isAnimated={getData?.isAnimated}
              />
            </Col>

            <Col xs={12} xl={6} xxl={6}>
              <DepartmentLocationChart
                data={
                  getData?.departmentHeadData?.departmentClientLocationCounts ||
                  {}
                }
                isAnimated={getData?.isAnimated}
              />
            </Col>

            <Col xs={12} xl={6} xxl={6}>
              <DepartmentEngagementTypeCount
                data={
                  getData?.departmentHeadData?.departmentEngagementTypeCounts ||
                  {}
                }
                isAnimated={getData?.isAnimated}
              />
            </Col>

            <Col xs={12} xl={6} xxl={6}>
              <DepartmentSeniorityTypeCount
                data={
                  getData?.departmentHeadData?.departmentSeniorityTypeCounts ||
                  {}
                }
                isAnimated={getData?.isAnimated}
              />
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default UserStats;
