import React from 'react';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';
import myImage from '../../assets/images/catsone.png';

const Catsone = ({ className, disabled1, buttonID, onClick }) => (
  <>
    <Button
      id={buttonID}
      className={`${className} d-flex align-items-center text-white border-0 hover-button`}
      disabled={disabled1}
      onClick={onClick}
    >
      <span className="pe-2">
        <img src={myImage} height={20} width={20} alt="Icon" />
      </span>
      <span>Import to CATS</span>
    </Button>
  </>
);
Catsone.propTypes = {
  variant: PropTypes.string,
  name: PropTypes.string,
  ButtonName: PropTypes.string,
  commentHead: PropTypes.string,
  className: PropTypes.string,
  img: PropTypes.string,
  disabled: PropTypes.string,
  disabled1: PropTypes.string,
  SubmitButton: PropTypes.string,
  acceptPRF: PropTypes.any,
  buttonID: PropTypes.string,
  onClick: PropTypes.func,
};

export default Catsone;
